import React, { Component } from 'react';
import { connect } from 'react-redux';


class ListCreator extends Component {

  constructor(props) {
    super(props);
    this.state = { data: props.data, new: {}, id_event: props.id_event }; 
  }

  
  // ############################ RENDER ###################################
  // ############################ RENDER ###################################
  render() {

        const { styleList, type, columns } = this.props;
        // console.log('LISTCREATOR RENDER => ', this.state.data);
        if(this.state.id_event !== this.props.id_event){
          this.setState({id_event:this.props.id_event, data: this.props.data});
        }

        return (
          <div className={styleList.container}>
            {/* DATA INSERTED */}
            {Object.keys(this.state.data).map((line,index)=>{
            return(  
              <div key={index} className={styleList.line}>
                {Object.keys(columns).map((column,index2)=>{
                return(  
                  <div key={index2} className={styleList.column}>
                    <div className={styleList.label}>
                      {columns[column]}
                    </div>
                    <div key={index2} className={styleList.text} >
                      {this.state.data[line][columns[column]]}
                    </div>
                  </div>
                );
                })}
                {/* DELETE LINE */}
                  {type !== 'display' && <div className={styleList.columnIcon + " "} onClick={()=>{this.deleteLine(index)}}>
                    <i className={styleList.icon}></i>
                  </div>}
              </div>
            );
            })}
            
            {type !== 'display' &&
              <div className={styleList.lineInput}>
                  {Object.keys(columns).map((column,index3)=>{
                  return(
                    <div key={index3} className={styleList.columnInput}>
                      <div key={index3} className={styleList.text} >
                        <input key={index3} placeholder={columns[column]} className={styleList.input} value={this.state.new[columns[column]]} onChange={(e) => this.handleChange(columns[column], e.target.value)}></input>
                      </div>
                    </div>
                    );
                  })}
                  {/* ADD LINE */}
                  <button className={styleList.columnIcon  + " btn-light BCTrans"} disabled={this.state.new[columns[0]] == null || this.state.new[columns[1]] == null} onClick={()=>{this.addLine()}}>
                        <i className={styleList.iconAdd}></i>
                  </button>
              </div>}
          
          </div>       
        );
        
  }

  //############################# HANDLE CHANGE #############################
  //############################# HANDLE CHANGE #############################
  handleChange = (field, e) => {
    let temp = this.state.new;
    temp[field] = e;
    this.setState({new: temp});
    // console.log('CHANGE => ', this.state.new);
  }
  
  // ############################ ADD LINE ###################################
  // ############################ ADD LINE ###################################
  addLine() {
    let temp = this.state;
    temp.data.push(this.state.new);
    temp['new'] = {item: '', offer: ''};
    // console.log('ADDLINE => ', temp);
    this.setState({temp});
    this.props.handleData(temp.data)
    // console.log('ADDED 1 => ', this.state);
  }

  // ############################ DELETE LINE ###################################
  // ############################ DELETE LINE ###################################
  deleteLine(index) {
    let temp = this.state.data;
    delete temp[index];
    // console.log('DELETE LINE => ', temp);
    this.setState({data: temp});
  }
  
}

export default connect(null, null)(ListCreator);

