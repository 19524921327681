import React, { Component } from 'react';
import { Tab, Nav  } from 'react-bootstrap';
import Event from './event';
import BusinessProfile from './businessProfile';
import * as design from "../../resources/constants/design";

export default class Ad extends Component {  
  constructor(props) {
    super(props);
    this.state = {data: props.data};
  }

  // ############################ RENDER ###################################
  // ############################ RENDER ###################################
  setKey = (k) => {
    this.setState({key: k});
  }
  
  // ############################ RENDER ###################################
  // ############################ RENDER ###################################
  render() {
    
    // console.log('########## AD => ', this.props.data);
    return (
        <div className={"BRL z30 BCGray1 BottomShadow7 BorderRadius2 Padding0px"} style={{width: '100%', height: '100%', overflow: 'auto' }}>
          
          <Tab.Container 
            id="ad-details" 
            defaultActiveKey={(this.props.data['id_event'] ? "event" : "spot")} 
            className="BRL Wide100" 
            style={{height:'100%', display:'flex', flexDirection: 'column'}}>
            
            <div 
                className="BRL Wide100 BottomShadow7 BCGray1 BottomPadding10px" 
                style={{maxHeight:'600px',overflow:'hidden'}}>
              <Tab.Content className="BottomShadow7" style={{maxHeight:'800px'}}>
                    
                  {this.props.data['id_event'] && 
                    <Tab.Pane eventKey="event">
                      <Event data={this.props.data} style={design.EVENT}></Event>
                    </Tab.Pane>}

                    <Tab.Pane eventKey="spot">
                      <BusinessProfile data={this.props.data.business} style={design.BUSINESS}></BusinessProfile>
                    </Tab.Pane>
                  </Tab.Content>
              </div>
            
            <div className="BRL Wide100 BCWhite"  style={{flex: 1}}>
              <Nav variant="pills" className="BRL Wide100 flex-column">
                  {this.props.data['id_event'] && <Nav.Item className="BRL Wide50 CenterAlign">
                    <Nav.Link eventKey="event" className="HeaderFont FCGray FS40 Padding10px Capital ">{this.props.data.type}</Nav.Link>
                  </Nav.Item>}

                  <Nav.Item className="BRL Wide50 CenterAlign">
                    <Nav.Link eventKey="spot" className="HeaderFont FCGray FS40 Padding10px Capital ">{this.props.data.business.companyname}</Nav.Link>
                  </Nav.Item>
                </Nav>
            </div>
            </Tab.Container>
        </div>  
      );

  }

  
}

