export const MAPBOX_TOKEN = "pk.eyJ1IjoiZmxvcmVudG4iLCJhIjoiY2thaWVnbDBpMHBmNDJ3bnY5ZHlxcG9kbiJ9.XfUFXCPBFIaxgPjcILCVPQ";

// export const URL_SERVER_DJANGO = "http://localhost:8000";
// export const URL_SERVER_DJANGO = "https://strangers-m3fj8.ondigitalocean.app";
export const URL_SERVER_DJANGO = "https://server.strangersapp.cc";
export const URL_SERVER_DJANGO_S3 = "https://strangers-business-profile.ams3.digitaloceanspaces.com";
export const URL_SERVER_DJANGO_STRUCTURE = "https://strangers-business-profile.ams3.digitaloceanspaces.com/strangers/media/design/structure";
export const URL_SERVER_DJANGO_STRUCTURE_RELATIVE = "/media/design/structure";
export const URL_SERVER_DJANGO_IMAGE = "https://strangers-business-profile.ams3.digitaloceanspaces.com/strangers";
export const URL_SERVER_DJANGO_IMAGE_RELATIVE = "/media/design/images";
export const URL_SERVER_DJANGO_VIDEO = "https://strangers-business-profile.ams3.digitaloceanspaces.com/strangers";
export const URL_SERVER_DJANGO_VIDEO_RELATIVE = "/media/design/videos";
export const URL_MEDIA_BUSINESS = " https://strangers-business-profile.ams3.digitaloceanspaces.com/strangers/media/business";
export const URL_MEDIA_BUSINESS_RELATIVE = "/strangers/media/business";

export const URL_SERVER_VECTOR = "mapbox://nickitaliano.cj94go8xl18fl2qp92v8bdivv-4kgl9";
export const PAYPAL_DETAILS = {environment: "production", idClient:{
    sandbox:'ATTR2Kwbz30FA9we4bwqrWgnO7UwuKRTkNIRWCl487_lBVUNekuZN1KAMM6VNi9R6vwGBPM6duB-S3ob', 
    production:'ATTR2Kwbz30FA9we4bwqrWgnO7UwuKRTkNIRWCl487_lBVUNekuZN1KAMM6VNi9R6vwGBPM6duB-S3ob'},
    style: {size: 'medium', color: 'blue', shape: 'rect', label: 'checkout'}
};
