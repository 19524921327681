import React from "react";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Chart from './chart';
import Input from '../elements/input';
import { getBusinessActivity, getUsersActivity, processData} from "../services/monitor";
import * as constants from "../../resources/constants/design";
import { Component } from "react";
import MoonLoader from "react-spinners/ClipLoader";
import { formatDate } from "../utils/tools"

const chartWidgets = [
  {'label': 'userActivity', 'type': 'bar', 'key': 'users', 'aggregation': 'user-trends', 'title': 'User Activity' },
  {'label': 'businessActivity', 'type': 'bar', 'key': 'business', 'aggregation': 'business-trends', 'title': 'Business Activity'},
];

class Monitor extends Component {
  constructor(props) {
    super(props);
    this.state = {rawData: null, chartData: null, loading: true, refreshing: true};
    this.startDate = formatDate(new Date(new Date() - (1000 * 60 * 60 * 24 * 2)), 'yyyy-mm-ddL');
    this.endDate = formatDate(new Date(), 'yyyy-mm-ddL');
  }

  async componentDidMount(){
    await this.loadData();
  }

  // ################################### LOAD DATA FROM SERVER ###################################
  // ################################### LOAD DATA FROM SERVER ###################################
  loadData = async () => {
    // let data = {range:[new Date(new Date() - (1000 * 60 * 60 * 24 * 2)).toUTCString(),'Live']};
    this.endDate2 = new Date(this.endDate).toUTCString();
    if (JSON.stringify(new Date(this.endDate)) === JSON.stringify(new Date(formatDate(new Date(), "yyyy-mm-ddL")))){ this.endDate2 = 'Live'; }
    let data = {range:[new Date(this.startDate).toUTCString(), this.endDate2]};
    let temp = {};
    temp['business'] = await getBusinessActivity(data);
    temp['business'] = temp['business']['data'];
    // console.log('MONITOR 1 => ', temp['business']);
    temp['users'] = await getUsersActivity(data);
    temp['users'] = temp['users']['data'];
    console.log('MONITOR 2 => ', temp['users']);
    await this.processData(temp);
  } 


  // ################################### PROCESS DATA FOR CHARTS ###################################
  // ################################### PROCESS DATA FOR CHARTS ###################################
  processData = async raw => {
    const data = await processData(raw, chartWidgets);
    // console.log('MONITOR 3 => ', data);
    this.setState({rawData: raw, chartData: data, refreshing: false, loading: false});
  } 


  // ################################### REFRESH DATA ###################################
  // ################################### REFRESH DATA ###################################
  refreshData = async () => {
    this.setState({refreshing: true});
    await this.loadData();
  } 



  // ############################ RENDER ###################################
  // ############################ RENDER ###################################
  render(){
    // if(this.state.refreshing === false){
      return (
        <div className="BRL Wide100" style={{minHeight: "90vh"}}>
            {this.state.loading === true && <div className={"Absolute Top0 Left0 Flex FlexCol JustifyCenter AlignCenter"} style={{height:"100vh", width:"100%", zIndex:100, backgroundColor: "rgba(240,240,240,0.5)"}}>
                <div className={""}><FontAwesomeIcon className={"FS10 Spin"} icon={"compass"} color="#ad182f" /></div>
            </div>}

            {/* ACTION MENU */}
            <div key={'action'} className="BRL Wide100 Flex AlignCenter JustifyCenter">
              <Input key={'start'} onChange={(type, field, e) => {this.handleChange(field,'startDate', e) }} value={this.startDate} config={constants.MONITOR.date} />
              <Input key={'end'} onChange={(type, field, e) => {this.handleChange(field, 'endDate', e);}} value={this.endDate} config={constants.MONITOR.date}  />
      
              <div className="BRL Wide30 ">
                <div className="BRL FS41 LabelFont FCBlack BottomMargin5px">action</div>
                <button key={ 'b_refresh'} onClick={() => { this.refreshData() }} className={"BRL Wide100 FS40 ButtonFont btn HPadding0px " + (!this.state.refreshing ? 'btn-primary' : 'btn-danger')} style={{padding:"10px"}}>
                    {this.state.refreshing === false && 
                      <div className="BRL Wide100 Flex AlignCenter JustifyCenter">
                          <div className="BRL Wide80 CenterAlign">Refresh</div>
                            <i className="fa fa-refresh"></i>
                      </div>
                    }
                    {this.state.refreshing === true && 
                    <div className="BRL Wide100 Flex AlignCenter JustifyCenter">
                        <div className="BRL Wide80 CenterAlign">Refreshing</div>
                        <MoonLoader size={17} color={"#ffffff"} loading={true}/>
                    </div>}
                </button>
              </div>
            </div>
            
            {/* CHARTS */}
            <div key={'charts'} className="BRL Wide100 ">
              {this.state.chartData && this.state.refreshing === false ?
                Object.keys(chartWidgets).map((type, index)=>{
                  // console.log('---> ', chartWidgets[type], ' => ', this.state.chartData[chartWidgets[type]['key']]);
                  return (
                    <div key={"div" + index} className="BRL Wide100" style={{padding: "20px"}}>
                      <Chart key={"chart" + index} endDate={this.endDate2} data={this.state.chartData[chartWidgets[type]['key']]} config={chartWidgets[type]}/>
                    </div>
                    );}) :null}
            </div>
        </div>
      );
  }

  //############################# HANDLE CHANGE #############################
  //############################# HANDLE CHANGE #############################
  handleChange = (field, type,e) => {
    
    switch(field){
      case "date": this[type] = e; break;
      default: break;
    }
  }
}

export default withRouter(Monitor);