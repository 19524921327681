import React, { Component } from 'react'
import ListApp from './list'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux';
import * as actions from '../../store/pseudo/general';
import { withRouter } from 'react-router-dom';

// export default class SearchBox extends Component {
class SearchBox extends Component {  
  
  // ############################ CONSTRUCTOR ###################################
  // ############################ CONSTRUCTOR ###################################
  constructor(props) {
    super(props);
    this.state = { value: '', selectedItem: 0 };
  }
  
  
  // ############################ CONSTRUCTOR ###################################
  // ############################ CONSTRUCTOR ###################################
  render() {

    const { suggestions, onSubmit, onFocus, boxStyle, loading} = this.props;

    return (
      <div className={"BRL " + boxStyle}>
            {/* {loading && <div style={{width: '100%', height: '100%', zIndex:99999, backgroundColor:'rgba(40,40,40,0.01)'}} className={"Absolute Top0px Left0px"}></div>} */}
            <div style={{'textDecoration': 'none'}} className={"Absolute Top0px Left5px FS37 VPadding7px HPadding5px Hand z1000"} onClick={() => {this.props.sideMenuFunction(!this.props.sideMenu)}}><FontAwesomeIcon icon={"bars"} color="#ad182f" /></div>
            <input className={"BRL Wide100 NoBorder VPadding12px LeftPadding40px RightPadding60px NoOutline FS40 HeaderFont Capital BorderRadius5 BottomShadow7"} 
              type="text" 
              value= {this.state.value} 
              placeholder="SEARCH A PLACE"
              onChange={(e) => {this.setSearchText(e.target.value);this.props.onChange(e.target.value);}} 
              onKeyDown={e => {this.handleKeys(e, suggestions.length)}} 
              onTouchStart={onFocus} 
              onClick={() => {this.setState({value: ''})}}
            />
            {this.state.value !== '' && <div className={"Absolute Top0px VPadding10px Right50px FS39"} onClick={() => {this.setSearchText('')}}><FontAwesomeIcon icon={"times"} color="#333333" /></div>}
            <div className={"Absolute Top0px Right0px Padding10px FS39 Hand btn-light BCTrans"} onClick={() => {this.props.onRefresh()}}><FontAwesomeIcon className={(loading ? 'Spin' : '')} icon={"redo"} color="black" /></div>
            <ListApp type={"search"} styleList={"Absolute Wide100 Top60px Left0px z10"} data={suggestions} onSelected={e =>{onSubmit(this.formatCoordinates(e));this.setSearchText(e.place);}} selectedItem={this.state.selectedItem} />
      </div>
    );
  }

  handleKeys = (e, length) =>{
    let temp;
    if(length === 0){length=1;}
    if (e.keyCode === 13) {this.returnSuggestion();}
    if (e.keyCode === 38) {
      temp = this.state.selectedItem;temp--;temp = Math.max(temp, 0);
      this.setState({selectedItem: temp});
    }
    //DOWN 40
    if (e.keyCode === 40) {
      temp = this.state.selectedItem;temp++;temp = Math.min(temp, length-1);
      this.setState({selectedItem: temp});
    }
  }

  
  setSearchText = (e)  => {
    // console.log('SETSEARCHTEXT => ', e);
    this.setState({value: e});
  }
  
  returnSuggestion = ()  => {
    if(this.props.suggestions.length>0){
      this.setSearchText(this.props.suggestions[this.state.selectedItem].place_name);
      
      const temp = this.formatCoordinates(this.props.suggestions[this.state.selectedItem]);
      this.props.onSubmit(temp);
    }
    else{
      if(this.state.value !== ''){this.props.onSubmit(this.lastSent);}
    }
  }
 
  formatCoordinates = (e) => {
    const temp = {'latitude': e['center'][1], 'longitude': e['center'][0]};
    this.lastSent = temp;
    // console.log('RETURN SUGGESTION => ', temp);
    return temp
  }

}

const mapStateToProps = (state) => { return {sideMenu: state.sideMenu, isAuthenticated: state.status.token !== null && state.status.token !== '' ? true : false, status:state.status, user:state.user, settings:state.settings, architecture:state.architecture};}
const mapDispatchToProps = dispatch => {
  return {
      sideMenuFunction: (bVal) => dispatch(actions.sideMenu(bVal)),
      onTryAutoSignup: (history) => dispatch(actions.authCheckState(history)),
      navigate: (direction, history) => dispatch(actions.navigate(direction, history))
  }}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchBox));
