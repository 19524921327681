import React, { Component } from 'react';
import { truncate, styleMapping, getEquivalent, isObjectComplete } from "../utils/tools";


export default class Breakdown extends Component{    
    // ##################################  CONSTRUCTOR  ####################################
    // ##################################  CONSTRUCTOR  ####################################
    constructor(props) {
        super(props);
        
    }
    
    async componentDidMount() {
    }
    
    
    // ##################################  RENDER  ####################################
    // ##################################  RENDER  ####################################
    render(){
        const { data, selectEvent, eventMode } =  this.props;
        return ( 
            <div className={"BRL w-100 p-1 container_breakdown"}>
                {data.map((element, i) =>{
                return (    
                    <button key={"element_" + i } 
                        className="BRL w-100 element_breakdown" 
                        onClick={() => {
                            selectEvent(i);
                        }}>
                        <div className="text_breakdown" >
                            {/* { element['properties']['title'] } */}
                            {(eventMode ? element.properties.title : element.properties.companyname)}

                        </div>
                    </button>
                )})}

            </div>
        );
    }

}     
