// Homescreen.js
import React, { Component } from 'react';
import { editorMenuPosition, getDesigns } from "../services/editor";
import CTRI from "./c-tri"
import CWINDOW from "./c-window"
import CINFO from "./c-info"
import CVIDEO from "./c-video"
import CMAPS from "./c-maps"
import CIMAGE from "./c-image"
import CCUSTOM from "./c-custom"
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as design from "../../resources/constants/design";
import CustomModal from "../elements/customModal";
import { getTextDraft, styleMapping } from "../utils/tools";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import $ from 'jquery';
import _ from "lodash";

// const width = $(window).width();

class Canvas extends Component {
    //############################ CONSTRUCTOR ###################################
	//############################ CONSTRUCTOR ###################################
	constructor(props) {
        super(props);
        this.state = { structureDetails: null, structure: null, selectedMenu: null, bModal: false, selectedElement: null, meta:null, data: null };
        
        // if(width < 981){
        //     $('.MobileMenu').css({'display': 'block'});
        //     $('.DesktopMenuFake').css({'display': 'none'});
        //     $('.logo_container').css({'display': 'none'});       
        // } else {
        //     $('.MobileMenu').css({'display': 'none'});
        //     $('.DesktopMenuFake').css({'display': 'block'});
        //     $('.logo_container').css({'display': 'block'}); 
        // }
    }
        
    componentDidMount(){this.loadData(this.props);}
    UNSAFE_componentWillReceiveProps(props){this.loadData(props);}
    loadData = (data)  => {
        // console.log('CANVAS DATA => ', data['structure']);
        this.setState({structureDetails: data['structure']['structureDetails'], structure: data['structure']['structure'], selectedMenu: null}, function(){});}
    
    componentWillUnmount(){
        // $('.MobileMenu').css({'display': 'none'});
        // $('.logo_container').css({'display': 'block'});
        // $('.DesktopMenuFake').css({'display': 'block'});
    }

    //############################ RENDER ###################################
	//############################ RENDER ###################################
	render() {
        const { structureDetails, meta, data } = this.state; 
        
        if(this.state.structureDetails){    
            // console.log('CANVAS 2 => ', structureDetails);
            return (
                <div className={"BRL Wide100"}>   
                {this.state.bModal && 
                    <CustomModal show={this.state.bModal} size={'lg'} meta={meta} data={data} onSave={(data)=>{this.modifyStructure(data);}} onClose={()=>{this.setState({bModal: false});}} /> 
                }
                
                
                {structureDetails.map((part,index)=>{

                    if(part.tag === 'C-TRI') { return (<div key={index} className={"CTRI BRL " + part.structure.superContainer.class} style={styleMapping(part.structure.superContainer)}>
                        <CTRI key={index} structure={part} />
                        {this.props.edit && this.renderActions(index)}
                    </div>);}                    

                    if(part.tag === 'C-WINDOW') { return (<div key={index} className={"CWINDOW BRL " + part.structure.superContainer.class} style={styleMapping(part.structure.superContainer)}>
                        <CWINDOW key={index} structure={part} />
                    {this.props.edit && this.renderActions(index)}
                    </div>);}                    

                    if(part.tag === 'C-INFO') { return (<div key={index} className={"CINFO BRL Wide100 "}>             
                        <CINFO key={index} structure={part.structure} />
                        {this.props.edit && this.renderActions(index)}
                    </div>);}
                                
                    if(part.tag === 'C-VIDEO') { return (<div key={index} className={"CVIDEO BRL " + part.structure.container.class} style={styleMapping(part.structure.container)}>             
                        <CVIDEO structure={part} />
                        {this.props.edit && this.renderActions(index)}
                    </div>);}

                    if(part.tag === 'C-IMAGE') { return (<div key={index} className={"CIMAGE BRL " + part.structure.container.class} style={styleMapping(part.structure.container)}>
                        <CIMAGE key={index} structure={part} />
                        {this.props.edit && this.renderActions(index)}
                    </div>);}

                    if(part.tag === 'C-MAPS') { return (<div key={index} className={"CMAPS OverflowHidden BRL " + part.structure.container.class} style={styleMapping(part.structure.container)}>             
                        <CMAPS key={index} structure={part} />
                        {this.props.edit && this.renderActions(index)}
                    </div>);}

                    if(part.tag === 'C-CUSTOM') { return (<div key={index} className={"CIMAGE BRL Wide100"}>
                        <CCUSTOM key={index} structure={part} />
                        {this.props.edit && this.renderActions(index)}
                    </div>);}
                    return null;
                })}
            
                {this.props.edit && <div key={'addElement'} className="BRL Wide100 CenterAlign TopMargin50px">             
                    <div onClick={() => {this.menuAction('add', null)}} className=" col-lg-5 col-md-5 col-sm-5 btn btn-primary btn-lg ButtonFont FS38">             
                        ADD ELEMENT
                    </div>
                </div>}

            </div>
            );
        } else return null;
    }




    // ############################ RENDER ACTIONS ###################################
    // ############################ RENDER ACTIONS ###################################
    renderActions = (index) => {    
        var data =          
            <div className={"Hand Absolute Top10px Right10px HPadding5px VPadding0px btn-primary BorderRadius5 BottomShadow7 z7000"}>
                <div key={'action_' + index} onClick={() => {this.openMenu(index)}} className={"BR"}><FontAwesomeIcon icon={"th-list"} style={{fontSize: 15}} color="white"/></div>
                <div className={"Absolute Top0px Right30px BCGray3 OverflowHidden BottomShadow7 " +  (this.state.selectedMenu !== index ? 'High0px' : '') }>
                    <button disabled={index === 0} onClick={() => this.menuAction('move', {from: index, to: index-1})} className="BRL Wide100 VPadding5px NoWhiteSpace FS41 BottomShadow7 NoBorder btn btn-light">MOVE UP</button>
                    <button onClick={() => {this.menuAction('edit', index)}} className="BRL Wide100 VPadding5px NoWhiteSpace FS41 BottomShadow7 NoBorder btn btn-light">EDIT ELEMENT</button>
                    <button disabled={index >= this.lengthStructure}  onClick={() => this.menuAction('move', {from: index, to: parseInt(index + 1,10)})} className="BRL Wide100 VPadding5px NoWhiteSpace Padding5px CenterAlign FS41 BottomShadow7 NoBorder btn btn-light">MOVE DOWN</button>
                    <button onClick={() => {this.menuAction('remove', index)}} className={"BRL Wide100 VPadding5px NoWhiteSpace FS41 BottomShadow7 NoBorder btn btn-light"} >REMOVE ELEMENT</button>
                </div>  
            </div>;
        return data;
    };

    // ############################ OPEN MENU ###################################
    // ############################ OPEN MENU ###################################
    openMenu = (index) => {    
        
        if(this.state.selectedMenu !== index){
            this.setState({selectedMenu: index});
        } else this.setState({selectedMenu: null});
    
    };


    // ############################ MENU ACTIONS ###################################
    // ############################ MENU ACTIONS ###################################
    menuAction = async (action, index) => {    
        let structure = this.state.structure;
        let structureDetails = this.state.structureDetails;
        let menuIndex = null;                
        let bModal = false;
        let selectedElement = null;
        let meta = null;
        let data = null;

        switch(action){
            case 'edit':
                bModal = true;
                selectedElement = index;
                meta = _.cloneDeep(structure.structure[selectedElement]);
                data = _.cloneDeep(structureDetails[selectedElement]);

            break;
            case 'move':
                structure.structure = editorMenuPosition(structure.structure, index);
                structureDetails = editorMenuPosition(structureDetails, index);
                menuIndex = index.to;
            break; 
            case 'add':
                structure.structure.push(design.TEMPLATE);
                const temp = await getDesigns('');
                // console.log('MENUACTION => ', action, ' => ', design.TEMPLATE['reference'], ' => ', temp['results'][design.TEMPLATE['reference']], ' => ', temp);
                structureDetails.push(temp['results'][design.TEMPLATE['reference']]);
                menuIndex = structureDetails.length-1;
            break;
            case 'remove':
                structure.structure.splice(index, 1);
                structureDetails.splice(index, 1);
                menuIndex = null;
            break;
            default:break;    
        }
        this.setState({structureDetails: structureDetails, structure: structure, selectedMenu: menuIndex, bModal: bModal, selectedElement: selectedElement, meta: meta, data:data});
        if(action !== 'edit'){this.props.onChange({structureDetails: structureDetails, structure: structure});}
    }

    // ############################ MENU ACTIONS ###################################
    modifyStructure = (data) => {    
        
        let tempStructure = this.state.structure;
        let tempStructureDetails = this.state.structureDetails;
        tempStructure.structure[this.state.selectedElement] = data.meta;
        // console.log('CANVAS MODIFY STRUCTURE 1 => ', data.meta , ' => ', data.data);
        if(data.meta['tag'] === 'C-VIDEO' || data.meta['tag'] === 'C-IMAGE' || data.meta['tag'] === 'C-WINDOW' || data.meta['tag'] === 'C-TRI' || data.meta['tag'] === 'C-CUSTOM' || data.meta['tag'] === 'C-MAPS'){tempStructureDetails[this.state.selectedElement] = data.data;} 
        else {tempStructureDetails[this.state.selectedElement] = JSON.parse(getTextDraft(data.data));}
        // console.log('CANVAS MODIFY STRUCTURE 2 => ', tempStructure, ' => ',tempStructureDetails);
        this.setState({structureDetails: tempStructureDetails, structure: tempStructure, bModal: false});
        this.props.onChange({structureDetails: tempStructureDetails, structure: tempStructure});
    }  

}




  const mapStateToProps = (state) => { return {isAuthenticated: state.status.token !== null && state.status.token !== '' ? true : false, status:state.status, user:state.user, settings:state.settings, architecture:state.architecture};}
  export default withRouter(connect(mapStateToProps, null)(Canvas));
  
  