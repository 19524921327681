import React, { Component } from 'react';
import MapBox from './mapbox';
import SearchBox from '../elements/searchBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SearchMenuBar from '../elements/searchMenuBar';
import FilterMenu2 from '../core/filterMenu2';
import InfoModal from "../elements/infoModal";
import RangeSlider from "../elements/slider";
import ModalFilter from '../elements/modalFilter';
import { geoCodeAddress, getHeatMap, jsonToGeoJSON, jsonToGeoJSONBusiness, jsonToGeoJSONDirectory, getCurrentGPSPosition} from "../services/geolocation";
import { truncate, styleMapping, getEquivalent, isObjectComplete } from "../utils/tools";
import { getProfile } from "../../components/services/profile";
import $ from 'jquery';
import { connect } from 'react-redux';

const exceptionList = ['target_stereotype', 'target_age', 'target_stereotype', 'active', 'last_online', 'is_dark_mode', 'target_age', 'target_stereotype', 'is_premium', 'date_premium_valid', 'subscription']
const equivalentFields = [['target_age','age'],['target_gender','gender'],['target_orientation','orientation']];
const width = $(window).width();

// export default class GeoLocation extends Component{    
class GeoLocation extends Component{    
    // ##################################  CONSTRUCTOR  ####################################
    // ##################################  CONSTRUCTOR  ####################################
    constructor(props) {
        super(props);
        let dNow = Date.now();
        let dEnd2 = new Date(dNow + 1000 * 3600 * 24 * 3);
        this.state = { 
            latitude: null, longitude: null, 
            dataPoints: null, 
            loading: true, refresh: true, 
            infoModal: null, profile: null, 
            bComplete: null, bPopUp: true, popUpData: null, 
            debugUserCoordinates: ''
        };
        
        let t1 = new Date();
        t1.setHours(0,0,1)
        dEnd2.setHours(23,31,1)
        this.range = [t1.toUTCString(), dEnd2.toUTCString()];
        
        this.filterOpened = false;
        this.bdebug = false;
        this.filters = {'gender': '', 'age': '', 'stereotype': '', 'political': '' };
        this.filteredDataPoints = {};
        this.suggestions=[];
        if(width > 980){this.styleMap = {height:'100vh',width: '100%'};}
        else{this.styleMap = {height:'100vh',width: '100%'};}
    }
    
    async componentDidMount() {
        $('.logo_container').css({'display': 'none'});
        let coordinates = await getCurrentGPSPosition();
        this.coordinates = {'latitude': coordinates['latitude'], 'longitude': coordinates['longitude']}
        // console.log('GEOLOCATION CDM 2 ========> ', this.coordinates);
        this.Lastcoordinates = this.coordinates;
        if(this.props.user){this.isProfile(this.props);}
        await this.isChanged(this.coordinates);
        await this.getResults();
    }
    
    async UNSAFE_componentWillReceiveProps(props){if(props.user){this.isProfile(props);}}

    componentWillUnmount(){
        $('.logo_container').css({'display': ''});
    }
  
    // ##################################  MAP CHANGED  ####################################
    // ##################################  MAP CHANGED  ####################################
    async isProfile(data){
        if(data.user.idUser){
            const temp = await getProfile(data.user.idUser);
            this.setState({profile: temp, bComplete: isObjectComplete(temp, exceptionList)}); 
        } else{this.setState({bComplete: false}); }
    }
    
    isChanged = async (coordinates, zoom, origin) => {
        this.coordinates = coordinates;
        if(zoom == null){this.zoom = 16;} else {this.zoom = zoom;}
        // console.log('GEOLOCATION isChanged => ', coordinates);
        
        try{
            this.coordinatesTrunc = {'latitude': truncate(coordinates['latitude'],3), 'longitude': truncate(coordinates['longitude'],3)}
            this.suggestions=[];
            if(origin === 'search'){
                this.setState({loading: true });
                this.zoom = 16; 
                setTimeout(() => {
                    this.getResults();
                }, 200)          
            } 
        }
        catch(e){
            console.log('eror no city : ', e)
        }

        // else {this.setState({ latitude: this.coordinates['latitude'], longitude: this.coordinates['longitude']});} 
    }
    
    // ##################################  GET DENSITY  ####################################
    // ##################################  GET DENSITY  ####################################
    getResults = async () => {
        let debugUserCoordinates = '';
        this.suggestions=[];
        this.Lastcoordinates = this.coordinates;

        if(this.bdebug === true){
            debugUserCoordinates = await getCurrentGPSPosition();
        } 
        
        this.setState({ 
            latitude: this.coordinates['latitude'], 
            longitude: this.coordinates['longitude'], 
            loading: true, 
            debugUserCoordinates: JSON.stringify(debugUserCoordinates) 
        });

        this.dataPoints = await getHeatMap({
            'coordinates': this.coordinates, 
            'zoom': this.zoom, 
            'range': this.range
        }, this.props.directory);
        
        console.log('GEOLOCATION GETREUSLTS 1 => ', this.coordinates, ' => ',this.range, ' => ',this.dataPoints);
        
        if (this.dataPoints){
            this.filterResults();
        } else {
            this.setState({loading: false, refresh: true });
        }

    }
    
    // ##################################  GET RANGE  ####################################
    // ##################################  GET RANGE  ####################################
    getRange = range => {
        // this.range = [new Date(range[0]), new Date(range[1])];
        this.range = [range[0], range[1]];
        // console.log('GEOLOCATION GETRANGE => ', this.range);
        this.getResults();
    }
    
    // ##################################  GET FILTERS  ####################################
    // ##################################  GET FILTERS  ####################################
    getFilters = filters => {
        this.filters = filters;
        this.filterResults();
    }

    // ##################################  GET GEOLOCATION OF PLACE  ####################################
    // ##################################  RETURNS LIST OF MATCHING PLACES  ####################################
    getPlace = async (data) => {

        if(data.length > 3){
            this.suggestions = await geoCodeAddress(data, this.coordinates);
        } else{ this.suggestions=[]; }
        
        this.preRender();
    }

    // ################################## PRERENDER  ####################################
    // ################################## PRERENDER  ####################################
    preRender = () => {    
        
        try{

            const data2 = jsonToGeoJSON(this.filteredDataPoints);
            const data3 = (this.dataPoints.businesses ? jsonToGeoJSONBusiness(this.dataPoints.businesses) : jsonToGeoJSONBusiness([]));
            const data4 = (this.dataPoints.directory ? jsonToGeoJSONDirectory(this.dataPoints.directory) : jsonToGeoJSONDirectory([]));

            const data = { dataPoints: data2, dataBusinesses: data3, dataDirectory: data4, latitude: this.coordinates['latitude'], longitude: this.coordinates['longitude'], loading: true };
            this.setState(data, function(){            
                setTimeout(() => {
                    this.setState({loading: false, refresh: true });
                }, 500);
            });
        }
        catch(e){
            console.log('error: ', e);
            this.setState({loading: false, refresh: true });
        }
    }
    
    // ##################################  RENDER  ####################################
    // ##################################  RENDER  ####################################
    render(){
        if(this.state.longitude){
            // console.log("===> ", this.state.bComplete, ' === ', this.state.infoModal);
            return ( 
                <div className={"BRL Wide100 "}>
                    {/*################################ MODAL POPUP ################################*/}
                    {/*################################ MODAL POPUP ################################*/}
                    <InfoModal type={'geolocation'} show={(this.state.bComplete === false && this.state.infoModal === null ? true:false)} onClose={() => {this.setState({infoModal: false})}} />
                    {this.state.popUpData && <InfoModal type={'popUp'} data={this.state.popUpData} show={this.state.bPopUp} onClose={() => {this.setState({bPopUp: false})}} />}

                    {this.bdebug ===  true && <div className={"Fixed Top0px Right50px z2000 FS36 BCBlack FCWhite FuturaL"}>                            
                        {this.state.debugUserCoordinates} 
                    </div>}

                    {/*################################ DESKTOP ################################*/}
                    {/*################################ DESKTOP ################################*/}
                    <div className={"DesktopMenu Absolute Top0px Left0px col-lg-12 col-md-12 col-sm-12 HPadding0px VPadding10px z1001"}>
                        <div className={"DesktopMenu BRL col-lg-4 col-md-4 col-sm-12 VPadding10px MaxWide350px z1001"}>
                            <div className="BRL col-lg-12 col-md-12 col-sm-12 BottomMargin5px z2000 HPadding0 ">
                                <SearchBox title={true} icon="map" settings={this.props.settings.logo} 
                                iconLib="font-awesome" placeholder="search a place" loading={this.state.loading} 
                                onChange={(e) => this.getPlace(e)} 
                                onSubmit={(e) => {this.isChanged(e, this.zoom, 'search')}} 
                                onRefresh={() => {this.getResults()}}  
                                boxStyle={'Wide100'} 
                                suggestions={this.suggestions}/></div>
                            <div className="BRL col-lg-12 col-md-12 col-sm-12 z1000 HPadding0 "><FilterMenu2 data={this.filters} open={false} onFilter={e => this.getFilters(e)} /></div>
                        </div>

                        <div className={"DesktopMenu Flex FlexRow JustifyCenter AlignCenter BRL col-lg-7 col-md-7 col-sm-12 Padding20px z3000"}>
                            <RangeSlider onSlider={(e) => {this.getRange(e)}} />
                        </div>
                    </div>

                    {/*################################ MOBILE ################################*/}
                    {/*################################ MOBILE ################################*/}
                    {this.state.modal && <ModalFilter show={this.state.modal} data={this.filters} onClose={()=>{this.setState({modal: false});}} onFilter={e => this.getFilters(e)} /> }                   
                    <div className={"MobileMenu Absolute Top15px Left0px col-11 p-3 z2000"}>
                        <RangeSlider onSlider={(e) => {this.getRange(e)}} />
                    </div>
                    
                    <div className={"MobileMenu Fixed Bottom0px Left0px MarginAuto col-lg-12 col-md-12 col-sm-12 TopMargin0px Padding0 z2000"}>                            
                        <SearchMenuBar settings={this.props.settings} boxStyle={'Wide100 '} loading={this.state.loading} 
                            icon="map" iconLib="font-awesome" placeholder="search a place" onChange={(e) => this.getPlace(e)}  
                            onSubmit={(e) => {this.isChanged(e, this.zoom, 'search')}} onRefresh={() => {this.getResults()}} onFilter={() =>{this.setState({modal: true})}}
                            suggestions={this.suggestions}/>
                    </div>
                    
                    {/*################ MAPBOX ################*/}
                    {/*################ MAPBOX ################*/}
                    <div className="BRL Wide100 High100VH">
                        <MapBox 
                            idUser={this.props.user.idUser} 
                            settings={this.props.settings} 
                            styleMap={this.styleMap}
                            data={this.state} 
                            onChangeCenter={async (e, zoom, type) => { 
                                await this.isChanged(e, zoom, type); }} 
                            onPopUp={data =>{this.displayPopUp(data)}} 
                            onDblClick={(map, event) =>{} }/>
                    </div> 

            </div>);
        } else { 
            // return null;
            return ( 
                <div className={ this.props.settings.structure.outlet.class} style={styleMapping(this.props.settings.structure.outlet)}>
                    <div className={"Flex FlexCol JustifyCenter AlignCenter High100VH"} style={{backgroundColor: "rgba(240,240,240,0.5)"}}>
                        <div className="Monoton" style={{fontSize:'3rem', color:"#ad182f"}}>STRANGERS</div>
                        <div className={""}><FontAwesomeIcon className={"FS10 Spin"} icon={"compass"} color="#ad182f" /></div>
                    </div>
                </div>);
        }
    }

    
    // ##################################  FILTERING RESUKTS  ####################################
    // ##################################  FILTERING RESUKTS  ####################################
    filterResults = () => {    
        const filters = this.filters; 
        
            if(this.dataPoints){
                if(this.dataPoints.users){
                this.filteredDataPoints = this.dataPoints.users.filter(function(item) {
                    let bDisplay = true;
                    for (var key in filters) {
                        let filters2 = filters[key];
                        let bDisplayOR = '';
                        // console.log('FILTERED 1 => ', key, ' : ', filters2);
                        
                        for (var key2 in filters2) {
                            let filters3 = filters2[key2]['value'];
                            // console.log('FILTERED 3 => ', filters2);
                            // console.log('FILTERS 3 => ', filters3);
                            switch(key){
                                case 'age': 
                                const temp = filters3.split("-");
                                if (parseInt(item['age'],10) < parseInt(temp[0],10) || parseInt(item['age'],10) > parseInt(temp[1],10)){ 
                                    if(bDisplayOR !== true) {bDisplayOR = false;} 
                                } else {bDisplayOR = true;}
                                break;
                                default: 
                                    // console.log('FILTERED 4 => ', filters[key], ' !== "" ');
                                    if (filters3 !=='' && item[getEquivalent(key, equivalentFields, 1)] !== filters3){ 
                                            if(bDisplayOR !== true) {bDisplayOR = false;} 
                                    } else {bDisplayOR = true; }
                                    break;
                                }
                        }
                        if(bDisplayOR === false) {bDisplay = false;} 
                    }
                    return bDisplay;
                });
            }
        }
        // console.log('GEOLOCATION GETREUSLTS FILTERED 1 => ', this.filteredDataPoints);
        this.preRender();
    }


    // ##################################  DISPLAY POPUP  ####################################
    // ##################################  DISPLAY POPUP  ####################################
    displayPopUp = (dataPopUp) => { 
        const data = {
            bPopUp: true, 
            popUpData: dataPopUp, 
            latitude: dataPopUp['position'][0][0], 
            longitude: dataPopUp['position'][0][1]
        };
        // console.log('displayPopUp 2 => ', dataPopUp, ' => ', data);   
        this.setState(data);
    }
}     

const mapStateToProps = (state) => {

    const data = {
        darkMode: (state.profile ? state.profile.is_dark_mode : null),
        profile: state.profile,
        settings: state.settings,
        control: state.control,
        directory: state.directory,
        email: state.email,
    }
	return data;
}

export default connect(mapStateToProps, null)(GeoLocation);

