import React from "react";
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';

const CheckboxCustom = ({ label, onCheckboxChange }) => (
  
  
    <div className="BRL Wide100 CenterAlign ">
        <div className=" MarginAuto ">
            <span className=" LeftMargin10px FS38 FuturaM ">{label}</span>
            <div className=" form-check"><Checkbox type="checkbox" onChange={onCheckboxChange} /></div>
        </div>
    </div>

);

export default CheckboxCustom;