import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../../store/pseudo/general';
import MoonLoader from "react-spinners/ClipLoader";
// import { css } from "@emotion/core";

class GoogleAuthJs extends Component {
  
  constructor(props){
    super(props);
    this.state = {loading: false};
    this.googleSDK();
  }
  
  // ########################### LOAD GOOGLE API FOR LOGIN IN SPECIFIC APP ########################### 
  // ########################### LOAD GOOGLE API FOR LOGIN IN SPECIFIC APP ########################### 
  googleSDK() {
    window['googleSDKLoaded'] = () => {
      window['gapi'].load('auth2', () => {
        this.auth2 = window['gapi'].auth2.init({
          // client_id: '71336703063-57pau8vpt3c2part4k49gsi4s75jp8o1.apps.googleusercontent.com',
          client_id: this.props.settings.web,
          cookiepolicy: 'single_host_origin',
          scope: 'profile email'
        });
        this.prepareLoginButton();
      });
    }
    
    (function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'google-jssdk'));

  }


  // ########################### PREPARE LOGIN BUTTON ########################### 
  // ########################### PREPARE LOGIN BUTTON ########################### 
  prepareLoginButton = () => {
    this.auth2.attachClickHandler(this.refs.googleLoginBtn, {}, (googleUser) => {
      
      let profile = googleUser.getBasicProfile();
      this.props.onAuth(googleUser.getAuthResponse().access_token, profile, this.props.history);
    
    }, (error) => {});
  }
  
  
  // ########################### DISPLAY LOGIN FORM ########################### 
  // ########################### DISPLAY LOGIN FORM ########################### 
  render() {
    if(this.props.settings){
      return (
        <button type="submit" className={"BRL Wide100 BorderRadius2 btn-primary loginBtn loginBtn--google Padding10px"} ref="googleLoginBtn" style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center', border: 0}} onClick={()=> {this.setState({loading: true});}}>
          <div className="Capital FS40 FuturaH" style={{flex:9, padding:'2px 0'}}>{'Signin with Google'}</div>
          <div className="" style={{flex:1}}><MoonLoader size={17} color={"#ffffff"} loading={this.state.loading}/></div>
        </button>    
        );
    } else return null;
  }
}
  
  
const mapStateToProps = (state) => { return{status:{loading: state.loading,error: state.error}};}
const mapDispatchToProps = dispatch => {return {onAuth: (token, profile, history) => dispatch(actions.googleLogin(token, profile, history))}}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GoogleAuthJs));
