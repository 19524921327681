import React, { Component } from 'react';
import ListItem from './listItem';
import Item from '../business/item';
import { styleMapping } from "../utils/tools";
import { connect } from 'react-redux';


class ListApp extends Component {

  constructor(props) {
    super(props);
    this.state = { selectedItem: props.selectedItem }; 
  }

  // ############################ RENDER ###################################
  // ############################ RENDER ###################################
  render() {

    const { type, styleList, data, onSelected, selectedItem, fields, custom, filter } = this.props;
    // console.log('RENDER LIST => ', data);

    return (
            <div className={styleList.class} style={styleMapping(styleList)}>
            {Object.keys(data).map((sug,index)=>{
              switch(type){
                default:break;
                case "search": return (<ListItem key={index} class={"BRL Wide100 Hand"} custom={custom} fields={fields} index={index} selectedItem={selectedItem} place={data[sug]} onSelected={(e) => {onSelected(e);}} />);
                case "marketing": 
                    // console.log('RENDER LIST 1 => ', data[sug]);
                    return (
                      <React.Fragment key={'item_' + index}>
                        {(data[sug]['action'] === filter || (filter === '' && data[sug]['action'] !== 'deleted') || data[sug]['action'] === 'deleting' || index === 0 ) && 
                        <Item key={index} class="BRL Wide100 Hand" data={data[sug]} fields={fields} index={index} type={type} selectedItem={selectedItem}
                        styleLine={" BRL Wide100 Hand BottomShadow7 Flex JustifyCenter AlignCenter Padding2px " 
                          + (index === 0 ? ' Bold ': ' ') 
                          + (data[sug].action === 'hidden' ? 'BCTransparent4 FCGray12 ' : '') 
                          + (data[sug].action === 'deleting' ? 'BCPrimaryDisabled ' : '') 
                          + (data[sug].action === 'new' ? 'Bold ' : '') 
                          + (selectedItem === index ? 'BCPrimary FCWhite Bold ' : 'btn-light ')} 
                        onSelected={(action,data,index) => { onSelected(action,data,index);}} />}
                    </React.Fragment>
                  );
                  case "dashboard":
                    return (
                      <React.Fragment key={'item_' + index}>
                        <Item key={index} class="BRL Wide100 Hand" data={data[sug]} fields={fields} index={index} type={type} selectedItem={selectedItem}
                        styleLine={"BRL Wide100 Hand BottomShadow7 " 
                          + (index === 0 ? '  Bold': ' ') 
                          + (data[sug].action === 'hidden' ? ' BCTransparent4 FCGray12 ' : '') 
                          + (data[sug].action === 'delete' ? ' BCPrimaryDisabled ' : '') 
                          + (data[sug].action === 'new' ? ' Bold ' : '') 
                          + (selectedItem === index ? ' BCPrimary FCWhite Bold' : ' btn-light ')} 
                        onSelected={(action,data,index) => { onSelected(action,data,index);}} />
                    </React.Fragment>);
                }
              return null;
            })
            }
            </div>       
    );

  }
}

export default connect(null, null)(ListApp);

