
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Input } from "reactstrap";
import Select from "../elements/select";
import { transformArray, selectListGenerator, getEquivalent, getTextDraft, generateReference } from "../utils/tools";
import CVIDEO from "../design/c-video";
import CIMAGE from "../design/c-image";
import CMAPS from "../design/c-maps";
import CCUSTOM from "../design/c-custom";
import CWINDOW from "../design/c-window";
import CTRI from "../design/c-tri";
import { getDesigns, designAction, designImages, extractImages } from "../services/editor";
import { Editor, EditorState, ContentState } from 'draft-js';
import MoonLoader from "react-spinners/ClipLoader";

const selectFields = [['name', 'reference', 'custom'], ['tag', 'tag', 'tag']];

export default class CustomModal extends Component {
  constructor(props) {
    super(props);
    this.state = {meta: null, tag: null, designList:null, designs:null, images: null,data: null, bSaved: true, bDesignSaved: true, element: null, saving: false};
    this.setEditor = (editor) => { this.editor = editor; };
    this.onChangeRaw = (editorState) => {this.setState({data: editorState, bDesignSaved: false});};
  }
  componentDidMount(){this.LoadData(this.props);}
  UNSAFE_componentWillReceiveProps(props){this.LoadData(props);}

  //########################### LOAD DATA IN MODAL ###################################
  //########################### LOAD DATA IN MODAL ###################################
  LoadData = async (data) => {
    const designs = await getDesigns('');
    // console.log('CUSTOMMODAL LOADDATA => ', data, ' => |||   =Z ', typeof designs.results);
    const tempImages = await extractImages(data.data['tag'], data.data);
    const list = await this.getDesignList(designs.results, data.meta['tag']);
    // this.setState({meta: data.meta, designs: designs.results, tag: data.meta['tag'], images: tempImages, data: data.data}, function(){ this.getDesignList(data.meta['tag']); });
    this.setState({meta: data.meta, designs: designs.results, tag: data.meta['tag'], images: tempImages, data: data.data, designList: list}, function(){  });
  }

  getDesignList = async (designs, tag) => {
    const tagDesigns = await this.getSubDesigns(designs, tag);
    const designList = selectListGenerator(transformArray(true, tagDesigns, null), 'name', 'reference');
    // console.log('GETDESIGNLIST => ', tag, ' => ',this.state.designs, ' => ', tagDesigns, ' => ', designList);
    // this.setState({designList: designList});
    return designList;
  }
  
  getSubDesigns = async (data, tag) => {
    let results = {};
    for (let key in data){if (data[key]['tag'] === tag){results[key] = data[key];}}
    return results;
  }

  // ############################ EVENT HANDLING ###################################
  // ############################ EVENT HANDLING ###################################
  handleChange(field, e){
      // let temp = this.state.element;

      // switch(field){
      //     case 'font': temp[field] = e.value; break;
      //     default: temp[field] = e; break;
      // }
      // this.setState({element: temp});
      // this.props.onChange(params, this.state.structure, this.state.images, false);
  } 



  handleChangeType = async (key, e) => {
    let tempMeta = this.state.meta;
    let tempData = this.state.data;
    tempMeta[key] = e.value;
    // console.log('HANDLECHANGETYPE => ', key, ' => ',tempMeta[key], ' => ', e.value);

    switch(key){
      case 'tag': 
        // console.log('CHANGE TAG  0 => ', key, ' => ', tempMeta[key]);
        const List = await this.getDesignList(this.state.designs, e.value);
        // console.log('CHANGE TAG 1 => ', key, ' => ', List);
        tempMeta['name'] = List[0]['label'];
        tempMeta['reference'] = List[0]['value'];
        tempMeta['tag'] = e.value;
        // console.log('CHANGE TAG  2 => ', key, ' => ', List[0] , ' => ', tempMeta);
        
        if(tempMeta['tag'] === 'C-VIDEO' || tempMeta['tag'] === 'C-IMAGE' || tempMeta['tag'] === 'C-WINDOW' || tempMeta['tag'] === 'C-TRI' || tempMeta['tag'] === 'C-MAPS' || tempMeta['tag'] === 'C-CUSTOM'){tempData = this.state.designs[List[0]['value']];} 
        else {tempData = EditorState.createWithContent(ContentState.createFromText(JSON.stringify(this.state.designs[List[0]['value']])));} 



        break;
      case 'reference': 
        // console.log('CUSTOMMODAL CHANGE 2 ', this.state.designs, ' =>' , this.state.designs[e.value]);
        tempMeta['reference'] = this.state.designs[e.value]['reference'];
        tempMeta['name'] = this.state.designs[e.value]['name'];
        if(tempMeta['tag'] === 'C-VIDEO' || tempMeta['tag'] === 'C-IMAGE' || tempMeta['tag'] === 'C-WINDOW' || tempMeta['tag'] === 'C-TRI' || tempMeta['tag'] === 'C-MAPS' || tempMeta['tag'] === 'C-CUSTOM'){tempData = this.state.designs[e.value];} 
        else {tempData = EditorState.createWithContent(ContentState.createFromText(JSON.stringify(this.state.designs[e.value])));} 
        break;
      default: break;
    }
    this.LoadData({meta: tempMeta, data: tempData});
    this.setState({bSaved: false});
  }

  handleChangeName = async (key, e) => {
    let tempMeta = this.state.meta;
    let tempData = this.state.data;
    tempMeta['name'] = e.target.value;
    switch(key){
      case 'name': 
        if(tempMeta['tag'] === 'C-VIDEO' || tempMeta['tag'] === 'C-IMAGE' || tempMeta['tag'] === 'C-WINDOW' || tempMeta['tag'] === 'C-TRI' || tempMeta['tag'] === 'C-MAPS' || tempMeta['tag'] === 'C-CUSTOM'){
          tempData['name'] = e.target.value;} 
        else { let temp = JSON.parse(getTextDraft(tempData)); 
          temp['name'] = e.target.value; 
          tempData = EditorState.createWithContent(ContentState.createFromText(JSON.stringify(temp)));}break;
      default: break;}    

      // console.log('CHANGE NAME => ', tempData);

      this.setState({meta: tempMeta, data: tempData, bDesignSaved: false});
  }


  // ############################ ACTION DESIGN ###################################
  // ############################ ACTION DESIGN ###################################
  modifyDesign = async (action) => {   
    let tempTypeDesigns, newReference, newName, tempDesignList = this.state.designList, tempData = this.state.data, 
    tempMeta = this.state.meta, tempImages = this.state.images, tempDesigns = this.state.designs, oldReference = null, 
    refImage = null, oldName = null, typology = null; 
    // console.log('A MODIFYDESIGN 0  => ', this.state.data);

    switch(tempMeta['tag']){
      case 'C-VIDEO': typology = 'videos'; break;
      default: typology = 'images'; break;
    }

    switch(action){
        case 'save':
          this.setState({saving: true});
            if(tempMeta['tag'] === 'C-VIDEO' || tempMeta['tag'] === 'C-IMAGE' || tempMeta['tag'] === 'C-WINDOW' || tempMeta['tag'] === 'C-TRI' || tempMeta['tag'] === 'C-MAPS' || tempMeta['tag'] === 'C-CUSTOM'){
              tempDesigns[tempMeta['reference']] = tempData;
              // console.log('MODIFYDESIGN 1 => ', tempDesigns[tempMeta['reference']] , ' = ', tempData);
            } else {tempDesigns[tempMeta['reference']] = JSON.parse(getTextDraft(tempData));}
            break;
        case 'duplicate':
              oldReference = tempData['reference']; 
              newReference = generateReference(20);
              oldName = tempData['name'];
              newName = tempData['name'] + '_' + generateReference(3);
              tempData = JSON.parse(JSON.stringify(tempData).replace(new RegExp(oldReference, "g"), newReference));
              tempData = JSON.parse(JSON.stringify(tempData).replace(new RegExp(oldName, "g"), newName));
              tempDesigns[newReference] = tempData;
              tempMeta['reference'] = newReference;
              tempMeta['name'] = newName;
              tempImages = await extractImages(tempData['tag'], tempData);
          break;
        case 'remove':
            refImage = Object.assign(tempMeta['reference']);
            delete tempDesigns[refImage];
            tempTypeDesigns = await this.getSubDesigns(tempDesigns, tempMeta['tag']);
            tempDesignList = selectListGenerator(transformArray(true, tempTypeDesigns, null), 'name', 'reference');

            const tempDel = tempDesigns[tempDesignList[0]['value']];
            tempMeta['name'] = tempDel['name'];
            tempMeta['reference'] = tempDel['reference'];
            if(tempDel['tag'] === 'C-VIDEO' || tempDel['tag'] === 'C-IMAGE' || tempDel['tag'] === 'C-WINDOW' || tempDel['tag'] === 'C-TRI' || tempDel['tag'] === 'C-MAPS' || tempDel['tag'] === 'C-CUSTOM'){tempData = tempDel;} 
            else {tempData = EditorState.createWithContent(ContentState.createFromText(JSON.stringify(tempDel)));}
          break;
        default: break;}

    // console.log('MODIFYDESIGN 2 => ', tempDesigns);
    await designAction(tempDesigns);
    if(tempImages !== undefined && tempImages !== null ){
      await designImages({'action': action, 'reference':(refImage == null ? tempMeta['reference'] : refImage), 'typology': typology, 'images': tempImages, 'oldReference': oldReference});
    }
    
    tempTypeDesigns = await this.getSubDesigns(tempDesigns, tempMeta['tag']);
    tempDesignList = selectListGenerator(transformArray(true, tempTypeDesigns, null), 'name', 'reference');
    this.setState({designs: tempDesigns, designList: tempDesignList, meta: tempMeta, images: tempImages, data: tempData, bDesignSaved: true, bSaved: false, saving: false});
  };


  // ############################ RENDER ###################################
  // ############################ RENDER ###################################
  render() {
    
    const { show, size, onSave, onClose } = this.props;    

    if(this.state.meta){  
      // console.log('$$$$$$$$$$$$ CUSTOMMODAL RENDER => ', this.state.data, ' => ', this.state.images);
      return (
        <Modal centered show={show} size={size} className={"Block BCTransparent1"} style={{zIndex: 9999}} aria-labelledby="contained-modal-title-vcenter" onHide={() => {onClose()}}>
          <Modal.Header className="ABRL NoBorder z200" closeButton>
            <Modal.Title id="contained-modal-title-vcenter" className={"BRL Wide100"} >
              <button key={'save_design'} onClick={() => { onSave(this.state)}} disabled={this.state.bSaved} className={"BRR Wide200px Padding10px FS40 ButtonFont btn " + (this.state.bSaved ? 'btn-primary' : 'btn-danger')}>{(this.state.bSaved ? 'No Changes' : 'Save Design')}</button>
              
              <div className="BRL Wide100 Padding10px TopMargin10px BorderRadius5 BottomShadow7">
                {Object.keys(this.state.meta).map(key => {
                return (
                    <React.Fragment key={ 'r_' + key}>
                        {(key !== 'reference' && key !== 'animation' && this.state.designList) && 
                            <li key={ 'c_' + key} className="BRL col-lg-3 col-md-3 col-sm-4 NoBorder TopMargin5px HPadding5px" style={{listStyleType: 'none'}}>
                                 {(key === 'tag' || key=== 'name' ) && <div key={ 'l_' + key} className="BRL Wide100 BottomMargin2px ButtonFont Capital FS41">{key}</div>}            
                                {/* {(key !== 'tag' && key!== 'name') && <Input key={'f1_'+ key} type="text" name={key} onChange={(e) => this.handleChange(key,e)} value={this.state.meta[key]} className="BRL Wide100 NoBorder btn-light BCTrans InputFont FS40 TopMargin5px BottomShadow7"/>} */}
                                {(key === 'tag' || key=== 'name' ) && 
                                  <Select key={'f2_'+ key} name={key} id={getEquivalent(key, selectFields, 1)} value={this.state.meta[getEquivalent(key, selectFields, 1)]}
                                    fields={getEquivalent(key, selectFields, 2)} list={(key === 'name' ? this.state.designList: [{label: '', value: '',}])}
                                    classes={{container:"BRL form-control NoBorder FS40 LabelFont Padding3px btn-light BCTrans", options:"BRL Wide100 TopMargin5px CenterAlign BottomShadow7"}}  
                                    onChange={(key, val) => {
                                      this.handleChangeType(getEquivalent(key, selectFields, 1), val)
                                    }}></Select>}
                            </li>
                        }
                    </React.Fragment>
                );})}
                <li key={ 'name'} className="BRL col-lg-3 col-md-3 col-sm-6 NoBorder TopMargin5px HPadding5px" style={{listStyleType: 'none'}}>
                    <div className="BRL Wide100 BottomMargin2px ButtonFont Capital FS41">structure name</div>             
                    <Input key='design_name' type="text" onChange={(e) => this.handleChangeName('name', e)} disabled={this.state.meta['name'] === 'template'} value={this.state.meta['name']} className="BRL Wide100 NoBorder btn-light BCTrans InputFont FS40 TopMargin5px BottomShadow7"/>
                </li>

                <li key={'linkDetailAction'} className={"BRL col-lg-2 col-md-2 col-sm-6 NoBorder HPadding5px TopMargin5px"}>
                    <div className="BRL Wide100 BottomMargin2px ButtonFont Capital FS41">{this.state.meta['reference']}</div>             
                    <button key={ 'b1_'} onClick={() => { this.modifyDesign('save') }} disabled={this.state.bDesignSaved || this.state.meta['name'] === 'template'} className={"BRL Wide30 TopMargin5px RightMargin5 FS40 ButtonFont btn btn-sm HPadding0px " + (this.state.bDesignSaved ? 'btn-primary' : 'btn-danger')} >
                      
                      {this.state.saving === false && <i className="fa fa-floppy-o"></i>}
                      {this.state.saving === true && <MoonLoader size={17} color={"#ffffff"} loading={true}/>}
                      
                    </button>
                    <button key={ 'b2_'} onClick={() => { this.modifyDesign('duplicate') }} className="BRL Wide30 TopMargin5px RightMargin5 FS40 ButtonFont btn btn-sm btn-secondary HPadding0px"><i className="fa fa-files-o"></i></button>
                    <button key={ 'b3_'} onClick={() => { this.modifyDesign('remove')}} disabled={this.state.meta['name'] === 'template'} className="BRL Wide30 TopMargin5px FS40 ButtonFont btn btn-sm btn-danger HPadding0px"><i className="fa fa-trash"></i></button>
                </li>
            </div>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className={"HPadding10px"}>
            { this.state.data.tag === 'C-VIDEO' && <CVIDEO structure={this.state.data} videos={this.state.images} editor={true} onChange={(key, e, images, bChange) => this.handleChangeGUI(key, e, images, bChange)} /> }                    
            { this.state.data.tag === 'C-IMAGE' && <CIMAGE structure={this.state.data} images={this.state.images} editor={true} onChange={(key, e, images, bChange) => this.handleChangeGUI(key, e, images, bChange)} /> }                    
            { this.state.data.tag === 'C-WINDOW' && <CWINDOW structure={this.state.data} images={this.state.images} editor={true} onChange={(key, e, images, bChange) => this.handleChangeGUI(key, e, images, bChange)}/> }                    
            { this.state.data.tag === 'C-TRI' && <CTRI structure={this.state.data} editor={true} onChange={(key, e, images, bChange) => this.handleChangeGUI(key, e, images, bChange)}/> }                    
            { this.state.data.tag === 'C-MAPS' && <CMAPS structure={this.state.data} editor={true} onChange={(key, e, images, bChange) => this.handleChangeGUI(key, e, images, bChange)}/> }                    
            { this.state.data.tag === 'C-CUSTOM' && <CCUSTOM structure={this.state.data} editor={true} onChange={(key, e, images, bChange) => this.handleChangeGUI(key, e, images, bChange)}/> }                    
            { this.state.data.tag !== 'C-IMAGE' && this.state.data.tag !== 'C-WINDOW' && this.state.data.tag !== 'C-VIDEO' && this.state.data.tag !== 'C-TRI' && this.state.data.tag !== 'C-CUSTOM' && this.state.data.tag !== 'C-MAPS' &&
            <div className="BRL Wide100 Padding20px BottomShadow7 BCWhite">
              <Editor ref={this.setEditor} editorState={this.state.data} onChange={(e) => this.onChangeRaw(e)}   />
            </div> }
          </Modal.Body>      

      </Modal>
      );
    } else return null;
  }

  // HANDLE CHANGES MADE THROUGH GUI 
  handleChangeGUI = async (key, structure, images, bChanged) => {
    let tempSaved = this.state.bDesignSaved;
    if(tempSaved === true){ tempSaved = bChanged;}
    // console.log('HANDLECHANGE A  => ', key, ' => ',structure, '  ==>  ', images, ' => ', bChanged);
    this.setState({data: structure, images: images, bDesignSaved: tempSaved});
  }



}