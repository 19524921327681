  import React, {Component} from "react";
  import t from 'tcomb-form'
  import { createBusiness } from "../services/business"
  import stylesheet from '../../resources/style/customStyleTComb.js';
  import { connect } from 'react-redux';
  import { withRouter } from 'react-router-dom';
  import { styleMapping, scrollTo, setLocalData } from "../utils/tools";
  import * as actions from '../../store/pseudo/general';
  import { getGPSCoordinates } from "../services/geolocation";
  import MoonLoader from "react-spinners/ClipLoader";
  // import { css } from "@emotion/core";

  const inputStyle = 'BRL FS39 InputFont Padding10px NoBorder BottomShadow7 BorderRadius5 BottomMargin10px TopMargin10px';
  const Form = t.form.Form;
  const formStyles = {
    ...Form.stylesheet,
    formGroup: { 
      normal: { marginBottom: 10, fontSize: 7 } 
    },
    controlLabel: {
      normal: { color: 'blue', fontSize: 7, marginBottom: 7, fontWeight: '600' },
      error: { color: 'red', fontSize: 7, marginBottom: 7, fontWeight: '600' }
    }
  };
  const options = {
    fields: {
      companyname: { label: '', auto: 'placeholders', stylesheet: stylesheet, error: '', attrs: { className: inputStyle }},
      // firstname: { label: '', auto: 'placeholders', stylesheet: stylesheet, error: '', attrs: { className: inputStyle }},
      // lastname: { label: '', auto: 'placeholders', stylesheet: stylesheet, error: '', attrs: { className: inputStyle }},
      address: { label: '', auto: 'placeholders', stylesheet: stylesheet, error: '', attrs: { className: inputStyle }},
      postcode: { label: '', auto: 'placeholders', stylesheet: stylesheet, error: '', attrs: { className: inputStyle }},
      city: { label: '', auto: 'placeholders', stylesheet: stylesheet, error: '', attrs: { className: inputStyle }},
      country: { label: '', auto: 'placeholders', stylesheet: stylesheet, error: '', attrs: { className: inputStyle }},
      telephone: { label: '', auto: 'placeholders', stylesheet: stylesheet, error: '', attrs: { className: inputStyle }},
      instagram: { label: '', auto: 'placeholders', stylesheet: stylesheet, error: '', attrs: { className: inputStyle }},
    },
    stylesheet: formStyles,
  };


  class RegisterBusiness extends Component {
    constructor(props) {
      super(props);
      this.state = { 
        loading: false,
        errors: {errors : ['']},
        text: {companyname:'', firstname:'', lastname:'', address:'',postcode:'',city:'',country:'',telephone:'',instagram:''}};
        // text: {companyname:'Black Market',firstname:'florent',lastname:'noirot',address:'Via del Ciancaleoni, 31',postcode:'00184',city:'roma',country:'italy',telephone:'+3883091816',iva:'ivanumbertest'}};
      this.FormSchema = t.struct({ companyname:t.String,address:t.String,postcode:t.String,city:t.String,country:t.String,telephone:t.maybe(t.String),instagram:t.maybe(t.String)});
      scrollTo(0,0);
  }

  //############################# HANDLING EMAIL LOGIN SUBMISSION #############################
  //############################# HANDLING EMAIL LOGIN SUBMISSION #############################
  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const temp0 = this.refs.form.getValue();
    let values = {...temp0};
    if (!values['telephone']){Object.assign(values, {'telephone': ''});}
    if (!values['instagram']){Object.assign(values, {'instagram': ''});}
    this.errors = this.refs.form.validate();
    if (this.errors.errors.length === 0) {
      const temp = {...values, id_user: this.props.user.idUser, email: this.props.user.email, token: this.props.status.token};
      console.log('CREATE BUSINESS => ', temp);
      temp['coordinates'] = await getGPSCoordinates(temp);
      const res = await createBusiness(temp);
      this.props.isBusiness(res);
      setLocalData('is_business', true);
      this.props.navigate({direction: 'business/dashboard'}, this.props.history); 
      this.setState({ loading: false });
      
    }
  }


  // ############################# HANDLE FORM CHANGE #############################
  // ############################# HANDLE FORM CHANGE #############################
  handleFormChange = (e) => {
    this.errors = this.refs.form.validate();
    // console.log('HANDLE CHANGE : ', e, ' => ', this.errors);
    this.setState({text: e, errors: this.errors});
  }

  // ############################# DISPLAY LOGIN FORM #############################
  // ############################# DISPLAY LOGIN FORM #############################
  render() {
    // console.log('RENDER REGISTER BUSINESS FORM => ', this.state);
    return (
      <div className={ this.props.settings.structure.outlet.class} style={styleMapping(this.props.settings.structure.outlet)}>      
        <div className="Flex FlexCol Wide100 JustifyCenter AlignCenter High90VH">
           
            {/* FORM */}
            <div className="col-lg-4 col-md-5 col-sm-8 MinWide400px MaxWide600px Padding20px BCGray1BottomShadow7 BorderRadius5 FlexCol">
                <div className={"BRL Wide100 FS38 TitleFont Padding10px BottomMargin20px"}>REGISTER YOUR BUSINESS</div>
                <div className={"BRL Wide100"}>
                  <Form ref="form" type={this.FormSchema} options={options} value={this.state.text} onChange={(v) => this.handleFormChange(v)} />
                </div>
                <div className="form-group">
                  <button type="submit" disabled={this.state.errors.errors.length > 0} className={"BRL Wide100 Padding5px FuturaH FCWhite FS38 NoBorder NoOutline BottomShadow7 BorderRadius5 BCPrimary TopMargin10px " + (this.state.errors.errors.length > 0 ? 'BCPrimaryDisabled' : 'BCPrimary')} onClick={(e) => this.handleSubmit(e)} >
                    <div className="BRL Wide90 VPadding3px FS39">Register your business</div>
                    <div className="BRL Wide10 VPadding3px "><MoonLoader size={20} color={"#ffffff"} loading={this.state.loading}/></div>
                  </button>
                </div>
            </div> 
    
        </div>
      </div>
      );
  }
}

const mapStateToProps = (state) => { return {isAuthenticated: state.status.token !== null && state.status.token !== '' ? true : false, status:state.status, user:state.user, settings:state.settings, architecture:state.architecture};}
const mapDispatchToProps = dispatch => {
  return {
    isBusiness: (bBusiness) => dispatch(actions.isBusiness(bBusiness)),
    navigate: (direction, history) => dispatch(actions.navigate(direction, history)), 
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterBusiness));
