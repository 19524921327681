
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Ad from "../business/ad";
import Event from "../business/event";
import Price from "../business/price";
import { styleMapping } from "../utils/tools";
import * as design from "../../resources/constants/design";
import $ from 'jquery';
// import PaypalExpressBtn from 'react-paypal-express-checkout';
import { formatData } from "../services/geolocation";
import VisualStats from "../elements/visualStats";
import InputAd from "../business/inputAd";
import Custom from "../core/custom";
import Error from "../business/error";
import CheckBoxCustom from "./checkBoxCustom";
const width = $(window).width();
const option = {label:"Accept user terms", isSelected: false, onCheckboxChange: 'onCheckboxChange' };


export default class InfoModal extends Component {
  constructor(props) {
    super(props);
    this.state = { paypalFeedback: {}, data: null };
  }
  
  async componentDidMount(){
    this.setState({bResult: false, data: this.props.data});
  }
  
  UNSAFE_componentWillReceiveProps(props){
    this.setState({bResult: false, data: props.data});
  }
    

  // ############################ RENDER ###################################
  // ############################ RENDER ###################################
  render() {
    const {onClose, type, show, data } = this.props;
    const { details } = this.props;
    let adData = null;
    let size = (width < 981 ? 'lg' : 'md');

    switch(type){
      default:break;
      case 'advertisement': 
        this.config = design.MODAL_PAYMENT; 
        const temp = JSON.parse(JSON.stringify(details));
        adData = formatData(true, temp['features'][0]);
      break;
      case 'geolocation': 
      this.config = design.MODAL_GEOLOCATION; break;
      case 'gdpr': 
      this.config = design.MODAL_GDPR; break;
      case 'dashboard': 
      this.config = design.MODAL_DASHBOARD; break;
      case 'profile': 
      this.config = design.MODAL_PROFILE; break;
      case 'popUp': 
      this.config = design.MODAL_POPUP; break;
      case 'input': 
      this.config = design.MODAL_INPUT; break;
      case 'stats':
      this.config = design.MODAL_STATS; break; 
      case 'reimbursment':
      this.config = design.MODAL_REIMBURSMENT; break;
    }

    return (
      <Modal centered={type !== 'stats'} backdrop={true} show={show} size={size} className={"modal z7000 " + this.config.background} style={styleMapping(this.config.style.container)} onHide={() => {onClose()}}>
      {this.config.content.title !== '' &&
      <Modal.Header style={styleMapping(this.config.style.header)} className="z200" closeButton>
        <Modal.Title id="modal1" className={"BRL Wide100"}><div className={"" + this.config.style.title.class} style={styleMapping(this.config.style.title)}>{this.config.content.title}</div></Modal.Title>
      </Modal.Header>}

      <Modal.Body className="p-0" style={{maxHeight: '70vh', zIndex:90, overflow:'auto'}}><React.Fragment>          
        
      {/* // ############################ BODY ################################### */}
      {/* // ############################ BODY ################################### */}
      {this.config.content.body !== '' && <div className={'' + this.config.style.body.class} style={styleMapping(this.config.style.body)} >{this.config.content.body}</div>}
     
        
        {/* // ############################ AVERTISEMENT ################################### */}
        {/* // ############################ AVERTISEMENT ################################### */}
        {!this.state.bResult && type === 'advertisement' && <React.Fragment>
          {this.config.style.paypal.display && <React.Fragment>
            <Event data={adData} style={design.EVENT} type='preview2'></Event>
            <Price data={adData['pricing']}></Price>
          </React.Fragment>}
        </React.Fragment>}

      {/* // ############################ POPUP ################################### */}
      {/* // ############################ POPUP ################################### */}
      {type === 'popUp' && <React.Fragment >
          {this.config.content.body !== '' && <div className={'' + this.config.style.body.class} style={styleMapping(this.config.style.body)}>
            {this.config.content.body}
          </div>}
          {this.state.data && <Ad data={this.state.data} />}
      </React.Fragment>}

      {/* // ############################ INPUT ################################### */}
      {/* // ############################ INPUT ################################### */}
      {type === 'input' && <React.Fragment >
          {this.config.content.body !== '' && <div className={'' + this.config.style.body.class} style={styleMapping(this.config.style.body)}>
            {this.config.content.body}
          </div>}
          <div className={"BRL col-lg-12 col-md-12 col-sm-12 p-0"} >   
            {data && <InputAd data={data} onSubmit={(data, images, bSubmit, typeAction) => {
              this.props.handleActivitySubmit(data, images, bSubmit, typeAction);
            }} disabled={data.id_event === 'new' && data.title === ''} />}
            {this.props.errors['complete'].length > 0 && <div className={"BRL Wide100"} >
              {data.id_event === 'new' && <Error style={design.ERROR} data={this.props.errors}/>}
            </div>}
          </div>
      </React.Fragment>}

      {/* // ############################ POST AVERTISEMENT A CHANGER ################################### */}
      {/* // ############################ POST AVERTISEMENT A CHANGER ################################### */}
      {this.state.bResult && 
        <div className={"BRL Wide100 Padding10px BCGray1 z100"}>
          <div className={"BRL Wide100 TitleFont FCBlack FS36 CenterAlign Padding10p TopMargin20px"} style={{minHeight:'50px'}}>
            {this.state.paypalFeedback['event'] === 'success' && <div className={"Wide80 MarginAuto TitleFont FCBlack FS36"} style={{minHeight:'50px'}}>Your payment was successfully processed. Your ad has been added to our system and will be visible during the date and time specified. An email confirmation has been sent to {adData['business']['email']} </div>  }
            {this.state.paypalFeedback['event'] !== 'success' && <div className={"Wide80 MarginAuto TitleFont FCBlack FS36"} style={{minHeight:'50px'}}>No payment has been processed. Your ad has not been added to our system.</div>  }
          </div>
          {this.state.paypalFeedback['event']!=='success' && <div className={"BRL Wide100 TitleFont FS35 CenterAlign Padding10 TopMargin20"} style={{minHeight:'50px'}}>
              <Button className="MarginAuto Wide60 btn-md btn-primary ButtonFont BottomShadow7 Capital" onClick={()=>this.handleRetry()}>Retry</Button>
          </div>}
        </div>}
      </React.Fragment>
       
      {/* // ############################ GDPR ################################### */}
      {/* // ############################ GDPR ################################### */}
      {type === 'gdpr' && 
      <div className={"BRL Wide100 Padding0px BCGray1 z100 Flex FlexCol"} style={{maxHeight: '40vh'}}>
        <div className={"BRL Wide100 Padding10px Flex5 OverflowAuto"} style={{}}>
          <Custom type={'custom'} reference={'q6IMjZXvXZUbTwT3bVFA'} />
        </div>
        <div className={"Flex1 Padding5px BCGray1 z100 "}>
          <div className={"BRL Wide100 Padding0px TopMargin0px CenterAlign "}>
            <CheckBoxCustom  label={option.label} isSelected={option.isSelected} onCheckboxChange={() => {this.setState({bTerms: true})}} key={option.key}/>
          </div>
          <button type="button" disabled={!this.state.bTerms} className="BRL Wide100 btn btn-secondary TopMargin20px" onClick={() => this.props.onAgreed()}>Proceed</button>
        </div>

      </div>}

      {/* // ############################ STATS ################################### */}
      {/* // ############################ STATS ################################### */}
      {type === 'stats' && 
      <div className={"BRL Wide100 z100 Flex FlexCol"} style={{maxHeight: '100vh'}}>
        <VisualStats config={design.CHARTCONFIG} data={this.props.data}/>
      </div>}


      {/* // ############################ REIMBURSMENT ################################### */}
      {/* // ############################ REIMBURSMENT ################################### */}
      {type === 'reimbursment' &&<React.Fragment >
          <div className={"BRL col-lg-12 col-md-12 col-sm-12 p-1"} >   
            <div className={"BRL col-lg-12 col-md-12 col-sm-12 p-1 Flex JustifyCenter AlignCenter"}>
              <div className={"Padding10px btn btn-success"}>EUR {this.props.data}</div>  
            </div>
            <div className={"BRL col-lg-12 col-md-12 col-sm-12 p-1 TopMargin20px Flex FlexRow"}>
              <button type="button" className="Flex1 btn btn-primary " onClick={() => {this.props.onAction('deleting');}}>Confirm</button>
              <button type="button" className="Flex1 btn btn-light " onClick={() => {this.props.onAction('visible');}}>Cancel</button>
            </div>
          </div>
      </React.Fragment>}

      {/* // ############################ FOOTER ################################### */}
      {/* // ############################ FOOTER ################################### */}
      {this.config.style.footer.display && <div className="modal-footer"><button type="button" className="btn btn-secondary" onClick={onClose}>OK</button></div>}
      

      </Modal.Body>
      
    </Modal>
    );

  }
  
    // ############################ HANDLE EVENTS ###################################
    // ############################ HANDLE EVENTS ###################################
    handleRetry = () => {
      this.setState({bResult: false});
    };
    
    
    // ############################ HANDLE EVENTS ###################################
    // ############################ HANDLE EVENTS ###################################
    onSuccess = e => {
      // console.log('PAYPAL SUCCESS : ', e);    
      e['event'] = 'success';
      this.setState({bResult: true, paypalFeedback: e});
      this.props.onSuccess(e);
    };
    
    onError = e => {
      // console.log('PAYPAL ERROR : ', e);
      e['event'] = 'error';
      this.setState({bResult: true, paypalFeedback: e});
    };
    
    onCancel = e => {
      e['event'] = 'cancel';
      this.setState({bResult: true, paypalFeedback: e});
      // console.log('PAYPAL CANCEL : ', e);
    };
}