import React, { Component } from 'react'
import { styleMapping, generateReference } from "../utils/tools";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ContentEditable from "react-contenteditable";
import { Input } from "reactstrap";
import Select from "../elements/select";
import { extractImages } from "../services/editor";
import * as server from "../../resources/constants/api";
import $ from 'jquery';

export default class CIMAGE extends Component {
    constructor(props) {
        super(props)
        this.state = { structure: null, editor: props.editor, images: null, params: null, element: null }
    }

    componentDidMount(){ this.loadData(this.props); }
    UNSAFE_componentWillReceiveProps(props){ this.loadData(props); }
    loadData = async (data)  => { 
        // console.log('CIMAGE LOAD DATA 1 => ', data.structure, ' => ', data.images);
        let tempImages = await extractImages(data.structure['tag'], data.structure);;
        // console.log('CIMAGE LOAD DATA 2 => ', tempImages);
        if (data !== null && data !== undefined) {        
            if(data.images !== null && data.images !== undefined){tempImages = data.images; }   
        }
        this.setState({structure: data.structure, editor: data.editor, images: tempImages}, function(){
            if (tempImages.length > 0){$('#0_ci').addClass('active');}
        });
    }

    // ############################ HANDLE NEW IMAGE ###################################
    // ############################ HANDLE NEW IMAGE ###################################
    deleteImage = (key, index) => {
        let tempImages = this.state.images;
        let tempImages2 = this.state.structure.structure.carousel;
        // console.log('DELETE IMAGE 0 => ', key, ' => ', tempImages[key]);

        if (tempImages[key]['action'] === 'new'){
            tempImages.splice(key, 1);
            tempImages2.splice(index, 1);
        }
        else {
            tempImages[key]['action'] = 'delete';
            tempImages[key]['url'] = tempImages[key]['url'].replace(new RegExp(server.URL_SERVER_DJANGO_IMAGE, "g"), '');
            tempImages2.splice(index, 1);
        }
        // console.log('^^^^^^^^^^^^^^^^^^^^^^ DELETE IMAGE 1 => ', key, ' => ', tempImages);
        this.setState({'params': 'structure', element: this.state.structure.structure, 'images': tempImages},function(){
            this.handleChange(this.state.params, 'carousel', tempImages2);
            if (tempImages.length > 0){$('#0_ci').addClass('active');}
        });
    }

    
    handleImage = (e) => {
        // console.log('HANDLE IMAGE');
        const input = e.target;
        if (input.files && input.files.length) {
            if (input.files && input.files[0]) {
                const reader = new FileReader();
                reader.onloadend = (e) => {
                let image = reader.result;
                let tempImages = this.state.images;
                let tempImages2 = this.state.structure.structure.carousel;
                const refGen = generateReference(20); 
                const refRelative = server.URL_SERVER_DJANGO_IMAGE_RELATIVE + '/' + this.state.structure.reference + '/' + refGen + '.png';
                const ref = server.URL_SERVER_DJANGO_IMAGE + '/' + this.state.structure.reference + '/' + refGen + '.png';
                tempImages.push({url: image, action: 'new', path: refRelative, pathAbsolute: ref});
                tempImages2.push({ "action": "", "image":{"class": "Wide120", "style": "", "url": refRelative}, 
                "elements": [{"display": false, "element": {"text": "Insert text", "background": "", "color": "#ad182F", "style": "padding:5px;font-weight:600;", "class": "Wide90 Absolute Bottom15 Right5 FS20 Bold RightAlign", "font": "Metropolis", "link": ""}}]});
                this.getParams('structure', this.state.structure.structure);
                this.setState({'images': tempImages});

                // console.log(refGen, ' => NEW IMAGE => ', tempImages2);
                this.handleChange(this.state.params, 'carousel', tempImages2);};
                reader.readAsDataURL(input.files[0]);
            }
        }
    } 
     
    // ############################ GET PARAMS WHEN SUBELEMENT CLICKED ###################################
    // ############################ GET PARAMS WHEN SUBELEMENT CLICKED ###################################
    getParams = (params, data) => {
        // console.log('GETPARAMS => ', params, ' => ', data);
        this.setState({element: data, params: params});
        this.props.onChange(params, this.state.structure, this.state.images, true);
    }

    // ############################ HANDLE INPUT CHANGE ###################################
    // ############################ HANDLE INPUT CHANGE ###################################
    handleChange(params, field, e){
        let temp = this.state.element;
        switch(field){
            case 'font': temp[field] = e.value; break;
            default: temp[field] = e; break;
        }
        // console.log(e ,' => HANDLECHANGE => ', temp[field], ' => ', params, ' => ', this.state.structure);

        this.setState({element: temp});
        this.props.onChange(params, this.state.structure, this.state.images, false);
    } 


    // ############################ HANDLE INPUT CHANGE ###################################
    // ############################ HANDLE INPUT CHANGE ###################################
    toggleMessage(index){
        let temp = this.state.structure; 
        temp.structure.carousel[index].elements[0].display = !temp.structure.carousel[index].elements[0].display;
        // console.log('CIMAGE TOGGLE MESSAGE => ', index, ' => ', temp.structure.carousel, ' => ', temp.structure.carousel[index].elements[0]['display']);

        switch(temp.structure.carousel[index].elements.display){
            case true: this.getParams('temp.structure.carousel['+index+'].elements[0].element', temp.structure.carousel[index].elements[0].element);break;
            case false: this.getParams(null, null);break;
            default: break;
        }
        this.setState({structure: temp});
        this.props.onChange('structure', temp, this.state.images, false);
    }

    // ############################ RENDER ###################################
    // ############################ RENDER ###################################
    render() {
         
        if(this.state.structure){   

            this.index = -1;
            this.index2 = -1;
            const { editor, images } = this.state;
            const { structure } = this.state.structure;

            if(isNaN(structure.container.height) === false && structure.container.height!== ''){this.height = parseFloat(structure.container.height) * parseInt(window.innerHeight, 10);}
            else { this.height = 'auto'; }

            if(editor === true){   
                // console.log('@@@@@@@@@@@@@@@@@@ CIMAGE RENDER 2 => ', images, ' => ', structure.carousel);
                return (
                        <div key={'cw'} className="BRL Wide100">

                            {/* ##################### FIELD PARAMETERS ##################### */}
                            {/* ##################### FIELD PARAMETERS ##################### */}
                            {this.state.element && <div className="BRL Wide100 Padding10px BottomShadow7 BorderRadius5 BottomMargin20px">
                                <div className={"BRL Wide100 FS38 TitleFont BottomMargin5px HPadding10px Capital"}>{this.state.params}</div>
                                {Object.keys(this.state.element).map((key, index) => {
                                return (
                                    <React.Fragment key = {index}>
                                    {key !== 'text' && key !== 'url' && key !== 'carousel' && key !== 'structure' && key !== 'container' && 
                                    <li key={ 'c_' + key} className={"BRL NoBorder TopMargin5px HPadding5px " + ((key === 'class' || key === 'style') ? "col-lg-6 col-md-6 col-sm-12" : "col-lg-3 col-md-3 col-sm-4")} style={{listStyleType: 'none'}}>
                                    <div key={ 'l_' + key} className="BRL Wide100 BottomMargin2px ButtonFont Capital FS41">{key}</div>             
                                    
                                    {(key !== 'font' && key !== 'color' && key !== 'background') && <Input key={'f1_'+ key} type={(key === 'color' || key === 'background' ? 'color' : 'text')} name={key} onChange={(e) => this.handleChange(this.state.params, key, e.target.value)} value={this.state.element[key]} className={"BRL Wide100 NoBorder btn-light BCTrans InputFont FS40 TopMargin5px BottomShadow7"}/>}
                                    {(key === 'color' || key === 'background') && 
                                        <React.Fragment>
                                        <Input key={'f1_'+ key} type={'color'} name={key} onChange={(e) => this.handleChange(this.state.params, key, e.target.value)} value={this.state.element[key]} className={"BRL Wide30 NoBorder btn-light BCTrans InputFont FS40 TopMargin5px BottomShadow7"}/>
                                        <Input key={'f2_'+ key} type={'text'} name={key + '_code'} onChange={(e) => this.handleChange(this.state.params, key, e.target.value)} value={this.state.element[key]} className={"BRL Wide65 NoBorder btn-light BCTrans InputFont FS40 TopMargin5px LeftMargin5 BottomShadow7"}/>
                                        </React.Fragment>}
                                    {(key === 'font') && 
                                        <Select key={'f2_'+ key} name={key} id={'font'} value={this.state.element[key]} fields={key} 
                                        classes={{container:"BRL form-control NoBorder FS40 LabelFont Padding3px btn-light BCTrans", options:"BRL Wide100 TopMargin5px CenterAlign BottomShadow7"}}  
                                        onChange={(name, val) => this.handleChange(this.state.params, key, val)}></Select>}
                                    </li>}
                                    </React.Fragment>
                                );})}
                            </div>}

                            {/* ##################### DESIGN EDITOR ##################### */}
                            {/* ##################### DESIGN EDITOR ##################### */}
                            <div className={"" + structure.container.class} style={{ ...{height: this.height},...styleMapping(structure.container)}}>
                                {editor && <div onClick={() => {this.getParams('structure.container', structure.container)}} className={"Hand Absolute Top0px Right0px Padding8px btn-light BorderRadius5 BottomShadow7 z1000 "}><FontAwesomeIcon icon={"pen"} size="1x" color="black"/></div> }
                                    {/* IMAGES */}
                                    {images!== null && <div id="carousel2Edit" className="carousel slide High100" data-ride="carousel">
                                        
                                        {/* ##################### INDICATORS ##################### */}
                                        <ol className="carousel-indicators">                                        
                                            {Object.keys(images).map((key) => {
                                                this.index2 = this.index2 + 1;
                                                if(images[key]['action'] === 'delete') { this.index2--;}
                                                if(images[key]['action'] !== 'delete'){return (
                                                    <React.Fragment key={'inn_c_' + this.index2}>
                                                        {images[key]['action'] !== 'delete' && <li data-target="#carousel" data-slide-to={key} className={(this.index2 === 0 ? "active" : "")}></li>}
                                                    </React.Fragment>
                                                );}
                                                return null;
                                            })}
                                        </ol>
 
                                        {/* ##################### IMAGES ##################### */}
                                        <div className="carousel-inner High100">
                                            {Object.keys(images).map((key) => {
                                                this.index = this.index + 1;
                                                if(images[key]['action'] === 'delete') { this.index--;}
                                                const index = this.index;
                                                
                                                // console.log('###### images => ', key, ' =>  ', '' ,' => ', index);
                                                // if(images[key]['action'] !== 'delete'){console.log('DISPLAY => ', key, ' ======> ', this.index);}
                                                return (
                                                    <React.Fragment key={'inn_c_e_' + key}>
                                                    {images[key]['action'] !== 'delete' && <div id={index + '_ci'} key={key + '_i'} className={"Wide100 High100 carousel-item " + key + "-" + index + " " + (this.index < 1 ? "" : "" )}>
                                                        <div className={"High100 Flex FlexRow JustifyCenter AlignCenter"}>
                                                            <img className={"d-block " + structure.carousel[index].image.class} style={styleMapping(structure.carousel[index].image)} src={images[key]['url']} alt={"Image " + index} />
                                                        </div>
                                                        {structure.carousel[this.index] && <React.Fragment key={'text_e'}>
                                                            {structure.carousel[this.index].elements.map((element, key2) => {
                                                                return (<React.Fragment key={'text2_e'}> {element['display'] === true && <ContentEditable html={element.element.text} disabled={false} onChange={(e) => {this.handleChange('structure.carousel['+ key +'].elements['+ key2 +']', 'text', e.target.value)}} onClick={() => {this.getParams('structure.carousel['+ this.index +'].elements['+ key2 +'].element', structure.carousel[this.index].elements[key2].element)}} className={"Hand NoBorder NoOutline btn-light " + element.element.class} style={styleMapping(element.element)} />}</React.Fragment>); 
                                                            })}
                                                        </React.Fragment>} 
                                                    
                                                        {/* ##################### ACTIONS ##################### */}
                                                        {/* ##################### ACTIONS ##################### */}
                                                        <div key="actions" className={"Hand Absolute Bottom10px LeftMargin10px"}>
                                                            <div onClick={() => {this.getParams('structure.carousel[' + index + '].image', structure.carousel[index].image)}} className={"structure.carousel[" + index + "].image " + " Hand BRL LeftMargin2px VPadding5px HPadding5px BorderRadius50 BottomShadow7 z2000 btn btn-light"}>
                                                                <button className={"BRL Wide100 NoBorder Capital"} style={{'color':'inherit', 'backgroundColor':'inherit'}}>
                                                                    <FontAwesomeIcon icon={"pen"} size="sm" color="black"/>
                                                                </button>    
                                                            </div>
                                                            <div onClick={() => {this.deleteImage(key, index)}} className={"Hand BRL LeftMargin2px VPadding5px HPadding5px BorderRadius50 BottomShadow7 z2000 btn btn-danger"}>
                                                                <button className={"BRL Wide100 NoBorder Capital"} style={{'color':'inherit', 'backgroundColor':'inherit'}}>
                                                                    <FontAwesomeIcon icon={"trash"} size="sm" color="white"/>
                                                                </button>    
                                                            </div>
                                                            <div onClick={() => {this.toggleMessage(index);}} className={"Hand BRL LeftMargin1px VPadding5px HPadding5px BorderRadius50 BottomShadow7 z2000 btn btn-info"}>
                                                                <button key={'add_video'} className={"BRL Wide100 NoBorder Capital"} style={{'color':'inherit', 'backgroundColor':'inherit'}}>
                                                                    <FontAwesomeIcon icon={"edit"} size="sm" color="white"/>
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>}
                                                    </React.Fragment>
                                                );
                                                
                                            })}
                                        </div>

                                        {/* ##################### ARROWS ##################### */}
                                        {/* ##################### ARROWS ##################### */}
                                        {images.length > 1 && <React.Fragment key={'action_c_'}>
                                        <a className="carousel-control-prev" href="#carousel2Edit" role="button" data-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Previous</span>
                                        </a>

                                        <a className="carousel-control-next" href="#carousel2Edit" role="button" data-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                        </React.Fragment>}  
                                    </div>}

                                    {/* ##################### ADD IMAGE ##################### */}
                                    {/* ##################### ADD IMAGE ##################### */}
                                    <div className={"Hand Absolute Bottom10px LeftMargin30 Wide40 Padding10px BorderRadius50 BottomShadow7 z2000 btn btn-primary"}>
                                        <button key={'add_image'} className={"BRL Wide100 FS40 ButtonFont NoBorder Capital"} style={{'color':'inherit', 'backgroundColor':'inherit'}}>add image</button>
                                        <input onChange={(e)=> this.handleImage(e)} className="Hand Absolute Wide100 High100 Left0px Top0px z1000 Hand" type="file" style={{opacity: 0}} />        
                                    </div>

                                {/* </div> */}
                
                            </div>
                        </div>        
                );
            } else {
                return (<div key={'cw_e'} className={'BRL Wide100'} style={{ height: this.height}}>
                            {/* IMAGES */}
                                {images!== null && <div key={'carousel_'} id="carousel2" className="carousel slide High100" data-ride="carousel">
                                    
                                    {/* ##################### INDICATORS ##################### */}
                                    <ol key={'ind_'} className="carousel-indicators">                                        
                                        {Object.keys(images).map(key => {
                                            return (
                                                <React.Fragment key={'ci_c_' + key}>
                                                    {images[key]['action'] !== 'delete' && <li key={'ci_' + key} data-target="#carousel" data-slide-to={key} className={(key === 0 ? "active" : "")}></li>}
                                                </React.Fragment>
                                            ); 
                                        })}
                                    </ol>

                                    {/* ##################### IMAGES ##################### */}
                                    <div className="carousel-inner High100">
                                        {Object.keys(structure.carousel).map(key => {
                                            this.index = this.index + 1;
                                            // console.log('C-IMAGES DISPLAY  => ', this.index, ' => ', structure.carousel[this.index], ' ======> ',  structure.carousel);
                                            return (
                                                <React.Fragment key={'inn_c_e_' + key}>
                                                <div key={key + '_i'} className={"Wide100 High100 carousel-item " + key + " " + (this.index === 0 ? "active" : "")}>
                                                    <div className={"High100 Flex FlexRow JustifyCenter AlignCenter"}>
                                                        <img className={"d-block " + structure.carousel[this.index].image.class} style={styleMapping(structure.carousel[this.index]['image'])} src={server.URL_SERVER_DJANGO_IMAGE + structure.carousel[this.index]['image']['url']} alt={"Image " + this.index} />
                                                    </div>
                                                    {structure.carousel[this.index] &&
                                                     <React.Fragment key={'text_e'}>
                                                        {structure.carousel[this.index].elements.map((element, key2) => {
                                                            return (<React.Fragment key={'text2_e'}> {element['display'] === true && <ContentEditable html={element.element.text} disabled={false} onChange={(e) => this.handleChange('structure.carousel['+ this.index +'].elements['+ key2 +']', 'text', e.target.value)} onClick={() => {this.getParams('structure.carousel['+ this.index +'].elements['+ key2 +'].element', structure.carousel[this.index].elements[key2].element)}} className={"Hand NoBorder NoOutline " + element.element.class} style={styleMapping(element.element)} />}</React.Fragment>); 
                                                        })}
                                                    </React.Fragment>
                                                    } 
                                                </div>
                                                </React.Fragment>
                                            );
                                            
                                        })}
                                    </div>

                                    {/* ##################### ARROWS ##################### */}
                                    {/* ##################### ARROWS ##################### */}
                                    {images.length > 1 && <React.Fragment key={'action_c_'}>
                                    <a key={'prev_'} className="carousel-control-prev" href="#carousel2" role="button" data-slide="prev">
                                        <span key={'1_'} className="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span key={'2_'} className="sr-only">Previous</span>
                                    </a>

                                    <a key={'next_'} className="carousel-control-next" href="#carousel2" role="button" data-slide="next">
                                        <span key={'1_'} className="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span key={'2_'} className="sr-only">Next</span>
                                    </a>
                                    </React.Fragment>}  
                                </div>}

            
                        </div>
                        );
            }

            } else return null;
    }
}

