import React, { Component} from "react";
import { Route, Switch, Redirect,withRouter  } from "react-router-dom";
import Admin from "./admin/admin";
import Editor from "./editor/editor";
import Server from "./admin/server";
import LoginFormAnt from "./auth/loginForm";
import Forgotten from "./auth/forgotten";
import Custom from "./core/custom";
import Profile from "./core/profile";
import Home from "./core/home";
import * as actions from '../store/pseudo/general';
import { connect } from 'react-redux';
import RegisterBusiness from "./business/registerBusinessForm";
import DashBoard from "./business/dashboard";
import Monitor from "./admin/monitor";
import Marketing from "./business/marketing";
import GeoLocation from "./geolocation/geolocation";
import Website from "./design/website";
import Data from "./admin/data";

class BaseRouter extends Component {
  render() {    

      // this.props.sideMenuFunction(false);

      return (
        <Switch>
          <Route path="/login" render={ () => {return <LoginFormAnt settings={this.props.settings} /> }} />
          <Route path="/forgotten" render={ () => {return <Forgotten settings={this.props.settings} /> }} />
          <Route path="/maps" render={ () => {return <GeoLocation settings={this.props.settings} user={this.props.user} />  }} />
          <Route path="/business/dashboard" render={ () => { return <DashBoard settings={this.props.settings} user={this.props.user} /> }} />
          <Route path="/business/marketing" render={ () => { return <Marketing settings={this.props.settings} user={this.props.user} /> }} />
          
          <Route path="/business" render={ () => { 
            if(!this.props.user.isBusiness) { return <Custom type={'custom'} structure={'business'} /> }
            else return <Custom type={'custom'} structure={'business'} /> }} />
          
          <Route path="/registerBusinessForm" render={ () => { 
            if(this.props.isAuthenticated){ 
               if(this.props.user.isBusiness){ return <DashBoard settings={this.props.settings} user={this.props.user} /> } else return <RegisterBusiness settings={this.props.settings}/> 
            } else return <LoginFormAnt settings={this.props.settings}/>
           }} />
          
          <Route path="/admin/dashboard/data" render={ () => {if(this.props.user.isAdmin) {return <Data settings={this.props.settings} />} else {return <GeoLocation settings={this.props.settings} />} }} />
          <Route path="/monitor" render={() => {if(this.props.user.isAdmin) return <Monitor settings={this.props.settings} /> }} />
          <Route path="/editor" render={() => {if(this.props.user.isAdmin) return <Editor settings={this.props.settings} /> }} />
          <Route path="/admin" render={() => {if(this.props.user.isAdmin) return <Admin settings={this.props.settings} /> }} />
          <Route path="/server" render={() => {if(this.props.user.isAdmin) return <Server settings={this.props.settings} /> }} />
          <Route path='/profile/:idUser' render={(props) => <Profile {...props} settings={this.props.settings} />} />
          <Route path='/custom/:reference' render={(props) => <Custom type={'custom'} structure={''} {...props} settings={this.props.settings} />} />
          <Route path='/website/:reference' render={(props) => <Website {...props} settings={this.props.settings} />} />
          <Route path="/" render={ () => {return <Home settings={this.props.settings} /> }} />
          <Redirect from="/index.html " exact to="/" />
          <Redirect from="/notFound " exact to="/maps" />
          <Redirect from="/" exact to="/" />
          <Redirect from="/index"  to="/" />
      </Switch>
      );
  }
}

const mapStateToProps = (state) => { return {isAuthenticated: state.status.token !== null && state.status.token !== '' ? true : false, status:state.status, user:state.user, settings:state.settings, architecture:state.architecture};}
const mapDispatchToProps = dispatch => {return {sideMenuFunction: (bVal) => dispatch(actions.sideMenu(bVal))}}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BaseRouter));
