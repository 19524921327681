import React, {Component} from "react";
import { isTrue} from "../utils/tools"
import Input from '../elements/input';
import * as constants from "../../resources/constants/design";
import 'react-inputs-validation/lib/react-inputs-validation.css';
import MoonLoader from "react-spinners/ClipLoader";

export default class InputData extends Component {
  constructor(props) {
    super(props);
    this.state = { loading:{add:false, remove:false, removeAll:false}, data: {latitude:props.data.latitude, longitude:props.data.longitude, quantity:props.data.quantity, scale:props.data.scale, unit:props.data.unit, duration:props.data.duration}, bDisabled: false }; 
    this.validFields = {quantity:true, scale:true, unit:true, duration:true};
  }

  // ############################# DISPLAY LOGIN FORM #############################
  // ############################# DISPLAY LOGIN FORM #############################
  render() {
        if(this.state.data.latitude !== this.props.data.latitude || this.state.data.longitude !== this.props.data.longitude){
        let temp = this.state;
        temp.data.latitude = this.props.data.latitude;
        temp.data.longitude = this.props.data.longitude;
        this.setState(temp);
        return null;
      } else {
        return (
          <div className="BRL Wide100 HPadding10px VPadding10px BCGray1 BottomShadow7">
            <div className="BRL Wide100 form-group">
              <div type="submit" className={"BRL Wide100 TitleFont FS39 VPadding10px  NoBorder Capital "} >DATA GENERATOR</div>
            </div>
            <div className="BRL Wide100 TopMargin5px BottomMargin30px">
              <Input onChange={(type, field, e, validation) => {this.handleChange('', field, e); this.manageFields(field, validation);}} value={this.state.data.latitude} config={constants.INPUTDATA.latitude} disabled={true} />
              <Input onChange={(type, field, e, validation) => {this.handleChange('', field, e); this.manageFields(field, validation);}} value={this.state.data.longitude} config={constants.INPUTDATA.longitude} disabled={true} />
              <Input onChange={(type, field, e, validation) => {this.handleChange('', field, e); this.manageFields(field, validation);}} value={this.state.data.quantity} config={constants.INPUTDATA.quantity} />
              <Input onChange={(type,  field, e, validation) => {this.handleChange('', field, e); this.manageFields(field, validation);}} value={this.state.data.duration} config={constants.INPUTDATA.duration} />
              <Input onChange={(type, field, e, validation) => {this.handleChange('', field, e); this.manageFields(field, validation);}} value={this.state.data.scale} config={constants.INPUTDATA.scale} />
              <Input onChange={(type, field, e, validation) => {this.handleChange('', field, e); this.manageFields(field, validation);}} value={this.state.data.unit} config={constants.INPUTDATA.unit} disabled={true} />
            </div>
            <div className="BRL Wide100 form-group BottomMargin0px ">
              <button disabled={this.state.bDisabled} type="submit" className={"BRL Wide100 BottomMargin10px BorderRadius10 TitleFont FCWhite FS40 VPadding0px HPadding20px NoBorder Capital " + ((!this.state.bDisabled || !this.props.disabled) ? 'BCPrimary' : 'BCPrimaryDisabled') } onClick={() => this.handleSubmit('add')}>
                <div className="BRL Wide90 VPadding8px FS40">GENERATE DATA</div>
                <div className="BRL Wide10 VPadding5px"><MoonLoader size={20} color={"#ffffff"} loading={this.state.loading.add}/></div>
              </button>
              
              <button disabled={this.state.bDisabled} type="submit" className={"BRL Wide100 BottomMargin10px BorderRadius10 TitleFont FCWhite FS40 VPadding0px HPadding20px NoBorder Capital " + ((!this.state.bDisabled || !this.props.disabled) ? 'BCPrimary' : 'BCPrimaryDisabled') } onClick={() => this.handleSubmit('remove')}>
                <div className="BRL Wide90 VPadding8px FS40">REMOVE ZONE DATA</div>
                <div className="BRL Wide10 VPadding5px"><MoonLoader size={20} color={"#ffffff"} loading={this.state.loading.remove}/></div>
              </button>

              <button disabled={this.state.bDisabled} type="submit" className={"BRL Wide100 BottomMargin10px BorderRadius10 TitleFont FCWhite FS40 VPadding0px HPadding20px NoBorder Capital " + ((!this.state.bDisabled || !this.props.disabled) ? 'BCPrimary' : 'BCPrimaryDisabled') } onClick={() => this.handleSubmit('removeAll')}>
                <div className="BRL Wide90 VPadding8px FS40">REMOVE ALL DATA</div>
                <div className="BRL Wide10 VPadding5px"><MoonLoader size={20} color={"#ffffff"} loading={this.state.loading.removeAll}/></div>
              </button>

              {/* <button disabled={this.state.bDisabled} type="submit" className={"BRL Wide100 BottomMargin10px BorderRadius10 TitleFont FCWhite FS40 VPadding10px HPadding20px NoBorder Capital " + ((!this.state.bDisabled || !this.props.disabled) ? 'BCPrimary' : 'BCPrimaryDisabled') } onClick={() => this.handleSubmit('add')}>GENERATE DATA</button>
              <button type="submit" className={"BRL Wide100 BottomMargin10px BorderRadius10 TitleFont FCWhite FS40 VPadding10px HPadding20px NoBorder Capital " + ((!this.state.bDisabled || !this.props.disabled) ? 'BCPrimary' : 'BCPrimaryDisabled') } onClick={() => this.handleSubmit('remove')}>REMOVE ZONE DATA</button>
              <button type="submit" className={"BRL Wide100 BorderRadius10 TitleFont FCWhite FS40 VPadding10px HPadding20px NoBorder Capital " + ((!this.state.bDisabled || !this.props.disabled) ? 'BCPrimary' : 'BCPrimaryDisabled') } onClick={() => this.handleSubmit('removeAll')}>REMOVE ALL DATA</button> */}
            
            
            </div>
        </div>);
    } 
  }
    
  //############################# HANDLE CHANGE #############################
  //############################# HANDLE CHANGE #############################
  handleChange = (type, field, e) => {
    let temp = this.state.data;
    switch(type){
      case "": temp[field] = e; break;
      default: temp[type][field] = e; break;
    }
    // console.log('HANDLE CHANGE => ', temp);
    this.setState({data: temp});
  }

  //############################# HANDLING EMAIL LOGIN SUBMISSION #############################
  //############################# HANDLING EMAIL LOGIN SUBMISSION #############################
  handleSubmit = (action) => {

    let temp = this.state.loading
    temp[action] = true;
    this.setState({ loading: temp });
    this.props.onSubmit(action, this.state.data);
    
    setTimeout(() => {
      temp[action] = false;
      this.setState({ loading: temp });
      // toast.success("Data was generated successfully", options)
    }, 500)
  }
  
  //############################# HANDLE CHANGE #############################
  //############################# HANDLE CHANGE #############################
  manageFields = (type, val) => {
    // console.log('AAA => ', this.validFields);
    this.validFields[type] = val;
    this.setState({bDisabled: !isTrue(this.validFields)});
    // console.log(type, ', ', val , '=> ', this.validFields, ' => ', isTrue(this.validFields));
  }

}
