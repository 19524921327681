import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select2 from "../elements/select2";
import * as constants from "../../resources/constants/design";

class FilterMenu2 extends Component {
  constructor(props) {
    super(props);
    this.state = {bFilter: props.open};
    this.fields = {};
  }
  
  // ############################ HANDLE CHANGE ###################################
  // ############################ HANDLE CHANGE ###################################
  dropDownChange = (e, val)  => {
    // console.log('HANDLE FILTER  => ', e, ' => ', val);
    this.fields[e] = val;
    this.props.onFilter(this.fields);
  }
    
  // ############################ RESET FIELDS ###################################
  // ############################ RESET FIELDS ###################################
  reset = ()  => {
    for(const key in this.fields){
      this.fields[key] = '';
    }
    this.props.onFilter(this.fields);
    this.setState({});
  }
    
    
    

  // ############################# DISPLAY LOGIN FORM #############################
  // ############################# DISPLAY LOGIN FORM #############################
  render() {
    const { data } = this.props;
    // console.log('RENDER FILTER MENU => ', data);
    return (
      <div className="BRL Flex FlexCol Wide100 TopMargin0px BCWhite BottomShadow7">
          <div className='BRL col-lg-12 col-md-12 col-sm-12 NoOutline Padding7px CenterAlign AllTrans2 HeaderFont Capital btn-light Hand FS40' style={{}} onClick={() => this.setState({bFilter: !this.state.bFilter})}>Filter</div>
          {this.state.bFilter &&  <React.Fragment >
            <Select2 name={'gender'} id={'gender'} classes='BRL col-lg-12 col-md-12 col-sm-12 NoOutline VPadding5px HPadding0px AllTrans2' style={constants.MAP_FILTER} value={data['gender']} fields={'target_gender'} onChange={(e, v) => this.dropDownChange(e, v)}></Select2>
            <Select2 name={'age'} id={'age'} classes='BRL col-lg-12 col-md-12 col-sm-12 NoOutline VPadding5px HPadding0px AllTrans2' style={constants.MAP_FILTER} value={data['age']} fields={'target_age'} onChange={(e, v) => this.dropDownChange(e, v)}></Select2>
            <Select2 name={'stereotype'} id={'stereotype'} classes='BRL col-lg-12 col-md-12 col-sm-12 NoOutline VPadding5px HPadding0px AllTrans2' style={constants.MAP_FILTER} value={data['stereotype']} fields={'target_stereotype'} onChange={(e, v) => this.dropDownChange(e, v)}></Select2>
            <Select2 name={'political'} id={'political'} classes='BRL col-lg-12 col-md-12 col-sm-12 NoOutline VPadding5px HPadding0px AllTrans2' style={constants.MAP_FILTER} value={data['political']} fields={'target_political'} onChange={(e, v) => this.dropDownChange(e, v)}></Select2>
            <button className="BRL col-lg-12 col-md-12 col-sm-12 NoBorder NoOutline VPadding10px HeaderFont btn-light FCPrimary BottomShadow7 Capital FS40" onClick={() => this.reset()}>Reset</button>
          </React.Fragment>}

      </div>);
  }
  
}

export default connect(null, null)(FilterMenu2);
