import React, {Component} from "react";
import t from 'tcomb-form'
import stylesheet from '../../resources/style/customStyleTComb.js';
import * as actions from '../../store/pseudo/general';  import {connect} from 'react-redux';
import { styleMapping, scrollTo } from "../utils/tools";
import { withRouter } from 'react-router-dom';
import MoonLoader from "react-spinners/ClipLoader";
import { resetPasswordRequest } from "../services/general";

// const options2 = { autoClose: 1500, type: toast.TYPE.INFO, hideProgressBar: false};
const Form = t.form.Form;
const email = t.refinement(t.String, email => {
  const reg = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/; //or any other regexp
  return reg.test(email);
});

const formStyles = {
  ...Form.stylesheet,
  formGroup: { normal: { marginBottom: 10 } },
  controlLabel: {
    normal: { color: 'blue', fontSize: 15, marginBottom: 7, fontWeight: '600' },
    error: { color: 'red', fontSize: 12, marginBottom: 7, fontWeight: '600' }
  }
};

const options = {
  fields: {
    email: { label: '', auto: 'placeholders', stylesheet: stylesheet, error: '', attrs: { className: 'FS40 LabelFont Padding5px NoBorder BottomShadow7' }},
  },
  stylesheet: formStyles,
};


class Forgotten extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      user: {email: 'theflowerist@gmail.com'}};
    this.FormSchema = t.struct({ email: email});

    scrollTo(0,0);
}

//############################# HANDLING EMAIL LOGIN SUBMISSION #############################
//############################# HANDLING EMAIL LOGIN SUBMISSION #############################
handleSubmit = (e) => {

  this.setState({ loading: true });
  e.preventDefault();
  const values = this.refs.form.getValue();
  const errors = this.refs.form.validate();
  // console.log('HANDLE SUBMIT : ', this.state.type, ' => ', values, ' => ', errors.errors);
  if (errors.errors.length === 0) {resetPasswordRequest(values.email);}
  setTimeout(() => {
    this.setState({ loading: false });
    this.props.navigate({direction: 'login'}, this.props.history); 
    // toast.success("Login is successful", options2)
  }, 1500)
};

// ############################# HANDLE FORM CHANGE #############################
// ############################# HANDLE FORM CHANGE #############################
handleFormChange = (e) => {
  // console.log('HANDLE CHANGE : ', e);
  this.setState({user: e});
}

// ############################# DISPLAY LOGIN FORM #############################
// ############################# DISPLAY LOGIN FORM #############################
render() {
  // console.log('RENDER => ', this.state);
    return (
      <div className={ this.props.settings.structure.outlet.class} style={styleMapping(this.props.settings.structure.outlet)}>
        <div className="MarginAuto col-lg-4 col-md-5 col-sm-8 MinHigh100VH ">
          <div className="BRL Wide100 TopMargin30">

              {/* FORM */}
              <div className="BRL Wide100 TopMargin20px">
                  <Form ref="form" type={this.FormSchema} options={options} value={this.state.user} onChange={(v) => this.handleFormChange(v)} />
                  <div className="form-group">
                    <button type="submit" className={"BRL Wide100 Padding5px BCPrimary ButtonFont FCWhite FS38 NoBorder NoOutline BottomShadow7 BorderRadius10 "} onClick={(e) => this.handleSubmit(e)} >
                      <div className="BRL Wide90 VPadding3px">{(this.state.type === 'signin' ? 'Signin with email' : 'Register with email')}</div>
                      <div className="BRL Wide10 VPadding3px "><MoonLoader size={20} color={"#ffffff"} loading={this.state.loading}/></div>
                  </button>
                  
                  </div>
              </div>

            </div>
        </div>
      </div>);
}
}

const mapStateToProps = (state) => {return{status:{loading: state.loading, error: state.error, settings: state.settings,isAuthenticated: state.token !== null ? true : false,}}}
const mapDispatchToProps = dispatch => {
return {  
    navigate: (direction, history) => dispatch(actions.navigate(direction, history)), 
    onAuth: (username, password, history) => dispatch(actions.authLogin(username, password, history)), 
    onRegister: (username, password, password2, history) => dispatch(actions.authSignup(username, password, password2, history)), 
}}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Forgotten));
