import React from "react";
import { Textbox} from 'react-inputs-validation';
import 'react-inputs-validation/lib/react-inputs-validation.css';

const Input = ({ config, value, onChange, disabled, ...rest }) => {
  
  // console.log(config.name, ' DISABLED => ', disabled);

  //############################# DISPLAY FIELD #############################
  //############################# DISPLAY FIELD #############################
  return (

    <div className={config.wrapper + " "}>
      <div className="BRL FS41 LabelFont FCBlack BottomMargin5px">{config.name}</div>
      <Textbox attributesInput={{ id: config.name, placeholder: config.placeholder, type: config.type }} disabled={disabled} classNameInput={config.input} classNameContainer={config.container} value={value} onChange={(e) => {
          
          if (config.regex){
            if (config.regex.test(String(e).toLowerCase())) { 
              if (config.extra === 'date') {
                const today = new Date();
                const temp = new Date(e.toString());
                if (temp < today) { return onChange(config.type, config.name, e, false);} 
              } 
              return onChange(config.type, config.name, e, true);   
            } 
            return onChange(config.type, config.name, e, false);
          } else {
            return onChange(config.type, config.name, e, true);   
          }
        }} 

        onBlur={e => {}}  validationOption={{ name: config.field, check: true, required: false, 
          customFunc: e => {
            if (config.regex){
              if (config.regex.test(String(e).toLowerCase())) { 
                if (config.extra === 'date') { 
                  let today = new Date();today.setHours(0,0,0,0);
                  let temp = new Date(e.toString());temp.setHours(0,0,0,0);
                  if (temp >= today) {
                    return true;
                  } else return 'selected day has already passed';  
                }
                else return true; 
              } else { 
                // console.log('ONBLUR ERROR => ', config.regex, ' => ', config.error);
                return config.error; 
              } 
            } else {
              return true;
            }
          }}}
      />
    </div>
  );
};

export default Input;
