import React, { Component } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
// import faker from '@faker-js/faker';
import * as chartStatic from '../../resources/constants/charts';
import { getLabel } from "../services/monitor";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = { data: props.data, type: props.config, chartData: null };
  }

  componentDidMount(){
    // console.log('CHARTS CDM => ', this.props);
    this.processData(this.props.data, this.props.config, this.props.endDate);
  }

  UNSAFE_componentWillReceiveProps(props){
    // console.log('------CHARTS WRP => ', props);
    this.processData(props.data, props.config, props.endDate);
   
  }


  // ############################ RENDER ###################################
  // ############################ RENDER ###################################
  render(){

    if(this.state.chartData){
        // console.log('CHART DATA 2 => ', this.state.type['title'], ' => ',this.state.chartData);
        return (
          <div className="BRL Wide100 ">
            {this.state.type['type'] === 'line' && <Line height={300} options={chartStatic.OPTIONS} data={this.state.chartData} />}
            {this.state.type['type'] === 'bar' && <Bar height={300} options={chartStatic.OPTIONS} data={this.state.chartData} />}
          </div>
        );
    } else return null;
      
  }

  // ############################# TRANSFORM DATA FOR CHAT #############################
  // ############################# TRANSFORM DATA FOR CHAT #############################
  processData = async (raw, config, endDate) => {

    // console.log('CHART PROCESS DATA => ', raw, ' => ', config);

    let i = 0, tempDatasets = [], tempLabel = [],
    datasets = JSON.parse(JSON.stringify(chartStatic.TEMPLATEDATASETS[config['type']])), 
    options = JSON.parse(JSON.stringify(chartStatic.TEMPLATEOPTIONS[config['type']])),
    data = JSON.parse(JSON.stringify(chartStatic.TEMPLATE));

    switch(config['type']){

      default:
          tempLabel = [];
          datasets = JSON.parse(JSON.stringify(chartStatic.TEMPLATEDATASETS[config['type']]));
          datasets['borderColor'].push(chartStatic.CHARTCOLORS[i%chartStatic.CHARTCOLORSBAR.length]);
          datasets['backgroundColor'].push(chartStatic.CHARTCOLORS[i%chartStatic.CHARTCOLORSBAR.length]);
          datasets['label'] = config['title'];
          const setSize = raw.length;
          for(const keyPeriod in raw){
            datasets['data'].push(raw[keyPeriod]);
            const temp = getLabel('date', endDate, setSize - parseInt(keyPeriod, 10));
            tempLabel.push(temp);        
          }  
          tempDatasets.push(datasets);
      break;
    }
      

    // ################ FINALIZING CHART OBJECT ################ 
    // ################ FINALIZING CHART OBJECT ################ 
    data['options'] = options;
    data['labels'] = tempLabel;
    data['datasets'] = tempDatasets;
  
    // console.log('CHART => ', config['title'], ' => ',data);
    this.setState({chartData: data});
  }

}

export default Chart;