import React, { Component } from 'react';
import Viewer from '../elements/viewer';
import { getImages } from '../services/business';
import MoonLoader from "react-spinners/ClipLoader";
import * as design from "../../resources/constants/design";

export default class BusinessProfile extends Component {
  
  constructor(props) {
    super(props);
    this.state = {};
    this.data = props.data;
  }
  
  async componentDidMount(){
   await this.getData();
  }

  // ############################ GET DATA ###################################
  // ############################ GET DATA ###################################
  async getData(){
    const { data } = this.props 
      this.data = data; 
      await this.getImages();
      this.setState({});
  }
  
  async getImages(){  
    let temp = this.props.data;
    const images = await getImages([this.props.data.id_business]);
    temp['images'] =(images[temp.id_business] ? images[temp.id_business] : []);
    this.data = temp;

  }

  // ############################ RENDER ###################################
  // ############################ RENDER ###################################
  render() {
    
      // console.log('BUSINESSPROFILE DISPLAY  => ', this.data);
      if(this.data.companyname){
        return (
          <div className="BRL Wide100 ">
            <div className={this.props.style.companyname}>{this.data.companyname}</div>
            
            {this.data.images &&
              <div className="BRL Wide100 VPadding10px HPadding20px VIEW">
                {this.data.images.length > 0 && 
                  <Viewer 
                    type="display"
                    loading={(this.data.images ? false : true)}
                    styleList={design.VIEWER_AD} 
                    id={this.data.id_business} 
                    images={this.data.images} />}
              </div>
            }
            
            {!this.data.images && 
              <div className="BRL Wide100 VPadding10px HPadding20px viewer_text">
                 <React.Fragment>
                    <div className="BRL mr-1 p-1 Bold">Loading Images</div>
                    <div className="BRL p-1"><MoonLoader size={20} color={"#ad182f"} loading={this.state.loading}/></div>
                  </React.Fragment>
              </div>
            }
            
            <div className="BRL Wide100 Padding10px DESC"> 
              {/* {type == 'preview' && <div className={this.props.style.description}>{getTextDraft(this.data.description)}</div>} */}
              {/* {type != 'preview' && <div className={this.props.style.description}>{base64Converter(this.data.description, false)}</div>} */}
              {this.data.description.length > 5 && <div className={this.props.style.description} style={{borderRadius:5, backgroundColor:'#f0f0f0'}}>{this.data.description}</div>}
              
            </div>

            <div className="BRL Wide50 TopMargin10px LeftPadding20px"> 
              <div className="FS40 HeaderFont Capital">Address</div>
              <div className={this.props.style.address}>{this.data.address}</div>
              <div className={this.props.style.postcode}>{this.data.postcode}</div>
              <div className={this.props.style.city}>{this.data.city}</div>
            </div>
            <div className="BRL Wide50 TopMargin10px BottomMargin20px HPadding10px"> 
              <div className="FS40 HeaderFont Capital">Contact details</div>
              <div className={this.props.style.telephone}>{this.data.telephone}</div>
              <div className={this.props.style.email}>{this.data.email}</div>
            </div>
            
          </div>
        );
      } else {
        // console.log('BP RENDER NO IMAGES');
        return null;        
      }
  }

  
}

