import React, { Component } from 'react'
import ListApp from './list'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux';
import * as actions from '../../store/pseudo/general';
import { withRouter } from 'react-router-dom';

class SearchMenuBar extends Component {
  
  // ############################ CONSTRUCTOR ###################################
  // ############################ CONSTRUCTOR ###################################
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      selectedItem: 0
    };
  }

  
  // ############################ CONSTRUCTOR ###################################
  // ############################ CONSTRUCTOR ###################################
  render() {

    const { type, suggestions, onSubmit, onFocus, boxStyle, loading } = this.props;

    return (
      <div className={"BRL NoBorder BottomShadow7 HPadding10px BCWhite " + boxStyle}>
        {loading && <div style={{width: '100%', height: '100%', zIndex:99999, backgroundColor:'rgba(40,40,40,0.01)'}} className={"Absolute Top0px Left0px"}></div>}
        
        {type !== 'fake' && <div className={"BRL Wide100 "}>
          <ListApp type={"search"} custom={"FS35"} styleList={"Absolute Wide100 Bottom0px Left0px z300"} data={suggestions} onSelected={e =>{onSubmit(this.formatCoordinates(e));this.setSearchText(e.place);}} selectedItem={this.state.selectedItem} />
        </div>}
        <div className={"BRL z1000 Hand VPadding5px"} >
          <div className={"BRL FS20 VPadding10px HPadding10px"}><FontAwesomeIcon icon={"bars"} color="#ad182f" onClick={() => {this.props.sideMenuFunction(!this.props.sideMenu)}}/></div>
        </div>
        <input style={{"padding":"25px 10px 10px 10px"}} 
          className={"BRL Wide60 BorderRadius5 NoBorder NoOutline FS35 HeaderFont FCBlack Capital Transparent"} type="text"
          value= {this.state.value}
          placeholder="SEARCH A PLACE"
          onChange={(e) => {this.setSearchText(e.target.value);this.props.onChange(e.target.value);}}
          onKeyDown={e => {this.handleKeys(e, suggestions.length)}}
          onTouchStart={onFocus}
          onClick={() => {this.setState({value: ''})}}
          />
          {this.state.value !== '' && <div className={"Absolute Bottom20px Right100px FS30"}><FontAwesomeIcon icon={"times"} color="#333333" onClick={() => {this.setSearchText('')}}/></div>}
          <div className={"Absolute Bottom20px Right60px FS32"}><FontAwesomeIcon icon={"redo"} className={(loading ? 'Spin' : '')} color="#333333" onClick={() => {this.props.onRefresh()}}/></div>
          <div className={"Absolute Bottom20px Right20px FS32"}><FontAwesomeIcon icon={"filter"} color="#333333" onClick={() => {this.props.onFilter()}}/></div>
          
      </div>
    );
  }

  handleKeys = (e, length) =>{
    let temp;
    if(length === 0){length=1;}
    if (e.keyCode === 13) {this.returnSuggestion();}
    if (e.keyCode === 38) {
      temp = this.state.selectedItem;temp--;temp = Math.max(temp, 0);
      this.setState({selectedItem: temp});
    }
    //DOWN 40
    if (e.keyCode === 40) {
      temp = this.state.selectedItem;temp++;temp = Math.min(temp, length-1);
      this.setState({selectedItem: temp});
    }
  }

  setSearchText = (e)  => {
    // console.log('SETSEARCHTEXT => ', e);
    this.setState({value: e});
  }
  
  returnSuggestion = ()  => {
    if(this.props.suggestions.length>0){
      this.setSearchText(this.props.suggestions[this.state.selectedItem].place_name);
      const temp = this.formatCoordinates(this.props.suggestions[this.state.selectedItem]);
      this.props.onSubmit(temp);
    }
    else{ if(this.state.value !== ''){this.props.onSubmit(this.lastSent);}}
  }
 
  formatCoordinates = (e) => {
    const temp = {'latitude': e['center'][1], 'longitude': e['center'][0]};
    this.lastSent = temp;
    // console.log('RETURN SUGGESTION => ', temp);
    return temp
  }

}

const mapStateToProps = (state) => { return {sideMenu: state.sideMenu, isAuthenticated: state.status.token !== null && state.status.token !== '' ? true : false, status:state.status, user:state.user, settings:state.settings, architecture:state.architecture};}
const mapDispatchToProps = dispatch => {
  return {
      sideMenuFunction: (bVal) => dispatch(actions.sideMenu(bVal)),
      onTryAutoSignup: (history) => dispatch(actions.authCheckState(history)),
      navigate: (direction, history) => dispatch(actions.navigate(direction, history))
  }}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchMenuBar));
