import React, { Component } from 'react';
import { styleMapping } from "../utils/tools";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { truncate, reverseArray, getEquivalent } from "../utils/tools";
// import * as actions from '../../store/pseudo/general';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as server from "../../resources/constants/api";
import { toast } from 'react-toastify';
const options = { autoClose: 2000, type: toast.TYPE.ERROR, hideProgressBar: false };

class Viewer extends Component {
  
  constructor(props) {
    super(props);
    this.state = { id: props.id, bFullscreen: false };
    this.images = null;
  }


  // ############################ HANDLE NEW IMAGE ###################################
  // ############################ HANDLE NEW IMAGE ###################################
  handleImage(e){
    const input = e.target;
    if (input.files && input.files.length) {
      // console.log('HANDLE IMAGE SIZE => ', input.files[0].size);
      if (input.files && input.files[0] && input.files[0].size < 4000000) {
        const reader = new FileReader();
        reader.onloadend = (e) => {
          let image = reader.result;
          // console.log('NEW IMAGE => ', image);
          this.props.onAction('new', image);
        };
        reader.readAsDataURL(input.files[0]);
      } else toast.error("Image size needs to be inferior to 3MB ", options);

    }
  } 

  // ############################ RENDER ###################################
  // ############################ RENDER ###################################
  render() {
    const { styleList, onAction, type, images, limit } = this.props;
    let limit2 = limit;
    if(!limit) {limit2 = 20;}

    if(images){
        return (
              <div className={styleList.imageContainer.class} style={styleMapping(styleList.imageContainer)}>
                  {Object.keys(images).map((image,index)=>{
                    let url = images[image].url;
                    if(images[image].url.substr(0,4)==='http'){ url = images[image].url; }
                    else if(images[image].url.substr(0,4) !=='data') {url = server.URL_SERVER_DJANGO_S3 + images[image].url;}
                    // console.log('VIEWER IMAGES => ', url);
                  return(
                      <div key={index} className={styleList.imageDiv.class} style={styleMapping(styleList.imageDiv)}>
                          <img alt="" src={url} className={styleList.image.class} style={styleMapping(styleList.image)} onClick={()=>{this.carousel(true)}}/>
                          {type !== 'display' && images[image].action !== 'delete' && 
                            <div onClick={()=>{onAction('delete', index)}} className={"Absolute Top5px Right5px z10 Hand"}>
                              <i className={"fa fa-remove FCRed FS37 "}></i>
                            </div>
                          }
                          {type !== 'display' && images[image].action === 'delete' && 
                            <div className="Absolute Wide100 High100 Left0px Top0px z1000 BCPrimaryDisabled" type="file" style={{opacity: 0.5}}>
                                <div onClick={()=>{onAction('reset', index)}} className={"Absolute Top5px Right5px z10 Hand"}>
                                  <i className={"fa fa-history FCBlue FS37 "}></i>
                                </div>
                            </div>
                          }
                      </div>
                  );
                  })}
                  {type !== 'display' && Object.keys(images).length < limit2 && <div className={styleList.imageDiv.class} style={styleMapping(styleList.imageDiv)}>
                      <FontAwesomeIcon icon={"image"} style={{fontSize: 70}} color="red"/>
                      <input onChange={(e)=> this.handleImage(e)}  className="Absolute Wide100 High100 Left0px Top0px z1000 Hand" type="file" style={{opacity: 0}} />
                  </div>}

                  {/* FULLSCREEN CAROUSEL */}
                  {this.state.bFullscreen && 
                  <div id="carouselContainer" className={styleList.carouselContainer.class + ""} style={styleMapping(styleList.carouselContainer)}>
    
                    <div className={"Absolute Top15px Right10px z7000 "} onClick={()=>{this.carousel(false)}}>
                      <i className="fa fa-times-circle FS35 Hand FCRed"/>
                    </div>

                    <div id="carousel" className={"carousel slide  " + styleList.carousel.class} style={styleMapping(styleList.carousel)} data-ride="carousel">
                      <ol className="carousel-indicators">
                        {Object.keys(images).map((image,index)=>{
                          return(
                            <li key={index} data-target="#carousel" data-slide-to={index} className={(index === 0 ? 'active' : '')}></li>
                          );
                          })}
                      </ol>
                      
                      <div className="carousel-inner" style={{height:"100%"}}>
                      {Object.keys(images).map((image,index)=>{
                        let url = images[image].url;
                        if(url.substr(0,5)!=='data:'){ url = server.URL_SERVER_DJANGO_S3 + url; }
                        return(
                          
                          <div key={index} className={"carousel-item h-100 " + (index === 0 ? 'active' : '')} >
                            <div className={"BRL h-100 w-100"} style={{display:'flex',flexDirection: 'row', justifyContent:'center',alignItems:'center'}}>
                              <img alt="" className="" src={url} style={{height:'100%', overflow: 'hidden'}} />
                            </div>
                          </div>
                        );
                        })}
                      </div>


                      {Object.keys(images).length > 1 && <a className="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                      </a>}

                      {Object.keys(images).length > 1 && <a className="carousel-control-next" href="#carousel" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                      </a>}
                    </div>  

                  </div>
                  }


              </div>
          );
        // return null;
    } else return null;
  }


  // ############################ DSIPLAY FULLSCREEN CAROUSEL ###################################
  // ############################ DSIPLAY FULLSCREEN CAROUSEL ###################################
  carousel = (bDisplay) => {
    this.setState({bFullscreen: bDisplay});
  }

}

const mapStateToProps = (state) => { return {isAuthenticated: state.status.token !== null && state.status.token !== '' ? true : false, status:state.status, user:state.user, settings:state.settings, architecture:state.architecture};}
export default withRouter(connect(mapStateToProps, null)(Viewer));


