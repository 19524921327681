
import React, { Component } from 'react'
import { styleMapping } from "../utils/tools";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ContentEditable from "react-contenteditable";
import { Input } from "reactstrap";
import Select from "../elements/select";
import { connect } from 'react-redux';
import { htmlToText } from 'html-to-text';
import { withRouter, Link } from 'react-router-dom';
import * as actions from '../../store/pseudo/general';
import * as design from "../../resources/constants/design";


class CTRI extends Component {
    constructor(props) {
        super(props)
        this.state = { structure: null, editor: props.editor, params: null, element: null }
    }

    componentDidMount(){ this.loadData(this.props); }
    UNSAFE_componentWillReceiveProps(props){ this.loadData(props); }
    loadData = async (data)  => { 
        this.setState({structure: data.structure, editor: data.editor, reference: data}, function(){});
    }


    // ############################ HANDLE LINK ###################################
    // ############################ HANDLE LINK ###################################
    handleLink(direction) {
        this.props.navigate({direction:direction}, this.props.history);
    }

    // ############################ GET PARAMS WHEN SUBELEMENT CLICKED ###################################
    // ############################ GET PARAMS WHEN SUBELEMENT CLICKED ###################################
    getParams = (params, data) => {
        // console.log('GETPARAMS => ', params, ' => ', data);
        this.setState({element: data, params: params});
        this.props.onChange(params, this.state.structure, this.state.images, true);
    }

    // ############################ ADD ELEMENT ###################################
    // ############################ ADD ELEMENT ###################################
    addElement(template){
        let data;
        if (template === null) { data = design.CTRI_DEFAULT_ELEMENT;}
        // else { data = Object.assign({}, template);}
        else { data = JSON.parse(JSON.stringify(template));}
        let temp = this.state.structure;
        temp.structure.elements.push(data);
        // console.log('ADDELEMENT 2 => ', data, ' => ', temp);
        this.setState({structure: temp});
        this.getParams('structure.elements['+parseInt(temp.structure.elements.length-1,10)+'].container', temp.structure.elements[temp.structure.elements.length-1].container);
    } 

        // ############################ ADD ELEMENT ###################################
    // ############################ ADD ELEMENT ###################################
    deleteElement(key){
        let temp = this.state.structure;
        delete temp.structure.elements.splice(key, 1);
        this.setState({structure: temp});
        this.getParams('structure.container', temp.structure.container);
    } 


    // ############################ HANDLE INPUT CHANGE ###################################
    // ############################ HANDLE INPUT CHANGE ###################################
    handleChange(params, field, e, html){
        let temp = this.state.element;

        if(e !== '' && html === true) {e = htmlToText(e, {wordwrap: 500});}
        // console.log('CTRI HANDLE CHANGE => ', field, ' => ', e);
        switch(field){
            case 'font': temp[field] = e.value; break;
            default: temp[field] = e; break;
        }
        this.setState({element: temp});
        this.props.onChange(params, this.state.structure, this.state.images, false);
    } 


    // ############################ RENDER ###################################
    // ############################ RENDER ###################################
    render() {
         
        if(this.state.structure){   

            const { editor } = this.state;
            const { structure } = this.state.structure;
            

            if(editor === true){   
                return (
                        <div key={'cw'} className="BRL Wide100">
                            {/* ##################### FIELD PARAMETERS ##################### */}
                            {/* ##################### FIELD PARAMETERS ##################### */}
                            {this.state.element && <div className="BRL Wide100 Padding10px BottomShadow7 BorderRadius5 BottomMargin20px">
                                <div className={"BRL Wide100 FS38 TitleFont BottomMargin5px HPadding10px Capital"}>{this.state.params}</div>
                                {Object.keys(this.state.element).map((key, index) => {
                                return (
                                    <React.Fragment key={index}>
                                    {/* {key !== 'text' && key !== 'url' &&  */}
                                    {key !== 'text' &&  
                                    <li key={ 'c_' + key} className={"BRL NoBorder TopMargin5px HPadding5px " + ((key === 'class' || key === 'style') ? "col-lg-6 col-md-6 col-sm-12" : "col-lg-3 col-md-3 col-sm-4")} style={{listStyleType: 'none'}}>
                                    <div key={ 'l_' + key} className="BRL Wide100 BottomMargin2px ButtonFont Capital FS41">{key}</div>             
                                    
                                    {(key !== 'font' && key !== 'color' && key !== 'background') && <Input key={'f1_'+ key} type={(key === 'color' || key === 'background' ? 'color' : 'text')} name={key} onChange={(e) => this.handleChange(this.state.params, key, e.target.value)} value={this.state.element[key]} className={"BRL Wide100 NoBorder btn-light BCTrans InputFont FS40 TopMargin5px BottomShadow7"}/>}
                                    {(key === 'color' || key === 'background') && 
                                        <React.Fragment>
                                        <Input key={'f1_'+ key} type={'color'} name={key} onChange={(e) => this.handleChange(this.state.params, key, e.target.value)} value={this.state.element[key]} className={"BRL Wide30 NoBorder btn-light BCTrans InputFont FS40 TopMargin5px BottomShadow7"}/>
                                        <Input key={'f2_'+ key} type={'text'} name={key + '_code'} onChange={(e) => this.handleChange(this.state.params, key, e.target.value)} value={this.state.element[key]} className={"BRL Wide65 NoBorder btn-light BCTrans InputFont FS40 TopMargin5px LeftMargin5 BottomShadow7"}/>
                                        </React.Fragment>}
                                    {(key === 'font') && 
                                        <Select key={'f2_'+ key} name={key} id={'font'} value={this.state.element[key]} fields={key} 
                                        classes={{container:"BRL form-control NoBorder FS40 LabelFont Padding3px btn-light BCTrans", options:"BRL Wide100 TopMargin5px CenterAlign BottomShadow7"}}  
                                        onChange={(name, val) => this.handleChange(this.state.params, key, val)}></Select>}
                                    </li>}
                                    </React.Fragment>
                                );})}
                            </div>}

                            {/* ##################### DESIGN EDITOR ##################### */}
                            {/* ##################### DESIGN EDITOR ##################### */}
                            <div className={"" + structure.superContainer.class} style={styleMapping(structure.superContainer)}>
                                <div className={"" + structure.container.class} style={styleMapping(structure.container)}>
                                {Object.keys(structure.elements).map((part,index)=>{
                                    return (
                                        <div key={index} className={"" + structure.elements[part].container.class} style={styleMapping(structure.elements[part].container)}>

                                            <div onClick={() => {this.getParams('structure.elements['+part+'].graphic', structure.elements[part].graphic)}} className={"Hand NoBorder NoOutline VPadding5px btn-light " + structure.elements[part].graphic.class} style={styleMapping(structure.elements[part].graphic)}>{structure.elements[part].graphic.icon !== '' && <FontAwesomeIcon icon={structure.elements[part].graphic.icon} />} </div>
                                            <ContentEditable html={structure.elements[part].title.text} disabled={false} onChange={(e) => this.handleChange('structure.elements['+part+'].title', 'text', e.target.value, true)} onClick={() => {this.getParams('structure.elements['+part+'].title', structure.elements[part].title)}} className={"Hand NoBorder NoOutline btn-light " + structure.elements[part].title.class} style={styleMapping(structure.elements[part].title)} />
                                            <ContentEditable html={structure.elements[part].description.text} disabled={false} onChange={(e) => this.handleChange('structure.elements['+part+'].description', 'text', e.target.value, true)} onClick={() => {this.getParams('structure.elements['+part+'].description', structure.elements[part].description)}} className={"Hand NoBorder NoOutline btn-light " + structure.elements[part].description.class} style={styleMapping(structure.elements[part].description)} />
                                            <ContentEditable html={structure.elements[part].link2.text} disabled={false} onChange={(e) => this.handleChange('structure.elements['+part+'].link2.text', 'text', e.target.value, true)} onClick={() => {this.getParams('structure.elements['+part+'].info.link2', structure.elements[part].link2)}} className={"Hand NoBorder NoOutline btn-light " + structure.elements[part].link2.class} style={styleMapping(structure.elements[part].link2)} />

                                            <div key="actions" className={"Hand Absolute TopM20px Right0px"}>
                                                <div onClick={() => {this.getParams('structure.elements['+part+'].container', structure.elements[part].container)}} className={"Hand BRL LeftMargin2px HPadding5px VPadding0px BorderRadius50 BottomShadow7 z2000 btn btn-light"}>
                                                    <FontAwesomeIcon icon={"pen"} style={{fontSize: 10}} color="black"/>
                                                </div>
                                                <div onClick={() => {this.addElement(structure.elements[part])}} className={"Hand BRL LeftMargin2px HPadding5px VPadding0px BorderRadius50 BottomShadow7 z2000 btn btn-primary"}>
                                                    <FontAwesomeIcon icon={"copy"} style={{fontSize: 10}} color="white"/>
                                                </div>
                                                <div onClick={() => {this.deleteElement(part)}} className={"Hand BRL LeftMargin2px HPadding5px VPadding0px BorderRadius50 BottomShadow7 z2000 btn btn-danger"}>
                                                    <FontAwesomeIcon icon={"trash"} style={{fontSize: 10}} color="white"/>
                                                </div>
                                            </div>
                                        </div>                   
                                        // onClick={() => {this.handleLink(structure.elements[part].link2.linkUrl)}}
                                    );
                                })}

                                {/* ##################### ACTIONS GLOBAL ##################### */}
                                {/* ##################### ACTIONS GLOBAL ##################### */}
                                <div key="actions_global" className={"Hand Absolute Bottom10px Right10px"}>
                                    <div onClick={() => {this.getParams('structure.superContainer', structure.superContainer)}} className={"Hand BRL LeftMargin2px BorderRadius50 BottomShadow7 z2000 btn btn-danger"}>
                                        <FontAwesomeIcon icon={"crop-alt"} style={{fontSize: 15}} color="white"/>
                                    </div>
                                    <div onClick={() => {this.getParams('structure.container', structure.container)}} className={"Hand BRL LeftMargin2px BorderRadius50 BottomShadow7 z2000 btn btn-light"}>
                                        <FontAwesomeIcon icon={"pen"} style={{fontSize: 15}} color="black"/>
                                    </div>
                                    <div onClick={() => {this.addElement(null)}} className={"Hand BRL LeftMargin1px BorderRadius50 BottomShadow7 z2000 btn btn-info"}>
                                        <FontAwesomeIcon icon={"plus"} style={{fontSize: 15}} color="white"/>
                                    </div>
                                </div>
                            </div>
                        </div>        
                    </div>);
            } else {
                return (
                    // <div className={"" + structure.superContainer.class} style={styleMapping(structure.superContainer)}>
                        <div className={"" + structure.container.class} style={styleMapping(structure.container)}>
                        {Object.keys(structure.elements).map((part,index)=>{
                            return (
                                <div key={index} className={"" + structure.elements[part].container.class} style={styleMapping(structure.elements[part].container)}>
                                    {structure.elements[part].graphic.icon !== '' && <div className={"" + structure.elements[part].graphic.class} style={styleMapping(structure.elements[part].graphic)}><FontAwesomeIcon icon={structure.elements[part].graphic.icon} /></div>}
                                    {structure.elements[part].title.text !== '' && <div className={"" + structure.elements[part].title.class} style={styleMapping(structure.elements[part].title)}>{structure.elements[part].title.text}</div> }
                                    {structure.elements[part].description.text !=='' && <div className={"" + structure.elements[part].description.class} style={styleMapping(structure.elements[part].description)}>{structure.elements[part].description.text}</div>}
                                    {structure.elements[part].link2.text !== '' && 
                                    <Link className={"Hand NoOutline " + structure.elements[part].link2.class} style={styleMapping(structure.elements[part].link2)} to={"/" + structure.elements[part].link2.link} >{structure.elements[part].link2.text}</Link>}
                                </div>                   
                            );
                        })}
                    </div>
                // </div>
                );
            } 
        } else return null;
    }

}

const mapDispatchToProps = dispatch => {
    return {
        navigate: (direction, history) => dispatch(actions.navigate(direction, history)), 
    }
  }
  export default withRouter(connect(null, mapDispatchToProps)(CTRI));
  


    

