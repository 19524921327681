import React from 'react';
import { styleMapping } from "../utils/tools";
import * as actions from '../../store/pseudo/general';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class Logo extends React.Component {
        
    //############################ CONSTRUCTOR ###################################
	//############################ CONSTRUCTOR ###################################
	constructor(props) {
        super(props);
        this.state={logo: null};
    }  

    async componentDidMount(){
        const temp = JSON.parse(this.props.settingsManual);
        if(this.props.settingsManual){this.setState({logo: temp});} 
        else {this.setState({logo: this.props.settings.logo});}
    }
    
    UNSAFE_componentWillReceiveProps(props){
        const temp = JSON.parse(props.settingsManual);
        this.setState({logo: temp});
    }

    // ############################ RENDER ###################################
    // ############################ RENDER ###################################
    render() {      
        let logo;
        if(this.state.logo){ logo = this.state.logo; }
        else { logo = this.props.settings.logo; }
                        
        if(logo){
            // console.log('LOGO RENDER => ', logo.title.font);
            return (
                <div className={ logo.container.class} style={styleMapping(logo.container)} >
            
                <div className={ logo.title.class} style={styleMapping(logo.title)} >
                    { logo.title.text }
                </div>
            
                <div className={ logo.subtitle.class} style={styleMapping(logo.subtitle)}>
                    { logo.subtitle.text }
                </div>

            </div>
            );
        } else { return null; } 

    }
}


const mapstateToProps = (state) => {
    return {isAuthenticated: state.status.token !== null && state.status.token !== '' ? true : false, status:state.status, user:state.user, settings:state.settings, architecture:state.architecture};}
const mapDispatchToProps = dispatch => {return {navigate: (direction, history) => dispatch(actions.navigate(direction, history)), sideMenuFunction: (bVal) => dispatch(actions.sideMenu(bVal))}}
export default withRouter(connect(mapstateToProps, mapDispatchToProps)(Logo));
