import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styleMapping } from "../utils/tools";
import ContentEditable from "react-contenteditable";

let newTemplate = {};

class CustomList extends Component {

  // ############################ CONSTRUCTOR ###################################
  // ############################ CONSTRUCTOR ###################################
  constructor(props) {
    super(props);
    for(let col in props.columns){newTemplate[props.columns[col]] = '';}
    // console.log('COL => ', props.columns, ' => ', newTemplate);
    const temp = JSON.parse(JSON.stringify(newTemplate));
    this.state = { data: props.data, new:temp, id_event: props.id_event }; 
  }


  
  // ############################ RENDER ###################################
  // ############################ RENDER ###################################
  render() {

        const { styleList, type, columns } = this.props;
        // console.log('LISTCUSTOM RENDER => ', this.state.data, ' => ', styleList);
        if(this.state.id_event !== this.props.id_event){
          this.setState({id_event:this.props.id_event, data: this.props.data});
        }

        return (
          // <div className={styleList.container.class}>
          <div className={'BRL Wide100'}>
            
            {type !== true && <React.Fragment>
              {this.state.data.map((line,index)=>{
              return(<div key={index} className={styleList.line.class}>
                      {Object.keys(line).map((key, index)=>{
                          return(<div key={index} 
                          className={styleList[key].class}>
                            {line[key]}</div>);
                      })}
                    </div>);
              })}
            </React.Fragment>}

            {type === true && <React.Fragment>
              {this.state.data.map((line,index)=>{
                return(<div key={index} className={styleList.lineEdit.class}>
                    {Object.keys(line).map((key, index)=>{return(<div key={index} className={styleList[key].class}>{line[key]}</div>);})}
                      {type === true && <div id="containerAction" className={"Absolute Top0px Right10px"}>
                          <div onClick={() => {this.deleteLine(index)}} className={"Hand BRL LeftMargin2px HPadding3px VPadding0px BorderRadius5 z2000"}><FontAwesomeIcon icon={"times-circle"} style={{fontSize: 14}} color="red"/></div>
                      </div>}
                  </div>
                );
                })}
              
                <div className={styleList.lineEdit.class + " TopMargin20px BottomShadow7"}>
                    {Object.keys(columns).map((index)=>{
                    return(
                      <ContentEditable key={index} html={this.state.new[columns[index]]} disabled={false} onChange={(e) => this.handleChange(columns[index], e.target.value)} className={"NoBorder NoOutline btn-light " + styleList[columns[index]].class + " btn-light"} style={styleMapping(styleList[columns[index]])} />
                    )})}
                    {/* ADD LINE */}
                    <div id="containerAction" onClick={() => {this.addLine()}}  className={"Hand BRL Wide100 Padding5px btn-primary FS39 ButtonFont CenterAlign"}>
                      Add Element
                    </div>

                </div>
                </React.Fragment>}
          </div>       
        );
        
  }

  //############################# HANDLE CHANGE #############################
  //############################# HANDLE CHANGE #############################
  handleChange = (field, e) => {
    let temp = this.state.new;
    temp[field] = e;
    this.setState({new: temp});
    // console.log('CHANGE => ', this.state.new);
  }
  
  // ############################ ADD LINE ###################################
  // ############################ ADD LINE ###################################
  addLine() {
    let temp = this.state;
    temp.data.push(this.state.new);
    temp['new'] = newTemplate;
    this.setState({temp});
    this.props.onAction(temp.data);
    // console.log('ADDED 1 => ', newTemplate, ' => ',this.state.new);
  }

  // ############################ DELETE LINE ###################################
  // ############################ DELETE LINE ###################################
  deleteLine(index) {
    let temp = this.state.data;
    temp.splice(index, 1);
    this.setState({data: temp});
    this.props.onAction(temp);
  }
  
}

export default connect(null, null)(CustomList);

