import Axios from 'axios';
import { setLocalObject, getLocalData } from "../utils/tools";
import { createProfile } from "./profile";
import { authSuccess, authFail, logout, navigateTo } from "../../store/actions/general";
import * as server from "../../resources/constants/api";
// import * as settings from "../../resources/data/settings";
// import * as architecture from "../../resources/data/architecture";
// import * as addresses from "../../resources/data/addresses";
// import * as structure from "../../resources/data/structure";
// import * as structureCatalog from "../../resources/data/structure_catalog";



//########################### GET SETTINGS + ARCHITECTURE ###################################
//########################### GET SETTINGS + ARCHITECTURE ###################################
export async function getSettings() {
    let result = {}    
    let url = server.URL_SERVER_DJANGO+"/useradmin/web";
    await Axios.get(url).then(res => {
        const temp = JSON.parse(res.data.results);
        result['settings'] = temp['settings'];
        result['control'] = temp['control'];
        result['directory'] = temp['directory'];
        result['architecture'] = temp['architecture'];
    }).catch(err => console.log(err))
    
    return result;
};



//############################ REFRESH LIST ###################################
//############################ REFRESH LIST ###################################
export async function getStructure(type, name) {
    
    let headers = {};
    const url = server.URL_SERVER_DJANGO+'/useradmin/structure/?type='+type+'&struct='+name;
        return await Axios.get(url, headers).then(res => {
        const temp = res.data.results;
        // console.log('SERVICE GETSTRUCTURE 2 => ', typeof(temp) );		
        return temp;		
    }).catch(err => console.log(err));
};


//########################### HANDLING AUTH ACTIONS ###################################
//########################### HANDLING AUTH ACTIONS ###################################
export function setAuthState(object)  {
    return async dispatch => {
        await setLocalObject(object);      
        createProfile(object);
        dispatch(authSuccess(object));
        dispatch(navigateTo({direction:'profile'}));
    }
};

export function failedAuthState(object)  {
    return async dispatch => {
        dispatch(authFail());
        dispatch(navigateTo({direction:'first'}));
    }
};

export function deleteAuthState(object)  {
    return async dispatch => {
        await setLocalObject(object);      
        dispatch(logout());
        dispatch(navigateTo({direction:'login'}));
    }
};
  
//########################### NEW EMAIL REQUEST FROM DRF ###################################
//########################### NEW EMAIL REQUEST FROM DRF ###################################
export async function resetPassword(email) {
    return await Axios.get(`${server.URL_SERVER_DJANGO}/api-auth/password/reset/`, { email: email }).then(res => {
        return res.data; 
    });
}

export async function resetPasswordRequest(email) {
    return await Axios.post(`${server.URL_SERVER_DJANGO}/api-auth/password/reset/`, { email: email }).then(res => {
        return res.data; 
    });
}



//########################### SETIMAGES ON SERVER ###################################
//########################### SETIMAGES ON SERVER ###################################
export async function setImages(url, data) {
    const token = await getLocalData('token');
    let headers = {'headers': {'authorization': `Token ${token}`}};
    if (token === '' || token === null){headers = {};}

    // console.log('SETIMAGES => ', data);
    return await Axios.post(`${server.URL_SERVER_DJANGO}/tools/images/`, {'data': data, 'url': url}, headers).then(res => {
        return res.data;
    });
}

