import { Component } from 'react'
import { connect } from 'react-redux';
import * as actions from '../../store/pseudo/general';
import { withRouter } from 'react-router-dom';

class Website extends Component {
    constructor(props) {
        super(props)

        const source = props.match.params.reference;
        // console.log('WEBSITE => ', source);
        switch(source){
            default:break;
            case 'android':window.open('https://play.google.com/store/apps/details?id=com.gazzaapp');break;
            case 'ios':window.open('https://play.google.com/store/apps/details?id=com.gazzaapp');break;
        }
        
        this.props.navigate({direction:'home'}, this.props.history);
    }

    // ############################ RENDER ###################################
    // ############################ RENDER ###################################
    render() {
        return null;
    }
}

const mapDispatchToProps = dispatch => {
    return {
        navigate: (direction, history) => dispatch(actions.navigate(direction, history)), 
    }
  }
  export default withRouter(connect(null, mapDispatchToProps)(Website));
  
  
  