import React, { Component } from 'react'
import { styleMapping, generateReference } from "../utils/tools";
import { extractImages } from "../services/editor";
import * as server from "../../resources/constants/api";
import $ from 'jquery';
import { Input } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Select from "../elements/select";
import CustomList from "../elements/customList";
import Viewer from '../elements/viewer';
const width = $(window).width();

const styleViewer = { 
    imageContainer: {class: "BRL Wide100 TopMargin10px NoBorder BottomShadow7", style: ""}, 
    imageDiv: {class: "BRL Wide100px High100px NoBorder OverflowHidden BorderRadius5 RightMargin5px btn-light", style: "display:flex;flex-direction:column;alignItems:center;justifyContent:center"}, 
    image: {class: "High100 ImageFit ImageCentered", style: ""}, 
    icon: {class: "BRL Padding20px FS20 FCRed fa fa-picture-o", style: ""}, 
    carouselContainer: {class: "Fixed Top0px Left0px Wide100 High1200px BCTransparent5 z7000", style: ""},
    carousel: {class: "carousel slide Wide80 MaxWidth1600px MarginAuto TopMargin10", style: ""} };

export default class CCUSTOM extends Component {
    constructor(props) {
        super(props)
        this.state = {structure: props.structure, resize: true, diff: null, editor: props.editor, preview: false, images: null};
        this.oldRatio = 99;this.left = null;this.oldLeft = null;this.left2 = null;this.oldLeft2 = null;
        this.oldWidth2 = null;this.oldWidth = null;this.width = null;this.width2 = null;
    }

    componentWillUnmount() {window.removeEventListener("scroll", this.handleScroll);window.removeEventListener("resize", this.handleResize);}
    componentDidMount(){this.loadData(this.props);window.addEventListener("scroll", this.handleScroll);window.addEventListener("resize", this.handleResize);}
    UNSAFE_componentWillReceiveProps(props){ this.loadData(props); }
    loadData = async (data)  => { 
        if (data !== null && data !== undefined) {        
            if (this.state.images === null) {
                let tempImages = await extractImages(data.structure['tag'], data.structure);
                this.setState({images: tempImages}, function(){});
            }
            else{
                if(data.images !== null && data.images !== undefined){
                    if (this.state.imagesDisplay !== null){this.setState({images: data.images}, function(){});}
                    else{this.setState({images: data.images}, function(){});}
                }   
            }
        }
        this.setState({structure: data.structure, editor: data.editor}, function(){});
    }


    // ############################ GET PARAMS WHEN SUBELEMENT CLICKED ###################################
    // ############################ GET PARAMS WHEN SUBELEMENT CLICKED ###################################
    getParams = (params, data) => {
        // console.log('GETPARAMS => ', params, ' => ', data);
        this.setState({element: data, params: params});
        this.props.onChange(params, this.state.structure, this.state.images, true);
    }

    // ############################ HANDLE INPUT CHANGE ###################################
    // ############################ HANDLE INPUT CHANGE ###################################
    handleChange(params, field, e){
        let temp = this.state.element;
        switch(field){
            case 'font': temp[field] = e.value; break;
            default: temp[field] = e; break;
        }
        this.setState({element: temp});
        this.props.onChange(params, this.state.structure, this.state.images, false);
    } 

    // ############################ HANDLE IMAGES ###################################
    // ############################ HANDLE IMAGES ###################################
    handleImages = (action, image)  => {

        // console.log('CCUSTOM HANDLE IMAGE => ', action, ' : ', image);
        let tempImages = this.state.images;
        let tempImages2 = this.state.structure.structure.image;
        
        switch(action){
            case "new":
                // temp.structure.image.push({url: data, action:'new', name: refRelative });
                this.getParams('structure', this.state.structure.structure);
                const refGen = generateReference(20); 
                const refRelative = server.URL_SERVER_DJANGO_IMAGE_RELATIVE + '/' + this.state.structure.reference + '/' + refGen + '.png';
                const ref = server.URL_SERVER_DJANGO_IMAGE + '/' + this.state.structure.reference + '/' + refGen + '.png';
                tempImages.push({url: image, action: 'new', path: refRelative, pathAbsolute: ref});
                tempImages2.push({url: refRelative, action: ''});
                break;
            
            case "delete":
                if (tempImages[image]['action'] === 'new'){
                    tempImages.splice(image, 1);
                    tempImages2.splice(image, 1);
                }
                else {
                    tempImages[image]['action'] = 'delete';
                    tempImages[image]['url'] = tempImages[image]['url'].replace(new RegExp(server.URL_SERVER_DJANGO_S3, "g"), '');
                    tempImages2.splice(image, 1);
                }
                break;

            case "reset":
                tempImages[image]['action'] = '';
                tempImages2[image]['action'] = '';                
                break;
            default: break;
        }

        this.setState({'params': 'structure', element: this.state.structure.structure, 'images': tempImages},
        function(){this.handleChange(this.state.params, 'images', tempImages2);});
        // this.setState({'params': 'structure', element: this.state.structure.structure});
        // this.setState({'images': tempImages});
        // this.handleChange(this.state.params, 'image', tempImages2);
    }

    // ############################ HANDLE IMAGES ###################################
    // ############################ HANDLE IMAGES ###################################
    handleText = (data)  => {

        this.getParams('structure', this.state.structure.structure);
        // console.log('CCUSTOM HANDLE TEXT => ', data);
        this.setState({'params': 'structure', element: this.state.structure.structure},
        function(){this.handleChange(this.state.params, 'text', data);});
    }



    // ############################ RENDER ###################################
    // ############################ RENDER ###################################
    render() {    
        if(this.state.structure){   
            const { editor, images } = this.state;
            const { structure, reference } = this.state.structure;
            const styleList = {container: structure.style.textContainer, header: structure.style.header, text: structure.style.text, line: structure.style.commentContainer, lineEdit: {class:"BRL Wide100 Padding5px"}};

            if(editor === true){   
                return (
                        <div key={'cw'} className="BRL Wide100 OverflowHidden">
                            {/* ##################### FIELD PARAMETERS ##################### */}
                            {/* ##################### FIELD PARAMETERS ##################### */}
                            {this.state.element && <div key={'dw'} className="BRL Wide100 Padding10px BottomShadow7 BorderRadius5 BottomMargin20px">
                                <div key={'dw1'} className={"BRL Wide100 FS38 TitleFont BottomMargin5px HPadding10px Capital"}>{this.state.params}</div>
                                {Object.keys(this.state.element).map(key => {
                                return (
                                    <React.Fragment key={'dw2_'+ key}>
                                    {key !== 'image' && key !== 'text' && key !== 'container' && key !== 'elements' && key !== 'name' && key !== 'street' && key !== 'postcode' && key !== 'city' && key !== 'country' && 
                                    <li key={ 'c_' + key} className={"BRL NoBorder TopMargin5px HPadding5px " + ((key === 'class' || key === 'style') ? "col-lg-6 col-md-6 col-sm-12" : "col-lg-3 col-md-3 col-sm-4")} style={{listStyleType: 'none'}}>
                                    <div key={ 'l_' + key} className="BRL Wide100 BottomMargin2px ButtonFont Capital FS41">{key}</div>             
                                    {(key !== 'font' && key !== 'color' && key !== 'background' && key !== 'url') && <Input key={'f1_'+ key} type={(key === 'color' || key === 'background' ? 'color' : 'text')} name={key} onChange={(e) => this.handleChange(this.state.params, key, e.target.value)} value={this.state.element[key]} className={"BRL Wide100 NoBorder btn-light BCTrans InputFont FS40 TopMargin5px BottomShadow7"}/>}
                                    {(key === 'color' || key === 'background') && 
                                        <React.Fragment>
                                        <Input key={'f1_'+ key} type={'color'} name={key} onChange={(e) => this.handleChange(this.state.params, key, e.target.value)} value={this.state.element[key]} className={"BRL Wide30 NoBorder btn-light BCTrans InputFont FS40 TopMargin5px BottomShadow7"}/>
                                        <Input key={'f2_'+ key} type={'text'} name={key + '_code'} onChange={(e) => this.handleChange(this.state.params, key, e.target.value)} value={this.state.element[key]} className={"BRL Wide65 NoBorder btn-light BCTrans InputFont FS40 TopMargin5px LeftMargin5 BottomShadow7"}/>
                                        </React.Fragment>}
                                    {(key === 'font' || key === 'url') && 
                                        <Select key={'f3_'+ key} name={key} id={'font'} value={this.state.element[key]} fields={(key === 'url' ? 'frame' : key)} 
                                        classes={{container:"BRL form-control NoBorder FS40 LabelFont Padding3px btn-light BCTrans", options:"BRL Wide100 TopMargin5px CenterAlign BottomShadow7"}}  
                                        onChange={(name, val) => this.handleChange(this.state.params, key, val)}></Select>}
                                    </li>}
                                    </React.Fragment>
                                );})}
                            </div>}

                            {/* ##################### DESIGN EDITOR ##################### */}
                            {/* ##################### DESIGN EDITOR ##################### */}
                            <div id="container2" key={'ew'} className={"BRL " + structure.style.container.class} style={styleMapping(structure.style.container)}>
                                    <div key={'dw'} id="containerAction" className={"Absolute Top0px Right10px"}>
                                        <div key={'dw1'} onClick={() => {this.setState({preview: !this.state.preview})}} className={"Hand BRL LeftMargin2px Padding7px FS37 " + (this.state.preview ? "btn-danger": "btn-info") + " ButtonFont BorderRadius5 BottomShadow7 z2000"}>{(this.state.preview ? 'EDIT' : 'PREVIEW')}</div>
                                        <div key={'dw2'} onClick={() => {this.getParams('structure.style.container', structure.style.container)}} className={"Hand BRL LeftMargin2px HPadding3px VPadding0px btn-light BorderRadius5 BottomShadow7 z2000"}><FontAwesomeIcon icon={"pen"} style={{fontSize: 20}} color="black"/></div>
                                    </div>
                                    
                                    {/* FRAME */}
                                    <div key={'fw'} id="frameContainer" className={"" + structure.style.frameContainer.class} style={styleMapping(structure.style.frameContainer)}>
                                        <div key={'gw'} id="frameAction" className={"Absolute Top0px Left10px"}>
                                            <div onClick={() => {this.getParams('structure.style.frameContainer', structure.style.frameContainer)}} className={"Hand BRL LeftMargin2px HPadding3px VPadding0px btn-info BorderRadius5 BottomShadow7 z2000"}><FontAwesomeIcon icon={"crop"} style={{fontSize: 15}} color="white"/></div>
                                            <div onClick={() => {this.getParams('structure.style.frame', structure.style.frame)}} className={"Hand BRL LeftMargin2px HPadding3px VPadding0px btn-primary BorderRadius5 BottomShadow7 z2000"}><FontAwesomeIcon icon={"crop-alt"} style={{fontSize: 15}} color="white"/></div>
                                            <div onClick={() => {this.getParams('structure.style.imageContainer', structure.style.imageContainer)}} className={"Hand BRL LeftMargin2px HPadding3px VPadding0px btn-danger BorderRadius5 BottomShadow7 z2000"}><FontAwesomeIcon icon={"crop"} style={{fontSize: 15}} color="white"/></div>
                                            <div onClick={() => {this.getParams('structure.style.image', structure.style.image)}} className={"Hand BRL LeftMargin2px HPadding3px VPadding0px btn-secondary BorderRadius5 BottomShadow7 z2000"}><FontAwesomeIcon icon={"image"} style={{fontSize: 15}} color="white"/></div>
                                        </div>
                                                                                                                          
                                            {/* IMAGES */}
                                            {!this.state.preview && <div className={"BRL Wide100 Padding10px TopMargin20px"}> 
                                                <div className={"BRL TopMargin10px"}><Viewer styleList={styleViewer} id={reference} images={images} onAction={(action, data) => { this.handleImages(action, data)}} /></div>
                                            </div>}
                                            
                                            <div className={"BRR High100"}>
                                            {this.state.preview && <React.Fragment>
                                                {structure.style.frame.url !== '' && <img alt='' className={"" + structure.style.frame.class} style={styleMapping(structure.style.frame)} src={server.URL_SERVER_DJANGO_IMAGE + server.URL_SERVER_DJANGO_IMAGE_RELATIVE + structure.style.frame.url} />}
                                                {Object.keys(structure.image).map((key,index)=>{
                                                    const element = structure.image[key];
                                                    return (<div key={'image'+ parseInt(index +1,10) } id={'image'+ parseInt(index +1,10)} className={'image'+ parseInt(index +1,10) + ' '  + structure.style.imageContainer.class} style={styleMapping(structure.style.imageContainer)}>                       
                                                            <img alt='' src={server.URL_SERVER_DJANGO_IMAGE + element.url} className={" " + structure.style.image.class} />
                                                        </div>);
                                                })}
                                            </React.Fragment>}
                                        </div>
                                    </div>
                                
                                    {/* TEXTS */}
                                    <div key={"hw"} id="textContainer" className={(this.state.preview ? "OverflowHidden" : "") + " " + structure.style.textContainer.class} style={styleMapping(structure.style.textContainer)}>
                                        <div id="textAction" className={"Absolute Top0px Left10px"}>
                                            <div key={"hw1"} onClick={() => {this.getParams('structure.style.textContainer', structure.style.textContainer)}} className={"Hand BRL LeftMargin2px HPadding3px VPadding0px btn-info BorderRadius5 BottomShadow7 z2000"}><FontAwesomeIcon icon={"crop-alt"} style={{fontSize: 15}} color="white"/></div>
                                            <div key={"hw2"} onClick={() => {this.getParams('structure.style.header', structure.style.header)}} className={"Hand BRL LeftMargin2px HPadding3px VPadding0px btn-primary BorderRadius5 BottomShadow7 z2000"}><FontAwesomeIcon icon={"font"} style={{fontSize: 15}} color="white"/></div>
                                            <div key={"hw3"} onClick={() => {this.getParams('structure.style.text', structure.style.text)}} className={"Hand BRL LeftMargin2px HPadding3px VPadding0px btn-primary BorderRadius5 BottomShadow7 z2000"}><FontAwesomeIcon icon={"paragraph"} style={{fontSize: 15}} color="white"/></div>
                                        </div>
                                        
                                        {!this.state.preview && <CustomList type={editor} data={structure.text} styleList={styleList} columns={['header', 'text']} onAction={(data) => { this.handleText(data)}} />}
                                        {this.state.preview && Object.keys(structure.text).map((key,index)=>{
                                            const element = structure.text[key];
                                            return (
                                                <div key={'text'+ parseInt(index +1,10)} id={'text'+ parseInt(index +1,10)} className={'text'+ parseInt(index +1,10) + ' ' + structure.style.commentContainer.class} style={styleMapping(structure.style.commentContainer)}>                       
                                                    {Object.keys(element).map((key2,index2)=>{
                                                        let sub = element[key2];
                                                        return (
                                                            <div key={key2+index2} className={"" + structure.style[key2].class} style={styleMapping(structure.style[key2])}>{sub}</div>                            
                                                        );
                                                    })}
                                                </div>
                                            );
                                        })}
                                    
                                    </div>
                            </div>
                        </div>
                        );
                                                
                                                
            } else {
                return (
                    <div className={"BRL Wide100"}>
                    <div id="container2" className={"" + structure.style.container.class} style={styleMapping(structure.style.container)}>
                            {/* FRAME */}
                            <div id="frameContainer" className={"" + structure.style.frameContainer.class} style={styleMapping(structure.style.frameContainer)}>
                                <div className={"BRR High100"}>
                                    {structure.style.frame.url !== '' && <img alt='' className={"" + structure.style.frame.class} style={styleMapping(structure.style.frame)} src={server.URL_SERVER_DJANGO_IMAGE + server.URL_SERVER_DJANGO_IMAGE_RELATIVE + structure.style.frame.url} />}
                                    {/* IMAGES */}
                                    {Object.keys(structure.image).map((key,index)=>{
                                        const element = structure.image[key];
                                        return (
                                            <div key={'image'+ parseInt(index +1,10) } id={'image'+ parseInt(index +1,10)} className={'image'+ parseInt(index +1,10) + ' '  + structure.style.imageContainer.class} style={styleMapping(structure.style.imageContainer)}>                       
                                                <img alt='' src={server.URL_SERVER_DJANGO_IMAGE + element.url} className={" " + structure.style.image.class} />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        
                            {/* TEXTS */}
                            <div id="textContainer" className={"" + structure.style.textContainer.class} style={styleMapping(structure.style.textContainer)}>
                            {Object.keys(structure.text).map((key,index)=>{
                                const element = structure.text[key];
                                return (
                                    <div key={'text'+ parseInt(index +1,10)} id={'text'+ parseInt(index +1,10)} className={'text'+ parseInt(index +1,10) + ' ' + structure.style.commentContainer.class} style={styleMapping(structure.style.commentContainer)}>                       
                                        {Object.keys(element).map((key2,index2)=>{
                                            let sub = element[key2];
                                            return (
                                                <div key={key2+index2} className={"" + structure.style[key2].class} style={styleMapping(structure.style[key2])}>{sub}</div>                            
                                            );
                                        })}
                                    </div>
                                );
                            })}
                            </div>
                    </div>
                    </div>
                );
            }

        } else return null;
    }
    
    
    // ########################### HANDLE SCROLLING EVENT THROUGH JQUERY ########################### 
    // ########################### HANDLE SCROLLING EVENT THROUGH JQUERY ########################### 
    handleScroll = (event) => {
        
        const nbImage = 4;
        const windowWidth = $(window).width();
        const windowHeight = $(window).height();
        const limit = (width > 980 ? windowHeight * 0.1 : 20);
        const scroll_top = $(window).scrollTop();
        const dom = document.getElementById('container2');
        const containerPosition = {top: $('#container2').offset().top, left: $('#container2').offset().left};
        const containerHeight = dom.offsetHeight;
        const containerWidth = $('#container2').css('width').slice(0,-2);
        const relativeScroll = scroll_top - containerPosition['top'];

        // console.log(limit, ': SCROLLING => ',  windowWidth, ' - ', containerWidth ,' => ', this.containerMargin);
        if(this.state.resize === true){

            this.containerMargin =  (windowWidth - containerWidth) / 2;
            this.containerPadding = 0;
            
            // IMAGE
            this.width = parseInt($('#frameContainer').css('width'),10);
            this.oldWidth = parseInt($('#frameContainer').css('width').slice(0,-2),10);
            this.height = parseInt($('#frameContainer').css('height'), 10);
            this.left = parseInt($('#frameContainer').position()['left'] + this.containerMargin + this.containerPadding, 10);
            this.oldLeft = parseInt($('#frameContainer').css('left').slice(0,-2),10);
            this.overLeft = this.left - this.containerMargin;
            this.top = parseInt($('#frameContainer').position()['top'] + limit + this.containerPadding, 10);
            // console.log('SCROLL IMAGE => ', this.top,' = ', $('#frameContainer').position()['top'], '+ ', this.containerPadding, ' + ', limit);
            this.oldTop = parseInt($('#frameContainer').css('top').slice(0,-2),10);
            
            // TEXT
            this.height2 = parseInt($('#textContainer').css('height'), 10);
            this.margin2 = parseInt($('#textContainer').css('margin-top'), 10);
            this.width2 = parseInt($('#textContainer').css('width'),10);
            this.oldWidth2 = parseInt($('#textContainer').css('width').slice(0,-2),10);
            this.left2 = parseInt($('#textContainer').position()['left'] + this.containerMargin , 10);
            this.oldLeft2 = parseInt($('#textContainer').css('left').slice(0,-2),10);
            this.overLeft2 = this.left2 - this.containerMargin;
            this.top2 = parseInt($('#textContainer').position()['top'] + this.containerPadding + limit, 10);
            this.oldTop2 = parseInt($('#textContainer').css('top').slice(0,-2),10) ;
                        
            this.setState({resize: false});
            this.displayElement(1, true);
            // console.log('SCROLL TEXT => ', this.left2,' = ', $('#textContainer').position()['left'], '+ ', this.containerMargin , ' + ', this.containerPadding, ' + ', $('#textContainer').css('padding-left').slice(0,-2));
            // console.log('SCROLL TEXT => ', this.top2,' = ', $('#textContainer').position()['top'], '+ ', this.containerPadding, ' + ', limit);
            // console.log('SCROLL INIT => ', this.left);
            // console.log('SCROLL INIT => ', $('#textContainer').position()['left'],'/', this.left2, ' => ', this.top, '/ ', this.top2);

        }
        
        // console.log(this.containerMargin, ': ', this.containerPadding,'SCROLLING => ', this.left, ' => ', this.oldLeft, ' || ', this.left2, ' => ', this.oldLeft2);
        // console.log('SCROLL => ', $('#frameContainer').css('top').slice(0,-2));

        
        if (relativeScroll >= -limit && relativeScroll <= containerHeight - this.height - limit - 20){
            $('#frameContainer').removeClass('BRL Absolute').addClass('Fixed').css({'top': this.top, 'bottom': '', 'left': this.left });
            $('#textContainer').removeClass('BRL Absolute').addClass('Fixed').css({'top': this.top2, 'bottom': '', 'left': this.left2});
            
            // TEXT
            if(!this.state.diff){
                this.topFixed = parseInt($('#frameContainer').position()['top'] , 10);
                this.topFixed2 = parseInt($('#textContainer').position()['top'], 10);                
                this.diff = parseInt(this.topFixed2 + limit,10) - parseInt(this.topFixed + this.height + this.height2 + this.margin2,10); 
                this.setState({diff: this.diff});

            }

            const ratio = Math.max(Math.floor(relativeScroll / 300),1);
            // const ratio = 1;
            if(ratio !== this.oldRatio && ratio <= nbImage){
                this.displayElement(ratio, true);
                this.displayElement(this.oldRatio, false);
                this.oldRatio = ratio;
                // this.oldRatio = 0;
            }
        }
        else{
            if (relativeScroll > containerHeight - this.height - limit -20 ){
                // this.overBottom = parseInt(containerHeight - $('#frameContainer').css('top').slice(0,-2) - this.height,10);
                // this.overBottom2 = parseInt(this.overBottom + this.height - this.height2,10);
                this.overBottom = 0;
                this.overBottom2 = parseInt(0 + this.height - this.height2,10);
                // console.log('SCROLL OVER IMAGE => ', this.overBottom,' = ', containerHeight, ' + ', $('#container2').css('top').slice(0,-2), ' - ', $('#frameContainer').css('top').slice(0,-2) , ' - ', this.height);
                // console.log('SCROLL OVER TEXT => ', this.overBottom2,' = ', this.overBottom, ' - ', this.height , ' - ', this.height2);
                $('#frameContainer').addClass('Absolute').removeClass('Fixed BRL').css({'bottom': this.overBottom, 'top': '', 'left': this.overLeft, 'width': this.width});
                $('#textContainer').addClass('Absolute').removeClass('Fixed BRL').css({'bottom': this.overBottom2 , 'top': '', 'left': this.overLeft2, 'width': this.width2});
            }
            else {
                // console.log('OUT 2 => ', this.oldLeft);
                this.displayElement(1, true);
                $('#frameContainer').addClass('BRL').removeClass('Fixed Absolute').css({'top': '', 'left': this.oldLeft, 'width': this.oldWidth});
                $('#textContainer').addClass('BRL').removeClass('Fixed Absolute').css({'top': this.oldTop2, 'left': this.oldLeft2, 'width': this.oldWidth2});
            }
            this.oldRatio = 99;

        }
        
    }

    
    displayElement(element, bDisplay){

        switch(bDisplay){
            case true:
                $('.image' + 1).css({'z-index': 2});
                $('.text' + element).addClass('AllTrans1');
                $('.text' + element).css({'z-index': 2,'opacity':1});
                
            break;
            case false:
                $('.image' + 1).css({'z-index': 1});
                $('.text' + element).removeClass('AllTrans1');
                $('.text' + element).css({'z-index': 1, 'opacity':0});
            break;
                
            default:break;
        }

    }

    
    //###############################  WINDOW RESIZED  #####################################
    //###############################  WINDOW RESIZED  #####################################
    handleResize = (event) => {
        this.setState({resize: true, diff: null});
    }
    
}