import React, {Component} from "react";
import GoogleAuthJs from "./googleAuth_js"
// import AppleAuthJs from "./appleAuth_js"
import Toggle from "../elements/toggle"
import InfoModal from "../elements/infoModal";
import * as actions from '../../store/pseudo/general';  
import { connect } from 'react-redux';
import { styleMapping, scrollTo } from "../utils/tools";
import { withRouter } from 'react-router-dom';
import MoonLoader from "react-spinners/ClipLoader";

class LoginFormAnt extends Component {
  constructor(props) {
    super(props);
    this.state = {loading: false, type: 'signin', bModal: true, bTerms: false, email: '', password: '', password2: '', errors: {email: 'error', password: 'error', password2: 'error'}, disabled: true};    
    this.props.sideMenuFunction(false);
    scrollTo(0,0);
}


async componentDidMount(){
  if(this.props.settingsManual){
    this.data = [{ label: 'SIGNIN', value: 'signin', selectedBackgroundColor: this.props.settingsManual.login.toggle.color }, { label: 'REGISTER', value: 'register', selectedBackgroundColor: this.props.settingsManual.login.toggle.color }];
    this.setState({settings: this.props.settingsManual});} 
  else {
    this.data = [{ label: 'SIGNIN', value: 'signin', selectedBackgroundColor: this.props.settings.login.toggle.color }, { label: 'REGISTER', value: 'register', selectedBackgroundColor: this.props.settings.login.toggle.color }];
    this.setState({settings: this.props.settings});}
}
UNSAFE_componentWillReceiveProps(){this.setState({settings: this.props.settingsManual});}


//############################# HANDLING CHANGE SIGNIN REGISTER #############################
//############################# HANDLING CHANGE SIGNIN REGISTER #############################
handleChange = (e) => {
  this.setState({type: e});
};

//############################# HANDLING EMAIL LOGIN SUBMISSION #############################
//############################# HANDLING EMAIL LOGIN SUBMISSION #############################
handleSubmit = (e) => {
  this.setState({ loading: true });
  e.preventDefault();
  
  switch (this.state.type){
    case 'signin': this.props.onAuth(this.state.email, this.state.password, this.props.history); break;
    case 'register': this.props.onRegister(this.state.email, this.state.password, this.state.password2, this.props.history); break;
    default:break;}
    setTimeout(() => {this.setState({ loading: false });}, 500);
};

// ############################# HANDLE FORM CHANGE #############################
// ############################# HANDLE FORM CHANGE #############################
handleFormChange = (field, e) => {
  const reg = e.target.getAttribute("data-regex");
  let temp = this.state.errors;

  if(field !== 'password2'){ 
    if(e.target.value.match(reg) !== null){delete temp[field];}else{temp[field] = 'error';}
  } else { if(this.state.password === e.target.value){delete temp[field];}else{temp[field] = 'error';} }
    this.checkDisableState(field, e.target.value, temp);
}

// ############################ HANDLE EVENTS ###################################
// ############################ HANDLE EVENTS ###################################
checkDisableState = (field, value, temp) => {
  let disabled = ((this.state.type === 'signin' && Object.keys(temp).length > 1) || (this.state.type === 'register' && Object.keys(temp).length > 0) || (this.state.type === 'register' && !this.state.bTerms));
  // console.log('CHECK DISABLE STATE => ', temp, ' => ', disabled);
  this.setState({[field]: value, errors: temp, disabled: disabled});
};

// ############################ HANDLE EVENTS ###################################
// ############################ HANDLE EVENTS ###################################
handleTerms = () => {
  this.setState({bTerms: true});
};

// ############################# DISPLAY LOGIN FORM #############################
// ############################# DISPLAY LOGIN FORM #############################
render() {
  // console.log('RENDER => ', this.state);
  // if(this.props.isAuthenticated === true && !this.props.settingsManual){this.props.navigate({direction:'profile'}, this.props.history); return null;}
  // else{
    let settings;
    if(this.state.settings){ settings = this.state.settings; }
    else { settings = this.props.settings; }       
    if(settings){
      return (
        <div className={ settings.structure.outlet.class} style={styleMapping(settings.structure.outlet)}>
            <div className={settings.login.container.class} style={styleMapping(settings.login.container)}>

              <InfoModal type={'gdpr'} show={this.state.bModal} onClose={() => {this.setState({bModal: false}); this.props.navigate({direction:'home'}, this.props.history);}} onAgreed={(e) => {this.setState({bModal: false}); this.setState({bTerms: true});}}/>
              
              <div className={settings.login.div.class} style={styleMapping(settings.login.div)}>
              {/* SIGNIN REGISTER SWITCH */}
                <div className={"react-switch-selector-wrapper " + settings.login.toggle.class } style={styleMapping(settings.login.toggle)}>
                  <Toggle onChange={(e) => {this.handleChange(e)}} data={this.data} backgroundColor={settings.login.toggle.background} selectionIndicatorMargin={settings.login.toggle.margin} fontSize={14}/>
                </div>
                {/* FORM */}
                  <div className="BRL Wide100 TopMargin20px">
                      <form onSubmit={(e) => this.handleSubmit(encodeURI)}>
                        <div className="BRL Wide100 VPadding3px TopMargin5px">
                          <div className="BRL Wide100 VPadding3px TopMargin5px">
                            <label className="BRL VPadding3px FS40 FuturaH" style={{marginBottom:0}} htmlFor="email">Email</label>
                            {this.state.errors['email'] && this.state.email.length>8 && <label className="BRL Wide100 VPadding3px FS40 FuturaM BottomMargin5px FCRed">Email is not valid</label>}
                          </div>    
                          <input className="BRL Wide100 VPadding3px FS40 FuturaH NoBorder BottomShadow7 Padding10px BorderRadius5px" id="email" name="email" type="email" placeholder="email" data-regex="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?" onChange={(e) => this.handleFormChange('email', e)} value={this.state.email} />
                        </div>                       
                        
                        <div className="BRL Wide100 VPadding3px TopMargin5px">
                          <div className="BRL Wide100 VPadding3px TopMargin5px">
                            <label className="BRL VPadding3px FS40 FuturaH" style={{marginBottom:0}} htmlFor="email">Password</label>
                            {this.state.errors['password'] && this.state.password.length>6 && <label className="BRL Wide100 VPadding3px FS40 FuturaM BottomMargin5px FCRed">must be at least 8 characters long with at least one number, one uppercase and one special character</label>}
                          </div>    
                          <input className="BRL Wide100 VPadding3px FS40 FuturaH NoBorder BottomShadow7 Padding10px BorderRadius5px" id="password" name="password" type="password" placeholder="password" data-regex="^(?=.*[A-Za-z])(?=.*[0-9])(?=.{8,})" onChange={(e) => this.handleFormChange('password', e)} value={this.state.password} />
                        </div>    

                        {this.state.type === 'register' && <div className="BRL Wide100 VPadding3px TopMargin5px">
                        <div className="BRL Wide100 VPadding3px TopMargin5px">
                            <label className="BRL VPadding3px FS40 FuturaM" style={{marginBottom:0}} htmlFor="email">Repeat Password</label>
                            {this.state.errors['password2'] && this.state.password2.length>6 && <label className="BRL Wide100 VPadding3px FS40 FuturaM BottomMargin5px FCRed">Passwords do not match</label>}
                          </div>  
                          {/* (?=.*[!@#\$%\^&\*]) */}
                          <input className="BRL Wide100 VPadding3px FS40 FuturaH NoBorder BottomShadow7 Padding10px  BorderRadius5px" id="password2" name="password2" type="password" placeholder="repeat password" data-regex="^(?=.*[A-Za-z])(?=.*[0-9])(?=.{8,})" onChange={(e) => this.handleFormChange('password2', e)} value={this.state.password2} />
                        </div>} 

                        <div className="BRL Wide100 TopMargin20px form-group NoOutline">
                          <button type="submit" disabled={this.state.disabled} className={settings.login.buttons.class + " " + (this.state.disabled ? 'btn-light ' : 'BCPrimary FCWhite')} style={styleMapping(settings.login.buttons)} onClick={(e) => this.handleSubmit(e)} >
                            <div className="BRL Wide90 VPadding3px FS40 FuturaH">{(this.state.type === 'signin' ? 'Signin with email' : 'Register with email')}</div>
                            <div className="BRL Wide10 VPadding3px"><MoonLoader size={17} color={"#ffffff"} loading={this.state.loading}/></div>
                          </button>
                        </div>
                      </form>
                  </div>

                  <div className="BRL Wide100 TopMargin30px">
                    <button type="submit" className={"BRL Wide100 Padding5px ButtonFont FS40 NoBorder NoOutline Transparent "} onClick={(e) => this.props.navigate({direction: 'forgotten'}, this.props.history)} >
                      Password forgotten?
                    </button>
                  </div>
                  
                  <div className="BRL Wide100 TopMargin30px">
                    <GoogleAuthJs settings={settings.admin.google}></GoogleAuthJs>
                  </div>
                  <div className="BRL Wide100 TopMargin30px">
                    {/* <AppleAuthJs ></AppleAuthJs> */}
                  </div>
                  
              </div>
          
          </div>
        </div>);
      } else { return null; } 
  // }
  }
}

const mapStateToProps = (state) => {return {isAuthenticated: state.status.token !== null && state.status.token !== '' ? true : false, status:state.status, sideMenu:state.sideMenu, user:state.user, architecture:state.architecture, settings:state.settings};}
const mapDispatchToProps = dispatch => {
return {  
    sideMenuFunction: (bVal) => dispatch(actions.sideMenu(bVal)),
    navigate: (direction, history) => dispatch(actions.navigate(direction, history)), 
    onAuth: (username, password, history) => dispatch(actions.authLogin(username, password, history)), 
    onRegister: (username, password, password2, history) => dispatch(actions.authSignup(username, password, password2, history)), 
}}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginFormAnt));
