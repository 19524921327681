import React, { Component } from "react";
import { Button, FormGroup, Input,  } from "reactstrap";
import { toast } from 'react-toastify';
import { withRouter } from "react-router-dom";
import { styleMapping } from "../utils/tools";
import { getAdmin, setAdmin } from "../services/admin";

import Toggle from "../elements/toggle"
import Footer from "../core/footer";
import NavBar from "../core/navbar";
import Profile from "../core/profile";
import AddressSimple from "../elements/addressSimple";
import LoginFormAnt from "../auth/loginForm";
import Select from "../elements/select";
import { designImages } from "../services/editor";
import * as server from "../../resources/constants/api";

class Admin extends Component {
    constructor(props) {
        super(props);
        this.data = [{ label: 'WEBSITE', value: 'web', selectedBackgroundColor: "#ad182f" }, { label: 'APP', value: '', selectedBackgroundColor: "#ad182f" }];
        this.state = { images: [], settingsVersion: 'web', settings: null, fields: "", items: "", newField: "", newItem: "", newCategory: "", bEditMode: "", bChanged: false, selectedCategory: "", selectedItem: ""};
        this.selectedItems = "";
        this.bEdit = false;
        this.selectedFields = {};
        this.address = { "type": "Headquarter", "name": "Lorem Ipsum", "address1": "10 Lorem street", "address2": "", "postalcode": "12345", "city": "Ipsum City", "country": "Loreny", "telephone": "+39.388.30.91.816", "email": "info@starngersapp.cc"};
        this.options = { autoClose: 1500, type: toast.TYPE.INFO, hideProgressBar: false };
      }

    // ############################ COMPONENT ###################################
    // ############################ COMPONENT ###################################
    async componentDidMount() {
        this.getList('web', true);
    }
    
    // ############################ GET ADMIN SETTINGS ###################################
    // ############################ GET ADMIN SETTINGS ###################################
    getList = async (type, refresh) => {
        const results = await getAdmin(type);
        const cat = Object.keys(results);
        // console.log(type, ' GETLIST 2 => ', cat);
        this.setState({settings: results});
        if(refresh ===  true){
            this.setState({selectedCategory: cat});
            this.getItems(cat[0], true);
        }
    };
  
    
    // ############################ SAVE LIST ###################################
    // ############################ SAVE LIST ###################################
    saveList = async () => {
        
        let results = await setAdmin(this.state.settingsVersion, this.state.settings);
        switch(results){
            case true:
                toast.success("Settings have been saved", this.options);
                this.setState({bChanged: false});    
                break;
                default: toast.error("An error occured : " , results);break;
            }
            
        if(this.state.images.length > 0){
            results = await designImages({'action': 'save', 'reference': this.state.selectedCategory, 'typology': 'structure', 'images': this.state.images, 'oldReference': ''});
            // console.log('IMAGES RESULTS => ', results);
            switch(results.status){
                case true:
                    toast.success(this.state.selectedCategory + " media has been saved", this.options);
                    this.setState({bChanged: false});    
                    break;
                    default: toast.error("An error occured Images: " , results);break;
                }
                
            }
    };

  
  // ############################ HANDLE CHANGE ###################################
  // ############################ HANDLE CHANGE ###################################
  handleChange = (type, e)  => {
    let { name, value } = e.target;
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    }
    
    switch(type){
      default: break;
      
      case "category": 
            switch(name){
                default: break;
                case "new_category": 
                this.setState({ newCategory: value });break;
            }
      break;
      
      case "item": 
            switch(name){
                default: break;
                case "new_item": 
                this.setState({ newItem: value });break;
            }
      break;
      
      case "field": 
            switch(name){
                case "new_field": 
                this.setState({ newField: value });break;
                default: 
                this.updateFields('update',name, value); 
                break;
            }
      break;
    }
    this.setState({bChanged: true});
  }
  
  // ############################ HANDLE IMAGE / VIDEO ###################################
  // ############################ HANDLE IMAGE / VIDEO ###################################
  handleImage = (e)  => {
    const input = e.target;
    
    if (input.files && input.files.length) {

        if (input.files && input.files[0]) {
            const reader = new FileReader();
            reader.onloadend = async (e) => {
            let image = reader.result;
            let tempImages = [];
            let ext = input.files[0].name.split('.').pop();
            const refRelative = server.URL_SERVER_DJANGO_STRUCTURE_RELATIVE + '/' + this.state.selectedCategory + '/' + this.state.selectedCategory + '.' + ext;
            const ref = server.URL_SERVER_DJANGO_STRUCTURE + '/' + this.state.selectedCategory + '/' + this.state.selectedCategory + '.' + ext;
            tempImages.push({url: image, action: 'new', path: refRelative, pathAbsolute: ref});
            this.updateFields('update', 'url', refRelative); 
            this.setState({'images': tempImages});
        }
        reader.readAsDataURL(input.files[0]);
    }
  }}

  // ############################ HANDLE CHANGE ###################################
  // ############################ HANDLE CHANGE ###################################
  handleToggle = (value)  => {
      this.getList(value);
      this.setState({settingsVersion: value});
  }


  // ############################ HANDLE CHANGE ###################################
  // ############################ HANDLE CHANGE ###################################
  dropDownChange = (name, value)  => {
    this.updateFields('update', name, value.value);
  }

  
  // ############################ RENDER CATEGORIES ###################################
  // ############################ RENDER CATEGORIES ###################################
  renderCategories = () => {
    const { settings } = this.state;
    
    var list = Object.keys(settings).map(key => {
      return( 
        <li key={key} className={"BRL d-flex justify-content-between align-items-center HPadding0px BottomMargin2px "}>
            <div onClick={() => { this.getItems(key, true) }} className={"btn BRL Wide97 VPadding5px HPadding20px FS40 ButtonFont Bold Capital Hand BottomShadow7 " + (this.state.selectedCategory === key ? 'btn-primary' : 'btn-light')}>
                <span className="BRL Wide80">{key}</span>
                {this.state.bEditMode && <Button onClick={() => { this.updateCategories('remove', key, '') }} color="danger" size="sm"  className="Absolute Right5px Padding0px TopMargin5px FS42"><i className="fa fa-remove"></i></Button>}
            </div>
        </li>);
    });
    
    return list;
  };
  
    // ############################ MODIFY CATEGORIES ###################################
    // ############################ MODIFY CATEGORIES ###################################
    updateCategories = (action, value)  => {
        let temp2;
    
        switch(action){
            case "remove":
                temp2 = this.state.settings; 
                delete temp2[value];
                this.setState({settings: temp2}, function () {});
            break;
            
            case "add": 
                if (this.state.newCategory !== '') {
                    temp2 = { ...this.state.settings, [this.state.newCategory]: {} }; 
                    this.setState({settings: temp2}, function () {
                        this.getItems(this.state.newCategory);
                        this.setState({ newCategory: '' });
                    });
                }  
            break;      
            default: break;
        } 
        this.setState({bChanged: true});
    }
  
  
    // ############################ DISPLAY FIELDS ###################################
    // ############################ DISPLAY FIELDS ###################################
    getItems = (key, bInit) => {
        const { settings } = this.state;
        this.setState({selectedCategory: key});
        this.selectedItems = settings[key];

        // console.log('ADMIN GETITEMS => ', key, ' => ', this.selectedItems);

        const temp = Object.keys(this.selectedItems).map(key2 => {
            return (
                <li key={key2} className={"BRL Wide100 BottomMargin3px d-flex justify-content-between align-items-center"}>
                    <div onClick={() => { this.getFields(key2) }} className={"btn BRL Wide100 VPadding5px HPadding20px ButtonFont Bold Capital Hand FS40 BottomShadow7 " + (key2 === this.state.selectedItem ? 'btn-primary' : 'btn-light')}>
                        <span className="BRL Wide80">{key2}</span>
                        {this.state.bEditMode && <Button onClick={() => { this.updateItems('remove', key2, '') }} color="danger" size="sm"  className="Absolute Right5px Padding0px TopMargin5px FS42"><i className="fa fa-remove"></i></Button>}
                    </div>
                </li>);
        });

        this.setState({ items: temp});
        if(bInit === true){
            // console.log('GET ITEMS 2 => ', Object.keys(this.selectedItems)[0] );
            this.getFields( Object.keys(this.selectedItems)[0] );
        }
    };
  

    // ############################ MODIFY CATEGORIES ###################################
    // ############################ MODIFY CATEGORIES ###################################
    updateItems = (action, name, value)  => {
        let temp, temp2;
        
        switch(action){
            default: break;
         
            case "remove":
                temp2 = this.state.settings; 
                delete temp2[this.state.selectedCategory][name];
            break;
            
            case "add": 
                if (this.state.newItem !== '') {
                temp = { ...this.state.settings[this.state.selectedCategory], [this.state.newItem]: '' }; 
                temp2 = this.state.settings;
                temp2[this.state.selectedCategory] = temp;
                this.setState({ newItem: '' });
                }  
            break;      
            
            case "update": 
                temp = { ...this.state.settings[this.state.selectedCategory], [name]: value }; 
                temp2 = this.state.settings;
                temp2[this.state.selectedCategory] = temp;
            break;
        }      
        
        this.setState({ settings: temp2, bChanged: true });
        this.getItems(this.state.selectedCategory);
    }


    // ############################ DISPLAY FIELDS ###################################
    // ############################ DISPLAY FIELDS ###################################
    getFields = (key) => {

        this.selectedFields = this.selectedItems[key];
        this.setState({selectedItem: key}, function () {
        const temp = Object.keys(this.selectedFields).map(key => {
            return (
                <li key={key} className="d-flexjustify-content-between align-items-center align-items-center NoBorder TopMargin5px" style={{listStyleType: 'none'}}>
                    <FormGroup>
                        <div className="BRL Wide100 BottomMargin2px">
                            <div className="BRL ButtonFont Capital FS41">{key}</div> 
                            {this.state.bEditMode && <Button onClick={() => { this.updateFields('remove', key, '') }} color="danger" size="sm"  className="BRL LeftMargin20px Padding0px FS42"><i className="fa fa-remove"></i></Button>}
                        </div>
        
                        {key !== 'color' && key !== 'background' && key !== 'font' && key !== 'url' && <Input type="text" name={key} onChange={(e) => this.handleChange('field',e)} value={this.selectedFields[key]} className="BRL col-lg-10 col-md-10 col-sm-10 NoBorder btn-light BCTrans InputFont FS40 TopMargin5px BottomShadow7"/>}
                        {(key === 'color' || key === 'background') && 
                            <div className="BRL col-lg-10 col-md-6 col-sm-6 HPadding0">
                                <input onChange={(e) => this.handleChange('field',e)} name={key} type="color" value={this.selectedFields[key]} className="BRL Wide40 form-control form-control-lg NoBorder btn-light BCTrans InputFont FS40 TopMargin5px BottomShadow7"/>
                                <input onChange={(e) => this.handleChange('field',e)} name={key} type="text" value={this.selectedFields[key]} className="BRL Wide40 form-control form-control-lg NoBorder btn-light BCTrans InputFont FS40 LeftMargin5 TopMargin5px BottomShadow7"/>
                            </div>
                        }
                        {key === 'font' && <Select classes={{container:"BRL col-lg-12 col-md-12 col-sm-12 form-control NoBorder FS40 LabelFont Padding3px TopMargin5px btn-light BottomShadow7", options:"BRL Wide50 CenterAlign"}} name={key} id={key} label={key} fields={key} value={this.selectedFields[key]} onChange={(name, value) => this.dropDownChange(name, value)}></Select>}
                        {key === 'url' && <input onChange={(e)=> {this.handleImage(e);}} className="BRL col-lg-10 col-md-10 col-sm-12 form-control NoBorder FS40 LabelFont Padding3px TopMargin5px btn-light BottomShadow7" type="file" style={{opacity: 1}} />}
                               
                    </FormGroup>  
                </li>);
            });
            this.setState({ fields: temp, selectedItem: key  });
            this.getItems(this.state.selectedCategory, false);
        });
    };
    

    // ############################ MODIFY FIELD STRUCTURE ###################################
    // ############################ MODIFY FIELD STRUCTURE ###################################
    updateFields = (action, name, value)  => {
        let temp, temp2;
        switch(action){

            default: break;
            
            case "remove":
                temp2 = this.state.settings; 
                delete temp2[this.state.selectedCategory][this.state.selectedItem][name];
            break;

            case "add": 
                if (this.state.newField !== '') {
                temp = { ...this.state.settings[this.state.selectedCategory][this.state.selectedItem], [this.state.newField]: '' }; 
                temp2 = this.state.settings;
                temp2[this.state.selectedCategory][this.state.selectedItem] = temp;
                this.setState({ newField: '' });
                }  
            break;

            case "update": 
                temp = { ...this.state.settings[this.state.selectedCategory][this.state.selectedItem], [name]: value }; 
                temp2 = this.state.settings;
                temp2[this.state.selectedCategory][this.state.selectedItem] = temp;
            break;
        }      
        
        this.bEdit = !this.bEdit;
        // console.log('TEMP2 => ', temp2.logo.title.font);
        this.setState({ settings: temp2, bChanged: true });
        this.getFields(this.state.selectedItem);
    }
    
  
  
    // ############################ RENDER ###################################
    // ############################ RENDER ###################################
    render(){
        if(this.state.settings){
            // console.log(' => ', this.state.settings.logo.title.font);
            return (  
                <div className={ this.props.settings.structure.outlet.class} style={styleMapping(this.props.settings.structure.outlet)}>      
                    <main className="BRL Wide100 PaddingResponsive content">
                        <div className="Absolute Right20px">
                            <button onClick={() => { this.saveList() }} className={"ButtonFont FS40 BRL btn " + (this.state.bChanged ? 'btn-danger' : 'btn-primary')}>
                                {(this.state.bChanged ? 'Save Settings' : 'Settings Saved')}
                            </button>
                        </div>

                        <div className="row TopMargin50px">
                            {/* ################## SETTINGS TOGGLE APP/WEBSITE ################## */}
                            <div className="BRL col-lg-6 col-md-12 col-sm-12 p-0 ">
                                <div className="BRL col-lg-6 col-md-12 col-sm-12 p-0 react-switch-selector-wrapper BottomMargin10px">
                                    <Toggle onChange={(e) => {this.handleToggle(e)}} data={this.data} backgroundColor={"#efefef"} selectionIndicatorMargin={0} fontSize={15}/>
                                </div>
                                {/* <div className="BRL col-lg-6 col-md-12 col-sm-12 HPadding20px BottomMargin10px"> 
                                    <button className={"BRL Wide100 ButtonFont Capital btn " + (this.state.settingsVersion === 'web' ? 'btn-info' : 'btn-secondary')}>{( this.state.settingsVersion === 'web' ? 'Copy into App' : 'Copy into Web')}</button>
                                </div> */}
                            </div>
                        
                            {/* ################## CATEGORY BAR ################## */}
                            <div className="col-md-12 col-sm-12 BRL p-0 TopMargin10px">
                                <ul className="BRL col-lg-9 col-md-9 col-sm-9 list-group list-group-flush">{this.renderCategories()}</ul>
                                
                                {this.state.bEditMode && 
                                <div className="col-lg-2 col-md-1 col-sm-1 BRL">
                                    <Input className="BRL Wide80" type="text" name='new_category' value={this.state.newCategory} onChange={(e) => this.handleChange('category', e)} />
                                    <button  onClick={() => { this.updateCategories('add', '', '') }} disabled={this.state.newCategory === ''} className="BRL Wide60px LeftMargin2 btn FCGreen1"><i class="far fa-plus-square FS34"></i></button>
                                </div>}
                            </div>
                        
                            {/* ################## ITEM LIST ################## */}
                            {this.state.selectedCategory !== '' &&  
                            <div className="col-lg-4 col-md-4 col-sm-4 BRL TopPadding20px HPadding0">
                                <ul className="BRL col-lg-10 col-md-10 col-sm-10 list-group list-group-flush TopMargin2 ">{this.state.items} </ul>
                                
                                {this.state.bEditMode && 
                                <div className="BRL col-lg-10 col-md-10 col-sm-10 HPadding0 TopMargin2">
                                    <Input className="BRL Wide85" type="text" name='new_item' value={this.state.newItem} onChange={(e) => this.handleChange('item', e)} />
                                    <button  onClick={() => { this.updateItems('add', '', '') }} disabled={this.state.newItem === ''}  className="BRL Wide10 LeftMargin2 btn FCGreen1"><i class="far fa-plus-square FS34"></i></button>
                                </div>}
                            
                            </div>}
                        
                            {this.selectedItems !== '' &&  
                            <React.Fragment>
                                {/* ################## FIELD ELEMENTS ################## */}
                                <div className="col-lg-8 col-md-8 col-sm-8 BRL TopPadding20px">
                                    <ul className="list-group list-group-flush">{this.state.fields}</ul>
                                    {this.state.bEditMode && this.state.selectedItem && 
                                    <div className="BRL BRL col-lg-8 col-md-8 col-sm-8 TopMargin2 HPadding0">
                                        <Input className="BRL Wide85" type="text" name='new_field' value={this.state.newField} onChange={(e) => this.handleChange('field', e)} />
                                        <button onClick={() => { this.updateFields('add', '', '') }} disabled={this.state.newField === ''} className="BRL Wide10 LeftMargin2 btn FCGreen1"><i class="far fa-plus-square FS34"></i></button>
                                    </div>}
                                    
                                </div>

                                {/* ################## PREVIEW ################## */}
                                {this.state.settingsVersion === 'web' && <div className="col-lg-12 col-md-12 col-sm-12 BRL TopPadding20px">
                                    <div className="BRL Wide100 TopMargin10px BottomMargin20px TitleFont FS38">PREVIEW</div>
                                    {(this.state.selectedCategory === 'logo' || this.state.selectedCategory === 'menu' || this.state.selectedCategory === 'personal') && <NavBar settingsManual={this.state.settings} changed={this.bEdit}></NavBar>}
                                    {this.state.selectedCategory === 'footer' && <Footer settingsManual={this.state.settings} changed={this.bEdit}></Footer>}
                                    {this.state.selectedCategory === 'profile' &&  <Profile settingsManual={this.state.settings.profile} changed={this.bEdit} bAdmin={true}></Profile>}
                                    {this.state.selectedCategory === 'addresses' &&  <AddressSimple index={'99'} key={'99'} settings={this.state.settings.addresses} address={this.address} bAdmin={true}></AddressSimple>}
                                    {this.state.selectedCategory === 'login' && <LoginFormAnt settingsManual={this.state.settings} changed={this.bEdit}></LoginFormAnt>}
                                </div>}
                            </React.Fragment>}
                    </div>
                </main>
            </div>);
        } else return null;
  }
}

export default withRouter(Admin);