import React from 'react';
import Footer from './core/footer';
import { styleMapping } from "./utils/tools";
import NavBar from './core/navbar';
import Sidebar from "react-sidebar";
import SideMenu from './core/sideMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as actions from '../store/pseudo/general';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class CustomLayout extends React.Component {

    // ##################################  CONSTRUCTOR  ####################################
    // ##################################  CONSTRUCTOR  ####################################
    constructor(props) {
        super(props);
        this.state = { infoModal: null };
    }
    
    render() {        
        if(this.props.settings){
            const {structure, logo} = this.props.settings;

            return (
            <div id="container" className={"layout " + structure.container.class} style={styleMapping(structure.container)}>
                <NavBar></NavBar>
                    <div  className={"MobileMenu Fixed Bottom10px Left20px z2000 Hand Padding5px"} >
                        <div className={"BRL FS20 VPadding10px"}>
                            <FontAwesomeIcon 
                                icon={"bars"} 
                                color={logo.title.color} 
                                onClick={() => {this.props.sideMenuFunction(!this.props.sideMenu)}}/>
                        </div>
                    </div>
                <Sidebar 
                    settings={this.props.settings} 
                    sidebar={<SideMenu />} 
                    dragToggleDistance={15} 
                    onSetOpen={()=>{ this.props.sideMenuFunction(!this.props.sideMenu)}} 
                    open={this.props.sideMenu} 
                    styles={{ sidebar: { position: 'fixed', height:'120vh', minWidth:200, maxWidth:500, width:'30%', background: "white", zIndex: 3001, padding: '5px 0px 5px 0px' } }}>
                    <div />
                </Sidebar>

               <div className="BRL Wide100" style={{minHeight:'70vh'}}>
                    {
                        this.props.children
                    }
                </div>

                {/* {this.props.direction !== 'maps' && this.props.direction !== '/business/dashboard/' && this.props.direction !== '/business/marketing/' && this.props.direction !== null && 
                    <Footer></Footer>
                } */}
            </div>
            );
        } else return null; 
    }
}


const mapstateToProps = (state) => {
    return {
        isAuthenticated: state.status.token !== null && state.status.token !== '' ? true : false, 
        status:state.status, 
        sideMenu:state.sideMenu, 
        user:state.user, 
        architecture:state.architecture, 
        settings:state.settings,
        direction: state.current.direction
    };
}
const mapDispatchToProps = dispatch => {
    return {
        navigate: (direction, history) => dispatch(actions.navigate(direction, history)), 
        sideMenuFunction: (bVal) => dispatch(actions.sideMenu(bVal))
    }
}
export default withRouter(connect(mapstateToProps, mapDispatchToProps)(CustomLayout));

