import React from "react";
import { styleMapping } from "../utils/tools";

const AddressSimple = props => {
  
  const { settings, index, address } = props;

  return (<div key={index} className={ settings.container.class } style={styleMapping(settings.container)}>          
            <div className={ settings.name.class } style={styleMapping(settings.name)}>{ address.type }</div>
            {address.fullname && (<div className={ settings.address.class } style={styleMapping(settings.address)}>{ address.fullname }</div>)}
            <div className={ settings.address.class } style={styleMapping(settings.address)}>{ address.address1 }</div>
            {address.address2 && (<div className={ settings.address.class } style={styleMapping(settings.address)}>{ address.address2 }</div>)}
            <div className="BRL Wide100">
                <div className={"BRL TopMargin5px LeftAlign " + settings.address.class} style={styleMapping(settings.address)}>{ address.postalcode }</div>
                <div className={"BRL TopMargin5px LeftMargin5px LeftAlign " + settings.address.class} style={styleMapping(settings.address)}>{ address.city }</div>
            </div>      
            <div className={"BRL TopMargin5px Wide100 LeftAlign " + settings.address.class} style={styleMapping(settings.address)}>{ address.country }</div>
            <div >
              {address.telephone && (<div className={ settings.extra.class } style={styleMapping(settings.extra)}>{ address.telephone }</div>)}
              {address.email && (<a className={ settings.extra.class } style={styleMapping(settings.extra)} href="mailto:{{ address.email }}?Subject=Need%20information" target="_top">{ address.email }</a>)}
            </div>
          </div> 
  );
};
export default AddressSimple;
