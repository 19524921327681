import * as actionTypes from './actionTypes';
// import history from '../../components/history';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}
  
export const authSuccess = data => {
    // console.log('AUTH SUCCESS => ', data);
    return {
        type: actionTypes.AUTH_SUCCESS,
        data: data
    }
}

export const authFail = data => {
    // console.log('AUTH FAIL => ', data);
    return {
        type: actionTypes.AUTH_FAIL,
        data: data
    }
}

export const logout = () => {
    
    return {
        type: actionTypes.AUTH_LOGOUT
    };
}

export const broadcast = (data) => {
    return {
        type: actionTypes.BROADCAST,
        data: data,
    };
}

export const navigateTo = (data, history) => {
    // console.log('NAVIGATE TO : ', history, '  => ',data.direction);
    // RootNavigation.navigate(data.direction);
    history.push('../../' + data.direction);
    return {
        type: actionTypes.NAVIGATE,
        data: data,
    };
}

export const changeMenu = (data) => {
    return {
        type: actionTypes.CHANGE_MENU,
        data: data,
    };
}

export const isBusiness2 = (data) => {
    return {
        type: actionTypes.IS_BUSINESS,
        data: data,
    };
}


export const setSettings = (data) => {
    // console.log('STORE ACTIONS - SETSETTINGS: ', data);
    return {
        type: actionTypes.SET_SETTINGS,
        data: data,
    };
}

export const sideMenu2 = (data) => {
    return {
        type: actionTypes.SIDE_MENU,
        data: data,
    };
}

export const geolocation2 = (data) => {
    return {
        type: actionTypes.GEOLOCATION,
        data: data,
    };
}


export const setProfile = (data) => {
    return {
        type: actionTypes.SET_PROFILE,
        data: data,
    };
}
