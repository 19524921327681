import React, {Component} from 'react';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import GeoLocation from "../geolocation/geolocation";
import InputData from "./inputData";
import { setFakeData } from "../services/geolocation";
import MapBox from '../geolocation/mapbox';
import InfoModal from "../elements/infoModal";
import SearchMenuBar from '../elements/searchMenuBar';
import FilterMenu2 from '../core/filterMenu2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalFilter from '../elements/modalFilter';
import { jsonToGeoJSON, jsonToGeoJSONBusiness } from "../services/geolocation";
import SearchBox from '../elements/searchBox';
import { truncate } from "../utils/tools";
import $ from 'jquery';
const width = $(window).width();


// class Data extends GeoLocation {
  class Data {
  constructor(props) {
    // super(props);
    this.styleMap = {height:'100vh', width: '100%'};
    this.state = {input: {latitude:37.4, longitude:-112, quantity:50, scale:0, unit: 2, duration: 2}, loading: false, bData: false, popUpData: null, bPopUp: null};
    this.options = {autoClose: 3000, type: toast.TYPE.INFO,hideProgressBar: false};
    this.bRefresh = true;
    this.filters = {'gender': '', 'age': '', 'stereotype': '', 'political': '' };
    // $('.MobileMenu').css({'display': 'block'});
      
  }

  // ##################################  MAP CHANGED  ####################################
  // ##################################  MAP CHANGED  ####################################
  isChanged = async (coordinates, zoom) => {
      this.suggestions=[];
      this.coordinates = coordinates;
      this.coordinatesTrunc = {'latitude': truncate(coordinates['latitude'],3), 'longitude': truncate(coordinates['longitude'],3)}

      // const temp = deltaCoordinates(this.coordinates, this.Lastcoordinates);
      // if (temp > 300 || Math.abs(parseInt(this.zoom,10) - parseInt(zoom,10)) > 1 ){this.bRefresh = true;}
      if(zoom == null){this.zoom = 16;}else {this.zoom = zoom;}
      this.input = {...this.state.input, ...this.coordinates};
      this.setState({latitude: truncate(this.coordinates['latitude'], 6), longitude: truncate(this.coordinates['longitude'], 6), input: this.input});
  }
  

  // ################################## PRERENDER  ####################################
  // ################################## PRERENDER  ####################################
  preRender = () => {    

      // console.log('PRERENDER 1 => ', this.filteredDataPoints, ' => ', this.dataPoints.businesses);
      
      const data2 = jsonToGeoJSON(this.filteredDataPoints);
      const data3 = jsonToGeoJSONBusiness(this.dataPoints.businesses);
      const data = { latitude: this.coordinates['latitude'], longitude: this.coordinates['longitude'], dataPoints: data2, dataBusinesses: data3, input: this.input }
      // console.log('PRERENDER 2 => ', data);
      this.setState(data, function(){
        setTimeout(() => {this.setState({loading: false });}, 2000);
      }); 
  }

  // ############################ RENDER ###################################
  // ############################ RENDER ###################################
  render(){
    if(this.coordinates){
      return (
          <div className="BRL Wide100 ">
            {this.state.popUpData && <InfoModal type={'popUp'} data={this.state.popUpData} show={this.state.bPopUp} onClose={() => {this.setState({bPopUp: false})}} />}
            {/*################################ INPUT ################################*/}
            {/*################################ INPUT ################################*/}
            { (this.state.bData || width > 980) && <div className={"Absolute col-lg-3 col-md-4 col-sm-12 MaxWide300px HPadding10px z100 " + (width > 980 ? 'Bottom10px Left10px' : 'Bottom100px Left0px')}><InputData data={this.state.input} onSubmit={(action, data) => {this.handleActivitySubmit(action, data)}} /></div>}
            <div className="MobileMenu Absolute Top10px Left10px Padding10px Hand">
                <button type="submit" onClick={e => this.setState({bData: !this.state.bData}) } className={"BRL Wide100 btn btn-danger z10 BottomShadow7"}>
                    <div className="BRL Wide100 VPadding3px HeaderFont FS38 Capital z10">Data</div>
                </button>
            </div>

            {/* MAP */}
            <div className={"BRL col-lg-12 col-md-12 col-sm-12 NoBorder HPadding0px"} style={{minHeight:'800px'}}>   
                {/*################################ DESKTOP ################################*/}
                {/*################################ DESKTOP ################################*/}
                <div className={"DesktopMenu Absolute Top0px Left0px col-lg-4 col-md-4 col-sm-12 HPadding20px VPadding10px MaxWide300px z1001"}>
                  <div className="BRL col-lg-12 col-md-12 col-sm-12 BottomMargin10px z2000 HPadding0 "><SearchBox title={true} icon="map" settings={this.props.settings.logo} iconLib="font-awesome" placeholder="search a place" loading={this.state.loading} onChange={(e) => this.getPlace(e)} onSubmit={(e) => {this.isChanged(e, this.zoom, 'search')}} onRefresh={() => {this.getResults()}}  boxStyle={'Wide100'} suggestions={this.suggestions}/></div>
                  <div className="BRL col-lg-12 col-md-12 col-sm-12 z1000 HPadding0 "><FilterMenu2 data={this.filters} onFilter={e => this.getFilters(e)} /></div>
                </div>
                {/*################################ MOBILE ################################*/}
                {/*################################ MOBILE ################################*/}
                {this.state.modal && <ModalFilter show={this.state.modal} data={this.filters} onClose={()=>{this.setState({modal: false});}} onFilter={e => this.getFilters(e)} /> }                   
                <div className={"MobileMenu Fixed Bottom0px Left0px MarginAuto col-lg-12 col-md-12 col-sm-12 TopMargin0px Padding0 z2000"}>                            
                    <SearchMenuBar settings={this.props.settings} boxStyle={'Wide100'} loading={this.state.loading} 
                        icon="map" iconLib="font-awesome" placeholder="search a place" onChange={(e) => this.getPlace(e)}  
                        onSubmit={(e) => {this.isChanged(e, this.zoom, 'search')}} onRefresh={() => {this.getResults()}} onFilter={() =>{this.setState({modal: true})}}
                        suggestions={this.suggestions}/>
                </div>

                {/*################################ MAP ################################*/}
                {/*################################ MAP ################################*/}
                <div className="BRL Wide100 High100VH">
                  <MapBox settings={this.props.settings} dragPan={false} styleMap={this.styleMap} data={this.state} zoom={this.zoom} onChangeCenter={(e, zoom, type) => {this.isChanged(e, zoom, type)}} onPopUp={data =>{this.displayPopUp(data)}} onDblClick={(map, event) =>{} }/>
                  
                </div>
            </div>
          </div>
        // </div>
        );
    } else { 
      return ( 
        <div className={"Flex FlexCol JustifyCenter AlignCenter High100VH"} style={{backgroundColor: "rgba(240,240,240,0.5)"}}>
                        <div className="Monoton" style={{fontSize:'3rem', color:"#ad182f"}}>STRANGERS</div>
          <div className={""}><FontAwesomeIcon className={"FS10 Spin"} icon={"compass"} color="#ad182f" /></div>
        </div>);
    }
  }

  // ############################# HANDLE FORM SUBMIT #############################
  // ############################# HANDLE FORM SUBMIT #############################
  handleActivitySubmit = async (action, data) => {
    const result = await setFakeData({action:action, data:data});
    // console.log('DATA GENERATION => ', result);
    switch(result){
      default:break;
      case true:toast.success("Fake data generated", this.options);break;
      case false:toast.error("Fake data was not generated", this.options);break;
    }
  }

}

export default withRouter(Data);