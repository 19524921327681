import React, { Component } from 'react';
import { getEquivalent } from "../utils/tools";
import { PieChart } from 'react-minimal-pie-chart';
// import * as design from "../../resources/constants/design";
const equivalentFields = [['age/gender','age/gender'],['age/political','age/political'],['gender/political','gend/political'],['age/stereotype','age/stereo'],['gender/stereotype','gend/stere'],['stereotype/political','stereo/political'],
['totalUsers','Users in Area']];
const styleViewer = { imageContainer: 'BRL Wide100 TopMargin5px NoBorder High100px ', imageDiv: 'BRL Wide100px High100 RightMargin2px BorderRadius5 NoBorder OverflowHidden ',
image: 'High100 ImageFit ImageCentered OverflowHidden Hand', icon: 'BRL Padding20px FS20 FCRed fa fa-picture-o ', carousel: 'Fixed Wide100 Top0px Left0px BCTransparent3 High1200px z8000', fullscreen: "carousel slide Wide80 MarginAuto TopMargin10 "};

export default class VisualStats extends Component {  
  constructor(props) {
    super(props);
    this.state = {data: props.data, config: props.config, bFullscreen: false};
  }

  // ############################ TAB SELECTOR ###################################
  // ############################ TAB SELECTOR ###################################
  setKey = (k) => {
    this.setState({key: k});
  }
  
  // ############################ RENDER ###################################
  // ############################ RENDER ###################################
  render() {
    
    const {config, data} = this.props;

    return (  <React.Fragment>
              <div className={"float-left col-lg-12 col-md-12 col-sm-12 col-12 p-0 Flex FlexRow "}>
                
                {/* GENERAL STATS */}
                <div className={"p-2"} style={{flex:2}}>
                  <div className={"BRL Wide100 FS41 TitleFont Capital BottomBorder BorderCGray CenterAlign"} style={{marginBottom:'10px'}}>{"General Stats"}</div>
                  
                  
                  {Object.keys(data['general']).map((key,index)=>{
                  return( 
                    <div key={key+index} className={"float-left col-lg-12 col-md-12 col-sm-12 col-12 p-1"} >
                      <div className={"BRL Wide100 FS42 TitleFont Capital"} style={{marginBottom:'5px'}}>{getEquivalent(key, equivalentFields, 1)}</div>
                      <div className={"BRL Wide100 FS40 TitleFont CenterAlign"} style={{}}>{data['general'][key]}</div>
                    </div>
                  );
                })}
                    
                </div>

                {/* PIE CHARTS */}
                <div className={""} style={{flex:7}}>
                  {Object.keys(config['summary']).map((stat,index2)=>{
                    
                    // console.log(index2, ' : RENDERING VISUAL STATS => ', stat);
                    return( 
                    <div key={stat+index2} className={"Hand float-left col-lg-3 col-md-3 col-sm-4 col-6 Padding10px MaxHigh170px"} >
                      <div className={"BRL Wide100 FS42 TitleFont Capital CenterAlign BottomBorder BorderCGray"} style={{marginBottom:'10px'}}>{getEquivalent(stat, equivalentFields, 1)}</div>
                      {data && <PieChart style={{ height: '130px' }} labelPosition={95} animate={true} radius={50} data={data['charts'][stat]} labelStyle={{'fontFamily': 'FuturaM', 'fontSize': '9px', 'color': '#fff'}} onClick={() => this.displayDetails(data[stat],true)}  />}
                    
                    </div>
                  );
                  })}
                </div>
              </div>

               {/* FULLSCREEN CAROUSEL */}
               {this.state.bFullscreen && 
                <div className={styleViewer.carousel + ""}>
  
                  <div className={"Absolute Top50px Right50px "} onClick={()=>{this.displayDetails({}, false)}}>
                    <i className="fa fa-times-circle FS30 FCBlack Hand"/>
                  </div>

                  <div id="carouselExampleIndicators" className="carousel slide Wide100 MarginAuto TopMargin200px" data-ride="carousel">
                    <ol className="carousel-indicators TopMargin100px">
                      {Object.keys(config['summary']).map((stat,index)=>{
                        return(
                          <li data-target="#carouselExampleIndicators" data-slide-to={index} className={(index === 0 ? 'active' : '')}></li>
                        );
                        })}
                     
                    </ol>
                    
                    <div className="carousel-inner">
                    {Object.keys(data['charts']).map((element,index)=>{
                      return(
                        <div key={index} className={"carousel-item " + (index === 0 ? 'active' : '')}>
                          <div className={"BRL Wide100 FS33 FuturaH Capital CenterAlign BottomMargin2"}>{getEquivalent(element, equivalentFields, 1)}</div>
                          <PieChart label={({ dataEntry }) => dataEntry.title + ', ' + Math.round(dataEntry.percentage) + '%'} viewBoxSize={[100, 130]} style={{ height: '400px' }} labelPosition={90} animate={true} radius={50} data={data['charts'][element]} labelStyle={{'fontFamily': 'FuturaH', 'fontSize': '4px', 'color': '#fff', 'backgroundColor': '#fff', 'border': '0' }} />
                        </div>
                      );
                      })}
                    </div>

                    <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                      <span className="carousel-control-prev-icon FCBlack" aria-hidden="true"></span>
                      <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                      <span className="carousel-control-next-icon FCBlack" aria-hidden="true"></span>
                      <span className="sr-only">Next</span>
                    </a>
                  </div>  

                </div>
                 }
              </React.Fragment>
        
      );
  }  

     
  // ################################## PRERENDER  ####################################
  // ################################## PRERENDER  ####################################
  displayDetails = (data, bDisplay) => {    

    switch(bDisplay){
      
      case true:break;
      case false:break;  
      default:break;  
    }
    this.setState({bFullscreen: bDisplay});
  }
  

}


// return (
//   <div className={"BRL z30 BCGray1 BottomShadow7 OverflowAuto"} style={{width: '100%' }}>
//     <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => {this.setKey(k)}}>
//     {Object.keys(config).map((tab,index)=>{
//       return(  
//         <Tab key={tab} eventKey={tab} title={tab} tabClassName={"BRL TitleFont FS41 FCBlack CenterAlign NoBorder Capital"}>
//           {Object.keys(config[tab]).map((stat,index2)=>{
//           return( 
//             // <div className={"Hand"} style={styleMapping({})}>
//             <div key={stat+index2} className={"Hand BRL Wide50 Padding10px"} >
//               <div className={"BRL Wide100 FS42 TitleFont Capital CenterAlign FCBlack"}>{stat}</div>
//               <PieChart viewBoxSize={[100,100]} animate={true} radius={35} rounded lineWidth={25} data={data[stat]} label={({ dataEntry }) => Math.round(dataEntry.percentage) + '%'} labelStyle={{fontSize: '10px'}} />
//             </div>
//           );
//           })}
//         </Tab>
//       );
//       })}
//     </Tabs>
//   </div>  

// );
