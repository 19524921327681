// export const DASHBOARD = {
//     companyname: {name:'companyname', placeholder:'name of company',  type: 'text', error: 'company name needs to be at least 5 characters long', regex: /^.{3,50}$/, input:'form-control form-control-lg HPadding10px BorderRadius5 NoBorder NoOutline InputFont FS38 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide100 TopMargin20px BottomMargin10px form-control form-control-lg Padding0px NoBorder NoOutline', container:'NoBorder NoOutline BCGray1' },
//     firstname: {name:'firstname', placeholder:'First name',  type: 'text', error: 'First name needs to be at least 2 characters long', regex: /^[a-zA-Z]{0,30}$/, input:'form-control form-control-lg HPadding10px BorderRadius5 NoBorder NoOutline InputFont FS38 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide45 TopMargin20px BottomMargin10px RightMargin2 form-control form-control-lg Padding0px NoBorder NoOutline', container:'NoBorder NoOutline BCGray1' },
//     lastname: {name:'lastname', placeholder:'Last name',  type: 'text', error: 'Last name needs to be at least 2 characters long', regex: /^[a-zA-Z]{0,30}$/, input:'form-control form-control-lg HPadding10px BorderRadius5 NoBorder NoOutline InputFont FS38 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide45 TopMargin20px BottomMargin10px  form-control form-control-lg Padding0px NoBorder NoOutline', container:'NoBorder NoOutline BCGray1' },
//     email: {name:'email', placeholder:'Email address',  type: 'text', error: 'This is not a valid email address', regex: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/, input:'form-control form-control-lg HPadding10px BorderRadius5 NoBorder NoOutline InputFont FS38 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide100 TopMargin20px BottomMargin10px form-control form-control-lg Padding0px NoBorder NoOutline', container:'NoBorder NoOutline BCGray1' },
//     telephone: {name:'telephone', placeholder:'+XX Telephone',  type: 'text', error: 'This is not a valid telephone number. Needs to start with +', regex: /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/, input:'form-control form-control-lg HPadding10px BorderRadius5 NoBorder NoOutline InputFont FS38 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide100 TopMargin20px BottomMargin10px form-control form-control-lg Padding0px NoBorder NoOutline', container:'NoBorder NoOutline BCGray1' },
//     instagram: {name:'instagram', placeholder:'Instagram name',  type: 'text', error: '', regex: /^.{0,50}$/, input:'form-control form-control-lg HPadding10px BorderRadius5 NoBorder NoOutline InputFont FS38 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide100 TopMargin20px BottomMargin10px RightMargin2 form-control form-control-lg Padding0px NoBorder NoOutline', container:'NoBorder NoOutline BCGray1' },
//     website: {name:'website', placeholder:'Website',  type: 'text', error: '', regex: /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/, input:'form-control form-control-lg HPadding10px BorderRadius5 NoBorder NoOutline InputFont FS38 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide100 TopMargin20px BottomMargin10px RightMargin2 form-control form-control-lg Padding0px NoBorder NoOutline', container:'NoBorder NoOutline BCGray1' },
//     address: {name:'address', placeholder:'Street address',  type: 'text', error:'Address needs to be at least 6 characters long', regex: /^.{4,50}$/, input:'form-control form-control-lg HPadding10px BorderRadius5 NoBorder NoOutline InputFont FS38 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide100 TopMargin20px BottomMargin10px form-control form-control-lg Padding0px NoBorder NoOutline', container:'NoBorder NoOutline BCGray1' },
//     postcode: {name:'postcode', placeholder:'PostCode',  type: 'text', error: 'This is not a valid postcode', regex: /^[a-zA-Z0-9 ]{4,}$/, input:'form-control form-control-lg HPadding10px BorderRadius5 NoBorder NoOutline InputFont FS38 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide30 TopMargin20px BottomMargin10px RightMargin2 form-control form-control-lg Padding0px NoBorder NoOutline', container:'NoBorder NoOutline BCGray1' },
//     city: {name:'city', placeholder:'City',  type: 'text', error: 'This is not a valid city', regex: /^.{2,50}$/, input:'form-control form-control-lg HPadding10px BorderRadius5 NoBorder NoOutline InputFont FS38 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide60 TopMargin20px BottomMargin10px RightMargin2 form-control form-control-lg Padding0px NoBorder NoOutline', container:'NoBorder NoOutline BCGray1' },
//     country: {name:'country', placeholder:'Country',  type: 'text', error: 'This is not a valid country', regex: /^[a-zA-Z]{0,30}$/, input:'form-control form-control-lg HPadding10px BorderRadius5 NoBorder NoOutline InputFont FS38 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide50 TopMargin20px BottomMargin10px RightMargin2 form-control form-control-lg Padding0px NoBorder NoOutline', container:'NoBorder NoOutline BCGray1' },
//   };

  export const DASHBOARD = {
    companyname: {name:'companyname', placeholder:'name of company',  type: 'text', error: 'company name needs to be at least 5 characters long', regex: /^.{3,50}$/, input:'form-control form-control-lg HPadding10px BorderRadius5 NoBorder NoOutline InputFont FS38 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide100 TopMargin20px BottomMargin10px form-control form-control-lg Padding0px NoBorder NoOutline', container:'NoBorder NoOutline BCGray1' },
    firstname: {name:'firstname', placeholder:'First name',  type: 'text', error: 'First name needs to be at least 2 characters long', regex: null, input:'form-control form-control-lg HPadding10px BorderRadius5 NoBorder NoOutline InputFont FS38 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide45 TopMargin20px BottomMargin10px RightMargin2 form-control form-control-lg Padding0px NoBorder NoOutline', container:'NoBorder NoOutline BCGray1' },
    lastname: {name:'lastname', placeholder:'Last name',  type: 'text', error: 'Last name needs to be at least 2 characters long', regex: null, input:'form-control form-control-lg HPadding10px BorderRadius5 NoBorder NoOutline InputFont FS38 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide45 TopMargin20px BottomMargin10px  form-control form-control-lg Padding0px NoBorder NoOutline', container:'NoBorder NoOutline BCGray1' },
    email: {name:'email', placeholder:'Email address',  type: 'text', error: 'This is not a valid email address', regex: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/, input:'form-control form-control-lg HPadding10px BorderRadius5 NoBorder NoOutline InputFont FS38 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide100 TopMargin20px BottomMargin10px form-control form-control-lg Padding0px NoBorder NoOutline', container:'NoBorder NoOutline BCGray1' },
    telephone: {name:'telephone', placeholder:'+XX Telephone',  type: 'text', error: 'This is not a valid telephone number. Needs to start with +', regex: null, input:'form-control form-control-lg HPadding10px BorderRadius5 NoBorder NoOutline InputFont FS38 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide100 TopMargin20px BottomMargin10px form-control form-control-lg Padding0px NoBorder NoOutline', container:'NoBorder NoOutline BCGray1' },
    instagram: {name:'instagram', placeholder:'Instagram name',  type: 'text', error: '', regex: /^.{0,50}$/, input:'form-control form-control-lg HPadding10px BorderRadius5 NoBorder NoOutline InputFont FS38 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide100 TopMargin20px BottomMargin10px RightMargin2 form-control form-control-lg Padding0px NoBorder NoOutline', container:'NoBorder NoOutline BCGray1' },
    website: {name:'website', placeholder:'Website',  type: 'text', error: '', regex: null, input:'form-control form-control-lg HPadding10px BorderRadius5 NoBorder NoOutline InputFont FS38 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide100 TopMargin20px BottomMargin10px RightMargin2 form-control form-control-lg Padding0px NoBorder NoOutline', container:'NoBorder NoOutline BCGray1' },
    address: {name:'address', placeholder:'Street address',  type: 'text', error:'Address needs to be at least 6 characters long', regex: /^.{4,50}$/, input:'form-control form-control-lg HPadding10px BorderRadius5 NoBorder NoOutline InputFont FS38 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide100 TopMargin20px BottomMargin10px form-control form-control-lg Padding0px NoBorder NoOutline', container:'NoBorder NoOutline BCGray1' },
    postcode: {name:'postcode', placeholder:'PostCode',  type: 'text', error: 'This is not a valid postcode', regex: /^[a-zA-Z0-9 ]{4,}$/, input:'form-control form-control-lg HPadding10px BorderRadius5 NoBorder NoOutline InputFont FS38 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide30 TopMargin20px BottomMargin10px RightMargin2 form-control form-control-lg Padding0px NoBorder NoOutline', container:'NoBorder NoOutline BCGray1' },
    city: {name:'city', placeholder:'City',  type: 'text', error: 'This is not a valid city', regex: /^.{2,50}$/, input:'form-control form-control-lg HPadding10px BorderRadius5 NoBorder NoOutline InputFont FS38 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide60 TopMargin20px BottomMargin10px RightMargin2 form-control form-control-lg Padding0px NoBorder NoOutline', container:'NoBorder NoOutline BCGray1' },
    country: {name:'country', placeholder:'Country',  type: 'text', error: 'This is not a valid country', regex: /^[a-zA-Z]{0,30}$/, input:'form-control form-control-lg HPadding10px BorderRadius5 NoBorder NoOutline InputFont FS38 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide50 TopMargin20px BottomMargin10px RightMargin2 form-control form-control-lg Padding0px NoBorder NoOutline', container:'NoBorder NoOutline BCGray1' },
  };

  export const INPUTAD = {
    title: {name:'title', placeholder:'title',  type: 'text', error: 'title needs to be at least 5 characters long', regex: /^.{2,50}$/, extra: null, input:'form-control form-control-lg NoBorder NoOutline InputFont FS40 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide100 TopMargin20px BottomMargin10px form-control form-control-lg Padding0px NoBorder NoOutline BCInherit', container:'NoBorder NoOutline' },
    date: {name:'date', placeholder:'specify date',  type: 'date', error: 'a date needs to be specified', regex: /^[0-9-/]{10}$/, extra: 'date', input:'form-control form-control-lg NoBorder NoOutline InputFont FS40 BCWhite btn-light BCTrans BottomShadow7', wrapper:'BRL Wide90 TopMargin20px RightMargin5px form-control form-control-lg Padding0px NoBorder NoOutline BCInherit', container:'NoBorder NoOutline' },
    start: {name:'start', placeholder:'HHM0',  type: 'text', error: 'start hour doesnt match HHM0 format', regex: /^([01]\d|2[0-4]):?([3|0][0-0])$/, extra: 'null', input:'Flex1 form-control form-control-lg NoBorder NoOutline InputFont HPadding10px FS40 btn-light BCTrans BottomShadow7', wrapper:'Flex3 Padding0px NoBorder NoOutline BCInherit', container:'Flex1 NoBorder NoOutline' },
    end: {name:'end', placeholder:'HHM0 ',  type: 'text', error: 'end hour doesnt match HHM0 format', regex: /^([01]\d|2[0-4]):?([3|0][0-0])$/, extra: null, input:'Flex1 form-control form-control-lg NoBorder NoOutline InputFont HPadding10px FS40 btn-light BCTrans BottomShadow7', wrapper:'Flex3 Padding0px NoBorder NoOutline BCInherit', container:'Flex1 NoBorder NoOutline' }
  };

  export const MONITOR = {
    date: {name:'date', placeholder:'specify date',  type: 'date', error: 'a date needs to be specified', regex: /^[0-9-/]{10}$/, extra: 'date2', input:'form-control form-control-lg NoBorder NoOutline InputFont FS40 BCWhite btn-light BCTrans BottomShadow7', wrapper:'BRL Wide30 RightMargin5px form-control form-control-lg Padding0px NoBorder NoOutline BCInherit', container:'NoBorder NoOutline' },
  };

  export const INPUTDATA = {
    latitude: {name:'latitude', placeholder:'GPS latitude',  type: 'text', error: 'GPS latitude', regex:null , extra: null, input:'form-control form-control-lg HPadding10px NoBorder NoOutline InputFont FS40 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide45 RightMargin5 form-control form-control-lg Padding0px NoBorder NoOutline BCInherit', container:'NoBorder NoOutline' },
    longitude: {name:'longitude', placeholder:'GPS longitude',  type: 'text', error: 'GPS longitude', regex:null , extra: null, input:'form-control form-control-lg HPadding10px NoBorder NoOutline InputFont FS40 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide45 form-control form-control-lg Padding0px NoBorder NoOutline BCInherit', container:'NoBorder NoOutline' },
    quantity: {name:'quantity', placeholder:'quantity',  type: 'text', error: 'quantity needs to be between 0 and 10000', regex:/^([1-9][0-9]{0,3}|10000)$/ , extra: null, input:'form-control form-control-lg HPadding10px NoBorder NoOutline InputFont FS40 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide45 TopMargin20px RightMargin5 form-control form-control-lg Padding0px NoBorder NoOutline BCInherit', container:'NoBorder NoOutline' },
    scale: {name:'scale', placeholder:'specify scale',  type: 'text', error: 'scale need to be between 0 and 20', regex:/^([0-9]|[1-1][0-9]|20)$/, extra: null, input:'form-control form-control-lg HPadding10px NoBorder NoOutline InputFont FS40 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide45 TopMargin20px RightMargin5 form-control form-control-lg Padding0px NoBorder NoOutline BCInherit', container:'NoBorder NoOutline' },
    unit: {name:'unit', placeholder:'gps unit',  type: 'text', error: 'unit need to be superior to 0.00001', regex: /^[0-9][0-9]?$|^1$/, extra: 'null', input:'form-control form-control-lg HPadding10px NoBorder NoOutline InputFont FS40 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide45 TopMargin20px form-control form-control-lg Padding0px NoBorder NoOutline BCInherit', container:'NoBorder NoOutline' },
    duration: {name:'duration', placeholder:'specify number of hours you want data generated',  type: 'text', error: 'hours needs to be between 0 and 6', regex: /^[0-9][0-9]?$|^6$/, extra: null, input:'form-control form-control-lg HPadding10px NoBorder NoOutline InputFont FS40 btn-light BCTrans BottomShadow7', wrapper:'BRL Wide45 TopMargin20px form-control form-control-lg Padding0px NoBorder NoOutline BCInherit', container:'NoBorder NoOutline' }
  };


  export const COLORS = {
    primary: '#ad182f',
    primaryTransparent: 'rgba(173, 24, 47, 0.3)',
    primaryDisabled: '#ba6370',
  };

  
export const CHARTCONFIG = {
  'summary': {
    'gender':{'type':'auto', 'style':{'container':'', 'a':'', 'b':'', 'label':'', 'total':'', 'percentage':'' }},
    'age':{'type':'auto', 'style':{'container':'', 'a':'', 'b':'', 'label':'', 'total':'', 'percentage':'' }},
    'stereotype':{'type':'auto', 'style':{'container':'', 'a':'', 'b':'', 'label':'', 'total':'', 'percentage':'' }},
    'political':{'type':'auto', 'style':{'container':'', 'a':'', 'b':'', 'label':'', 'total':'', 'percentage':'' }},
    
    'age/gender':{'type':'auto', 'style':{'container':'', 'a':'', 'b':'', 'label':'', 'total':'', 'percentage':'' }},
    'age/stereotype':{'type':'auto', 'style':{'container':'', 'a':'', 'b':'', 'label':'', 'total':'', 'percentage':'' }},
    'age/political':{'type':'auto', 'style':{'container':'', 'a':'', 'b':'', 'label':'', 'total':'', 'percentage':'' }},
    
    'gender/stereotype':{'type':'auto', 'style':{'container':'', 'a':'', 'b':'', 'label':'', 'total':'', 'percentage':'' }},
    // 'gender/political':{'type':'auto', 'style':{'container':'', 'a':'', 'b':'', 'label':'', 'total':'', 'percentage':'' }},
    // 'stereotype/political':{'type':'auto', 'style':{'container':'', 'a':'', 'b':'', 'label':'', 'total':'', 'percentage':'' }},
    
}};

  
export const COLOR_CODES = {
// BASIC
'female':'#C0392B','male':'#3498DB','non-binary':'#16A085',
'18-25':'#C0392B','26-35':'#3498DB','36-45':'#16A085','46-55':'#F1C40F','56-200':'#34495E','56+':'#34495E',
'none':'#C0392B','center':'#3498DB','left':'#16A085','right':'#16A085',
'yuppie':'#C0392B','trendy':'#3498DB','artistic':'#16A085','busy':'#F1C40F','elegant':'#34495E','normal':'#FBEEE6','socialite':'#A9CCE3',

// CROSS
'18-25/female':'#C0392B','18-25/male':'#3498DB','18-25/non-binary':'#16A085',
'26-35/female':'#F1C40F','26-35/male':'#34495E','26-35/non-binary':'#D35400',
'36-45/female':'#95A5A6','36-45/male':'#9B59B6','36-45/non-binary':'#CB4335',
'46-55/female':'#17A589','46-55/male':'#D68910','46-55/non-binary':'#EA4551',
'56+/female':'#D0D3D4','56+/male':'#212F3D','56+/non-binary':'#D2B4DE',

'18-25/none':'#C0392B','18-25/center':'#3498DB','18-25/left':'#16A085','18-25/right':'#27A085',
'26-35/none':'#F1C40F','26-35/center':'#34495E','26-35/left':'#D35400','26-35/right':'#38A085',
'36-45/none':'#95A5A6','36-45/center':'#9B59B6','36-45/left':'#CB4335','36-45/right':'#49A085',
'46-55/none':'#17A589','46-55/center':'#D68910','46-55/left':'#EA4551','46-55/right':'#5AA085',
'56+/none':'#D0D3D4','56+/center':'#212F3D','56+/left':'#D2B4DE','56+/right':'#6B6A085',

'18-25/yuppie':'#C0392B','18-25/trendy':'#3498DB','18-25/artistic':'#16A085','18-25/busy':'#FCF3CF','18-25/elegant':'#AFB42B','18-25/normal':'#BAB42B','18-25/socialite':'#CBB42B',
'26-35/yuppie':'#F1C40F','26-35/trendy':'#34495E','26-35/artistic':'#D35400','26-35/busy':'#154360','26-35/elegant':'#4D5656','26-35/normal':'#DFB52B','26-35/socialite':'#EFB62B',
'36-45/yuppie':'#95A5A6','36-45/trendy':'#9B59B6','36-45/artistic':'#CB4335','36-45/busy':'#FBEEE6','36-45/elegant':'#A9CCE3','36-45/normal':'#5FB72B','36-45/socialite':'#6FB82B',
'46-55/yuppie':'#17A589','46-55/trendy':'#D68910','46-55/artistic':'#EA4551','46-55/busy':'#6E2C00','46-55/elegant':'#F7F9F9','46-55/normal':'#7FB92B','46-55/socialite':'#7FBA2B',
'56+/yuppie':'#D0D3D4','56+/trendy':'#212F3D','56+/artistic':'#D2B4DE','56+/busy':'#A3E4D7','56+/elegant':'#F9E79F','56+/normal':'#9FBB2B','56+/socialite':'#AFBB24',

'male/yuppie':'#C0392B','male/trendy':'#3498DB','male/artistic':'#16A085','male/busy':'#FCF3CF','male/elegant':'#AFB42B','male/normal':'#BAB42B','male/socialite':'#CBB42B',
'female/yuppie':'#F1C40F','female/trendy':'#34495E','female/artistic':'#D35400','female/busy':'#154360','female/elegant':'#4D5656','female/normal':'#DFB52B','female/socialite':'#EFB62B',
'non-binary/yuppie':'#95A5A6','non-binary/trendy':'#9B59B6','non-binary/artistic':'#CB4335','non-binary/busy':'#FBEEE6','non-binary/elegant':'#A9CCE3','non-binary/normal':'#5FB72B','non-binary/socialite':'#6FB82B',

'male/none':'#C0392B','male/center':'#3498DB','male/left':'#16A085','male/right':'#FCF3CF',
'female/none':'#F1C40F','female/center':'#34495E','female/left':'#D35400','female/right':'#154360',
'non-binary/none':'#95A5A6','non-binary/center':'#9B59B6','non-binary/left':'#CB4335','non-binary/right':'#FBEEE6',

'yuppie/none':'#C0392B','yuppie/center':'#3498DB','yuppie/left':'#16A085','yuppie/right':'#FCF3CF',
'trendy/none':'#AFB42B','trendy/center':'#F1C40F','trendy/left':'#34495E','trendy/right':'#D35400',
'artistic/none':'#154360','artistic/center':'#4D5656','artistic/left':'#95A5A6','artistic/right':'#9B59B6',
'busy/none':'#CB4335','busy/center':'#FBEEE6','busy/left':'#A9CCE3','busy/right':'#17A589',
'elegant/none':'#D68910','elegant/center':'#EA4551','elegant/left':'#6E2C00','elegant/right':'#F7F9F9',
'normal/none':'#D0D3D4','normal/center':'#212F3D','normal/left':'#D2B4DE','normal/right':'#A3E4D7',
'socialite/none':'#F9E79F','socialite/center':'#2AE89F','socialite/left':'#3BE99F','socialite/right':'#4CEA9F',


};

export const selectProfile = {
'container':'form-control form-control-lg NoBorder BottomShadow7 Padding10px FS40 FuturaM',
'options':''
};


export const MODAL_PAYMENT = {
  'size':'md',
  'background':'BCTransparent1',
  'style':{
    'container':{style:'maxHeight:100vh', class:''},
    'header':{display:true, style:'', class:''},
    'title':{style:'display:block;position:relative;width:100%;color:#000;text-align:center;',class:'FCBlack FuturaU FS37 Padding0px'},
    'body':{style:'display:block;position:relative;width:100%;color:#000;text-align:left;',class:'FCBlack FuturaL FS38 BottomMargin10px Padding10px'},
    'footer':{display:false, style:'', class:''},
    'button':{display:true, style:'', class:''},
    'paypal':{display:true, style:'', class:''},
  },
  'content':{
    'title':'Advertisement Purchase',
    'body':'You are about to purchase the following advertisement on the platform. Each purchase is definitive. Up until the start of the advertisement period, you will be able to amend the details of your advertisement, except for the date and time period. '
  }
};

export const MODAL_GEOLOCATION = {
  'size':'md',
  'background':'BCTransparent1',
  'style':{
    'container':{style:'maxHeight:100vh', class:''},
    'header':{display:true, style:'', class:''},
    'title':{style:'display:block;position:relative;width:100%;color:#000;text-align:center;',class:'FCBlack FuturaU FS37 Padding0px'},
    'body':{style:'display:block;position:relative;width:100%;color:#000;text-align:left;',class:'FCBlack FuturaL FS38 BottomMargin10px Padding10px'},
    'footer':{display:true, style:'', class:''},
    'paypal':{display:false, style:'', class:''},
  },
  'content':{
    'title':'Information',
    'body':'We require users to be registered and have their anonymous profile filled in, in order to get access to the map data. Otherwise no results will be returned'
  }
};

export const MODAL_GDPR = {
  'size':'md',
  'background':'BCTransparent1',
  'style':{
    'container':{style:'maxHeight:100vh', class:''},
    'header':{display:true, style:'', class:''},
    'title':{style:'display:block;position:relative;width:100%;color:#000;text-align:center;',class:'BRL FuturaU FS37 Padding0px'},
    'body':{style:'display:block;position:relative;width:100%;color:#000;text-align:left;',class:'BRL FuturaL FS38 HPadding40px VPadding20px OverflowAuto'},
    'footer':{display:false, style:'', class:''},
    'paypal':{display:false, style:'', class:''},
  },
  'content':{
    'title':'GDPR & Registration',
    'body':"You are about to either sign-in or register. By checking the box and proceeding, you accept the terms of use. In accordance with GDPR, when deleting your profile, your personal data will be automatically and fully removed fron the platform. We dont use cookies, so no tracking of any sort. Nonetheless, for the app to work, you will need to grant the app the permission to access your geolocation. As you enjoy complete anonymity, your position will not be precise enough to pinpoint you, nor anyone will be able to identify you as any specific person"
  }
};

export const MODAL_DASHBOARD = {
  'size':'md',
  'background':'BCTransparent1',
  'style':{
    'container':{style:'maxHeight:100vh', class:''},
    'header':{display:true, style:'', class:''},
    'title':{style:'display:block;position:relative;width:100%;color:#000;text-align:center;',class:'FCBlack FuturaU FS37 Padding0px'},
    'body':{style:'display:block;position:relative;width:100%;color:#000;text-align:left;',class:'FCBlack FuturaL FS38 BottomMargin10px Padding10px'},
    'footer':{display:true, style:'', class:''},
    'paypal':{display:false, style:'', class:''},
  },
  'content':{
    'title':'NO BUSINESSES FOUND',
    'body':'You have no business registered. you can do so by clicking "Create Business". Then you will be able to use the Marketing tool to advertise your business'
  }
};

export const MODAL_PROFILE = {
  'size':'md',
  'background':'BCTransparent1',
  'style':{
    'container':{style:'maxHeight:100vh', class:''},
    'header':{display:true, style:'', class:''},
    'title':{style:'display:block;position:relative;width:100%;color:#000;text-align:center;',class:'FCBlack FuturaU FS37 Padding0px'},
    'body':{style:'display:block;position:relative;width:100%;color:#000;text-align:left;',class:'FuturaL FS38 BottomMargin10px Padding10px'},
    'footer':{display:true, style:'', class:''},
    'paypal':{display:false, style:'', class:''},
  },
  'content':{
    'title':'IMPORTANT',
    'body':"To use the map, you need to fill in your anonymous profile. They are very generic criterias that can't be linked to you"
  }
};

export const MODAL_POPUP = {
  'size':'md',
  'background':'',
  'style':{
    'container':{style:'maxHeight:90vh', class:''},
    'header':{display:false, style:'', class:''},
    'title':{style:'display:block;position:relative;width:100%;color:#000;text-align:center;',class:'FuturaU FS36 Padding0px'},
    'body':{style:'display:block;position:relative;width:100%;color:#000;text-align:left;',class:'FuturaL FS38 Padding10px'},
    'footer':{display:false, style:'', class:''},
    'paypal':{display:false, style:'', class:''},
  },
  'content':{
    'title':'',
    'body':""
  }
};

export const MODAL_INPUT = {
  'size':'md',
  'background':'BCTransparent1',
  'style':{
    'container':{style:'height:100vh;padding:20px', class:''},
    'header':{display:false, style:'', class:''},
    'title':{style:'display:block;position:relative;width:100%;color:#000;text-align:center;',class:'FCBlack FuturaU FS35 Padding10px'},
    'body':{style:'display:block;position:relative;width:100%;color:#000;text-align:left;',class:'FCBlack FuturaL FS38 Padding10px'},
    'footer':{display:false, style:'', class:''},
    'paypal':{display:false, style:'', class:''},
  },
  'content':{
    'title':'',
    'body':''
  }
};

export const MODAL_FILTER = {
  'type':'filter',
  'size':'lg',
  'background':'',
  'style':{
    'container':{style:'maxHeight:100vh', class:''},
    'header':{display:true, style:'', class:''},
    'title':{style:'display:block;position:relative;width:100%;color:#000;text-align:center;',class:'FCBlack FuturaU FS35'},
    'body':{style:'display:block;position:relative;width:100%;color:#000;text-align:left;',class:'FCBlack FuturaL FS40'},
    'footer':{display:true, style:'', class:''},
    'paypal':{display:false, style:'', class:''},
  },
  'content':{
    'title':'',
    'body':''
  }
};

export const MODAL_STATS = {
  'type':'filter',
  'size':'lg',
  'background':'',
  'style':{
    'container':{style:'position: fixed; width:400px;zIndex:1040;left:0px;top:55vh;', class:''},
    'header':{display:true, style:'', class:''},
    'title':{style:'display:block;position:relative;width:100%;text-align:center;',class:'FuturaU FS35'},
    'body':{style:'display:block;position:relative;width:100%;text-align:left;',class:'FuturaL FS40'},
    'footer':{display:false, style:'', class:''},
    'paypal':{display:false, style:'', class:''},
  },
  'content':{
    'title':'',
    'body':''
  }
};

export const MODAL_REIMBURSMENT = {
  'type':'filter',
  'size':'lg',
  'background':'',
  'style':{
    'container':{style:'maxHeight:100vh', class:''},
    'header':{display:true, style:'', class:''},
    'title':{style:'display:block;position:relative;width:100%;text-align:center;',class:'FuturaU FS35'},
    'body':{style:'display:block;position:relative;width:100%;text-align:left;',class:'Padding20px FuturaR FS38'},
    'footer':{display:false, style:'', class:''},
    'paypal':{display:false, style:'', class:''},
  },
  'content':{
    'title':'Order cancellation reimbursment',
    'body':'Please note that the cancellation will not be processed until you press the button "confirm changes". If you cancel your order, you will be reimbursed the following sum:'
  }
};

export const PRICE = {
  container: 'BRL Wide100 NoBorder Padding10px TopMargin20px BCGray2',
  container_line: 'BRL Wide100',
  container_label: 'BRL Wide30 HPadding10px NoBorder ',
  container_cost: 'BRL Wide70 HPadding10px NoBorder ',
  label: 'FS40 FCDarkGray LabelFont Capital',
  cost: 'FS40 FCDarkGray HeaderFont Capital',
  container_total: 'BRL Wide100 NoBorder TopBorder BorderCGray VPadding5px BCPrimary BorderRadius10',
  label_total: 'FS38 FCWhite LabelFont Bold Capital',
  cost_total: 'FS38 FCWhite HeaderFont Bold',
};


export const EVENT = {  
  title: 'BRL Wide100 Padding10px FS37 FuturaH NoBorder CenterAlign Capital PreWhiteSpace',
  description: 'BRL Wide100 Padding10px NoBorder FS39 FuturaM PreWhiteSpace OverflowAuto',
  time_container: 'BRL Wide100 Padding5px ', 
  time: 'BRL FS40 FuturaH',
  remaining: 'BRL Wide100 FS40 HeaderFont BCPrimary Padding5px',
};

export const BUSINESS = {  
    companyname: 'BRL Wide100 Padding10px FS37 FuturaH CenterAlign NoBorder Capital PreWhiteSpace',
    email: 'BRL Wide100 NoBorder FS40 FuturaM PreWhiteSpace',
    telephone: 'BRL Wide100 NoBorder FS40 FuturaM PreWhiteSpace',
    address: 'BRL Wide100 NoBorder FS40 FuturaM PreWhiteSpace',
    postcode: 'BRL NoBorder FS40 FuturaM PreWiteSpace',
    city: 'BRL LeftMargin5px NoBorder FS40 FuturaM PreWhiteSpace',
    description: 'BRL Wide100 Padding10px NoBorder FS40 FuturaM PreWhiteSpace OverflowAuto',
};

export const VIEWER_AD = {  
  imageContainer: {class:"BRL Wide100 TopMargin5px NoBorder ", style:""}, 
  imageDiv: {class:"BRL Wide100px High100px RightMargin2px BottomMargin2px BorderRadius5 NoBorder OverflowHidden", style:""},
  image: {class:"High100 ImageFit ImageCentered OverflowHidden Hand", style:""},
  icon: {class:"BRL Padding20px FS25 FCRed fa fa-picture-o", style:""},
  carouselContainer: {class:"Fixed Top0px Left0px Wide100 z7000 BCBlack", style:"height:100vh;overflow:hidden;"},
  carousel: {class:"BRL h-100 w-100", style:"marginTop:10px"}
};


export const VIEWER_DASHBOARD = { 
  imageContainer: {class: "BRL Wide100 TopMargin10px NoBorder", style: ""}, 
  imageDiv: {class: "BRL Wide100px High100px NoBorder OverflowHidden BorderRadius5 RightMargin5px BottomMargin5px Flex FlexCol JustifyCenter AlignCenter btn-light BCTrans", style: ""}, 
  image: {class: "High100 ImageFit ImageCentered", style: ""}, 
  icon: {class: "BRL Padding20px FS35 FCRed fa fa-picture-o", style: ""}, 
  carouselContainer: {class: "Fixed Flex Top0px Left0px JustifyCenter AlignCenter Wide100 High100VH BCTransparent5 z7000", style: ""},
  carousel: {class: "Wide80 MaxWidth1600px", style: ""} };



export const ERROR = {
  container:{style:'', class:'BRL Wide100 Padding10px BCPrimary FCWhite'},
  line:{style:'', class:'BRL Wide100 Padding5px FS40 LabelFont'}
}


export const MAP_FILTER = {
  control: styles => ({ 
    ...styles,
    backgroundColor: 'white',
    color: 'black',
    border: 0,
    fontFamily: 'FuturaM',
    fontSize: '12px',
    padding: '8px',
    textTransform: 'capitalize',
    boxShadow: 'none'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      border: 0,
      backgroundColor: 'white',
      color: 'black',
      fontSize: '12px',
      padding: '8px',
      fontFamily: 'FuturaM',
      textTransform: 'capitalize',
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
};

export const TEMPLATE = {"name":"template", "reference":"fLq0EUFa23TQt4IwY3d0", "tag": "C-TRI" , "animation":{"aos":{"aos":"", "aos-easing":"linear"}}};


export const CTRI_DEFAULT_ELEMENT = {            
        "container":{"class":"BRL col-xs-12 col-sm-12 col-md-12 col-lg-12","background":"","color":"","font":"","style":"margin-bottom:20px;"},
        "graphic":{"icon":"","url":"","background":"","color":"#AAAAAA","class":"MarginAuto","style":"width:20%;font-size:25;height:5px;text-align:center"},
        "title":{"text":"Lorem Ipsum Title","color":"#000000","background":"","font":"FuturaU","class":"Capital","style":"font-size:20;text-align:center;"},
        "description":{"text":"Lorem Ipsum description text. Lorem Ipsum description text. Lorem Ipsum description text. Lorem Ipsum description text. Lorem Ipsum description text. Lorem Ipsum description text. ","color":"#000000","background":"","font":"AGP","class":" ","style":"font-size:20;margin-top:10px;"},
        "link2":{"text":"Lorem ipsum link","linkUrl":"","color":"#ad182F","background":"","font":"FuturaM","class":"FS35 Bold","style":"margin-top:10px;"}
};


export const TEMPLATE_DETAILS = {
    "name": "template", "tag": "C-TRI", "style": "", "class": "", "heigth": "", "structure": {
        "class": "BRL CenterAlign",
        "background":"",
        "style":"width:100%;padding-left:20%;padding-right:20%;margin-top:150px;margin-bottom:20px;",
        "elements":[
            {
                "class":"","background":"","color":"","font":"","style":"width:100%;margin-bottom:50px;",
                "graphic":{"icon":"","url":"","background":"","color":"#AAAAAA","class":"","style":"width:20%;font-size:50;height:5px;text-align:center"},
                "title":{"text":"Lorem Ipsum Title","color":"#000000","background":"","font":"FuturaU","class":"Capital","style":"font-size:25;"},
                "description":{"text":"","color":"#525252","background":"","font":"FuturaL","class":" ","style":"font-size:17;margin-top:10px;"},
                "link2":{"text":"","linkUrl":"","color":"#ad182F","background":"","font":"LullaBelle","class":"FS20 Bold","style":"margin-top:50px;"}
            },
            {
                "class":"float-left col-xs-12 col-sm-12 col-md-4 col-lg-4","background":"","color":"","font":"","style":"width:100%;margin-bottom:20px;",
                "graphic":{"icon":"user-secret","url":"","background":"","color":"#AD182F","class":"MarginAuto","style":"width:20%;font-size:35;height:50px;text-align:center"},
                "title":{"text":"Lorem Ipsum","color":"#000000","background":"","font":"FuturaH","class":"CenterALign ","style":"font-size:19;"},
                "description":{"text":"Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.","color":"#525252","background":"","font":"FuturaL","class":" ","style":"font-size:17;margin-top:10px;"},
                "link2":{"text":"","linkUrl":"","color":"#ad182F","background":"","font":"LullaBelle","class":"FS20 Bold","style":"margin-top:50px;"}
            },
            {
                "class":"float-left col-xs-12 col-sm-12 col-md-4 col-lg-4","background":"","color":"","font":"","style":"width:100%;margin-bottom:20px;",
                "graphic":{"icon":"user-secret","url":"","background":"","color":"#AD182F","class":"MarginAuto","style":"width:20%;font-size:35;height:50px;text-align:center"},
                "title":{"text":"Lorem Ipsum","color":"#000000","background":"","font":"FuturaH","class":"CenterALign","style":"font-size:19;"},
                "description":{"text":"Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.","color":"#525252","background":"","font":"FuturaL","class":" ","style":"font-size:17;margin-top:10px;"},
                "link2":{"text":"","linkUrl":"","color":"#ad182F","background":"","font":"LullaBelle","class":"FS20 Bold","style":"margin-top:50px;"}
            },
            {
                "class":"float-left col-xs-12 col-sm-12 col-md-4 col-lg-4","background":"","color":"","font":"","style":"width:100%;margin-bottom:20px;",
                "graphic":{"icon":"map-marked-alt","url":"","background":"","color":"#AD182F","class":"MarginAuto","style":"width:20%;font-size:35;height:50px;text-align:center"},
                "title":{"text":"Lorem Ipsum","color":"#000000","background":"","font":"FuturaH","class":"CenterALign","style":"font-size:19;"},
                "description":{"text":"Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.","color":"#525252","background":"","font":"FuturaL","class":" ","style":"font-size:17;margin-top:10px;"},
                "link2":{"text":"","linkUrl":"","color":"#ad182F","background":"","font":"LullaBelle","class":"FS20 Bold","style":"margin-top:50px;"}
            }
            
        ]
        }
};

