import React, {Component} from 'react';
import MapBox from '../geolocation/mapbox';
import GeoLocation from "../geolocation/geolocation";
import ListApp from "../elements/list";
import InfoModal from "../elements/infoModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalFilter from '../elements/modalFilter';
import Select from '../elements/select';
import { withRouter } from 'react-router-dom';
import FilterMenu2 from '../core/filterMenu2';
import { getActivities, createActivity, getBusinesses, setActivity, processActivity } from "../services/business";
import { getAreaCoordinates, getAreaStats, getBasisCoordinates, deltaCoordinates, jsonToGeoJSON, jsonToGeoJSONBusiness, getHeatMap, getCurrentGPSPosition } from "../services/geolocation";
import { getProfile } from "../../components/services/profile";
import { truncate, generateCustomOptions, getArrayIndex, formatDate, styleMapping, isObjectEqual, isObjectComplete, getEquivalent } from "../utils/tools";
import * as actions from '../../store/pseudo/general';  
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import _ from "lodash";
import $ from 'jquery';

const resultsActivities = [{id_event: 'id', date: 'date', start: 'start', end: 'end', title: 'title', type: 'type', cost:'cost', visits:'visits'}];
const resultsBusiness = [{id_business:'id', companyname: 'name', description: 'description', address:'street', postcode:'post code', city:'city', country:'counry'}];
const newActivity = {id_event:'new', action: 'visible', inserted: formatDate(new Date(), 'yyyy-mm-dd'), insertedDetail: new Date(), type: 'discount', cost: 0, visits: 0, position:{ lat:'', lon:''}, date:new Date(), start:'', end:'', title:'', description: '', promotion:[], pricing:{}, images:[]};
const selectClasses = {container:"BRL col-lg-12 col-md-12 col-sm-12 Padding10px NoOutline NoBorder FS38 Capital HeaderFont ", options:"BRL Wide100 btn-light NoBorder"}
const selectClasses2 = {container:"BRL col-lg-12 col-md-12 col-sm-12 Padding5px NoOutline NoBorder FS40 Capital HeaderFont ", options:"BRL Wide100 btn Padding0px NoBorder"}

const exceptionList = ['target_stereotype', 'target_age', 'target_stereotype', 'active', 'last_online', 'is_dark_mode', 'target_age', 'target_stereotype', 'is_premium', 'date_premium_valid', 'subscription']
const equivalentFields = [['target_age','age'],['target_gender','gender'],['target_orientation','orientation']];
const width = $(window).width();

const fields = [ 
  {type:'column', label:'date', style:{width:'15%', textAlign:'center'}}, 
  {type:'column', label:'start', style:{width:'10%', textAlign:'center'}}, 
  {type:'column', label:'end', style:{width:'10%', textAlign:'center'}}, 
  {type:'column', label:'title', style:{width:'25%', textAlign:'center'}}, 
  {type:'column', label:'cost', style:{width:'10%', textAlign:'center'}},   
  {type:'column', label:'visits', style:{width:'10%', textAlign:'center'}},   
  {type:'action', label:'action', style:{color:'white', width:'20%', display: 'flex', flexDirection: 'row', borderRadius: '4px'}, 
    options:{
      visible:{label:'fa fa-eye FS40', style:{color:'white'}, action:'hidden'},
      hidden:{label:'fa fa-eye-slash FS40', style:{color:'white'}, action:'visible'},
      deleting:{label:'fa fa-eye FS40', style:{color:'white'}, action:'deleting'}
    }
  }
];

// class Marketing extends GeoLocation {
  class Marketing extends Component {
  
  // ############################ CONSTRUCTOR ###################################
  // ############################ CONSTRUCTOR ###################################
  constructor(props) {
    super(props);
    this.state = { 
      activities: null, modifiedActivities: [], visibile: 'visible', bSaved: true, 
      selectedActivity: null, selectedItem: 0, businesses: null, selectedBusiness: null, dataMock: null,
      area: null, stats: {}, modal: false, modalFilter: false, refresh: true, longitude: null, latitude: null, bReimbursment: false, valueReimbursment:0,
      settings: props.settings, errors: {complete:[]}, loading: false, loadingStats: false, popUpData: null, bPopUp: null, bStats: null, bInput: false };
    
    this.bRefresh = true; this.mock = null; this.zoom = 16;
    
    this.filters = {'gender': '', 'age': '', 'stereotype': '', 'political': '' };
    this.filteredDataPoints = {};
    this.styleMap = {height: '89vh',width: '100%'};
    this.options = { autoClose: 3000, type: toast.TYPE.INFO, hideProgressBar: false };

    let dNow = Date.now();
    let dEnd2 = new Date(dNow + 1000 * 3600 * 24 * 3);
    let t1 = new Date();
        t1.setHours(0,0,1)
        dEnd2.setHours(23,31,1)
        this.range = [t1.toUTCString(), dEnd2.toUTCString()];
        
  }

  async componentDidMount() {
    this.coordinates = null;
    this.Lastcoordinates = this.coordinates;
    this.getBusinesses(1);
  }
  
  UNSAFE_componentWillReceiveProps(props){
    // console.log('COMPONENT WILL RECEIVE PROPS => ', props);
    this.props = props;
    this.getBusinesses(1);
  }


  // ############################ GET BUSINESSES ###################################
  // ############################ GET BUSINESSES ###################################
  getBusinesses = async (index) => {
    // console.log('--MARKETING : GETDATA 0A => ',  this.props.user.idUser);
    if(this.props.user.idUser){
      let temp = JSON.parse(JSON.stringify(resultsBusiness));
      const businesses = await getBusinesses({id: this.props.user.idUser}, temp, 'info');
      // console.log('--MARKETING : GETDATA 1 => ',  businesses, ' ==> ', businesses.length);
      this.selectOptions = generateCustomOptions(businesses, {'label': 'companyname', 'value': 'id_business'});     

      if (businesses.length <2){
        await this.setState({businesses: [], selectedBusiness: {}, activities: []});
        this.props.navigate({direction: 'business/dashboard'}, this.props.history);
      } else {
        await this.setState({businesses: businesses});
        this.getData(index);
      }
      
    }
  }

  // ############################ GET BUSINESSES ###################################
  // ############################ GET BUSINESSES ###################################
  getData = async (index) => {
    await this.setState({selectedBusiness: this.state.businesses[index]});
    await this.getActivities(this.state.businesses[index]['id_business']);
    let coordinates = JSON.parse(this.state.selectedBusiness['coordinates']);
    this.coordinates = {'latitude': coordinates[0], 'longitude': coordinates[1]}
    // console.log('--MARKETING : GETDATA 3 => ',  coordinates);
    await this.getResults();
  }   
  
  // ##################################  MAP CHANGED  ####################################
  // ##################################  MAP CHANGED  ####################################
  async isProfile(data){
    if(data.user.idUser){
        const temp = await getProfile(data.user.idUser);
        this.setState({profile: temp, bComplete: isObjectComplete(temp, exceptionList)}); 
    } else{this.setState({bComplete: false}); }
  }

  // ##################################  MAP CHANGED  ####################################
  // ##################################  MAP CHANGED  ####################################
  isChanged = async (coordinates, zoom) => {
    this.coordinates = coordinates;
    const temp = deltaCoordinates(this.coordinates, this.Lastcoordinates);
    if (temp > 300 || Math.abs(parseInt(this.zoom,10) - parseInt(zoom,10)) > 1 ){this.bRefresh = true;}
    if(this.zoom == null){this.zoom = 16;}else {this.zoom = zoom;}
    this.coordinatesTrunc = {'latitude': truncate(coordinates['latitude'],3), 'longitude': truncate(coordinates['longitude'],3)}
    // this.setState({latitude: this.coordinates['latitude'], longitude: this.coordinates['longitude']});
  }

  // ##################################  GET DENSITY  ####################################
  // ##################################  GET DENSITY  ####################################
  getResults = async () => {
    let debugUserCoordinates = '';
    this.suggestions=[];
    this.Lastcoordinates = this.coordinates;

    if(this.bdebug === true){
        debugUserCoordinates = await getCurrentGPSPosition();
    } 
    
    this.setState({ 
        latitude: this.coordinates['latitude'], 
        longitude: this.coordinates['longitude'], 
        loading: true, 
        debugUserCoordinates: JSON.stringify(debugUserCoordinates) 
    });

    this.dataPoints = await getHeatMap({
        'coordinates': this.coordinates, 
        'zoom': this.zoom, 
        'range': this.range
    }, this.props.directory);
    
    console.log('GEOLOCATION GETREUSLTS 1 => ', this.coordinates, ' => ',this.range, ' => ',this.dataPoints);
    
    if (this.dataPoints){
        this.filterResults();
    } else {
        this.setState({loading: false, refresh: true });
    }

  }

  // ############################ GET BUSINESSES ###################################
  // ############################ GET BUSINESSES ###################################
  getActivities = async (id) => {
    let temp = JSON.parse(JSON.stringify(resultsActivities));
    const activities = await getActivities({id: id}, temp);
    const activities2 = this.formatActivitiesDate(_.cloneDeep(activities));
    // console.log('MARKETING ACTIVITIES 2 => ', activities2);
    this.setState({activities: activities, activitiesOld: activities2});
    // if(activities.length>1){this.handleSelect('select', activities[1],1, false);}
  }    

  // ################################## PRERENDER  ####################################
  // ################################## PRERENDER  ####################################
  preRender = () => {    
    if(this.dataPoints){
      // console.log('MARKETING PRERENDER : ', this.mock);
      const data4 = (this.mock == null? null : jsonToGeoJSONBusiness(this.mock));
      const data2 = jsonToGeoJSON(this.filteredDataPoints);
      const data3 = jsonToGeoJSONBusiness(this.dataPoints.businesses);
      const data = { 
        latitude: this.coordinates['latitude'], 
        longitude: this.coordinates['longitude'], 
        dataPoints: data2, 
        dataBusinesses: data3, 
        dataMock: data4, 
        loading: true 
      }
      
      // console.log('PRERENDER MARKETING 1 => ', data);
      this.setState(data, function(){            
        setTimeout(() => {this.setState({loading: false, refresh: true });}, 2000);
    });
    }
  }
  
  // ############################ RENDER ###################################
  // ############################ RENDER ###################################
  render() {
        if(this.state.latitude !== null){
        return (
            <div className={ this.props.settings.structure.outlet.class} style={styleMapping(this.props.settings.structure.outlet)}>
            <div className={"BRL Wide100 "} >   
                {this.state.stats.data && <InfoModal type={'stats'} data={this.state.stats.data} show={this.state.bStats} onClose={() => {this.setState({bStats: false})}} />}
                {this.state.popUpData && <InfoModal type={'popUp'} data={this.state.popUpData} show={this.state.bPopUp} onClose={() => {this.setState({bPopUp: false})}} />}
                {this.state.dataMock && <InfoModal type={'advertisement'} order={{total:this.state.dataMock}} show={this.state.modal} details={this.state.dataMock} onClose={()=>{this.setState({modal: false});}} onSuccess={(e)=>{this.processPurchase('success', e)}} onError={(e)=>{this.processPurchase('error', e)}} onCancel={(e)=>{this.processPurchase('cancel', e)}} /> }
                {this.state.bInput === true && <InfoModal type={'input'} 
                  data={this.state.selectedActivity} errors={this.state.errors} show={this.state.bInput} 
                  handleActivitySubmit={(data, images, bSubmit, typeAction) => {this.handleActivitySubmit(data, images, bSubmit, typeAction)}} 
                  onClose={()=>{this.setState({bInput: false});}} />
                } 
                {this.state.bReimbursment === true && <InfoModal type={'reimbursment'} data={this.state.valueReimbursment} show={this.state.bReimbursment} onClose={()=>{this.setState({bReimbursment: false});}} onAction={(e)=>{this.handleReimbursmentConfirmation(e);}} />} 
                
                {this.state.businesses.length === 1 && 
                <div className={"Absolute Wide100 High1000px Padding10px BCTransparent4 z10"}>
                  <div className={"BRL Wide30 TitleFont FS35 CenterAlign LeftMargin30 TopMargin20"} style={{minHeight:'50px'}}>In order to user the marketing tool, you first need to register a business on the Dashboard</div>
                </div>}  
                              
                <div className={"BRL col-lg-5 col-md-12 col-sm-12 HPadding20px BottomMargin20px OverflowHidden"} style={{height:'89vh'}}>   
                  {/* LIST APP */}
                  <div className={"BRL Wide100 BottomMargin10px OverflowAuto BottomShadow7"}>
                    {this.selectOptions && 
                      <Select   fields="custom" 
                                list={this.selectOptions} 
                                onChange={(e,index) => this.handleDropdown(index)} 
                                value={this.state.selectedBusiness['id_business']} 
                                classes={selectClasses}>
                      </Select>}
                  </div>

                  <div className={"BRL Wide100 BottomShadow7"}>   
                    {this.state.activities && 
                      <React.Fragment>
                        <div className={"BRL Wide100"}>   
                          <button className={"BRL Wide80 btn HPadding0 VPadding5px FS40 TitleFont Padding10px Capital " + (this.state.bSaved ? 'btn-light' : 'btn-danger') } disabled={this.state.bSaved} onClick={() =>{this.saveChanges();}}>Confirm Changes</button>   
                          <div className={"BRR Wide20 HPadding5px VPadding5px btn-light"}><Select fields="visible" onChange={(name, e) => this.handleVisible(e)} value={this.state.visibile} classes={selectClasses2}></Select></div>   
                        </div>
                        <div className={"BRL Wide100 FS40 TitleFont btn Padding10px Capital BottomShadow7 " + ( this.state.selectedItem === 'new' ? ' BCPrimary FCWhite ' : ' btn-light ')} onClick={() =>{this.handleSelect('details', newActivity, 'new')}} >Create Activity</div>   
                        <div className={"BRL Wide100 OverflowAuto"} style={{height:'70vh'}}>   
                          <ListApp type={"marketing"} fields={fields} styleList={{class: "BRL Wide100", style:""}} data={this.state.activities} onSelected={(action, data, index) =>{this.handleSelect(action, data, index)}} selectedItem={this.state.selectedItem} filter={this.state.visibile} />
                        </div>
                      </React.Fragment>}
                  </div>
                </div>

                {/* MAP */}
                <div className={"BRL col-lg-7 col-md-12 col-sm-12 NoBorder HPadding20px"} style={{height:'89vh'}}>   
                  <div className={"BRR col-lg-12 col-md-12 col-sm-12 NoBorder HPadding0px"} >   
                    <div className="BRL Wide100 ">
                      <MapBox 
                        settings={this.props.settings} dragPan={false} styleMap={this.styleMap} 
                        data={this.state} 
                        area={this.state.area} 
                        zoom={this.zoom} 
                        onPopUp={data =>{this.displayPopUp(data)}} 
                        onChangeCenter={(e, zoom) => {this.isChanged(e, zoom)}} 
                        onDblClick={(map, event) => {this.selectedArea(map, event)}}/>

                      {/*################################ DESKTOP ################################*/}
                      {/*################################ DESKTOP ################################*/}
                      <div className="DesktopMenu Absolute Top10px Left0px col-lg-3 col-md-3 col-sm-12 Padding10px MaxWide300px z10"><FilterMenu2 data={this.filters} onFilter={e => this.getFilters(e)} /></div>
                      
                      {/*################################ MOBILE ################################*/}
                      {/*################################ MOBILE ################################*/}
                      {this.state.modalFilter && <ModalFilter show={this.state.modalFilter} data={this.filters} onClose={()=>{this.setState({modalFilter: false});}} onFilter={e => this.getFilters(e)} /> }
                      
                      {/*################################ LOADING STATS ################################*/}
                      {/*################################ LOADING STATS ################################*/}
                      {this.state.loadingStats && 
                        <div className={"Absolute High100 Wide100 Flex FlexCol AlignEnd JustifyEnd"} style={{zIndex:1000}}>
                          <div className={"btn btn-danger FuturaH FS38 Capital"} style={{}}>
                            Processing Stats
                          </div>
                        </div>}


                    </div>

                  </div>
                   
                </div>

            </div>  
            </div>  
        );       
    } else {
        return ( 
        <div className={"Flex FlexCol JustifyCenter AlignCenter High100VH"} style={{backgroundColor: "rgba(240,240,240,0.5)"}}>
                        <div className="Monoton" style={{fontSize:'3rem', color:"#ad182f"}}>STRANGERS</div>
          <div className={""}><FontAwesomeIcon className={"FS10 Spin"} icon={"compass"} color="#ad182f" /></div>
        </div>
    );
    }
  }


    // ##################################  FILTERING RESUKTS  ####################################
    // ##################################  FILTERING RESUKTS  ####################################
    filterResults = () => {    
      const filters = this.filters; 
      
          if(this.dataPoints){
              if(this.dataPoints.users){
              this.filteredDataPoints = this.dataPoints.users.filter(function(item) {
                  let bDisplay = true;
                  for (var key in filters) {
                      let filters2 = filters[key];
                      let bDisplayOR = '';
                      // console.log('FILTERED 1 => ', key, ' : ', filters2);
                      
                      for (var key2 in filters2) {
                          let filters3 = filters2[key2]['value'];
                          // console.log('FILTERED 3 => ', filters2);
                          // console.log('FILTERS 3 => ', filters3);
                          switch(key){
                              case 'age': 
                              const temp = filters3.split("-");
                              if (parseInt(item['age'],10) < parseInt(temp[0],10) || parseInt(item['age'],10) > parseInt(temp[1],10)){ 
                                  if(bDisplayOR !== true) {bDisplayOR = false;} 
                              } else {bDisplayOR = true;}
                              break;
                              default: 
                                  // console.log('FILTERED 4 => ', filters[key], ' !== "" ');
                                  if (filters3 !=='' && item[getEquivalent(key, equivalentFields, 1)] !== filters3){ 
                                          if(bDisplayOR !== true) {bDisplayOR = false;} 
                                  } else {bDisplayOR = true; }
                                  break;
                              }
                      }
                      if(bDisplayOR === false) {bDisplay = false;} 
                  }
                  return bDisplay;
              });
          }
      }
      // console.log('GEOLOCATION GETREUSLTS FILTERED 1 => ', this.filteredDataPoints);
      this.preRender();
  }


  // ##################################  DISPLAY POPUP  ####################################
  // ##################################  DISPLAY POPUP  ####################################
  displayPopUp = (dataPopUp) => { 
    const data = {
        bPopUp: true, 
        popUpData: dataPopUp, 
        latitude: dataPopUp['position'][0][0], 
        longitude: dataPopUp['position'][0][1]
    };
    // console.log('displayPopUp 2 => ', dataPopUp, ' => ', data);   
    this.setState(data);
  }

  // ############################ HANDLE LIST SELECTION ###################################
  // ############################ HANDLE LIST SELECTION ###################################
  handleDropdown = async (data)  => {

    const temp = getArrayIndex(this.state.businesses, parseInt(data.value, 10), 'id_business')
    // console.log('MARKETING DROPDOWN 1 => ', temp);
    await this.getData(temp);
  
  }

  // ############################ HANDLE LIST SELECTION ###################################
  // ############################ HANDLE LIST SELECTION ###################################
  handleVisible = async (data)  => {
    this.setState({visibile: data.value});
  }

  // ############################ HANDLE LIST SELECTION ###################################
  // ############################ HANDLE LIST SELECTION ###################################
  handleSelect = async (action, data, index)  => {


    switch(action){
      default: 
        this.changeStatus(action, index, 0);
      break;
      case "select":
        data = await this.formatData(this.state.selectedBusiness, data);  
        data['position'] = JSON.parse(this.state.selectedBusiness['coordinates']); 
        this.mock = [data];
        // console.log('HANDLE SELECT ACTIVITIES => ', action, ' => ', index, ' => ', this.mock);
        this.preRender();
        this.setState({selectedActivity: data, selectedItem: index });
      break;
      case "details":
        data = await this.formatData(this.state.selectedBusiness, data);  
        data['position'] = JSON.parse(this.state.selectedBusiness['coordinates']); 
        this.mock = [data];
        this.preRender();
        this.setState({selectedActivity: data, selectedItem: index, bInput: true });
      break;
      case "duplicate":
        let data2 = JSON.parse(JSON.stringify(data));
        data2 = await this.formatData(this.state.selectedBusiness, data2);  
        data2['id_event'] = index;
        data2['position'] = JSON.parse(this.state.selectedBusiness['coordinates']); 
        this.mock = [data2];
        this.preRender();
        this.setState({selectedActivity: data2, selectedItem: index, bInput: true });
      break;
      case "deleting":
        this.checkAdValidity(data);
      break;
    }

  }

  // ############################# CHANGE EVENT STATUS #############################
  // ############################# CHANGE EVENT STATUS #############################
  changeStatus = async (action, index, reimbursment) => {
    let temp = this.state.activities, tempOld = this.state.activitiesOld, temp2 = this.state.modifiedActivities, temp3;

    temp[index]['action'] = action;
    if(action === 'deleting'){
      temp[index]['reimbursment'] = reimbursment;
      temp[index]['date_reimbursment'] = new Date();
    }
    else {
      if(temp[index]['reimbursment'] !== null && temp[index]['reimbursment']!== undefined){
        delete temp[index]['reimbursment'];
      }
      if(temp[index]['date_reimbursment']){
        delete temp[index]['date_reimbursment'];
      }
    }

    temp2.push(JSON.parse(JSON.stringify(temp[index])));
    let temp_1 = JSON.parse(JSON.stringify(temp));
    let tempOld_1 = JSON.parse(JSON.stringify(tempOld));  
    console.log('CHANGESTATUS 3 : temp_1 => ', tempOld_1, ' => ',temp_1);

    for (const key in temp_1){
      delete temp_1[key]['business'];
      delete temp_1[key]['date'];
      delete tempOld_1[key]['business'];
      delete tempOld_1[key]['date'];
    }
    const temp4 = isObjectEqual(temp_1[index], tempOld_1[index]);
    // console.log('CHANGE STATUS 4 => ', temp_1[index], ' ==> ', tempOld_1[index], ' => ', temp4);

    temp3 = isObjectEqual(temp_1, tempOld_1);
    this.setState({activities: temp, modifiedActivities: temp2, bSaved: temp3});
  }

  // ############################# HANDLE FORM SUBMIT #############################
  // ############################# HANDLE FORM SUBMIT #############################
  handleActivitySubmit = async (data, images, bSubmit, typeAction) => {
    let tempActivities = this.state.activities;
    this.formatedData = await this.formatData(this.state.selectedBusiness, data);
    this.mock = [this.formatedData];
    
    if(this.formatedData['end'] !== '' && this.formatedData['end'] !== null){
      const results = processActivity(this.formatedData, this.state.activities, this.state.settings.admin.pricing);
      data['cost'] = results['price']['total'];
      data['pricing'] = results['price'];
      data['id_user'] = this.props.user.idUser;
      // console.log('PRICE => ', results, ' => ', results['price']['total']);
      this.setState({errors: results.errors});
    }

    this.setState({selectedActivity: data});
    this.preRender();
    if(bSubmit === true){

      console.log('handleActivitySubmit => ', typeAction);
      switch(typeAction){
        case 'new':
          if(data['cost'] > 0){
            this.setState({modal: true});
          } else {
            
            const res = await createActivity(this.formatedData, images);
            this.formatedData['id_event'] = res.id;    
            tempActivities.splice(1, 0, this.formatedData);
            toast.success("Your advertizement has been succesfully created", this.options)

          }
          break;
        default:
          setActivity(this.state.selectedBusiness['id_business'], [this.formatedData], images);
          tempActivities[this.state.selectedItem] = this.formatedData;
          toast.success("Your advertizement has been succesfully modified", this.options)
          break;
      }
    }

    // console.log('POST SUBMIT ACTIVITIES => ', this.state.selectedItem, ' => ',tempActivities);
    this.setState({activities: tempActivities, activitiesOld: JSON.parse(JSON.stringify(tempActivities))});
  }
 
 
  //################### GET COORDINATES,  DRAW AREA AND DISPLAY STATS ###################
  //################### GET COORDINATES,  DRAW AREA AND DISPLAY STATS ###################
  async processPurchase(type, event){
    let tempActivities = this.state.activities;
    switch(type){
      case 'success':
        this.formatedData['payment'] = event;        

        try{
          const res = await createActivity(this.formatedData);
          this.formatedData['id_event'] = res.id;
          tempActivities.splice(1, 0, this.formatedData)
          this.setState({activities: tempActivities, modal: false});
          toast.success("Your advertizement has been succesfully saved", this.options)
        }
        catch(err){
          console.log('ERROR CREATING ACTIVITY : ', err);
        }

      break;
      default:  
        // console.log('PURCHASE CANCELED');
        toast.error("Your advertizement was processed. No payment was done. ", this.options)

      break;
    }
    return type;
  }

  //################### CHECKS AND HANDLES AD VALIDITY FOR REIMBURSMENT WHEN CANCELED ###################
  //################### CHECKS AND HANDLES AD VALIDITY FOR REIMBURSMENT WHEN CANCELED ###################
  async checkAdValidity(data){
    let result = false;
    let dateStart = new Date(Date.parse(data['date'] + ' ' + data['start'].substring(0, 2)+ ':' +data['end'].substring(-2)));
    //IF START HAS BEEN PASSED NO REIMBURSMENT OR FREE
    if(!data['payment'] || dateStart < new Date()){result = true;}
    //LAUNCH POPUP TO CONFIRM CANCELLATION FOR PARTIAL REINBURSMENT
    if(!result){
      const amount = this.calculateReimbursment(data);
      this.setState({bReimbursment: true, valueReimbursment: amount});
    }
    else {
      this.changeStatus('deleting', this.state.selectedItem, 0);
    }
  }


  // ############################# CALCULATE REIMBURSMENT #############################
  // ############################# CALCULATE REIMBURSMENT #############################
  calculateReimbursment = (data) => {
    let stubRaw = new Date(Date.parse(data['date'] + ' ' + data['start'].substring(0, 2)+ ':' +data['end'].substring(-2))) - new Date(data['insertedDetail']);
    let stub = Math.min(stubRaw/(3600*24*1000), 7);
    // console.log('stub:', stubRaw, ' => ',stub);
    let timeOnline = Math.min((new Date() - new Date(data['insertedDetail']))/(3600*24*1000), 7);
    // console.log('timeOnline:', timeOnline);
    let ratio = timeOnline/stub;
    
    // let amount = (timeOnline > (1/24) ? Math.trunc((12 * (1-ratio))*100)/100 : 12);
    let amount = (timeOnline > (1/24) ? Math.trunc((parseInt(data['cost'],10) * (1-ratio))*100)/100 : parseInt(data['cost'],10));
    console.log(ratio, ' : ', amount );

    return amount;
  }

  //################### CHECKS AND HANDLES AD VALIDITY FOR REIMBURSMENT WHEN CANCELED ###################
  //################### CHECKS AND HANDLES AD VALIDITY FOR REIMBURSMENT WHEN CANCELED ###################
  async handleReimbursmentConfirmation(data){
    console.log('handleReimbursmentConfirmation => ', data);
    this.setState({bReimbursment: false}, function(){            
      this.changeStatus(data, this.state.selectedItem, data)  
    });
  }

  // ############################# SAVE VISIBILITY #############################
  // ############################# SAVE VISIBILITY #############################
  saveChanges = async () => {
    let data =  _.cloneDeep(this.state.activities);
    let dataModified =  _.cloneDeep(this.state.modifiedActivities);
    data = this.confirmDeletionStatus(data);
    dataModified = this.confirmDeletionStatus(dataModified);
    let data2 =  _.cloneDeep(data);
    await setActivity(this.state.selectedBusiness['id_business'], dataModified);
    // console.log('SAVE CHANGES => ', data);
    this.setState({activities: data2, activitiesOld: data, bSaved: true, modifiedActivities: []});
  }

  // ############################ CONFIRM DELETION STATUS ###################################
  // ############################ CONFIRM DELETION STATUS ###################################
  confirmDeletionStatus = (data)  => { 
    for(const key in data){
      if(data[key]['action'] === 'deleting'){
        data[key]['action'] = "deleted";
      }  
    }
    return data;
  }

  // ############################ FORMAT DATA ###################################
  // ############################ FORMAT DATA ###################################
  formatData = async (business, activity)  => { 
    if(activity != null){
      let results = activity;
      let tempBusiness = {}
      const fieldBusiness = ['id_business', 'companyname', 'coordinates', 'telephone', 'description', 'email', 'address', 'postcode', 'city', 'country', 'images'];
      for(const key in fieldBusiness){ 
        tempBusiness[fieldBusiness[key]] = business[fieldBusiness[key]];
      }
      results['business'] = tempBusiness;
      results['users'] = 200;
      return results;
    }    
  }
                
  //################### GET COORDINATES,  DRAW AREA AND DISPLAY STATS ###################
  //################### GET COORDINATES,  DRAW AREA AND DISPLAY STATS ###################
  async selectedArea(map, event){

    this.setState({loadingStats: true}, async function(){            
      const lngLat = event.lngLat;
      const coords = getBasisCoordinates({longitude: lngLat['lng'], latitude: lngLat['lat']});
      const area = getAreaCoordinates(coords);
      const stats = await getAreaStats({longitude: lngLat['lng'], latitude: lngLat['lat']});
      setTimeout(() => {
        this.setState({bStats: true, loadingStats: false, area: area, stats: {data: stats, position: lngLat}});  
      }, 1200)
  });
  
  }
 

  //################### FORMAT DATE ACTIVITIES ###################
  //################### FORMAT DATE ACTIVITIES ###################
  formatActivitiesDate(data){
    for(const key in data){
      if(data[key]['id_event'] !== 'id'){data[key]['date'] = formatDate(new Date(data[key]['date']), 'M ddL');}
    }
    return data;
  }
                
}

const mapDispatchToProps = dispatch => { return {navigate: (direction, history) => dispatch(actions.navigate(direction, history))}}
const mapStateToProps = (state) => { 
  return {
    isAuthenticated: state.status.token !== null && state.status.token !== '' ? true : false, 
    status:state.status, 
    user:state.user, 
    settings:state.settings, 
    architecture:state.architecture,
    directory: state.directory,

  };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Marketing));
