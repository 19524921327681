import React, { Component } from 'react'
import { styleMapping, generateReference } from "../utils/tools";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ContentEditable from "react-contenteditable";
import { Input } from "reactstrap";
import Select from "../elements/select";
import { extractImages } from "../services/editor";
import * as server from "../../resources/constants/api";
import ReactPlayer from 'react-player'

export default class CVIDEO extends Component {
    constructor(props) {
        super(props)
        this.state = { structure: null, editor: props.editor, videos: null, videosDisplay: null, params: null, element: null}
    }

    componentDidMount(){ this.loadData(this.props); }
    UNSAFE_componentWillReceiveProps(props){ this.loadData(props); }
    loadData = async (data)  => { 
        if (data !== null && data !== undefined) {        
            if (this.state.videos === null) {
                    let tempvideos = await extractImages(data.structure['tag'], data.structure);
                    const tempvideos2 = Object.assign([], tempvideos);
                    // console.log('CVIDEO LOAD video A => ', tempvideos2);
                    this.setState({videos: tempvideos, videosDisplay: tempvideos2}, function(){});
            }
            else{
                if(data.videos !== null && data.videos !== undefined){
                    if (this.state.videosDisplay !== null){this.setState({videos: data.videos}, function(){});}
                    else{
                        const tempvideos2 = Object.assign([], data.videos);
                        // console.log('CVIDEO LOAD video B => ', tempvideos2);
                        this.setState({videos: data.videos, videosDisplay: tempvideos2}, function(){});    
                    }
                }   
            }
        }
        // console.log('CVIDEO LOAD VIDEO 111 => ', data.structure);
        this.setState({structure: data.structure, editor: data.editor, bMessage: data.structure.structure.info.display}, function(){});
    }

    // ############################ HANDLE NEW video ###################################
    // ############################ HANDLE NEW video ###################################
    handleVideo = (e) => {
        const input = e.target;
        if (input.files && input.files.length) {
            if (input.files && input.files[0]) {
                const reader = new FileReader();
                reader.onloadend = (e) => {
                let video = reader.result;
                this.getParams('structure.video.video', this.state.structure.structure.video.video);
                let tempVideos = this.state.videos;
                let tempVideosDisplay = [];
                let tempVideos2 = [];
                const refGen = generateReference(20); 
                const refRelative = server.URL_SERVER_DJANGO_VIDEO_RELATIVE + '/' + this.state.structure.reference + '/' + refGen + '.webm';
                const ref = server.URL_SERVER_DJANGO_VIDEO + '/' + this.state.structure.reference + '/' + refGen + '.webm';
                
                if(tempVideos.length > 0 && tempVideos[0]['action'] !== 'new'){
                    tempVideos = [{'action': 'delete', 'url': tempVideos[0]['url'].replace(new RegExp(server.URL_SERVER_DJANGO_S3, "g"), ''),}];
                } else {tempVideos = [];}
                tempVideos.push({url: video, action: 'new', path: refRelative, pathAbsolute: ref});
                
                tempVideosDisplay.push({url: video, action: 'new'});
                tempVideos2.push({url: refRelative, action: ''});
                // console.log('ADD VIDEOS => ', tempVideos, ' => ', tempVideosDisplay);                
                
                this.setState({'videos': tempVideos, 'videosDisplay': tempVideosDisplay});
                this.handleChange(this.state.params, 'url', tempVideos2);};
                reader.readAsDataURL(input.files[0]);
            }
        }
    } 
     
    // ############################ GET PARAMS WHEN SUBELEMENT CLICKED ###################################
    // ############################ GET PARAMS WHEN SUBELEMENT CLICKED ###################################
    getParams = (params, data) => {
        // console.log('GETPARAMS => ', params, ' => ', data);
        this.setState({element: data, params: params});
        this.props.onChange(params, this.state.structure, this.state.videos, true);
    }
    

    // ############################ HANDLE INPUT CHANGE ###################################
    // ############################ HANDLE INPUT CHANGE ###################################
    handleChange(params, field, e){
        let temp = this.state.element;
        switch(field){
            case 'font': temp[field] = e.value; break;
            default: temp[field] = e; break;
        }
        this.setState({element: temp});
        this.props.onChange(params, this.state.structure, this.state.videos, false);
    } 

    
    // ############################ HANDLE INPUT CHANGE ###################################
    // ############################ HANDLE INPUT CHANGE ###################################
    toggleMessage(){
        let temp = this.state.structure; 
        temp.structure.info.display = !temp.structure.info.display;
        switch(temp.structure.info.display){
            case true: 
                this.getParams('structure.info.container', temp.structure.info.container);
            break;
            case false: 
                this.getParams(null, null)
            break;
            default:break;
        }
        this.setState({structure: temp});
        this.props.onChange('structure.info.display', temp, this.state.videos, false);
    }

    // ############################ RENDER ###################################
    // ############################ RENDER ###################################
    render() {
        if(this.state.structure){   
            const { editor, videosDisplay } = this.state;
            const { structure } = this.state.structure;

            if(isNaN(structure.video.video.height) === false && structure.video.video.height!== ''){this.height = parseFloat(structure.video.video.height) * parseInt(window.innerHeight, 10) * 1.2;}
            else { this.height = 'auto'; }

            if(editor === true){   
                return (
                        <div key={'cw'} className="BRL Wide100">
                            {/* ##################### FIELD PARAMETERS ##################### */}
                            {/* ##################### FIELD PARAMETERS ##################### */}
                            {this.state.element && <div className="BRL Wide100 Padding10px BottomShadow7 BorderRadius5 BottomMargin20px">
                                <div className={"BRL Wide100 FS38 TitleFont BottomMargin5px HPadding10px Capital"}>{this.state.params}</div>
                                {Object.keys(this.state.element).map((key, index) => {
                                return (
                                    <React.Fragment key={index}>
                                    {key !== 'text' && key !== 'url' && key !== 'container' && key !== 'elements' && 
                                    <li key={ 'c_' + key} className={"BRL NoBorder TopMargin5px HPadding5px " + ((key === 'class' || key === 'style') ? "col-lg-6 col-md-6 col-sm-12" : "col-lg-3 col-md-3 col-sm-4")} style={{listStyleType: 'none'}}>
                                    <div key={ 'l_' + key} className="BRL Wide100 BottomMargin2px ButtonFont Capital FS41">{key}</div>             
                                    
                                    {(key !== 'font' && key !== 'color' && key !== 'background') && <Input key={'f1_'+ key} type={(key === 'color' || key === 'background' ? 'color' : 'text')} name={key} onChange={(e) => this.handleChange(this.state.params, key, e.target.value)} value={this.state.element[key]} className={"BRL Wide100 NoBorder btn-light BCTrans InputFont FS40 TopMargin5px BottomShadow7"}/>}
                                    {(key === 'color' || key === 'background') && 
                                        <React.Fragment>
                                        <Input key={'f1_'+ key} type={'color'} name={key} onChange={(e) => this.handleChange(this.state.params, key, e.target.value)} value={this.state.element[key]} className={"BRL Wide30 NoBorder btn-light BCTrans InputFont FS40 TopMargin5px BottomShadow7"}/>
                                        <Input key={'f2_'+ key} type={'text'} name={key + '_code'} onChange={(e) => this.handleChange(this.state.params, key, e.target.value)} value={this.state.element[key]} className={"BRL Wide65 NoBorder btn-light BCTrans InputFont FS40 TopMargin5px LeftMargin5 BottomShadow7"}/>
                                        </React.Fragment>}
                                    {(key === 'font') && 
                                        <Select key={'f2_'+ key} name={key} id={'font'} value={this.state.element[key]} fields={key} 
                                        classes={{container:"BRL form-control NoBorder FS40 LabelFont Padding3px btn-light BCTrans", options:"BRL Wide100 TopMargin5px CenterAlign BottomShadow7"}}  
                                        onChange={(name, val) => this.handleChange(this.state.params, key, val)}></Select>}
                                    </li>}
                                    </React.Fragment>
                                );})}
                            </div>}

                            {/* ##################### DESIGN EDITOR ##################### */}
                            {/* ##################### DESIGN EDITOR ##################### */}
                            <div id={"container"} className={"BRL " + structure.container.class} style={{ ...{height: this.height},...styleMapping(structure.container)}}>
                                {editor && <div onClick={() => {this.getParams('structure.container', structure.container)}} className={"Hand Absolute Top0px Right10px Padding5px btn-light BorderRadius5 BottomShadow7 z1000 "}><FontAwesomeIcon icon={"pen"} size="xs" color="black"/></div> }
                                
                                    <div onClick={() => {this.getParams('structure.video.container', structure.video.container)}} className={"Hand Absolute Top10px Left10px Padding5px btn-light BorderRadius5 BottomShadow7 z2000"}><FontAwesomeIcon icon={"pen"} size="xs" color="black"/></div>
                                    {Object.keys(videosDisplay).map(key => {
                                        return (
                                            <div id={"container_video_" + key} key={"vid_" + key} className={"" + structure.video.container.class} style={styleMapping(structure.video.container)} >
                                            <ReactPlayer url={videosDisplay[key].url} playsinline playing={structure.video.video.playing} loop={true} volume={1} muted={true} controls={false} width={structure.video.video.width} height={this.height} />
                                            </div> 
                                        ); 
                                    })}

                                    {structure.info.display && <React.Fragment key={'info_e'}>
                                        {Object.keys(structure.info.elements).map(key => {
                                            return (<ContentEditable html={structure.info.elements[key].text} disabled={false} onChange={(e) => this.handleChange('structure.info.elements['+ key +']', 'text', e.target.value)} onClick={() => {this.getParams('structure.info.elements['+ key +']', structure.info.elements[key])}} className={"Hand NoBorder NoOutline " + structure.info.elements[key].class} style={styleMapping(structure.info.elements[key])} />); 
                                        })}
                                    </React.Fragment>} 
                                
                                    {/* ##################### ADD VIDEO + TEXT ##################### */}
                                    {/* ##################### ADD VIDEO + TEXT ##################### */}
                                    <div key="actions" className={"Hand Absolute Bottom10px LeftMargin10px"}>
                                        <div onClick={() => {this.getParams('structure.video.video', structure.video.video)}} className={"Hand BRL LeftMargin2px VPadding5px HPadding5px BorderRadius50 BottomShadow7 z2000 btn btn-light"}>
                                            <button className={"BRL Wide100 NoBorder Capital"} style={{'color':'inherit', 'backgroundColor':'inherit'}}>
                                                <FontAwesomeIcon icon={"pen"} size="sm" color="black"/>
                                            </button>    
                                        </div>
                                        <div className={"Hand BRL LeftMargin1px VPadding5px HPadding5px BorderRadius50 BottomShadow7 z2000 btn btn-primary"}>
                                            <button key={'add_video'} className={"BRL Wide100 NoBorder Capital"} style={{'color':'inherit', 'backgroundColor':'inherit'}}>
                                                <FontAwesomeIcon icon={"upload"} size="sm" color="white"/>
                                            </button>
                                            <input onChange={(e)=> this.handleVideo(e)} className="Hand Absolute Wide100 High100 Left0px Top0px z1000 Hand" type="file" style={{opacity: 0}} />        
                                        </div>
                                        <div onClick={() => {this.toggleMessage();}} className={"Hand BRL LeftMargin1px VPadding5px HPadding5px BorderRadius50 BottomShadow7 z2000 btn btn-info"}>
                                            <button key={'add_video'} className={"BRL Wide100 NoBorder Capital"} style={{'color':'inherit', 'backgroundColor':'inherit'}}>
                                                <FontAwesomeIcon icon={"edit"} size="sm" color="white"/>
                                            </button>
                                        </div>
                                    </div>

                                                  
                                </div>
                        </div>        
                );
            } else {
                return (
                            <React.Fragment>
                            {Object.keys(videosDisplay).map(key => {
                                return (
                                    <div id={"container_video_" + key} key={"vid_" + key} className={"" + structure.video.container.class} style={styleMapping(structure.video.container)} >
                                        <ReactPlayer url={videosDisplay[key].url} playsinline playing={structure.video.video.playing} loop={true} volume={1} muted={true} controls={false} width={structure.video.video.width} height={this.height} />
                                    </div> 
                                ); 
                            })}
                            
                            {structure.info.display && <React.Fragment key={'info_d'}>
                                {Object.keys(structure.info.elements).map((key,index) => {
                                    return (<ContentEditable key={'ce_' + index} html={structure.info.elements[key].text} disabled={true} className={"NoBorder NoOutline " + structure.info.elements[key].class} style={styleMapping(structure.info.elements[key])} />); 
                                })}
                            </React.Fragment>}
                            </React.Fragment>
                    
                    );
        }

            } else return null;
    }
}

