import React, { Component } from 'react'
import { styleMapping } from "../utils/tools";
import ReactHtmlParser from 'react-html-parser';

export default class CINFO extends Component {
    constructor(props) {
        super(props)
        this.state = {
            structure: null
        }
    }

    componentDidMount(){ 
        this.loadData(this.props); 
    }
    
    UNSAFE_componentWillReceiveProps(props){ 
        this.loadData(props); 
    }
    
    loadData = (data)  => { 
        this.setState({structure: data.structure}, function(){}); 
    }

    // ############################ RENDER ###################################
    // ############################ RENDER ###################################
    render() {

        if(this.state.structure){   
            const text = window.atob(this.state.structure.text);
            // console.log('CINFO 2 => ', text);
            return (
                <div className={"" + this.state.structure.class} style={styleMapping(this.state.structure)}>{
                    ReactHtmlParser(text) 
                } </div>
                );
        } else return null;
    }
}
