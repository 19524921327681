// FOR BASIC STATIC EXAMPLE
export const OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: { position: 'top' },
    title: { display: true, text: '' },
  },
};
export const LABELS = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

// FOR MONITORING
export const CHARTCOLORS = ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)', 'rgba(235, 139, 44, 1)', 'rgba(34, 142, 215, 1)', 'rgba(245, 196, 76, 1)', 'rgba(173, 122, 235, 1)', 'rgba(245, 149, 54, 1)'];
export const CHARTCOLORSBAR = ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)', 'rgba(235, 139, 44, 1)', 'rgba(34, 142, 215, 1)', 'rgba(245, 196, 76, 1)', 'rgba(173, 122, 235, 1)', 'rgba(245, 149, 54, 1)'];
export const TEMPLATE = {labels: [], datasets: [], options: null};
export const TEMPLATEDATASETS = {
  pie: {backgroundColor: [], data: []}, 
  line : {label: [], data: [], fill: false, borderColor: [], tension: 0.1}, 
  bar : {label: [], data: [], backgroundColor: [], borderColor: [], borderWidth: 1}, 
  bar2 : {label: [], data: [], backgroundColor: [], borderColor: [], borderWidth: 1}};
export const TEMPLATEOPTIONS = {
  pie: {
    title: {display: true, text: 'EUR'}
  }, 
  line : {scales: {y: {beginAtZero: true, title: { display: true, text: 'activity'}}}}, 
  bar : {
    scales: {
      x: {stacked: true, title: { display: false, text: 'periods'}},
      y: {beginAtZero: true, title: { display: true, text: 'activity'}}
    }}, 
  bar2 : {responsive: true, scales: {x: {stacked: true, title: { display: false, text: 'periods'}}, y: {stacked: true, title: { display: true, text: 'activity'}}}}
};


