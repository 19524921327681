import React, { Component } from 'react';
import Canvas from "../design/canvas";
import { connect } from 'react-redux';
import { getStructure } from "../services/general";
// import { styleMapping, scrollTo } from "../utils/tools";

class Custom extends Component {
 
    //############################ CONSTRUCTOR ###################################
	//############################ CONSTRUCTOR ###################################
	constructor(props) {
        super(props);
        this.state={structure: null};
    }  

    async componentDidMount(){
        // console.log('CUSTOM RENDER => ', this.props.type, ' => ', this.props.reference);
        const temp = await this.getData(this.props.type, (this.props.reference? this.props.reference : this.props.match.params.reference));
        this.setState({structure: temp});
    }
    
    async UNSAFE_componentWillReceiveProps(props){
        const temp = await this.getData(props.type, props.match.params.reference);		
        this.setState({structure: temp});
    }

    //############################ GET DATA ###################################
    //############################ GET DATA ###################################
    async getData(type,name){
        const temp = await getStructure(type, name);
        return temp;		
    }		
            

    //############################ RENDER ###################################
    //############################ RENDER ###################################
    render() {
        if(this.state.structure){ 
            // console.log('CUSTOM RENDER => ', this.state.structure);
            return (<Canvas structure={this.state.structure} />);
        } else 
            return null;
    }
}

export default connect(null, null)(Custom);

