import React, { Component } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { formatDate } from "../utils/tools"

export default class Error extends Component {  
  constructor(props) {
    super(props);
    this.state = {data: props.data};
  }

  // ############################ RENDER ###################################
  // ############################ RENDER ###################################
  render() {
    
    const {data, style} = this.props;

    return (
        <div className={"" + style.container.class}>
          <div className={"FS38 TitleFont CenterAlign"} >
            Overlapping timeslots
          </div>
          <div className={"" + style.line.class} >
            <div className={"BRL Wide10 CenterAlign"} >id</div>
            <div className={"BRL Wide40 CenterAlign"}>day</div>
            <div className={"BRL Wide25 CenterAlign"}>start</div>
            <div className={"BRL Wide25 CenterAlign"}>end</div>
          </div>
          {Object.keys(data['complete']).map((line, index)=>{
            const element = data['complete'][line];
            return(
            <div className={"" + style.line.class} key={index} >
              <div className={"BRL Wide10 CenterAlign"} key={index + '-id'} >{element.id}</div>
              <div className={"BRL Wide40 CenterAlign"} key={index + '-day'} >{this.formatTime(element.timeslot[0], 0, 'day')}</div>
              <div className={"BRL Wide25 CenterAlign"} key={index + '-start'} >{this.formatTime(element.timeslot[0], 0, 'hour')}</div>
              <div className={"BRL Wide25 CenterAlign"} key={index + '-end'} >{this.formatTime(element.timeslot[element.timeslot.length - 1], 1, 'hour')}</div>
            </div>
            );
          })}
        </div>
      );
  }

 formatTime(data, offset, type){

  let result;
  let day = new Date(data);
  day.setHours(day.getHours() + offset);
  
  switch(type){
    default:break;
    case 'day': result = day.getFullYear().toString() + '/' + (day.getMonth()< 9 ? '0' + parseInt(day.getMonth() + 1 ,10).toString() : parseInt(day.getMonth() + 1 ,10).toString()) + '/' + (day.getDate()< 9 ? '0' + day.getDate().toString() : day.getDate().toString()); break;
    case 'hour':result = (day.getHours()< 9 ? '0' + day.getHours().toString() : day.getHours().toString()) + ':' + '00'; break;
  }

  return result;
}
  

}