import Axios from 'axios';
import { getLocalData, setLocalData } from "../utils/tools";
import * as server from "../../resources/constants/api";
import { toast } from 'react-toastify'; 

const options = {
    autoClose: 1500, 
    type: toast.TYPE.INFO,
    hideProgressBar: false
};

//########################### CREATE PROFILE ###################################
//########################### CREATE PROFILE ###################################
export async function createProfile(data)  {
    const token = data.token;
    let headers = {'headers': {'authorization': `Token ${token}`}};
    if (token === '' || token === null){headers = {};}
    // console.log('CREATE PROFILE : ', data);
    Axios.post(`${server.URL_SERVER_DJANGO}/profiles/profiles/`, {'idUser': data.idUser.toString()}, headers).then(res => {
        // console.log('GETUSER => ', res);
        return res.data;
    } );
};


//########################### GET PROFILE MEMORY OR SERVER ###################################
//########################### GET PROFILE MEMORY OR SERVER ###################################
export async function getProfile(id) {
    let profile = await getLocalData('profile');
    if (profile !== undefined && profile !== null){profile = JSON.parse(profile);}
    const token = await getLocalData('token');
    let headers = {'headers': {'authorization': `Token ${token}`}};
    if (token === '' || token === null){headers = {};}

    if(id !== '' && id !== null && (profile === null || profile === undefined)){
        // return await Axios.get(`${server.URL_SERVER_DJANGO}/profiles/profiles/${id}`, headers).then(res => {
        //     console.log('GETPROFILE 2 => ', res);
        //     setLocalData('profile', JSON.stringify(res.data));
        //     return res.data;
        // });
        console.log('GETPROFILE 0B => ',  id);
    
        return new Promise ((resolve, reject) => {
            Axios.get(`${server.URL_SERVER_DJANGO}/profiles/profiles/${id}`, headers).then(res => {
                console.log('GETPROFILE 2 => ', res.data);
                setLocalData('profile', JSON.stringify(res.data));
                resolve(res.data);
            }, err => { 
                console.log('GETPROFILE ERROR');
                return null;
              }
            );
        });   
    }
    return profile;
}

//########################### GET USER MEMORY OR SERVER ###################################
//########################### GET USER MEMORY OR SERVER ###################################
export async function getUser(id) {
    let user = await getLocalData('user');
    if (user !== undefined && user !== null){user = JSON.parse(user);}
    const token = await getLocalData('token');
    let headers = {'headers': {'authorization': `Token ${token}`}};
    if (token === '' || token === null){headers = {};}
    
    if(id !== '' && id !== null && (user === null || user === undefined)){
        return await Axios.get(`${server.URL_SERVER_DJANGO}/users/${id}`, headers).then(res => {
            // console.log(id, ' => GETUSER 2 => ', res.data);
            setLocalData('user', JSON.stringify(res.data));
            return res.data;
        });
    }
    return user;
}


//########################### SAVE PROFILE MEMORY AND SERVER ###################################
//########################### SAVE PROFILE MEMORY AND SERVER ###################################
export async function setProfile(id, data) {
    
    setLocalData('profile', JSON.stringify(data));
    const token = await getLocalData('token');
    let headers = {'headers': {'authorization': `Token ${token}`}};
    if (token === '' || token === null){headers = {};}
    // console.log('SETPROFILE 2 => ',data);
    return await Axios.put(`${server.URL_SERVER_DJANGO}/profiles/profiles/${id}/`, data, headers).then(res => {
        return res.data;
    });
    
}

//########################### DELETE PROFILE ###################################
//########################### DELETE PROFILE ###################################
export async function deleteProfile(id, data) {
    
    setLocalData('profile', null);
    setLocalData('user', null);

    const token = await getLocalData('token');
    let headers = {'headers': {'authorization': `Token ${token}`}};
    if (token === '' || token === null){headers = {};}
    await Axios.delete(`${server.URL_SERVER_DJANGO}/profiles/profiles/${id}/`, headers).then(res => {
        // console.log('DELETE PROFILE B1', res.data);
        return res.data;
    });
        await Axios.delete(`${server.URL_SERVER_DJANGO}/users/${id}/`, headers).then(res => {
        // console.log('DELETE USERS A1', res.data);
        toast.success("Your profile has been deleted successfully", options)
        return res.data;
    });
    

    return null;
}
