import React, { Component } from "react";
import { Router } from "react-router-dom";
import CustomLayout from "./components/customLayout";
import BaseRouter from './components/routes';
import { ToastContainer } from 'react-toastify';
import { styleMapping } from "./components/utils/tools";
import 'react-toastify/dist/ReactToastify.min.css'; 
import history from './components/history';
import { getSettings } from "./components/services/general";
import * as actions from './store/pseudo/general';
import { connect } from 'react-redux';
import $ from 'jquery';

class App extends Component {
  
  // ############################ CONSTRUCTOR ###################################
  // ############################ CONSTRUCTOR ###################################
  constructor(props) {
    super(props);
    this.state = { settings: {}, fields: "", newField: "", newStructure: "",};
    this.selectedKey = "";
    this.selectedFields = {};
    this.old_scroll = 0;
    this.handleScroll = this.handleScroll.bind(this);
  }

  async UNSAFE_componentWillMount() {
    //GET SETTINGS + ARCHITECTURE
    const settings = await getSettings();
    this.props.setSettings(settings);
    this.props.onTryAutoSignup(history);
  }
  
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    // window.addEventListener("scroll", this.handleScroll);
  }
  
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    // window.removeEventListener("scroll", this.handleScroll);
  }

  // ############################ RENDER ###################################
  // ############################ RENDER ###################################
  render() {      
    if(this.props.settings){
      return (
        <Router 
          history={history} 
          className={this.props.settings.structure.body.class} 
          style={styleMapping(this.props.settings.structure.body)} 
          onScroll={this.handleScroll}> 
          
          <ToastContainer />
          <CustomLayout {...this.props} >
            <BaseRouter /> 
          </CustomLayout>
        </Router>
      );
    } else return null;
  } 
 
  
  // ########################### HANDLE RESIZING EVENT THROUGH JQUERY ########################### 
  // ########################### HANDLE RESIZING EVENT THROUGH JQUERY ########################### 
  // handleResize = e => {
  //   const windowSize = window.innerWidth;
  //   $('.Resize').each(function() {
  //     console.log('Resizing 0 => ', $(this).height());
  //     if ($(this).height() > 0 ){
  //         const ratio = $(this).attr('data-ratio');
  //         const height = parseFloat(ratio) * windowSize;
  //         console.log('Resizing 1 => ',ratio + ' * ' + windowSize + ' => ' +  height);
  //         $(this).css({ 'height': height });

  //         const width = $(window).width();
  //         console.log('Resizing 2 => ', width);
  //         if(width < 1090){
  //           console.log('Resizing 3 => chanching color White');
  //           $('.MenuButton').css({color: '#ffffff'});
  //         } else{
  //           console.log('Resizing 3 => chanching color Black');
  //           $('.MenuButton').css({color: '#000000'});
  //         }
  //     }
  //   }); 
  // };

  // ########################### HANDLE SCROLLING EVENT THROUGH JQUERY ########################### 
  // ########################### HANDLE SCROLLING EVENT THROUGH JQUERY ########################### 
  handleScroll = (event) => {
    
    const scroll_top = $(window).scrollTop();
    const width = $(window).width();
    // console.log('HANDLE SCROLL : ', scroll_top, ' > ', this.old_scroll);
      if(scroll_top > this.old_scroll){ 
        $('.logo_container').css({top: '-150px'});
      }
      else if(scroll_top < this.old_scroll){ 
        if(width > 1090){
          if(scroll_top < 30){ 
            $('.logo_container').css({backgroundColor: ''});
            $('.MenuButton').css({color: '#000000'});
          }
          else {
            $('.logo_container').css({backgroundColor: '#000000'});
            $('.MenuButton').css({color: '#ffffff'});
          }
          $('.logo_container').css({top: '0px'});
        }
      }
      if(scroll_top <= 30){
        $('.logo_container').css({backgroundColor: ''});
        $('.logo_container').css({top: '0px'});
      }
    this.old_scroll = scroll_top;
  } 
}

const mapStateToProps = (state) => { return {isAuthenticated: state.status.token !== null && state.status.token !== '' ? true : false, status:state.status, user:state.user, settings:state.settings, architecture:state.architecture};}
const mapDispatchToProps = dispatch => {
  return {
    setSettings: (data) => dispatch(actions.setSettings2(data)),
    onTryAutoSignup: (history) => dispatch(actions.authCheckState(history)),
    navigate: (direction, history) => dispatch(actions.navigate(direction, history)), 
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);


