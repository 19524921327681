import React from "react";
import * as ReactDOM from 'react-dom';
import * as apiKeys from "../../resources/constants/api";
import { formatData } from "../services/geolocation";
import { manageEventViews } from "../services/business";
import { truncate } from "../utils/tools";
import { getCurrentGPSPosition } from '../services/geolocation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import mapboxgl from 'mapbox-gl';
import Breakdown from '../elements/breakdown';
import * as actions from '../../store/pseudo/general';
 
import events from './images/events.jpg';
import users from './images/users.jpg';

mapboxgl.accessToken = apiKeys.MAPBOX_TOKEN;
const JSON2 = { 'type': 'geojson', 'generateId': true, 'data': { 'type': 'FeatureCollection', 'features': [] }};    
const JSONCLUSTER = { 'type': 'geojson', 'generateId': true, 'data': { 'type': 'FeatureCollection', 'features': [] }, cluster: true, clusterMaxZoom: 13, clusterRadius: 25};    
const ICONE_COLORS = {'dataBusinesses': '#ad182f', 'dataDirectory': '#1f79ad'};

class MapBox extends React.Component {
  
  constructor(props) {
    super(props);
    this.today = new Date();
    this.t1 = this.today.setHours(this.today.getHours() + 12);
    this.mapContainer = React.createRef();
    this.data = { 
      zoom : 12, 
      region : null, 
      popUpData: null, 
      dataPoints: null, 
      dataDirectory: null, 
      dataBusinesses: null, 
      dataMock: null, 
      longitude: null, 
      styleMap: null, 
      config: null
    };

    this.state = { 
      clusterDetails: null,
      eventMode: true,
      heatmapMode: true
    };
  }

  componentDidMount() {
    this.loadData(this.props);
    this.createMap();
  }

  UNSAFE_componentWillReceiveProps(props){
    this.loadData(props);
  }

  //################### LOAD DATA ###################
  //################### LOAD DATA ###################
  loadData = (data)  => {
    if(data){

      // console.log('======> LOAD DATA => ', data);

      this.data['region'] = [parseFloat(data.data['longitude']), parseFloat(data.data['latitude'])];
      this.data['dataPoints'] = data.data['dataPoints'];
      this.data['dataBusinesses'] = data.data['dataBusinesses'];
      this.data['dataDirectory'] = data.data['dataDirectory'];
      this.data['dataMock'] = data.data['dataMock'];
      this.data['longitude'] = data.data['longitude'];
      this.data['styleMap'] = data.data['styleMap'];
      this.data['config'] = data.settings.admin.config;

      //UPDATES DATA LAYER
      if(this.data){
      
        // console.log('0 UPDATE LAYERS => ', this.data);
        this.updateDataLayers(this.data);
      }

      //UPDATE MAPS CENTER
      if(this.map){
        const mapCenter = [this.map.getCenter().lng, this.map.getCenter().lat]
        if(JSON.stringify(this.data['region']) !== JSON.stringify(mapCenter)){
          this.map.setCenter(this.data['region']);
        }
      }
    
    }
  }
  
  
  //################### RENDER ###################
  //################### RENDER ###################
  render() {

    return (
      <div ref={this.mapContainer}  className={"BRL"} style={this.props.styleMap}>
        <div className={"Absolute Hand z100 Padding10px btn-light BorderRadius10 BCTrans"} style={{top: '20px', right: '4%'}} onClick={() => {this.backtoUser()}}><FontAwesomeIcon icon={"bullseye"} /></div>

        <div className={"filter_container"} >
            <div 
              className={"filter"}
              onClick={() => {
                this.updatingDynamicLayers('dataBusinesses', !this.state.eventMode);
                this.updatingDynamicLayers('dataDirectory', this.state.eventMode);
                this.setState({eventMode: !this.state.eventMode})
              }}>
              <img className={"image_filter"} src={events} alt=""/>
              <div className={"text_filter_container"} style={{backgroundColor: (this.state.eventMode? 'rgba(173,24,47,0.5)': 'rgba(0,0,0,0.1)')}}>
                <div className={"text_filter"}>{(this.state.eventMode? 'Events': 'Spots')}</div>  
              </div>
            </div>

            {this.props.profile && 
              <div 
                className={"filter"}
                onClick={() => {
                  this.updatingDynamicLayers('dataPoints', !this.state.heatmapMode);
                  this.setState({heatmapMode: !this.state.heatmapMode});
                }}>
                <img className={"image_filter"} src={users} alt=""></img>
                <div className={"text_filter_container"} style={{backgroundColor: (this.state.heatmapMode? 'rgba(173,24,47,0.5)': 'rgba(0,0,0,0.1)')}} >
                  <div className={"text_filter"}>{(this.state.heatmapMode? 'Users': 'None')}</div>  
                </div>
              </div>
            }
        </div>
      </div>
    );
  }


  //################### CAPTURE MAP CENTER ###################
  //################### CAPTURE MAP CENTER ###################
  async updateCenter(){
      this.getZoom();
      const center = {lat: this.map.getCenter().lat.toFixed(6), lng: this.map.getCenter().lng.toFixed(6)};
      const temp = {'latitude': truncate(center['lat'],10), 'longitude': truncate(center['lng'],10)};
      this.data.region = [truncate(center['lng'],10), truncate(center['lat'],10)];
      this.props.onChangeCenter(temp, this.data.zoom);
  }

  //################### GO TO USER POSITION ###################
  //################### GO TO USER POSITION ###################
  async backtoUser(){
    this.getZoom();
    const center = await getCurrentGPSPosition();
    this.props.onChangeCenter(center, this.data.zoom, 'search');
  }
  
  //################### CAPTURE CURRENT ZOOM LEVEL ###################
  //################### CAPTURE CURRENT ZOOM LEVEL ###################
  async getZoom(){
    const temp = this.map.getZoom().toFixed(2);
    this.data.zoom = temp;
  }


  //################### CREATE MAP ###################
  //################### CREATE MAP ###################
  createMap = ()  => {

    // CREATE MAP
    this.map = new mapboxgl.Map({
      container: this.mapContainer.current,
      style: ( this.today.getHours() > 19 ? "mapbox://styles/mapbox/light-v9" : "mapbox://styles/mapbox/light-v9"),
      center: this.data.region, zoom: [this.data.zoom], 
      attributionControl: true, 
      dragRotate: false, 
      touchZoomRotate: false, 
      pitchWithRotate: false,
      refreshExpiredTiles: false, 
      minZoom:8, 
      maxZoom:17, 
    });
    // MAP BEHAVIOR ON MOVE
    this.map.on('move', () => { this.updateCenter(); });

    // CREATE LAYER UPON LOADING
    const that = this;
    this.map.on('load', async function () {

      // ############### HEATMAP LAYER ###############
      that.map.addSource( 'dataPoints_source', JSON2);    
      that.map.addLayer({ 
        id: 'dataPoints', 'type': 'heatmap', 'source': 'dataPoints_source', 
        paint: that.data['config'].heatmap,
        layout: { 'visibility': 'visible' }
        });

      //############### ADDING EVENTS LAYER -  CLUSTER ###############
      let name = 'dataBusinesses';
      let name2 = 'dataDirectory';
      that.map.addSource( name + '_source', JSONCLUSTER);    
      that.map.addLayer({ id: name + '_clusters', type: 'circle', source: name + '_source', filter: ['has', 'point_count'], paint: {'circle-color': ['step', ['get', 'point_count'], ICONE_COLORS[name], 100, ICONE_COLORS[name], 750, ICONE_COLORS[name]], 'circle-radius': ['step', ['get', 'point_count'], 10, 7, 12, 10, 15, 20, 20, 30, 25, 50, 30, 100, 40]}});
      that.map.addLayer({ id: name + '_cluster-count', type: 'symbol', source: name + '_source', filter: ['has', 'point_count'], layout: { 'text-field': '{point_count_abbreviated}', 'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'], 'text-size':12}, paint: {"text-color": "#ffffff"}});    
      that.map.addLayer({ id: name + '_unclustered-symbol', type: 'symbol', source: name + '_source', filter: ['!', ['has', 'point_count']], layout: { 'icon-image': ['concat' ,['get', 'icon'], '-15'], 'icon-allow-overlap': true, 'text-field': ['get', 'title'], 'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'], 'text-size': 9, 'text-transform': 'uppercase', 'text-letter-spacing': 0.05, 'text-offset': [0, 1.5] }, 'paint': { "text-color": ["get", "color"], 'text-halo-color': '#fff', 'text-halo-width': 2} });
      
      // Assuming the map has a layer named 'clusters' and a source 'earthquakes'
      // The following creates a camera animation on cluster feature click
      that.map.on('click', name + '_clusters', (e) => {
        
        const clusterSource = that.map.getSource(name + '_source');
        const features = that.map.queryRenderedFeatures(e.point, { layers: [name + '_clusters'] });
        const cluster = features[0].properties;
        const clusterId = cluster.cluster_id;

        clusterSource.getClusterExpansionZoom(clusterId, (err, neededZoom) => {
          if (!err) {
            let zoom = Math.min(12, neededZoom);
            let tempCenter = new mapboxgl.LngLat(e.lngLat['lng'], e.lngLat['lat']);
            if (zoom >= 12){
              clusterSource.getClusterLeaves(clusterId, cluster.point_count, 0, (error, features) => {
              
                let leaves = JSON.parse(JSON.stringify(features));
                let coord = leaves[0].geometry.coordinates;
                coord = [parseFloat(coord[0] + 0.00), parseFloat(coord[1] + 0.001)];
                leaves[0].geometry.coordinates = coord;
                tempCenter = new mapboxgl.LngLat(parseFloat(leaves[0].geometry.coordinates[0]), parseFloat(leaves[0].geometry.coordinates[1]));
                zoom = (zoom < 12 ? parseInt(zoom + 0, 10) : zoom);
                that.displayBreakdown(leaves);
              });         
            } else{
              that.map.setZoom(zoom);
              that.map.setCenter(tempCenter);
            }
          }
        });
      });
      //UNCLUSTERED SYMBOLS CLICKS
      that.map.on('click', name + '_unclustered-symbol', (e) => {
        const marker = e.features[0];
        that.displayPopUp(marker);
      });
    


      //############### dataDirectory LAYER ###############
      that.map.addSource( name2 + '_source', JSONCLUSTER);    
      that.map.addLayer({ id: name2 + '_clusters', type: 'circle', source: name2 + '_source', filter: ['has', 'point_count'], paint: {'circle-color': ['step', ['get', 'point_count'], ICONE_COLORS[name2], 100, ICONE_COLORS[name2], 750, ICONE_COLORS[name2]], 'circle-radius': ['step', ['get', 'point_count'], 10, 7, 12, 10, 15, 20, 20, 30, 25, 50, 30, 100, 40]}});
      that.map.addLayer({ id: name2 + '_cluster-count', type: 'symbol', source: name2 + '_source', filter: ['has', 'point_count'], layout: { 'text-field': '{point_count_abbreviated}', 'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'], 'text-size':12}, paint: {"text-color": "#ffffff"}});    
      that.map.addLayer({ id: name2 + '_unclustered-symbol', type: 'symbol', source: name2 + '_source', filter: ['!', ['has', 'point_count']], layout: { 'icon-image':  'cafe-15', 'icon-allow-overlap': true, 'text-field': ['get', 'title'], 'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'], 'text-size': 8, 'text-transform': 'uppercase', 'text-letter-spacing': 0.05, 'text-offset': [0, 1.5] }, 'paint': { "text-color": ["get", "color"], 'text-halo-color': '#fff', 'text-halo-width': 2} });

      // Assuming the map has a layer name2d 'clusters' and a source 'earthquakes'
      // The following creates a camera animation on cluster feature click
      that.map.on('click', name2 + '_clusters', (e) => {
        
        const clusterSource = that.map.getSource(name2 + '_source');
        const features = that.map.queryRenderedFeatures(e.point, { layers: [name2 + '_clusters'] });
        const cluster = features[0].properties;
        const clusterId = cluster.cluster_id;

        clusterSource.getClusterExpansionZoom(clusterId, (err, neededZoom) => {
          if (!err) {
            let zoom = Math.min(12, neededZoom);
            let tempCenter = new mapboxgl.LngLat(e.lngLat['lng'], e.lngLat['lat']);
            if (zoom >= 12){
              clusterSource.getClusterLeaves(clusterId, cluster.point_count, 0, (error, features) => {
              
                let leaves = JSON.parse(JSON.stringify(features));
                let coord = leaves[0].geometry.coordinates;
                coord = [parseFloat(coord[0] + 0.00), parseFloat(coord[1] + 0.001)];
                leaves[0].geometry.coordinates = coord;
                tempCenter = new mapboxgl.LngLat(parseFloat(leaves[0].geometry.coordinates[0]), parseFloat(leaves[0].geometry.coordinates[1]));
                zoom = (zoom < 12 ? parseInt(zoom + 0, 10) : zoom);
                that.displayBreakdown(leaves);
              });         
            } else{
              that.map.setZoom(zoom);
              that.map.setCenter(tempCenter);
            }
          

          }
        });
      });
      //UNCLUSTERED SYMBOLS CLICKS
      that.map.on('click', name2 + '_unclustered-symbol', (e) => {
        const marker = e.features[0];
        that.displayPopUp(marker);
      });


     // ############### MOCK LAYER ###############
     that.map.addSource('dataMock_source', JSON2);    
     that.map.addLayer({ 
       id: 'mock_event', 'type': 'symbol', 'source': 'dataMock_source', 
       layout: { 'icon-image': ['concat' ,['get', 'icon'], '-15'], 'icon-allow-overlap': true, 'text-field': ['get', 'title'], 'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'], 'text-size': 10, 'text-transform': 'uppercase', 'text-letter-spacing': 0.05, 'text-offset': [0, 1.5] }, 
       paint: { "text-color": ["get", "color"], 'text-halo-color': '#fff', 'text-halo-width': 2}
      });
      //UNCLUSTERED SYMBOLS CLICKS
      that.map.on('click', 'mock_event', (e) => {
        const marker = e.features[0];
        that.displayPopUp(marker);
      });

      // ############### STATS ZONES ###############
      that.map.addSource('stats_source', JSON2);    
      that.map.addLayer({ 
        id: 'stats', 'type': 'fill', 'source': 'stats_source', 
        layout: { }, 
        paint: {'fill-color': 'rgba(173,24,47, 0.4)', 'fill-outline-color': 'rgba(173,24,47, 1)'}
      });
  
    });

  }

  //################### UPDATE LAYERS DATA ###################
  //################### UPDATE LAYERS DATA ###################
  updateDataLayers = (data) => {

    // console.log('UPDATE LAYERS => EM: ', this.state.eventMode);
    // if(data['dataPoints']){console.log('USERS => ', data['dataPoints']);}
    // if(data['dataBusinesses']){console.log('BUSINESS => ', data['dataBusinesses']);}
    // if(data['dataDirectory']){console.log('DIRECTORY => ', data['dataDirectory']);}

    let tempData;
    const keys = [{name: 'dataPoints', type: 'heatmap'}, {name: 'dataBusinesses', type: 'cluster'}, {name: 'dataMock', type: 'icon'}, {name: 'stats', type: 'zone'}];

    for(const key in keys){
      
      // console.log('--- => ', keys[key]);

      if(keys[key]['name'] === 'stats'){
          tempData = { 'type': 'Feature', 'geometry': { 'type': 'Polygon', 'coordinates': [] } }
          if(this.props.area){tempData.geometry.coordinates = this.props.area;}
      }
      else {
          if(data[keys[key]['name']]){
            data[keys[key]['name']]['features'].forEach(el => {  
            let color = '#202';
            
            if(keys[key]['name'] === 'dataBusinesses'){
              let tempDate = new Date(el['properties']['date']);
              tempDate = tempDate.setHours(tempDate.getHours() + 20);
              if(tempDate < this.t1 ){ color = "#ad182f"; }
              el['properties']['color'] = color;
            }
          });
        }
        
        switch(keys[key]['name']){
          case "dataPoints": 
            this.updatingDynamicLayers(keys[key]['name'], this.state.heatmapMode);
          break;
          case "dataBusinesses": 
            this.updatingDynamicLayers(keys[key]['name'], this.state.eventMode);
          break;
          default: 
            tempData = data[keys[key]['name']]; 
            if(data[keys[key]['name']] && this.map.getSource(keys[key]['name'] + '_source')){
            
              // console.log('SETDATA => ', keys[key], ' => ', tempData)
              this.map.getSource(keys[key]['name'] + '_source').setData(tempData);
            }
          break;
        }
      }
      
    }
  }

  updatingDynamicLayers(name, bVisible){
    let tempData = JSON2['data'];
    switch(bVisible){
      case true: tempData = this.data[name]; break;
      default: break;
    }
    // console.log('---> UPDATING SPECIFIC : ', name, ' ===> ',bVisible, ' => ', tempData, ' => ');
    if(tempData && this.map.getSource(name + '_source')){
      this.map.getSource(name + '_source').setData(tempData);
    }
  }


  //################### GENERATE POPUP ###################
  //################### GENERATE POPUP ###################
  displayPopUp(data){
    let data2 = JSON.parse(JSON.stringify(data));
    if(data2['properties']['images'] && typeof data2['properties']['images'] === 'string'){
      data2['properties']['images'] = JSON.parse(data2.properties.images)
    };
    data2['properties']['promotion'] = (typeof data2['properties']['promotion'] === 'string'? JSON.parse(data2.properties.promotion) : data2.properties.promotion);
    data2['properties']['business'] = (typeof data2['properties']['business'] === 'string'? JSON.parse(data2.properties.business) : data2.properties.business);
    data2['geometry'] =  (typeof data2.properties.position === 'string'? {coordinates: [JSON.parse(data2.properties.position)]} : {coordinates: [data2.properties.position]}); 
    const temp = formatData(this.state.eventMode, data2);
    this.props.onPopUp(temp);
    if(this.state.eventMode){manageEventViews({value:1, id_business: temp['business']['id_business'], id_event: temp['id_event']});}
  }


  displayBreakdown(data){
    const placeholder = document.createElement('div');
    // console.log('displayBreakdown => ', data);
    ReactDOM.render(<Breakdown data={data} eventMode={this.state.eventMode} selectEvent={(i) => this.displayPopUp(data[i])} />, placeholder);
    
    new mapboxgl.Popup({
      closeButton: true,
      closeOnClick: true,
    })
    .setLngLat(new mapboxgl.LngLat(data[0].geometry.coordinates[0], data[0].geometry.coordinates[1]))
    .setDOMContent(placeholder)
    .addTo(this.map);
  }
}

const mapDispatchToProps = dispatch => { 
  return {
    navigate: (direction) => dispatch(actions.navigate(direction))
  }
}

const mapStateToProps = (state) => {
  return { 
    profile: state.profile,
    settings: state.settings
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MapBox);
