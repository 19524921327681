import React, { Component } from 'react';
import { getBusinesses, manageBusinesses, generateReference, getImages } from "../services/business";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {Editor, EditorState} from 'draft-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Viewer from '../elements/viewer';
import Input from '../elements/input';
import InfoModal from "../elements/infoModal";
import ListApp from "../elements/list";
import MapBasic from '../geolocation/mapBasic';
import { geoCodeAddressStreet, jsonToGeoJSON, deltaCoordinates } from "../services/geolocation";
import { truncate, belongsTo, isTrue, styleMapping, isObjectEqual } from "../utils/tools";
import * as constants from "../../resources/constants/design";
import { toast } from 'react-toastify'; 
import MoonLoader from "react-spinners/ClipLoader";
import $ from 'jquery';


const options = { autoClose: 1500, type: toast.TYPE.INFO, hideProgressBar: false};
const addressFields = ['address', 'postcode', 'city', 'country'];

const resultsBusiness = [
  {
    id_business:'id', 
    companyname: 'name', 
    description: 'description', 
    address:'street', 
    postcode:'post code', 
    city:'city', 
    country:'counry'
  }
];

const fields = [ 
  {type:'column', label:'id_business', style:{width:'10%'}}, 
  {type:'column', label:'companyname', style:{width:'40%'}}, 
  {type:'column', label:'city', style:{width:'30%'}},
  {type:'action', label:'action', style:{width:'20%'}, options:{
    reset:{label:'fa fa-remove FS39 FCRed Padding0px', style:{width:'20%'}, action:'delete'},
    delete:{label:'fa fa-history FS39 FCBlue Padding0px', style:{width:'20%'}, action:'reset'},
    new:{label:'fa fa-remove FS39 FCGreen Padding0px', style:{width:'20%'}, action:'delete'}
  }}
];
const newBusiness = {id_business:'new',id_user:'', companyname: '', firstname: '', lastname: '', email: '', telephone: '', description: '', address:'', postcode:'', city:'', country:'', website:'', instagram:'', coordinates:"[0, 0]",  action: 'new', images: []};
const newValidFields = {companyname:false, address:false, postcode:false, city:false}; 
const oldValidFields = {companyname:true, address:true, postcode:true, city:true}; 
const width = $(window).width();

class Dashboard extends Component {  
  // ############################ CONSTRUCTOR ###################################
  // ############################ CONSTRUCTOR ###################################
  constructor(props) {
    super(props);
    this.validFields = oldValidFields; 
    this.state = {
      business: null, 
      businessOld: null, 
      selectedItem: null, 
      selectedData: null, 
      idBusiness: null, 
      geolocation: null, 
      bDisabled: false, 
      bSaved: true, 
      loading: false, 
      infoModal: null,
      images: null,
      imageLoading: false
    };

    
    this.setEditor = (editor) => { this.editor = editor; };
    this.onChange = (editorState) => {
      let temp = this.state.selectedData;
      temp.description = editorState;
      if((temp.id_business).toString().substring(0,3) !== 'new'){ temp.action = 'changed';}
      this.setState({selectedData: temp, bSaved:false});
    };
    if(width > 980){this.styleMap = {height:'35vh',width: '100%'};}
    else{this.styleMap = {height:'35vh',width: '100%'};}
  }

  async componentDidMount(){

  }


  // ##################################  MAP CHANGED  ####################################
  // ##################################  MAP CHANGED  ####################################
  isChanged = (coordinates, zoom) => {
    this.coordinates = coordinates;
    if(zoom == null){this.zoom = 16;}else {this.zoom = zoom;}
    const temp = deltaCoordinates(this.coordinates, this.Lastcoordinates);
    if (temp > 300){this.bRefresh = true;}
    this.coordinatesTrunc = {'latitude': truncate(coordinates['latitude'],3), 'longitude': truncate(coordinates['longitude'],3)}
    this.suggestions=[];
    this.preRender();
  }

  // ############################ GET DATA ###################################
  // ############################ GET DATA ###################################
  getData = async () => {
    if(this.props.user.idUser){
      let temp = JSON.parse(JSON.stringify(resultsBusiness));
      const business = await getBusinesses({id: this.props.user.idUser}, temp, '');
      const businessOld = JSON.parse(JSON.stringify(business));
      // console.log('DASHBOARD GETDATA => ', business, ' => ', business.size);
      if(business.length > 1){
        this.setState({business: business, businessOld: businessOld, selectedData : business[1], idBusiness : business[1].id_business});
        console.log('DASHBOARD GETDATA => 2');
        await this.handleSelect('select', business[1], 1);  
      }
      else{this.setState({business: business, businessOld: businessOld});}
    }
  }


  // ################################## PRERENDER  ####################################
  // ################################## PRERENDER  ####################################
  preRender = () => {    
    const data2 = jsonToGeoJSON(this.filteredDataPoints);
    const data = {
        latitude: this.coordinates['latitude'],
        longitude: this.coordinates['longitude'],
        dataPoints: data2
    }
    // console.log('PRERENDER 1: ', data);
    this.setState({geolocation: data}, function(){
    });
  }
  
  
  // ############################ RENDER ###################################
  // ############################ RENDER ###################################
  render() {
    // console.log('RENDER DASHBOARD => ', this.state.bSaved, ' : ', this.state.bDisabled);
    if(this.state.business && this.state.selectedItem){
      return (
          <div className={ this.props.settings.structure.outlet.class} style={styleMapping(this.props.settings.structure.outlet)}>
          <div className={"BRL Wide100 PaddingResponsive MaxWide1700px"} style={{height: '97vh', overflow: "hidden"}} >   
            
            <InfoModal type={'dashboard'} show={(this.state.business.length < 2 && this.state.infoModal === null ? true : false)} onClose={() => {this.setState({infoModal: false})}} />
            
            <div className={"BRL col-lg-4 col-md-4 col-sm-12 VPadding0px HPadding10px BottomMargin20px"} style={{minHeight:'50px', maxHeight:"100%"}}>   
              <div className={"BRL Wide100 "} >
                <div className={"BRL Wide100 BorderRadius5 BottomShadow7 FS40 TitleFont Padding5px Capital BottomMargin10px CenterAlign Hand " + (this.state.bSaved || this.state.bDisabled ? (this.state.bDisabled ? 'FCWhite BCPrimaryDisabled': '') : 'FCWhite BCPrimary' )} onClick={() =>{if(!this.state.bSaved && !this.state.bDisabled ){this.handleSubmit();}}}>
                  <div className="BRL Wide90 VPadding8px">{(this.state.bSaved? 'Businesses saved' : 'save Businesses')}</div>
                  <div className="BRL Wide10 VPadding5px"><MoonLoader size={20} color={"#ffffff"} loading={this.state.loading}/></div>
                </div>
              </div>   
              
              <div className={"BRL Wide100 BottomShadow7 "} style={{display: 'flex', flexDirection: 'column' }}>   
                <div className={"BRL Wide100 OverflowAuto"} style={{ height:'70vh' }}>   
                  <ListApp type={"dashboard"} fields={fields} styleList={{class:"BRL Wide100 BottomShadow7", style:""}} data={this.state.business} onSelected={(action, data, index) =>{this.handleSelect(action, data, index)}} selectedItem={this.state.selectedItem} />
                </div>
                <div className={"BRL Wide100"} style={{ }}>   
                  <button className={"BRL Wide100 BottomShadow7 FS40 TitleFont btn btn-light Padding10px Capital NoBorder NoOutline"} onClick={() =>{this.handleSelect('new', null, null)}} >create business</button>   
                </div>
              </div>
            </div>

            <div className={"BRL col-lg-8 col-md-8 col-sm-12 VPadding0px HPadding10px"} style={{height:"100%", overflow:"auto"}}>   
              {this.state.business.length > 1 && <React.Fragment>
              
                <div className="BRL Wide100 HPadding10px BottomMargin10px BottomMargin5px">
                  <div className="BRL Wide100 Padding10px BottomShadow7 BCGray1">
                    <MapBasic styleMap={this.styleMap} data={JSON.parse(this.state.business[this.state.selectedItem]['coordinates'])} zoom={16} />
                  </div>    
                </div>    

                <div className="BRL Wide100 HPadding10px BottomMargin10px">
                  <div className="BRL Wide100 Padding20px BottomShadow7 BCGray1">
                    <div className="BRL FS39 TitleFont">PICTURES</div>
                    {this.state.images && <Viewer styleList={constants.VIEWER_DASHBOARD} 
                            id={this.state.idBusiness}
                            loading={this.state.imageLoading}
                            images={this.state.images} 
                            onAction={(action, index) => { this.handleImages(action, index)}} 
                    /> }        

                    {!this.state.images && <div className="BRL Wide100 VPadding10px HPadding20px viewer_text">
                      <React.Fragment>
                          <div className="BRL mr-1 p-1 Bold">Loading Images</div>
                          <div className="BRL p-1"><MoonLoader size={20} color={"#ad182f"} loading={true}/></div>
                        </React.Fragment>
                    </div>}
                  </div>
                </div>

                <div className={"BRL col-lg-6 col-md-6 col-sm-12 VPadding0px HPadding10px"} >   
                  <div className={"BRL Wide100 Padding20px BottomShadow7 BCGray1"} >   
                    <div className="BRL FS39 TitleFont">COMPANY</div>
                    <Input onChange={(type, field, e, validation) => {this.handleInput(type, field, e); this.manageFields(field, validation);}} value={this.state.business[this.state.selectedItem].companyname} config={constants.DASHBOARD.companyname} disabled={false} />
                    {/* <Input onChange={(type, field, e, validation) => {this.handleInput(type, field, e); this.manageFields(field, validation);}} value={this.state.business[this.state.selectedItem].firstname} config={constants.DASHBOARD.firstname} disabled={false} />
                    <Input onChange={(type, field, e, validation) => {this.handleInput(type, field, e); this.manageFields(field, validation);}} value={this.state.business[this.state.selectedItem].lastname} config={constants.DASHBOARD.lastname} disabled={false} /> */}
                    {/* <Input onChange={(type, field, e, validation) => {this.handleInput(type, field, e); this.manageFields(field, validation);}} value={this.state.business[this.state.selectedItem].email} config={constants.DASHBOARD.email} disabled={false} /> */}
                    <Input onChange={(type, field, e, validation) => {this.handleInput(type, field, e); this.manageFields(field, validation);}} value={this.state.business[this.state.selectedItem].telephone} config={constants.DASHBOARD.telephone} disabled={false} />
                    <Input onChange={(type, field, e, validation) => {this.handleInput(type, field, e); this.manageFields(field, validation);}} value={this.state.business[this.state.selectedItem].instagram} config={constants.DASHBOARD.instagram} disabled={false} />
                    <Input onChange={(type, field, e, validation) => {this.handleInput(type, field, e); this.manageFields(field, validation);}} value={this.state.business[this.state.selectedItem].website} config={constants.DASHBOARD.website} disabled={false} />
                  </div>  
                                  
                  {/* <div className="BRL Wide100 Padding20px BottomShadow7 TopMargin20px BottomMargin20px BCGray1">
                    <div className="BRL FS39 TitleFont">DESCRIPTION</div>
                    <div className="BRL Wide100 Padding10px TopMargin10px btn-light FS39">
                      <Editor ref={this.setEditor} editorState={this.state.business[this.state.selectedItem].description} onChange={this.onChange}   />
                    </div>
                  </div> */}
                </div>
                <div className={"BRL col-lg-6 col-md-6 col-sm-12 VPadding0px HPadding10px"} >   
                  <div className={"BRL Wide100 Padding20px BottomShadow7 BCGray1"} >   
                    <div className="BRL Wide100 FS39 TitleFont ">ADDRESS</div>
                    <Input onChange={(type, field, e, validation) => {this.handleInput(type, field, e); this.manageFields(field, validation);}} value={this.state.business[this.state.selectedItem].address} config={constants.DASHBOARD.address} disabled={false} />
                    <Input onChange={(type, field, e, validation) => {this.handleInput(type, field, e); this.manageFields(field, validation);}} value={this.state.business[this.state.selectedItem].postcode} config={constants.DASHBOARD.postcode} disabled={false} />
                    <Input onChange={(type, field, e, validation) => {this.handleInput(type, field, e); this.manageFields(field, validation);}} value={this.state.business[this.state.selectedItem].city} config={constants.DASHBOARD.city} disabled={false} />
                    <Input onChange={(type, field, e, validation) => {this.handleInput(type, field, e); this.manageFields(field, validation);}} value={this.state.business[this.state.selectedItem].country} config={constants.DASHBOARD.country} disabled={false} />
                    <div className="BRL Wide100 FS40 TitleFont TopMargin30px">{this.state.business[this.state.selectedItem].coordinates}</div>
                  </div>
            
                  {/* <div className="BRL Wide100 Padding20px BottomShadow7 TopMargin20px BCGray1">
                    <div className="BRL FS39 TitleFont">PICTURES</div>
                    {this.state.images && <Viewer styleList={constants.VIEWER_DASHBOARD} 
                            id={this.state.idBusiness}
                            loading={this.state.imageLoading}
                            images={this.state.images} 
                            onAction={(action, index) => { this.handleImages(action, index)}} 
                    /> }        

                    {!this.state.images && <div className="BRL Wide100 VPadding10px HPadding20px viewer_text">
                      <React.Fragment>
                          <div className="BRL mr-1 p-1 Bold">Loading Images</div>
                          <div className="BRL p-1"><MoonLoader size={20} color={"#ad182f"} loading={true}/></div>
                        </React.Fragment>
                    </div>}
                  </div> */}
                </div>
            
                <div className="BRL Wide100 Padding20px BottomShadow7 TopMargin20px BottomMargin20px BCGray1">
                  <div className="BRL FS39 TitleFont">DESCRIPTION</div>
                  <div className="BRL Wide100 Padding10px TopMargin10px btn-light FS39">
                    <Editor ref={this.setEditor} editorState={this.state.business[this.state.selectedItem].description} onChange={this.onChange}   />
                  </div>
                </div>

                </React.Fragment>}

            </div>  
        </div>  
        </div>  
      );
    } else {
      this.getData();
      return ( 
        <div className={"Flex FlexCol JustifyCenter AlignCenter High100VH"} style={{backgroundColor: "rgba(240,240,240,0.5)"}}>
                        <div className="Monoton" style={{fontSize:'3rem', color:"#ad182f"}}>STRANGERS</div>
          <div className={""}><FontAwesomeIcon className={"FS10 Spin"} icon={"compass"} color="#ad182f" /></div>
        </div>
        );
    }

  }

  // ############################ HANDLE CHANGE ###################################
  // ############################ HANDLE CHANGE ###################################
  handleInput = (type, field, data)  => {

    let temp = this.state.business;
    temp[this.state.selectedItem][field] = data;
    // console.log('HANDLE INPUT 0 => ', temp[this.state.selectedItem], ' : ', field, ' => ', data);
    if(temp[this.state.selectedItem].action !== 'new' && temp[this.state.selectedItem].action !== 'delete' ){temp[this.state.selectedItem].action = 'changed';} 
    
    if(belongsTo(field, addressFields)){this.getGPSCoordinates();}
    this.setState({business: temp, bSaved: false});  
  }
  
  // ############################ HANDLE IMAGES ###################################
  // ############################ HANDLE IMAGES ###################################
  handleImages = (action, data)  => {
    
    let temp = this.state.business;
    let tempImages = this.state.images;

    switch(action){
      default:break;
        case "new":
          tempImages.push({url: data, action:'new', name:generateReference(14) + '.png' });
          temp[this.state.selectedItem].images = JSON.parse(JSON.stringify(tempImages));
        break;
        case "delete":
          if(tempImages[data].action !== 'new'){ 
            tempImages[data].action = 'delete';
            temp[this.state.selectedItem].images[data].action = 'delete';
          } 
          else {
            tempImages.splice(data,1);
            temp[this.state.selectedItem].images.splice(data,1);
          }
        break;
        case "reset": 
          tempImages[data].action = ''; 
          temp[this.state.selectedItem].images[data].action = '';
        break;
    }

    console.log('HANDLEIMAGE => ', temp , ' => ', this.state.images);
    const temp2 = this.changeStatus(temp, this.state.businessOld);
    this.setState({business: temp, images: tempImages, bSaved: temp2});  
  
  }

  
  // ############################ HANDLE LIST SELECTION ###################################
  // ############################ HANDLE LIST SELECTION ###################################
  handleSelect = async (action, data, index)  => {
    
    let temp = this.state.business;
    let temp2 = this.state.bSaved;
    
    switch(action){
      default:break;
      case "select": 
        
        if(index != this.state.selectedItem){
          this.getImages(data);
          this.setState({selectedData: data, selectedItem: index, idBusiness : data.id_business, images : null});
          if(Number.isInteger(data['id_business']) !== 'new'){this.validFields = oldValidFields;}
        }  

      break;
      case "delete":
        if(temp[index].action === 'new'){
          temp.splice(index, 1);
          if(this.state.selectedItem !== index){index = this.state.selectedItem}
          else {index = index - 1};
        } else {
          if(window.confirm('Are you sure to remove this business? The change will be definitely processed when you save your profile')){
            temp[index].action = 'delete';
          }
        }
        temp2 = this.changeStatus(temp, this.state.businessOld);
      break;
        
      case "new":
        let newBusiness2 = JSON.parse(JSON.stringify(newBusiness));
        this.validFields = newValidFields;
        this.setState({bDisabled: true, selectedData: newBusiness2});
        index = temp.length;
        newBusiness2.id_user = this.props.user.idUser;
        newBusiness2.id_business = newBusiness2.id_business + temp.length;
        newBusiness2.description = EditorState.createEmpty();
        // newBusiness2.description = EditorState.createWithContent(ContentState.createFromText(""));
        temp.push(newBusiness2);
        temp2 = this.changeStatus(temp, this.state.businessOld);
      break;
      
      case "reset":
        temp[index].action = '';
        if(this.state.selectedItem !== index){index = this.state.selectedItem}
        temp2 = this.changeStatus(temp, this.state.businessOld);

      break;
    }
    // console.log('CREATING NEW BUSINESS => ', index, ' => ', temp, ' => ', temp[index]);
    this.setState({selectedItem: index, business: temp, bSaved: temp2});
  }

  // ############################# CHANGE EVENT STATUS #############################
  // ############################# CHANGE EVENT STATUS #############################
  changeStatus = (data, old) => {
    
    let temp_1 = JSON.parse(JSON.stringify(data));
    let tempOld_1 = JSON.parse(JSON.stringify(old));
    const temp3 = isObjectEqual(temp_1, tempOld_1);
    // console.log('CHANGE STATUS 3 => ', temp_1, ' ==> ', tempOld_1, ' => ', temp3);
    return temp3;
  }

  // ############################ HANDLE LIST SELECTION ###################################
  // ############################ HANDLE LIST SELECTION ###################################
  handleSubmit = async ()  => {
    
    this.setState({ loading: true });
    let temp = this.state.business;

    try{
      temp = await manageBusinesses(temp);
      const temp2 = JSON.parse(JSON.stringify(temp));
      
      // console.log('POST SAVING 2 => ', temp);
      setTimeout(() => {
        // this.setState({ business: temp, businessOld: temp2, selectedItem: 1 , idBusiness: temp[0].id_business, loading: false, bSaved: true });
        this.setState({ business: temp, businessOld: temp2, loading: false, bSaved: true });
        toast.success("Business data was successfuly saved", options)
      }, 200);
  
    } catch(err){
      console.log('ERROR SAVING BUSINESS : ', err);
      this.setState({loading: false });
      toast.error("Saving business failed. Check your internet connection and try again", options)
    }
    

  }
  
  // ############################ GETGPS COORDINATES ###################################
  // ############################ GETGPS COORDINATES ###################################
  getGPSCoordinates = async ()  => {
    let searchText = "";

    for (const key in addressFields){searchText += this.state.selectedData[addressFields[key]] + " ";}
    if(searchText.length>10){
        let coordinates = await geoCodeAddressStreet(searchText, null);
        // console.log('GEOCODING 1 => ', coordinates);
      
      if(coordinates !== null && coordinates){
        this.coordinates = {'latitude': coordinates[0]['center'][1], 'longitude': coordinates[0]['center'][0]}
        let temp = this.state.business;
        temp[this.state.selectedItem]['coordinates'] = JSON.stringify([this.coordinates['latitude'], this.coordinates['longitude']]);
        this.setState({business: temp});  
      }
    
    }
  }

  //############################# HANDLE CHANGE #############################
  //############################# HANDLE CHANGE #############################
  manageFields = (type, val) => {
    this.validFields[type] = val;
    // console.log('MANAGE FIELDS => ', this.validFields, ' => ',isTrue(this.validFields));
    this.setState({bDisabled: !isTrue(this.validFields)});
  }



  //############################# GET IMAGES ASYNC #############################
  //############################# GET IMAGES ASYNC #############################
  getImages = async (data) => {
    // console.log('GETTING IMAGES 1 => ', data);
    let temp = JSON.parse(JSON.stringify(data))
    let tempBusiness = this.state.business;
    
    let images = await getImages(data.id_business);
    if(Object.keys(images).length === 0){ 
      images = {[data.id_business]: []} 
    };
    temp['images'] = images[data.id_business];
    tempBusiness[this.state.selectedItem]['images'] = images[data.id_business];
    // console.log('GETIMAGES 3 => ',this.state.selectedItem, ' => ', tempBusiness[this.state.selectedItem]);

    this.setState({business: tempBusiness, selectedData : temp, images: images[data.id_business]});
  }

}
      
const mapDispatchToProps = dispatch => {
  return {
    // navigate: (direction, history) => dispatch(actions.navigate(direction, history)), 
    // logout: (history) => dispatch(actions.authLogout(history)),
  }
}
const mapStateToProps = (state) => { 
    // console.log('REDUX DASHBOARD => ', state.user);
    return {isAuthenticated: state.status.token !== null && state.status.token !== '' ? true : false, status:state.status, user:state.user};
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
      
      
      