import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/pseudo/general';
import { styleMapping } from "../utils/tools";

class Support extends React.Component {
    
    //############################ CONSTRUCTOR ###################################
	//############################ CONSTRUCTOR ###################################
	constructor(props) {
        super(props);
        this.state = { personal: null };
    }  

    async componentDidMount(){
        if(this.props.settingsManual){
            const temp = JSON.parse(this.props.settingsManual);
            this.setState({personal: temp});
        } 
        else {this.setState({personal: this.props.settings.personal});}
    }
    
    UNSAFE_componentWillReceiveProps(props){
        if(props.settingsManual){            
            const temp = JSON.parse(props.settingsManual);
            this.setState({personal: temp});
        }
    }

    
    // ############################ RENDER ###################################
    // ############################ RENDER ###################################
    render() {
        // console.log('SUPPORT : RENDER => ' , this.props);
        const { architecture, custom } = this.props;

        let personal;
        if(this.state.data){ personal = this.state.personal; }
        else { personal = this.props.settings.personal; }
                        
        if(personal){
                return (
                    <div className={"BRL Wide100"} >
                        
                        {custom == null && <div className={personal.user.class} style={styleMapping(personal.user)} id="dropdownSupport" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Support</div> }
                        {custom != null && <div className={custom.element.class} style={styleMapping(custom.element)} id="dropdownSupport" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Support</div> }
                    
                        <div className={personal.user_menu.class} style={styleMapping(personal.user_menu)} aria-labelledby="dropdownSupport">
                        {architecture.support ?
                            Object.keys(architecture.support).map((part,index)=>{
                                return (
                                    <Link key={"support_" + index}className="BRL Wide100" to={"/" + architecture.support[part]['link']}><div className={personal.user_menu_element.class} style={styleMapping(personal.user_menu_element)}>{architecture.support[part]['name']}</div></Link>
                                );
                            }) : null }
                        </div>
                    </div>
                );                    
            }
    }
}

const mapStateToProps = (state) => {return { isAuthenticated: state.status.token !== null && state.status.token !== '' ? true : false, status:state.status, user:state.user, settings:state.settings, architecture:state.architecture};}
const mapDispatchToProps = dispatch => { return { logout: (history) => dispatch(actions.authLogout(history))} }
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Support));

