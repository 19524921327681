import React from 'react';
import Logo from '../core/logo';
import Menu from '../core/menu';
import Support from '../core/support';
import Personal from '../core/personal';
import { styleMapping } from "../utils/tools";
import * as actions from '../../store/pseudo/general';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class NavBar extends React.Component {

    //############################ CONSTRUCTOR ###################################
	//############################ CONSTRUCTOR ###################################
	constructor(props) {
        super(props);
        this.state={ setting: null };
    }  

    async componentDidMount(){
        if(this.props.settingsManual){this.setState({settings: this.props.settingsManual});} 
        else {this.setState({settings: this.props.settings});}
    }
    UNSAFE_componentWillReceiveProps(){this.setState({settings: this.props.settingsManual});}


    // ############################ RENDER ###################################
    // ############################ RENDER ###################################
    render() { 
        let settings;
        if(this.state.settings){ settings = this.state.settings; }
        else { settings = this.props.settings; }
              
        
        if(settings){
            const {structure, logo, menu, personal, support} = settings;
            // console.log('NAVBAR SETTINGS => ', settings);
            return (
                <div id="logo_container" className={"logo_container " + structure.logo_container.class} style={styleMapping(structure.logo_container)}>
                    <nav className={"LOGO navbar navbar-expand-lg MarginAuto " + structure.logo.class} style={styleMapping(structure.logo)}>
                        <Link className="BRL" to={"/"}>
                            <Logo settingsManual={JSON.stringify(logo)}></Logo>
                        </Link>

                        <div className="collapse navbar-collapse BorderRadius10 MobileMenu" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item">
                                    <Menu settingsManual={JSON.stringify(menu)}></Menu>
                                </li>
                                <li className="nav-item">
                                    <Support settingsManual={JSON.stringify(support)}></Support>
                                </li>
                                <li className="nav-item">
                                    <Personal settingsManual={JSON.stringify(personal)}></Personal>
                                </li>
                            </ul>
                        </div>
                    </nav>                        
                </div>
            );
        } else return null; 
    }
}


const mapstateToProps = (state) => {
    return {isAuthenticated: state.status.token !== null && state.status.token !== '' ? true : false, status:state.status, sideMenu:state.sideMenu, user:state.user, architecture:state.architecture, settings:state.settings};}
const mapDispatchToProps = dispatch => {return {navigate: (direction, history) => dispatch(actions.navigate(direction, history)), sideMenuFunction: (bVal) => dispatch(actions.sideMenu(bVal))}}
export default withRouter(connect(mapstateToProps, mapDispatchToProps)(NavBar));

