import React, { Component } from "react";
import { Input } from "reactstrap";
import { toast } from 'react-toastify';
import { withRouter } from "react-router-dom";
import { styleMapping, selectListGenerator, getEquivalent, generateReference } from "../utils/tools";
import { getArchitecture, setArchitecture, getStructureCatalog, editorMenuPosition, structureAction } from "../services/editor";
import { getStructure } from "../services/general";
import Canvas from "../design/canvas";
import Select from "../elements/select";
import MoonLoader from "react-spinners/ClipLoader";

const options = { autoClose: 1500, type: toast.TYPE.INFO, hideProgressBar: false };
const selectFields = [['type', 'type'], ['reference_name', 'name']];

class Editor extends Component {
    // ############################ CONSTRUCTOR ###################################
    // ############################ CONSTRUCTOR ###################################
    constructor(props) {
        super(props);
        this.state = { 
            architecture: null, selectedMain: null, selectedLink: null, selectedLinkMenu:null, selectedLinkData: null, selectedLinkStructure: null, 
            bSaved: true, bStructSaved: true, new: '', structureName: '', structureCatalog: null, saving: false
        };
      }

    async componentDidMount() {
        await this.getArchitecture();
    }

      
    // ############################ ARCHITECTURE ###################################
    // ############################ ARCHITECTURE ###################################
    getArchitecture = async () => {
        let results = await getArchitecture();
        results = results.results.architecture;
        // console.log('EDITOR GETARCHITECTURE 2 : ', results, ' => ' , results[Object.keys(results)[0]]);
        this.setState({architecture:  results, selectedMain:'menu'});
        this.getDetails('menu', 0);
    };

    setArchitecture = async () => {
        const results = await setArchitecture(this.state.architecture);
        switch(results){
            case true: toast.success("Architecture has been saved", options); this.saveStatus(true); break;
            default: toast.error("An error occured : " , results);break;
        }
        this.setState({saving: false});
        this.getDetails(this.state.selectedMain, this.state.selectedLink);
    };

    // ############################ LINKS ###################################
    // ############################ LINKS ###################################
    getLinks = async (main) => {
        // console.log('GETLINKS => ', Object.keys(this.state.architecture[main])[0]);
        this.setState({selectedMain: main});
        this.getDetails(main, 0);
    };

    getDetails = async (main, name) => {
        const temp = await getStructureCatalog();
        // console.log('#### GETDETAILS 0 => ', temp, ' => ', main, ' => ', name , ' => ',this.state.architecture[main][name]['type']);
        const tempList = selectListGenerator(temp.results[this.state.architecture[main][name]['type']], 'reference_name', 'reference');
        
        // console.log('#### GETDETAILS 1 => ', tempList);
        
        if(this.state.architecture[main][name]['type'] === 'custom'){
            const temp2 = await getStructure(this.state.architecture[main][name]['type'], this.state.architecture[main][name]['reference']); 
            // console.log('GETDETAILS 2 => ', temp2);
            this.setState({selectedLink: name, selectedLinkData: this.state.architecture[main][name], structureCatalog: temp.results, customList: tempList, selectedLinkStructure: temp2});
        }else{
            this.setState({selectedLink: name, selectedLinkData: this.state.architecture[main][name], structureCatalog: temp.results, customList: tempList});
        }
    
    
    };

    modifyArchitecture = async (action, data) => {
        let main = this.state.selectedMain;
        let archi = this.state.architecture;
        let temp = JSON.parse(JSON.stringify(data));
        switch(action){
            case 'add': archi[main].push({"name": this.state.new, "link": "", "animation": "", "reference": "", "reference_name": "", "type": "custom"}); break;
            case 'duplicate': temp['name'] = temp['name'] + '_D'; archi[main].push(temp); break;
            case 'remove':
                archi[main].splice(data, 1); break;
            case 'position': 
                archi[main] = editorMenuPosition(archi[main], data); 
            break;
            default: break;
        }
        this.setState({architecture: archi, new: '', selectedLinkMenu: null, bSaved: false});
    };


    // ############################ ARCHITECTURE + STRUCTURE META CATALOG ###################################
    // ############################ ARCHITECTURE + STRUCTURE META CATALOG ###################################
    handleStructureNameChange = async (e) => {
        let selectedLinkData = this.state.selectedLinkData;
        selectedLinkData['reference_name'] = e.target.value;
        this.setState({selectedLinkData: selectedLinkData, bStructSaved: false});
    }

    modifyStructure = async (action) => {
        
        let archi = this.state.architecture;
        let tempCustom =  this.state.customList;
        let tempCatalog = this.state.structureCatalog;
        let tempStruct = this.state.selectedLinkData;
        let tempStructDetails = this.state.selectedLinkStructure;
        let bSaved = false;
        let reference = null;
        
        // console.log('#### MODIFY STRUCTURE => ', tempStruct);
        
        switch(action){
            //SAVE STRUCTURE FOR structure_catalog,json when structure has had a design change/ name change 
            case 'save':
                this.setState({saving: true});
                archi[this.state.selectedMain][this.state.selectedLink] = tempStruct;
                // console.log('SAVE STRUCTURE => ', tempStruct , ' => ', tempStruct['reference'], ' |||| ', tempCatalog[tempStruct['type']], ' => ', tempCatalog[tempStruct['type']][tempStruct['reference']]);
                tempCatalog[tempStruct['type']][tempStruct['reference']]['reference_name'] = this.state.selectedLinkData['reference_name']; 
                tempStructDetails['structure']['name'] = this.state.selectedLinkData['reference_name'];  
                reference = tempStruct['reference'];
                break;
            //DUPLICATE STRUCTURE
            case 'duplicate':
                reference = generateReference(20);
                let temp = {};
                tempStruct['reference'] = reference;
                tempStruct['reference_name'] = tempStruct['reference_name'] + '_' + generateReference(3);
                if(this.state.selectedMain.toLowerCase() !== 'home'){tempStruct['link'] = tempStruct['type'] + '/' + reference;}
                temp['reference'] = reference;

                temp['reference_name'] = tempStruct['reference_name'];
                temp['type'] = tempStruct['type'];
                tempStructDetails['structure']['reference'] = reference;
                tempStructDetails['structure']['name'] = tempStruct['reference_name'];

                tempCatalog['custom'][reference] = temp;                 
                archi[this.state.selectedMain][this.state.selectedLink]['reference_name'] = tempStruct['reference_name'];
                archi[this.state.selectedMain][this.state.selectedLink]['reference'] = tempStruct['reference'];
                
                if(this.state.selectedMain.toLowerCase() !== 'home'){archi[this.state.selectedMain][this.state.selectedLink]['link'] = tempStruct['link'];}
                break;
            // REMOVE STRUCTURE
            case 'remove':
                // console.log('EDITOR REMOVE STRUCTURE 1 => ', tempCatalog['custom'], ' => ', this.state.selectedLinkData['reference']);
                reference = this.state.selectedLinkData['reference'];
                delete tempCatalog[tempStruct['type']][reference];
                // console.log('NEW CATALOG => ', tempCatalog['custom'], ' => ', archi[this.state.selectedMain][this.state.selectedLink]);
                archi[this.state.selectedMain][this.state.selectedLink]['reference'] = tempCatalog['custom'][Object.keys(tempCatalog['custom'])[0]]['reference'];
                archi[this.state.selectedMain][this.state.selectedLink]['reference_name'] = tempCatalog['custom'][Object.keys(tempCatalog['custom'])[0]]['reference_name'];
                if(this.state.selectedMain.toLowerCase() !== 'home'){archi[this.state.selectedMain][this.state.selectedLink]['link'] = tempCatalog['custom'][Object.keys(tempCatalog['custom'])[0]]['type'] + '/' + tempCatalog['custom'][Object.keys(tempCatalog['custom'])[0]]['reference'];}
                break;
            default: break;
        }
        
        //SAVES STRUCTURE DATA META + UPDATES DD LIST
        // console.log('EDITOR SET STRUCTURE 4 => ', reference);
        await this.setStructure('details', action, tempStructDetails, reference);        
        await this.setStructure('meta', action, tempCatalog, null);
        tempCustom = selectListGenerator(tempCatalog['custom'], 'reference_name', 'reference');
        this.setState({architecture: archi, new: '', customList: tempCustom, structureCatalog: tempCatalog, selectedLinkStructure: tempStructDetails, bSaved: bSaved, bStructSaved: true});
        this.setArchitecture();
    };

    setStructure = async (type, action, data, reference) => {   
        const results = await structureAction(type, action, data, reference);
        // console.log('EDITOR : SET STRUCTURE => ', type, ' : ', data, ' : ', results );
        switch(results.results){
            case true: 
                switch(type){
                    case 'meta': 
                        toast.success("Structure " + type + " have been saved", options);
                        this.setState({bStructSaved: false});
                     break;
                    case 'details': 
                        this.setState({selectedLinkStructure: data, bStructSaved: false}); break;
                    default: break;}        
            break;
            default: 
                switch(type){
                    case 'meta':toast.error("An error occured : " , results); break;
                    default: break;}        
            break;
        }
    };

    // ############################ HANDLE CHANGE STRUCTURE DETAILS ###################################
    // ############################ HANDLE CHANGE STRUCTURE DETAILS ###################################
    // SELECT STRUCTURE CHANGE
    handleChangeStructure = async (field, value)  => {
 
        let archi = this.state.architecture;
        const tempCatalog = await getStructureCatalog();
        const tempList = selectListGenerator(tempCatalog.results[value.value], 'reference_name', 'reference');
        
        switch(field){
            default:break
            case 'reference_name':
                const type = archi[this.state.selectedMain][this.state.selectedLink]['type'];
                // console.log('HANDLECHANGESTRUCTURE => ', type, field, value);
                archi[this.state.selectedMain][this.state.selectedLink]['reference'] = value.value;
                archi[this.state.selectedMain][this.state.selectedLink]['reference_name'] = value.label;
                if(this.state.selectedMain.toLowerCase() !== 'home'){archi[this.state.selectedMain][this.state.selectedLink]['link'] = this.state.selectedLinkData['type'] + '/' + value.value; }
                if(type === 'custom'){
                    const temp = await getStructure(this.state.architecture[this.state.selectedMain][this.state.selectedLink]['type'], archi[this.state.selectedMain][this.state.selectedLink]['reference']);
                    this.setState({architecture: archi, selectedLinkData: archi[this.state.selectedMain][this.state.selectedLink], selectedLinkStructure: temp, bSaved: false});    
                } else {this.setState({architecture: archi, selectedLinkData: archi[this.state.selectedMain][this.state.selectedLink], bSaved: false});}
                break;
            case 'type':
                archi[this.state.selectedMain][this.state.selectedLink]['type'] = value.value;
                archi[this.state.selectedMain][this.state.selectedLink]['reference'] = tempList[0].value;
                archi[this.state.selectedMain][this.state.selectedLink]['reference_name'] = tempList[0].value;
                if(this.state.selectedMain.toLowerCase() !== 'home'){archi[this.state.selectedMain][this.state.selectedLink]['link'] = value.value; }
                // console.log('CHANGING TYPE 2 => ', archi[this.state.selectedMain][this.state.selectedLink]);
                this.setState({architecture: archi, selectedLinkData: archi[this.state.selectedMain][this.state.selectedLink], structureCatalog: tempList, customList: tempList, bSaved: false});    
                break;
            }
            this.saveStatus(false);
    }
    
    // FIELD CHANGE
    handleChange = (field, val)  => {
        switch(field){
            default:
                let archi = this.state.architecture;
                archi[this.state.selectedMain][this.state.selectedLink][field] = val.target.value;
                this.setState({architecture: archi, selectedLinkData: archi[this.state.selectedMain][this.state.selectedLink]});    
                this.saveStatus(false);
            break;
        }     
    }

    // STRUCTURE DETAILS CHANGE FOR STRUCT CATALOG
    updateStructureDetails = async (data)  => {
        // console.log('CANVAS ONCHANGE 1 => ', this.state.selectedLinkData, '  =>  ', this.state.selectedLinkStructure);
        this.setState({selectedLinkStructure: data, bStructSaved: false}, function(){ 
            this.modifyStructure('save'); 
        });
    }

    // ############################ RENDER ###################################
    // ############################ RENDER ###################################
    render(){

        if(this.state.architecture){  
            // console.log('EDITOR RENDER => ', this.state.selectedLinkStructure);
            return (  
                <div className={ this.props.settings.structure.outlet.class} style={styleMapping(this.props.settings.structure.outlet)}>      
                    
                    {/* SAVE BUTTON  */}
                    <button key={'save_editor'} onClick={() => { this.setArchitecture() }} className={"BRR Wide200px Padding10px FS39 ButtonFont btn " + (this.state.bSaved ? 'btn-primary' : 'btn-danger')}>{(this.state.bSaved ? 'No Changes' : 'Save Changes')}</button>            
                    
                    {/* MAIN ELEMENTS  */}
                    <div className="BRL Wide100 HPadding10px">
                        {Object.keys(this.state.architecture).map(key => {
                            return (
                            <li key={'m_'+ key} className={"BRL col-lg-2 col-md-3 col-sm-4 BottomShadow7 LeftPadding0px RightPadding3px BottomMargin5px d-flex justify-content-between align-items-center"}>
                                <div onClick={() => { this.getLinks(key) }} className={"btn BRL Wide100 VPadding10px HPadding20px ButtonFont Bold Capital  Hand FS40 " + (key === this.state.selectedMain ? 'btn-primary' : 'btn-light')}>
                                    <span className="BRL Wide80">{key}</span>
                                    {this.state.bEditMode && <button onClick={() => { this.updateItems('remove', key, '') }} color="danger" size="sm"  className="Absolute Right5px Padding0px TopMargin5px FS42"><i className="fa fa-remove"></i></button>}
                                </div>
                            </li>
                            );})}
                    </div>
                    {/* MAIN SUBELEMENTS */}
                    <div className="BRL Wide100 HPadding10px TopMargin10px">
                        {this.state.architecture[this.state.selectedMain].map((key, index) => {
                            return (this.renderMenuElement(key, index));
                        })}

                        {this.state.selectedMain !== "home" && <li key={'mainAdd'} className={"BRL col-lg-2 col-md-3 col-sm-4 BottomShadow7 LeftMargin2px HPadding0px BottomMargin5px d-flex justify-content-between align-items-center "}>
                            <div className={"btn BRL Wide100 Padding0px ButtonFont Bold Capital Hand FS40 btn-light"}>
                                <input type="text" className="BRL Wide80 HPadding5px VPadding10px NoBorder NoOutline BCInherit" value={this.state.new} placeholder="new link" onChange={(e) => this.setState({new: e.target.value})}/>
                                <button disabled={this.state.new === ''} onClick={() => { this.modifyArchitecture('add', null) }} className="BRL Wide20 HPadding0px NoBorder NoOutline btn btn-primary"><i className="FS38 fa fa-plus"></i></button>
                            </div>
                        </li>}
                    </div>
                
                    {/* SUBELEMENTS DETAILS */}
                    {this.state.selectedLinkData && <div className="BRL Wide100 Padding10px TopMargin10px BottomShadow7">
                        {Object.keys(this.state.selectedLinkData).map(key => {
                        return (
                            <React.Fragment key={ 'r_' + key}>
                                {(key !== 'reference' && key !== 'animation') && 
                                <li key={ 'c_' + key} className="BRL col-lg-2 col-md-2 col-sm-3 NoBorder TopMargin5px HPadding5px" style={{listStyleType: 'none'}}>
                                    <div key={ 'l_' + key} className="BRL Wide100 BottomMargin2px ButtonFont Capital FS41">{getEquivalent(key, selectFields, 1)}</div>             
                                    {(key !== 'type' && key !== 'reference' && key !== 'reference_name') && <Input disabled={key === 'link'} key={'f1_'+ key} type="text" name={key} onChange={(e) => this.handleChange(key,e)} value={this.state.selectedLinkData[key]} className="BRL Wide100 NoBorder btn-light BCTrans InputFont FS40 TopMargin5px BottomShadow7"/>}
                                    {(key === 'reference_name') && <Select key={'f2_'+ key} classes={{container:"BRL form-control NoBorder FS40 LabelFont Padding3px btn-light BCTrans", options:"BRL Wide100 TopMargin5px CenterAlign BottomShadow7"}} name={key} id={getEquivalent(key, selectFields, 1)} fields={'custom'} list={this.state.customList}  value={this.state.selectedLinkData['reference']} onChange={(name, val) => this.handleChangeStructure(name, val)}></Select>}
                                    {(key === 'type') && <Select key={'f3_'+ key} classes={{container:"BRL form-control NoBorder FS40 LabelFont Padding3px btn-light BCTrans", options:"BRL Wide100 TopMargin5px CenterAlign BottomShadow7"}} name={key} id={getEquivalent(key, selectFields, 1)} fields={'type'} value={this.state.selectedLinkData[key]} onChange={(field, val) => this.handleChangeStructure(field, val)}></Select>}
                                </li>}
                            </React.Fragment>
                        );})}
                        <li key={ 'name'} className="BRL col-lg-2 col-md-2 col-sm-3 NoBorder TopMargin5px HPadding5px" style={{listStyleType: 'none'}}>
                            <div className="BRL Wide100 BottomMargin2px ButtonFont Capital FS41">structure name</div>             
                            <Input key='struct_name' type="text" onChange={(e) => this.handleStructureNameChange(e)} value={this.state.selectedLinkData['reference_name']} disabled={false} className="BRL Wide100 NoBorder btn-light BCTrans InputFont FS40 TopMargin5px BottomShadow7"/>
                        </li>

                        {true && <li key={'linkDetailAction'} className={"BRL col-lg-2 col-md-2 col-sm-3 NoBorder HPadding5px TopMargin5px"}>
                            <div className="BRL Wide100 BottomMargin2px ButtonFont Capital FS41">actions</div>             
                            <button key={ 'b1_'} disabled={this.state.bStructSaved} onClick={() => { this.modifyStructure('save') }} className={"BRL Wide30 TopMargin5px RightMargin5 FS40 ButtonFont btn btn-sm HPadding0px " + (this.state.bStructSaved ? 'btn-primary' : 'btn-danger')}>
                                {this.state.saving === false && <i className="fa fa-floppy-o"></i>}
                                {this.state.saving === true && <MoonLoader size={17} color={"#ffffff"} loading={true}/>}
                            </button>
                            <button key={ 'b2_'} onClick={() => { this.modifyStructure('duplicate') }} className="BRL Wide30 TopMargin5px RightMargin5 FS40 ButtonFont btn btn-sm btn-secondary HPadding0px"><i className="fa fa-files-o"></i></button>
                            <button key={ 'b3_'} onClick={() => { this.modifyStructure('remove')}} className="BRL Wide30 TopMargin5px FS40 ButtonFont btn btn-sm btn-danger HPadding0px"><i className="fa fa-trash"></i></button>
                        </li>}
                    </div>}
                
                    {/* STRUCTURE PREVIEW */}
                    {this.state.selectedLinkStructure && 
                    <div className="BRL Wide100 Padding0px TopMargin10px ">
                        <Canvas edit={true} structure={this.state.selectedLinkStructure} onChange={(data) => {this.updateStructureDetails(data)}} />
                    </div>}
                      
            </div>);
        } else return null;
  }



  // ############################ RENDER ACTIONS ###################################
  // ############################ RENDER ACTIONS ###################################
  renderMenuElement = (key, index) => {    
      var data =
        <li key={'s_'+ key.name + index} className={"BRL col-lg-2 col-md-3 col-sm-4 BottomShadow7 LeftPadding0px RightPadding3px BottomMargin3px d-flex justify-content-between align-items-center"}>
            <div className={"btn BRL Wide100 Padding0px ButtonFont Bold Capital  Hand FS40 " + (index === this.state.selectedLink ? 'btn-info' : 'btn-light')}>
                <div className={"BRL Wide80"} >
                    <span className="BRL Wide100 VPadding10px " onClick={() => {this.getDetails(this.state.selectedMain, index);}}>{key.name}</span>
                    {this.state.selectedMain !== "home" && <div className={"Absolute Wide150px Top40px Left0px BCGray3 OverflowHidden BottomShadow7 z2000 " +  (this.state.selectedLinkMenu !== index ? 'High0px' : '') }>
                        <div className={"BRL Wide100"}>
                            <button disabled={index === 0} onClick={() => {this.modifyArchitecture('position', {from: index, to: index-1})}} className="BRL Wide50 FS41 btn btn-light btn-sm Capital">Left</button>
                            <button disabled={index >= this.lengthStructure}  onClick={() => {this.modifyArchitecture('position', {from: index, to: parseInt(index + 1,10)})}} className="BRL Wide50 FS41 btn btn-light btn-sm Capital">Right</button>
                        </div>
                        <button onClick={() => {this.modifyArchitecture('duplicate', key)}} className={"BRL Wide100 NoWhiteSpace FS41 BottomShadow7 NoBorder btn btn-light"} >DUPLICATE</button>
                        <button onClick={() => {this.modifyArchitecture('remove', index)}} className={"BRL Wide100 NoWhiteSpace FS41 BottomShadow7 NoBorder btn btn-light"} >REMOVE</button>
                    </div>}
                </div>
                {this.state.selectedMain !== "home" && <div onClick={() => {this.openMenu(index)}} className="BRL Wide20 VPadding10px z2000">
                    <div key={'action_' + index} className={"BR"}><i className="Hand FS38 fa fa-sort-down"></i></div>  
                </div>}
            </div>
        </li>;

        return data;
    };








    // ############################ RENDER ACTIONS ###################################
    // ############################ RENDER ACTIONS ###################################
    openMenu = (index) => {    
        if(this.state.selectedLinkMenu !== index){this.setState({selectedLinkMenu: index});} 
        else {this.setState({selectedLinkMenu: null});}
    };

    // ############################ HANDLE SAVE STATUS ###################################
    // ############################ HANDLE SAVE STATUS ###################################
    saveStatus = (state) => {    
        this.setState({bSaved: state});
    }


    // ############################ HANDLE SAVE STATUS ###################################
    // ############################ HANDLE SAVE STATUS ###################################
    findStructure = (list, element) => {       
        list.map((el, index) => {
            // console.log('FIND STRUCTURE => ', el, ' == ',  element);
            if (el === element){ return index; }
            return null;
        });
    }

}

export default withRouter(Editor);