import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/pseudo/general';
import { styleMapping } from "../utils/tools";
import Menu from './menu';
import Support from '../core/support';
import Personal from './personal';

class SideMenu extends React.Component {
    
    // ############################ RENDER ###################################
    // ############################ RENDER ###################################
    render() {
        const {settings } = this.props;
        
        return ( 
            <div className={"Fixed Top10 Wide100 HPadding0px "}>
                {/* ############################ MENU ################################### */}
                {/* ############################ MENU ################################### */}
                <ul className="BRL Wide100 Bottom50px navbar-nav mr-auto TopMargin10px">
                    <span className="nav-item Hand BRL Wide100" onClick={()=>{this.handleHome()}}>
                        <div className={"BRL Wide100 FS28 CenterAlign Capital " + settings.logo.title.class} style={styleMapping(settings.logo.title)} onClick={() => {this.props.navigate({direction: 'home'}, this.props.history)}}>{settings.logo.title.text}</div>
                    </span>
                    <span className="nav-item Hand BRL Wide100 "><Menu custom={{container:"Wide100", element:{class: 'BRL Wide100 CenterAlign BCTrans HPadding10px VPadding10px HeaderFont BCTrans btn-light FS40 Capital', style:''}}}></Menu></span>
                    <span className="nav-item Hand BRL Wide100 "><Support custom={{container:"Wide100", element:{class: 'BRL Wide100 CenterAlign BCTrans HPadding10px VPadding10px HeaderFont BCTrans btn-light FS40 Capital', style:''}}}></Support></span>
                    <span className="nav-item Hand BRL Wide100 "><Personal custom={{class:"BRL Wide100 CenterAlign HPadding10px VPadding10px Capital BCTrans FS40 HeaderFont btn-light", style:''}}></Personal></span>
                </ul>

                {/* ############################ FILTER ################################### */}
                {/* ############################ FILTER ################################### */}
            </div>
        );
        
    }
    
    // ############################ HANDLE HOME ###################################
    // ############################ HANDLE HOME ###################################
    handleHome(){
        this.props.navigate({direction: 'home'}, this.props.history);
    }
}

const mapstateToProps = (state) => {return {sideMenu: state.sideMenu,  isAuthenticated: state.status.token !== null && state.status.token !== '' ? true : false, status:state.status, user:state.user, settings:state.settings, architecture:state.architecture};}
const mapDispatchToProps = dispatch => {return {navigate: (direction, history) => dispatch(actions.navigate(direction, history)), onTryAutoSignup: (history) => dispatch(actions.authCheckState(history)), sideMenuFunction: (bVal) => dispatch(actions.sideMenu(bVal))}}
export default withRouter(connect(mapstateToProps, mapDispatchToProps)(SideMenu));
