import React, { Component } from 'react';

export default class Item extends Component {
  
  // ############################ RENDER ###################################
  // ############################ RENDER ###################################
  render() {

    const {data, index, styleLine, onSelected, selectedItem, fields, type, } = this.props;
    return (
          <div className={"" + styleLine } key={index} >
              {Object.keys(fields).map((field, index2)=>{
              
              if (fields[field].type === 'column'){
                return (
                    <div key={index2} className={"BRL HPadding3px VPadding10px " + (index !== 0 ? "FS41 FuturaH" : "FS40 FuturaU") + " Capital CenterAlign OverflowHidden"} style={fields[field].style} onClick={()=>{if(index!==0){onSelected('select', data, index);}}} >
                      {data[fields[field].label]}
                    </div>
                  );
                }
                else{
                  if(index!==0){
                    let action = data[fields[field].type];
                    let option = fields[field].options[action];
                    
                    if (action === '' || action === 'changed'){
                      action = 'reset';
                      option = fields[field].options[action];
                    }
                    // console.log(type, ' : ITEM => ', action, ' => ', option);

                    return (
                      <React.Fragment key={index2}>
                        <div className={"BRL"} style={fields[field]['style']} >
                          
                          {type === 'marketing' && 
                            <React.Fragment>
                              {/* <div key={index2+'_1'} className={"BRL Padding7px CenterAlign " + (type === "marketing" ? "Flex1" : "Flex1") + (option.action === 'visible' || option.action === 'deleting' ? ' FCRed' : ' ') + (selectedItem === index ? ' btn-light' : '') } style={{borderRadius:'5px 0 0 5px'}} onClick={()=>{onSelected((index!==0 && index===selectedItem ? option.action : 'select'), data, index)}}>
                                {(selectedItem === index) && <i className={option.label}></i>}
                              </div> */}
                              <div key={index2+'_1'} className={"Flex1 Padding7px CenterAlign " + (selectedItem === index ? 'btn-primary' : '') } onClick={()=>{onSelected((index!==0 && index===selectedItem ? 'details' : 'select'), data, index)}}>
                                {(selectedItem === index) && <i className={'fa fa-eye FS40'}></i>}
                              </div>
                              <div key={index2+'_2'} className={"Flex1 Padding7px CenterAlign " + (selectedItem === index ? 'btn-success' : '') } onClick={()=>{onSelected((index!==0 && index===selectedItem ? 'duplicate' : 'select'), data, 'new')}}>
                                {(selectedItem === index) && <i className={'fa fa-files-o FS40'}></i>}
                              </div>
                              <div key={index2+'_3'} className={"Flex1 Padding7px CenterAlign " + (selectedItem === index ? 'btn-danger' : '') }style={{borderRadius:'0 5px 5px 0'}} onClick={()=>{if(index!==0 && index===selectedItem){onSelected((data['action']!== 'deleting' ? 'deleting' : 'visible'), data, index)} else {onSelected('select', data, index)}}}>
                                {(selectedItem === index) && <i className={'FS40 fa fa-' + (data['action']!== 'deleting' ? 'remove' : 'history') }></i>}
                              </div>
                            </React.Fragment>
                          }
                          
                          {type === 'dashboard' && 
                            <React.Fragment>
                              <div key={index2+'_1'} className={"BRR VPadding7px HPadding20px CenterAlign"} style={{borderRadius:'5px'}} onClick={()=>{onSelected((index!==0 && index===selectedItem ? option.action : 'select'), data, index)}}>
                                {(selectedItem === index) && <i className={option.label}></i>}
                              </div>
                            </React.Fragment>
                          }

                        </div>
                        </React.Fragment>
                    );
                  } else {
                      const option = fields[field];
                      return(<div key={index2} className={"BRL High100 btn " + option.style} style={option.style}></div>);
                  }
                }
              })
              }
          </div>
          );
  }

  
}



