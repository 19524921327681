import React, { Component } from 'react';
import Canvas from "../design/canvas";
import { connect } from 'react-redux';
import { getArchitecture } from "../services/editor";
import { getStructure } from "../services/general";
import { getArrayIndex } from "../utils/tools";

class Home extends Component {
 
    //############################ CONSTRUCTOR ###################################
	//############################ CONSTRUCTOR ###################################
	constructor(props) {
        super(props);
        this.state={};
    }  

    async componentDidMount(){
        const ref = await this.getHome("home");
        const structure = await getStructure('custom', ref);		
        this.setState({structure: structure});
    }


    //########################### GET HOME ###################################
    //########################### GET HOME ###################################
    async getHome(type) {
        let archi = await getArchitecture(null, null);
        archi = archi['results']['architecture']['home'];
        const index = getArrayIndex(archi, type, 'name');
        // console.log('HOME RES => ', index, ' => ', archi[index]['reference']);
        return archi[index]['reference'];
    }



    //############################ RENDER ###################################
    //############################ RENDER ###################################
    render() {

        if (this.state.structure){
            return (
                <Canvas structure={this.state.structure} />
            );
        } else return null;
    }
}

export default connect(null, null)(Home);

