export const GENDER =
    [
        { label: '', value: '' },
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
        // { label: 'Trans-Male', value: 'trans-male' },
        // { label: 'Trans-Female', value: 'trans-female' },
        { label: 'Non-Binary', value: 'non-binary' },
    ];
export const TARGET_GENDER =
    [
        { label: 'All', value: '' },
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
        // { label: 'Trans-Male', value: 'trans-male' },
        // { label: 'Trans-Female', value: 'trans-female' },
        { label: 'Non-Binary', value: 'non-binary' },
    ];

export const ORIENTATION =  [
    { label: '', value: '' },
    { label: 'Heterosexual', value: 'heterosexual' },
    { label: 'Homosexual', value: 'homosexual' },
    { label: 'Bisexual', value: 'bisexual' },
];

export const TARGET_ORIENTATION =  [
    { label: 'All', value: '' },
    { label: 'Heterosexual', value: 'heterosexual' },
    { label: 'Homosexual', value: 'homosexual' },
    { label: 'Bisexual', value: 'bisexual' },
];

export const TARGET_AGE =     [
    { label: 'All', value: '' },
    { label: '18-25', value: '18-25' },
    { label: '26-35', value: '26-35' },
    { label: '36-45', value: '36-45' },
    { label: '46-55', value: '46-55' },
    { label: '56+', value: '56-200' },
];

export const AGE =     [
    { label: '', value: '' },
    { label: '18-25', value: '18-25' },
    { label: '26-35', value: '26-35' },
    { label: '36-45', value: '36-45' },
    { label: '46-55', value: '46-55' },
    { label: '56+', value: '56-200' },
];

export const RACE = [
    { label: 'Races', value: '' },
    { label: 'Caucasian', value: 'caucasian' },
    { label: 'African', value: 'african' },
    { label: 'Arabic', value: 'arabic' },
    { label: 'Asiatic', value: 'asiatic' },
    { label: 'Indian', value: 'indian' },
    { label: 'Native', value: 'native' },
    { label: 'Mixed', value: 'mixed' }
];

export const STEREOTYPE = [
    { label: '', value: '' },
    { label: 'Artistic', value: 'artistic' },
    { label: 'Business', value: 'business' },
    { label: 'Classic', value: 'classic' },
    { label: 'Normal', value: 'normal' },
    { label: 'Outdoorsy', value: 'outdoorsy' },
    { label: 'Socialite', value: 'socialite' },
    { label: 'Trendy', value: 'trendy' },
    { label: 'Yuppie', value: 'yuppie' }
];

export const TARGET_STEREOTYPE = [
    { label: 'All', value: '' },
    { label: 'Artistic', value: 'artistic' },
    { label: 'Business', value: 'business' },
    { label: 'Classic', value: 'classic' },
    { label: 'Normal', value: 'normal' },
    { label: 'Outdoorsy', value: 'outdoorsy' },
    { label: 'Socialite', value: 'socialite' },
    { label: 'Trendy', value: 'trendy' },
    { label: 'Yuppie', value: 'yuppie' }
];

export const POLITICAL = [
    { label: '', value: '' },
    { label: 'None', value: 'none' },
    { label: 'Left', value: 'left' },
    { label: 'Center', value: 'center' },
    { label: 'Right', value: 'right' },
];

export const TARGET_POLITICAL = [
    { label: 'All', value: '' },
    { label: 'None', value: 'none' },
    { label: 'Center', value: 'center' },
    { label: 'Left', value: 'left' },
    { label: 'Right', value: 'right' },
];

export const VISIBLE = [
    { label: 'All', value: '' },
    { label: 'visible', value: 'visible' },
    { label: 'hidden', value: 'hidden' }
];

export const BROADCAST = 'BROADCAST'

export const FONTS = [{ label: '', value: '' },{ label: 'AdventorB', value: 'AdventorB' }, { label: 'AdventorBI', value: 'AdventorBI' }, { label: 'AdventorI', value: 'AdventorI' }, { label: 'AdventorR', value: 'AdventorR' }, { label: 'AGP', value: 'AGP' },
{ label: 'AileronB', value: 'AileronB' }, { label: 'AileronR', value: 'AileronR' }, { label: 'AileronSB', value: 'AileronSB' }, { label: 'AileronT', value: 'AileronT' }, { label: 'AkrobatB', value: 'AkrobatB' },{ label: 'AkrobatR', value: 'AkrobatR' },
{ label: 'AkrobatSB', value: 'AkrobatSB' }, { label: 'AlegreyaB', value: 'AlegreyaB' }, { label: 'AlegreyaI', value: 'AlegreyaI' },{ label: 'AlegreyaR', value: 'AlegreyaR' }, {label: 'AleoB', value: 'AleoB' },{ label: 'AleoL', value: 'AleoL' },
{ label: 'AleoR', value: 'AleoR' },{ label: 'ArvoB', value: 'ArvoB' },{ label: 'ArvoR', value: 'ArvoR' },{ label: 'Awesome', value: 'Awesome' },{ label: 'Bauru', value: 'Bauru' },{ label: 'Befolk', value: 'Befolk' },{ label: 'Bitter-Bold', value: 'Bitter-Bold' },
{ label: 'Bitter-BoldItalic', value: 'Bitter-BoldItalic' },{ label: 'Bitter-Italic', value: 'Bitter-Italic' },{ label: 'Bitter-Regular', value: 'Bitter-Regular' },{ label: 'BlackWillowR', value: 'BlackWillowR' },{ label: 'BlackWillowRO', value: 'BlackWillowRO' },
{ label: 'BlockB', value: 'BlockB' },{ label: 'BlockI', value: 'BlockI' },{ label: 'BlockR', value: 'BlockR' },{ label: 'BreeB', value: 'BreeB' },{ label: 'BreeR', value: 'BreeR' },{ label: 'BrelaR', value: 'BrelaR' },{ label: 'Brux', value: 'Brux' },
{ label: 'Bud', value: 'Bud' },{ label: 'BuffaloR', value: 'BuffaloR' },{ label: 'ButlerB', value: 'ButlerB' }, {label: 'ButlerM', value: 'ButlerM' },{ label: 'ButlerR', value: 'ButlerR' },{ label: 'CabinM', value: 'CabinM' },{ label: 'CabinR', value: 'CabinR' },
{ label: 'CamperR', value: 'CamperR' },{ label: 'ChivoB', value: 'ChivoB' },{ label: 'ChivoL', value: 'ChivoL' },{ label: 'ChivoR', value: 'AdvenChivoRtorB' },{ label: 'CinzelB', value: 'CinzelB' },{ label: 'CinzelR', value: 'CinzelR' },{ label: 'CinzelR2', value: 'CinzelR2' },
{ label: 'ClearSansB', value: 'ClearSansB' },{ label: 'ClearSansM', value: 'ClearSansM' },{ label: 'ClearSansR', value: 'ClearSansR' },{ label: 'ClearSansT', value: 'ClearSansT' },{ label: 'CodeB', value: 'CodeB' },{ label: 'CodeL', value: 'CodeL' },
{ label: 'CormorantB', value: 'CormorantB' },{ label: 'CormorantB2', value: 'CormorantB2' },{ label: 'CormorantI', value: 'CormorantI' },{ label: 'CormorantL2', value: 'CormorantL2' },{ label: 'CormorantM', value: 'CormorantM' },{ label: 'CormorantR', value: 'CormorantR' },
{ label: 'CormorantR2', value: 'CormorantR2' },{ label: 'CormorantSB', value: 'CormorantSB' },{ label: 'CormorantSBI', value: 'CormorantSBI' },{ label: 'CurvadaB', value: 'CurvadaB' },{ label: 'CurvadaR', value: 'CurvadaR' },{ label: 'DecaloB', value: 'DecaloB' },
{ label: 'DecaloR', value: 'DecaloR' },{ label: 'DecaloSB', value: 'DecaloSB' },{ label: 'FanwoodI', value: 'FanwoodI' },{ label: 'FanwoodR', value: 'FanwoodR' },{ label: 'FenixR', value: 'FenixR' },{ label: 'FriedR', value: 'FriedR' },{ label: 'Futura', value: 'Futura' },
{ label: 'FuturaBI', value: 'FuturaBI' },{ label: 'FuturaEB', value: 'FuturaEB' },{ label: 'FuturaH', value: 'FuturaH' },{ label: 'FuturaHI', value: 'FuturaHI' },{ label: 'FuturaL', value: 'FuturaL' },{ label: 'FuturaLI', value: 'FuturaLI' },{ label: 'FuturaM', value: 'FuturaM' },
{ label: 'FuturaMI', value: 'FuturaMI' },{ label: 'FuturaU', value: 'FuturaU' },{ label: 'GandhiB', value: 'GandhiB' },{ label: 'GandhiI', value: 'GandhiI' },{ label: 'GandhiR', value: 'GandhiR' },{ label: 'GiroR', value: 'GiroR' },{ label: 'GWF', value: 'GWF' },
{ label: 'HelpBig', value: 'HelpBig' },{ label: 'HelpSmall', value: 'HelpSmall' },{ label: 'Hovel', value: 'Hovel' },{ label: 'HVM', value: 'HVM' },{ label: 'Inkferno', value: 'Inkferno' },{ label: 'ITC', value: 'ITC' },{ label: 'JaapokkiR', value: 'JaapokkiR' },
{ label: 'JudsonB', value: 'JudsonB' },{ label: 'JudsonI', value: 'JudsonI' },{ label: 'JudsonR', value: 'JudsonR' },{ label: 'JuraB', value: 'JuraB' },{ label: 'JuraBI', value: 'JuraBI' },{ label: 'JuraI', value: 'JuraI' },{ label: 'JuraR', value: 'JuraR' },
{ label: 'KlavikaB', value: 'KlavikaB' },{ label: 'Lady', value: 'Lady' },{ label: 'LeagueGothicCI', value: 'LeagueGothicCI' },{ label: 'LeagueGothicCR', value: 'LeagueGothicCR' },{ label: 'LeagueGothicI', value: 'LeagueGothicI' },
{ label: 'LeagueGothicR', value: 'LeagueGothicR' },{ label: 'LeagueGothicR2', value: 'LeagueGothicR2' },{ label: 'LGC', value: 'LGC' },{ label: 'LGR', value: 'LGR' },{ label: 'LibreB', value: 'LibreB' },{ label: 'LibreI', value: 'LibreI' },
{ label: 'LibreR', value: 'LibreR' },{ label: 'Lobster', value: 'Lobster' },{ label: 'LotR', value: 'LotR' },{ label: 'LSN', value: 'LSN' },{ label: 'Lucida', value: 'Lucida' },{ label: 'LullaBelle', value: 'LullaBelle' },
{ label: 'LuthierB', value: 'LuthierB' },{ label: 'LuthierR', value: 'LuthierR' },{ label: 'Lymph', value: 'Lymph' },{ label: 'MagnaBL', value: 'MagnaBL' },{ label: 'Mathilde', value: 'Mathilde' },{ label: 'Metropolis', value: 'Metropolis' },
{ label: 'MFG', value: 'MFG' },{ label: 'Minotaur', value: 'Minotaur' },{ label: 'MonkeyWishes', value: 'MonkeyWishes' },{ label: 'MontserratB', value: 'MontserratB' },{ label: 'MontserratBI', value: 'MontserratBI' },
{ label: 'MontserratBl', value: 'MontserratBl' },{ label: 'MontserratBlI', value: 'MontserratBlI' },{ label: 'MontserratEB', value: 'MontserratEB' },{ label: 'MontserratEBI', value: 'MontserratEBI' },{ label: 'MontserratEL', value: 'MontserratEL' },
{ label: 'MontserratELI', value: 'MontserratELI' },{ label: 'MontserratI', value: 'MontserratI' },{ label: 'MontserratL', value: 'MontserratL' },{ label: 'MontserratLI', value: 'MontserratLI' },{ label: 'MontserratM', value: 'MontserratM' },
{ label: 'MontserratMI', value: 'MontserratMI' },{ label: 'MontserratR', value: 'MontserratR' },{ label: 'MontserratSB', value: 'MontserratSB' },{ label: 'MontserratSBI', value: 'MontserratSBI' },{ label: 'MontserratT', value: 'MontserratT' },
{ label: 'MontserratTI', value: 'MontserratTI' },{ label: 'MSR', value: 'MSR' },{ label: 'MusketB', value: 'MusketB' },{ label: 'MusketR', value: 'MusketR' },{ label: 'MyriadB', value: 'MyriadB' },{ label: 'MyriadR', value: 'MyriadR' },
{ label: 'NeutonB', value: 'NeutonB' },{ label: 'NeutonR', value: 'NeutonR' },{ label: 'NeutonR2', value: 'NeutonR2' },{ label: 'MonNickleyserratT', value: 'Nickley' },{ label: 'OIW', value: 'OIW' },{ label: 'Oranien', value: 'Oranien' },
{ label: 'OregonLDO', value: 'OregonLDO' },{ label: 'OregonLDOBlack', value: 'OregonLDOBlack' },{ label: 'ParalinesR', value: 'ParalinesR' },{ label: 'ParalinesB', value: 'ParalinesB' },{ label: 'PermianI', value: 'PermianI' },
{ label: 'PermianR', value: 'PermianR' },{ label: 'PetitLatin', value: 'PetitLatin' },{ label: 'PlayB', value: 'PlayB' },{ label: 'PlayI', value: 'PlayI' },{ label: 'PlayR', value: 'PlayR' },{ label: 'PlayR2', value: 'PlayR2' },
{ label: 'Presu', value: 'Presu' },{ label: 'PT', value: 'PT' },{ label: 'PWM', value: 'PWM' },{ label: 'Radley', value: 'Radley' },{ label: 'RalewayB', value: 'RalewayB' },{ label: 'RalewayB2', value: 'RalewayB2' },
{ label: 'RalewayL', value: 'RalewayL' },{ label: 'RalewayM', value: 'RalewayM' },{ label: 'RalewayR', value: 'RalewayR' },{ label: 'RalewayT', value: 'RalewayT' },{ label: 'Razor', value: 'Razor' },{ label: 'RidgeR', value: 'RidgeR' },
{ label: 'RomanaB', value: 'RomanaB' },{ label: 'RomanaI', value: 'RomanaI' },{ label: 'RomanaR', value: 'RomanaR' },{ label: 'Rutan', value: 'Rutan' },{ label: 'Ruthie', value: 'Ruthie' },{ label: 'Segan', value: 'Segan' },
{ label: 'Setta', value: 'Setta' },{ label: 'SimonettaI', value: 'SimonettaI' },{ label: 'SimonettaR', value: 'SimonettaR' },{ label: 'Sorrow', value: 'Sorrow' },{ label: 'STR', value: 'STR' },{ label: 'Street', value: 'Street' },
{ label: 'SuperTypeR', value: 'SuperTypeR' },{ label: 'SuperTypeSB', value: 'SuperTypeSB' },{ label: 'TripB', value: 'TripB' },{ label: 'TripI', value: 'TripI' },{ label: 'TripR', value: 'TripR' },{ label: 'UbuntuB', value: 'UbuntuB' },
{ label: 'UbuntuM', value: 'UbuntuM' },{ label: 'UbuntuR', value: 'UbuntuR' },{ label: 'Unna', value: 'Unna' },{ label: 'VolkhovB', value: 'VolkhovB' },{ label: 'VolkhovI', value: 'VolkhovI' },{ label: 'VolkhovR', value: 'VolkhovR' },
{ label: 'WaywardR', value: 'WaywardR' },{ label: 'Week', value: 'Week' },{ label: 'WildYouthR', value: 'WildYouthR' },{ label: 'WL', value: 'WL' },{ label: 'WLM', value: 'WLM' },{ label: 'WS', value: 'WS' },{ label: 'WSY', value: 'WSY' },
{ label: 'YellowTailR', value: 'YellowTailR' },{ label: 'Zviro', value: 'Zviro' }];


  export const COUNTRY = [
    { label: '', value: '' },
    { label: 'Austria', value: 'AT' },
    { label: 'China', value: 'CN' },
    { label: 'Czech Republic', value: 'CZ' },
    { label: 'Germany', value: 'DE' },
    { label: 'Spain', value: 'ES' },
    { label: 'France', value: 'FR' },
    { label: 'Great Britain', value: 'GB' },
    { label: 'Italy', value: 'IT' },
    { label: 'Japan', value: 'JP' },
    { label: 'Netherlands', value: 'NL' },
    { label: 'Switzerland', value: 'CH' },
    { label: 'Unknown', value: 'UN' }];
  
  export const ELEMENTTYPE = [
    { label: '', value: '' },
    { label: 'Tab', value: 'Tab' },
    { label: 'Link', value: 'Link' },
    { label: 'Div', value: 'Div' }];

  export const BOOLEAN = [
    { label: '', value: '' },
    { label: 'true', value: 'true' },
    { label: 'false', value: 'false' }];
  
  export const SELLTYPE = [
    { label: '', value: '' },
    { label: 'Fixed', value: 'Fixed' },
    { label: 'Auction', value: 'Auction' }];

    export const ORDERPROCESS = [
    { label: 'INSERTED', value: '' },
    { label: 'PAYMENT CONFIRMED', value: 'OPEN' },
    { label: 'ORDER IN PREPARATION', value: 'OPEN' },
    { label: 'ORDER SHIPPED', value: 'OPEN' },
    { label: 'RETURNING ORDER', value: 'OPEN' },
    { label: 'RETURN RECEIVED', value: 'OPEN' },
    { label: 'REIMBURSED', value: 'CLOSED' },
    { label: 'EXCHANGED', value: 'CLOSED' },
    { label: 'CANCELED', value: 'CLOSED' }
  ];

  export const ORDERPROCESS_SELLER = [
    { label: '', value: '' },
    { label: 'INSERTED', value: 'OPEN' },
    { label: 'PAYMENT CONFIRMED', value: 'OPEN' },
    { label: 'ORDER IN PREPARATION', value: 'OPEN' },
    { label: 'ORDER SHIPPED', value: 'OPEN' },
    { label: 'RETURNING ORDER', value: 'OPEN' },
    { label: 'RETURN RECEIVED', value: 'OPEN' },
    { label: 'REIMBURSED', value: 'CLOSED' },
    { label: 'EXCHANGED', value: 'CLOSED' },
    { label: 'CANCELED', value: 'CLOSED' }
  ];

    
  export const PAGETYPE = [
    { label: 'custom', value: 'custom' },
    { label: 'fundamental', value: 'fundamental' }];
  
  export const LINKTYPE = [
    { label: '', value: '' },
    { label: 'Function', value: 'Function' },
    { label: 'HTML', value: 'HTML' },
    { label: 'Text', value: 'Text' }
  ];

  export const POSTTYPE = [{ label: '', value: '' },
  { label: 'Event', value: 'Event' },
  { label: 'Post', value: 'Post' }
  ];
  
  export const TAGS = [
    { label: 'C-IMAGE', value: 'C-IMAGE' },
    { label: 'C-TRI', value: 'C-TRI' },
    { label: 'C-INFO', value: 'C-INFO' },
    { label: 'C-MAPS', value: 'C-MAPS' },
    { label: 'C-VIDEO', value: 'C-VIDEO' },
    { label: 'C-CUSTOM', value: 'C-CUSTOM' },
    { label: 'C-WINDOW', value: 'C-WINDOW' },
  ];

  export const MODULE = [
    { label: 'blog', value: 'blog' },
    { label: 'maps', value: 'maps' },
    { label: 'portfolio', value: 'portfolio' },
    { label: 'search', value: 'search' }
  ];

  export const TYPE_STRUCTURE = [
    { label: 'fundamental', value: 'fundamental' },
    { label: 'custom', value: 'custom' },
  ];

  export const FRAME = [
    { label: 'no frame', value: '' },
    { label: 'smartphone', value: '/frames/smartphone.png' },
    { label: 'computer', value: '/frames/computer.png' },
  ];

  export const TARGET_GENDER2 = { 
    all_genders: 'All Genders',
    male: 'Male',
    female: 'Female',
    non_binary: 'Non-Binary'};
  
  export const TARGET_ORIENTATION2 = { 
    all_orientations: 'All Orientations',
    heterosexual: 'Heterosexual',
    homosexual: 'Homosexual',
    bisexual: 'Bisexual'};

  export const TARGET_AGE2 = { 
    all_ages: 'All Ages',
    '18-25': '18-25',
    '26-35': '26-35',
    '36-45': '36-45',
    '46-55': '46-55',
    '56-200': '56+'};
