import Axios from 'axios';
import { getLocalObject } from "../../components/utils/tools";
import * as server from "../../resources/constants/api";
import { formatDate } from "../utils/tools"


//########################### GET BUSINESS ACTIVITY ###################################
//########################### GET BUSINESS ACTIVITY ###################################
export const getBusinessActivity = async (data) => {
    
    const temp = await getLocalObject({'token':'','idUser': ''});
    let headers = {'headers': {'authorization': `Token ${temp.token}`}}
    if (temp.token === '' || temp.token === null){headers = {};}
    const url = server.URL_SERVER_DJANGO+'/monitor/business/?start='+data.range[0]+'&end='+data.range[1];
    
    console.log('getBusinessActivity 1 => ', url);
    return new Promise(function(resolve, reject){
        Axios.get(url, headers).then(async res => {
            console.log('getBusinessActivity 2 => ', res);
            resolve(res)
        }).catch(err => {
            console.log('getBusinessActivity error => ', err);

        })
        .then(data => {
            // console.log('getBusinessActivity 3 => ', data);
            return data;
        });
    });
}

//########################### GET BUSINESS ACTIVITY ###################################
//########################### GET BUSINESS ACTIVITY ###################################
export const getUsersActivity = async (data) => {
    
    const temp = await getLocalObject({'token':'','idUser': ''});
    let headers = {'headers': {'authorization': `Token ${temp.token}`}}
    if (temp.token === '' || temp.token === null){headers = {};}
    const url = server.URL_SERVER_DJANGO+'/monitor/users/?start='+data.range[0]+'&end='+data.range[1];
    
    // console.log('getUsersActivity 1 => ', url);
    return new Promise(function(resolve, reject){
        Axios.get(url, headers).then(async res => {
            // console.log('getUsersActivity 2 => ', res);
            resolve(res)
        }).catch(err => {})
        .then(data => {
            // console.log('getUsersActivity 3 => ', data);
            return data;
        });
    });
}


//########################### PROCESS DATE FOR GRAPHS ###################################
//########################### PROCESS DATE FOR GRAPHS ###################################
export const processData = async (data, config) => {
    
    let transformedData = {};
    for (const key in config){
        let results = [], sum = 0;
        const widgetConfig = config[key];
        const rawData = data[widgetConfig['key']];
        
        switch(widgetConfig['aggregation']){
            default: break;
            case 'business-trends':  
                for(const keyPeriod in rawData){
                    let rawPeriod = rawData[keyPeriod];
                    sum = 0;
                    for(const key1 in rawPeriod){
                        for(const key2 in rawPeriod[key1]){
                            for(const key3 in rawPeriod[key1][key2]){
                                sum++;
                            } 
                        } 
                    } 
                    results.push(sum);   
                }
            break;

            case 'user-trends':  
                for(const keyPeriod in rawData){
                    let rawPeriod = rawData[keyPeriod];
                    sum = 0;
                    for(const key1 in rawPeriod){
                        for(const key2 in rawPeriod[key1]){
                            sum++;
                        } 
                    } 
                    results.push(sum);   
                }
            break;
        }
        transformedData[widgetConfig['key']] = results;
      }

    // console.log('MONITOR PROCESS DATA => ', transformedData);
    return transformedData;
  
}


//########################### PROCESS DATE FOR GRAPHS ###################################
//########################### PROCESS DATE FOR GRAPHS ###################################
export const getLabel = (type, refDate, val) => {
    let res, endDate, hours, minutes;
    
    if (refDate === 'Live'){
        endDate = new Date();
        hours = endDate.getHours();
        minutes = (endDate.getMinutes() < 30 ? 0 : 30);
    } else {
        endDate = new Date(refDate);
        hours = 23;
        minutes = 30;
    }
    endDate.setHours(hours, minutes);

    switch(type){
        case 'date':  
            const calculatedDate = new Date(endDate.getTime() - (val * (30 * 60 * 1000)))
            res = formatDate(calculatedDate, 'dd/mm hhmm');
            break;

    }
  
    // console.log('MONITOR PROCESS DATA => ', transformedData);
    return res;
  
}
