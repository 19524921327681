import Axios from 'axios';
import { getLocalObject, setLocalObject, isObjectComplete, clearLocalObject } from "../../components/utils/tools";
import { authStart, authSuccess, authFail, logout, navigateTo, setSettings, isBusiness2, sideMenu2, geolocation2, setProfile } from "../actions/general";
import { createProfile, getUser, getProfile } from "../../components/services/profile";
import { timerBroadcast } from "../../components/services/geolocation";
import * as server from "../../resources/constants/api";
import { toast } from 'react-toastify'; 

const options2 = { autoClose: 2000, type: toast.TYPE.INFO, hideProgressBar: false};
const options3 = { autoClose: 2000, type: toast.TYPE.ERROR, hideProgressBar: false};
const exceptionList = ['target_stereotype', 'target_age', 'target_stereotype', 'active', 'last_online', 'is_dark_mode', 'target_age', 'target_stereotype', 'is_premium', 'date_premium_valid', 'subscription']

//########################### SET SETTINGS ###################################
//########################### SET SETTINGS ###################################
export const setSettings2 = (data) => {
    return async dispatch => {
        dispatch(setSettings(data));
    }
}

//########################### PRE ACTIONS ###################################
//########################### PRE ACTIONS ###################################
export const authCheckState = (history) => {
    return async dispatch => {

        let object = await getLocalObject({'token':null, 'username':null, 'email':null, 'idUser':null});
        if (object.token === '' || object.token === null) { 
            setTimeout(async () => { 
                // console.log('AUTHCHECKSTATE 1 : ', object);
                dispatch(authFail(object));
            }, 0); } 
            else { setTimeout(async () => {
                try{
                    const userData = await getUser(object.idUser);
                    object = {...object, ...userData};
                    await setLocalObject(object);      
                    dispatch(authSuccess(object));
                    const userProfile = await getProfile(object.idUser);
                    const bComplete = isObjectComplete(userProfile, exceptionList);
                    dispatch(setProfile(userProfile));
                    if(!bComplete){ dispatch(navigateTo({direction:`profile/${object.idUser}` }, history));}
                    else {
                        const url = window.location.href;
                        let page = 'maps';
                        if(url.includes('marketing')){ page = 'business/marketing';}
                        if(url.includes('dashboard')){ page = 'business/dashboard';}
                        if(url.includes('profile')){ page = 'profile/' + object.idUser;}
                        if(url.includes('monitor')){ page = 'monitor';}
                        if(url.includes('login')){ page = 'login';}
                        console.log('###### => ',url, ' => ', page);
                        dispatch(navigateTo({direction:page}, history));
                    }
                    // else {preBroadcastPosition(object.idUser, true);}                
                } catch(err){
                    console.log('AUTHCHECKSTATE: ', err);
                    // dispatch(authLogout(history));
                }
        
        
            }, 0); }
    }
}

export const googleLogin =  (accessToken, profile, history) => {
    return async dispatch => {
        // console.log('CONTINUE GOOGLE  0 => ', accessToken, ' => ', profile, ' => ',history);
        
        dispatch(authStart());
        await Axios.post(server.URL_SERVER_DJANGO+'/api-auth/google/', { access_token: accessToken }).then(async res => {
            
            setTimeout(() => {toast.success("Google sign-in succesfull ", options2)}, 0);
            let object = {'token': res.data.key, 'username': profile.zt, 'email': profile.zt, 'idUser': res.data.user.toString()};
            await setLocalObject(object);      
            const userData = await getUser(object.idUser);
            const diffSeconds = Math.abs((new Date().getTime() - new Date(userData['date_joined']).getTime()) / 1000);
                if (diffSeconds < 10){try{
                    await createProfile(object);
                }catch {}}
                object = {...object, ...userData};
                await setLocalObject(object);
                dispatch(authSuccess(object));
                setTimeout(async () => { 
                    const userProfile = await getProfile(object.idUser);
                    const bComplete = isObjectComplete(userProfile, exceptionList);
                    dispatch(setProfile(userProfile));
                    setTimeout(async () => { dispatch(navigateTo({direction:(bComplete? '' :`profile/${object.idUser}` )}, history));}, 500);
                }, 1000);
            }).catch(err => {
                setTimeout(() => {toast.error("Google sign-in failed ", options2)}, 0);
                dispatch(authFail());
                dispatch(navigateTo({direction:'login'}, history));
            });
    }
}


export const authLogin = (username, password, history) => {
    return async dispatch => {
        dispatch(authStart());
        // console.log('AUTH LOGIN => ', username, ' : ', password);
        Axios.post(server.URL_SERVER_DJANGO+'/api-auth/login/', { username: username, password: password }).then(async res => {
            if(true){
                let object = {'token':res.data.key, 'username': username, 'email': username, 'idUser': res.data.user.toString()};
                const userData = await getUser(object.idUser);
                const userProfile = await getProfile(object.idUser);
                object = {...object, ...userData};
                await setLocalObject(object);      
                setTimeout(() => {toast.success("Login was succesfull ", options2)}, 0);
                dispatch(authSuccess(object));
                const bComplete = isObjectComplete(userProfile, exceptionList);
                dispatch(setProfile(userProfile));
                setTimeout(async () => { dispatch(navigateTo({direction:(bComplete? '' :`profile/${object.idUser}` ) }, history));}, 500);
            }
                

        }).catch(err => {             
            setTimeout(() => {toast.error("Authentication failed", options3)}, 0);
            dispatch(authFail());
            dispatch(navigateTo({direction:'login'}, history));
            // console.log('AUTHLOGIN FAILURE : ', err);
        });
    }
}


export const authSignup = (username, password1, password2, history) => {
    return async dispatch => {
        dispatch(authStart());
        const credential = { username: username, email: username, password1: password1, password2: password2 };
        // console.log('AUTH REGISTER 0 : ', credential);
        Axios.post(server.URL_SERVER_DJANGO+'/api-auth/registration/', credential).then(async res => {
            const object = {'token':res.data.key, 'username': username, 'email': username, 'idUser': res.data.user};
            await setLocalObject(object);      
            createProfile(object);
            setTimeout(() => {toast.success("Register succesfull ", options2)}, 0);
            dispatch(authSuccess(object));
            
            let userProfile = await getProfile(object.idUser);
            dispatch(setProfile(userProfile));

            setTimeout(async () => { dispatch(navigateTo({direction:'profile/'+object.idUser}, history));}, 500);

        }).catch(err => { 
            dispatch(authFail());
            dispatch(navigateTo({direction:'login'}, history));
            // console.log('AUTH REGISTER FAILURE : ', err); 
            setTimeout(() => {toast.error("Register failed. Email is already registered", options3)}, 0);

        })
    }
}
            
export const authLogout = (history) => {
    return async dispatch => {
        await clearLocalObject();      
        setTimeout(() => {toast.success("Logout succesfull ", options2)}, 0);
        dispatch(logout());
        dispatch(navigateTo({direction:'home'}, history));
    }
}

export const preChangeMenu = (data) => {    
    return dispatch => {
    }
}

export const navigate = (data, history) => {   
    return dispatch => {
        dispatch(navigateTo(data, history));
    }
}

export const isBusiness = (data) => {    
    return dispatch => {
        dispatch(isBusiness2(data));
    }
}

export const preBroadcastPosition = async (id, bBroadcast) => {    
    timerBroadcast(id, bBroadcast);
}

export const sideMenu = (data) => {
    return dispatch => {
        dispatch(sideMenu2(data));
    }
}

export const geolocation = (data) => {
    return dispatch => {
        dispatch(geolocation2(data));
    }
}

