import React, { Component } from 'react';
import * as design from "../../resources/constants/design";

export default class Price extends Component {

  // ############################ RENDER ###################################
  // ############################ RENDER ###################################
  render() {
    
    const {data} = this.props 
    // console.log('PRICE => ', data);

    return (
        <div className={design.PRICE.container}>
            {Object.keys(data).map((key,index)=>{
              return (
                <div key={index + 'a'}  className={(key !== 'total' ? design.PRICE.container_line : design.PRICE.container_total)}>
                  <div key={index + '1'} className={design.PRICE.container_label}>
                    <span className={(key !== 'total' ? design.PRICE.label : design.PRICE.label_total)}>{key}</span>
                  </div>
                  <div key={index + '2'} className={design.PRICE.container_cost}>
                    <div className={"BRL Wide10 RightMargin10px Bold " + (key !== 'total' ? design.PRICE.cost : design.PRICE.cost_total)}>&euro;</div>
                    <span className={"BRL " + (key !== 'total' ? design.PRICE.cost : design.PRICE.cost_total)}> {data[key]}</span>
                  </div>
                </div>  
              );
            })
          }
          
        </div>  
    );

  }
  
}
