import React, { Component } from 'react';
import { styleMapping, belongsTo, getEquivalent, transformNullToString, getLocalObject, setLocalObject, clearLocalObject, isObjectComplete } from "../utils/tools";
import { getProfile, setProfile, deleteProfile } from "../../components/services/profile";
import Select from "../elements/select";
import { Link, withRouter } from 'react-router-dom';
import { Input } from "reactstrap";
import { connect } from 'react-redux';
import * as actions from '../../store/pseudo/general';
import * as design from '../../resources/constants/design';
import InfoModal from "../elements/infoModal";
import MoonLoader from "react-spinners/ClipLoader";
import { toast } from 'react-toastify'; 
const options = { autoClose: 1500, type: toast.TYPE.INFO, hideProgressBar: false};
const exceptionList = ['target_stereotype', 'target_age', 'target_stereotype', 'active', 'last_online', 'is_dark_mode', 'target_age', 'target_stereotype', 'is_premium', 'date_premium_valid', 'subscription']

class Profile extends Component {
  
  constructor(props) {
    super(props);
    this.state = { data: '', bEditMode: true, bChanged: false, bComplete: false, loading: false, infoModal: null };
    this.selectFields = ['gender','target_age','target_race','age','orientation','stereotype','target_stereotype','political'];
    this.invisibleFields = ['idUser','image','active','last_online', 'is_dark_mode', 'target_age', 'target_stereotype', 'is_premium', 'date_premium_valid', 'subscription'];
    this.equivalentFields = [['target_age','target age'],['target_stereotype','target stereotype']];
    this.equivalentFields2 = [['56-200','56+']];
    if (props.bAdmin === true){this.idUser = 5;}
    else {this.idUser =  this.props.match.params.idUser;}
    this.props.sideMenuFunction(false);
  }
  
  componentDidMount() {
    const token = localStorage.getItem('token');
    this.headers = {'headers': {'authorization': `Token  ${token}`}}
    if (token === '' || token === null){this.headers = {};}
    this.getData();
  }

  componentWillUnmount(){}


  // ############################ GET DATA ###################################
  // ############################ GET DATA ###################################
  getData = async () => {
    
    this.profile = {'age':null, 'gender':null, 'target_age':null, 'stereotype':null, 'political':null,'target_stereotype':null};
    this.profile = await getLocalObject(this.profile);
    const bComplete = isObjectComplete(this.profile, exceptionList);
    // console.log('PROFILE 2 => ', this.profile, ' => ', bComplete);
    
    if(!bComplete){
      this.profile = await getProfile(this.idUser);
      this.profile = transformNullToString(this.profile);
      await setLocalObject(this.profile);
      this.profile['last_online'] = new Date();
    }
      
    this.setState({bComplete: bComplete, data: this.profile});
  };

  
  // ############################ HANFLE CHANGE ###################################
  // ############################ HANFLE CHANGE ###################################
  handleChange = (type, name, e)  => {
    // console.log('HANDLE CHANGE => ', e);
    // if (e.target.type === "checkbox") {
    //   value = e.target.checked;
    // }
    switch(type){
      default: break;
      case "profile":
        const temp = this.state.data;
        temp[name] = e.value;
        this.setState({data:temp, bChanged:true, bComplete:isObjectComplete(temp, exceptionList)});
        // console.log(type,' => ', name ,' => ', e ,' => ', this.state.data);
      break;
    }
  }
    
  // ############################ HANDLE IMAGE ###################################
  // ############################ HANDLE IMAGE ###################################
  handleImage = url => {
    // console.log('PROFILE Upload ', url);
    const temp = this.state.data;
    temp['image'] = url;
    this.setState({ data: temp, bChanged: true });
  };

  // ############################ SUBMIT ###################################
  // ############################ SUBMIT ###################################
  handleSubmit = async () => {
    // console.log('SUBMIT PROFILE : ', this.state.data); 
    if (this.props.user.idUser) {
        this.setState({ loading: true });
        await setLocalObject(this.profile);
        await setProfile(this.props.user.idUser, this.state.data);
        
        setTimeout(() => {
          this.setState({ loading: false, bChanged: false });
          toast.success("Your profile has been saved successfully", options)
        }, 1500)
    } 
  };
  
  // ############################ RENDER ###################################
  // ############################ RENDER ###################################
  render() {

    if(typeof this.state.data == 'object' && this.props.settings.profile){
      return (
        <div className={"Flex FlexCol JustifyCenter AlignCenter MinHigh80VH " +  this.props.settings.structure.outlet.class} style={styleMapping(this.props.settings.structure.outlet)}>      
          
          <InfoModal type={'profile'} show={(!this.state.bComplete && this.state.infoModal === null ? true : false)} onClose={() => {this.setState({infoModal: false})}} />
          <div className={"MaxWide400px TopMargin5 BottomMargin5 " + this.props.settings.profile.container.class } style={styleMapping(this.props.settings.profile.container)}>
            {/* <div className={"BRL Wide100 Padding10px TopMargin50px BottomMargin10px FS30 HeaderFont Capital"} >Profile Details</div> */}
            {/* <div className={"Resize " + this.props.settings.profile.image.class } style={styleMapping(this.props.settings.profile.image)} data-ratio={this.props.settings.profile.image.ratio}>
              <Image bEditMode={this.state.bEditMode} settings={this.props.settings.profile.image} idUser={this.state.data.id} url={this.state.data.image} onImageUpload={this.handleImage} ></Image>
            </div> */}

            <div className={"BRL col-lg-12 col-md-12 col-sm-12 Padding0px Flex FlexRow AlignCenter JustifyCenter"}>   
              <div className={"BRL col-lg-12 col-md-12 col-sm-12 Padding0px Flex FlexRow AlignCenter JustifyCenter "}>   
                  <button disabled={!this.state.bComplete} onClick={() => { this.handleSubmit() }} className={"FuturaH FS41 Flex1 HPadding10px btn " + (this.state.bChanged ? 'btn-danger' : 'btn-primary')}><div className="BRL Wide90 Capital">{(this.state.bChanged ? 'Save' : 'Saved')}</div><div className="BRL Wide10"><MoonLoader size={20} color={"#ffffff"} loading={this.state.loading}/></div></button>
                  <button className={"NoBorder FuturaH Flex1 FS41 Padding5px btn-light"} onClick={() => this.handleLogout()}>LOGOUT</button>
                  <button className={"NoBorder FuturaH Flex1 FS41 Padding5px btn BCPrimary FCWhite Hand"} onClick={() => {this.handleDelete()}}>DELETE</button>
              </div>
            </div>

            <div className={ this.props.settings.profile.details.class } style={styleMapping(this.props.settings.profile.details)}>   
              {Object.keys(this.state.data).map((key, index)=>{
                if(!belongsTo(key,this.invisibleFields)){
                  if(!this.state.bEditMode){  
                    return (
                      <div  key={key} className={"BRL Wide100 " + (index > 0 ? "TopMargin20px" : "")}>
                        <div className="BRL Wide100 HeaderFont Capital FS42 BottomMargin5px">{getEquivalent(key, this.equivalentFields, 1)}</div> 
                        <span className={ this.props.settings.profile.elements.class } style={styleMapping(this.props.settings.profile.elements)}>{getEquivalent(this.state.data[key], this.equivalentFields2, 1)}</span>
                      </div>);
                } else {
                  if(belongsTo(key,this.selectFields)){
                    return (
                      <div key={key} className={"BRL Wide100 " + (index > 0 ? "TopMargin20px" : "")}> 
                        <div className="BRL Wide100 HeaderFont Capital FS41 BottomMargin3px">{getEquivalent(key, this.equivalentFields, 1)}</div> 
                        <Select type="text" name={key} fields={key} classes={design.selectProfile} onChange={(type,e) => this.handleChange('profile', type, e)} value={this.state.data[key]} className="BRL col-lg-10 col-md-10 col-sm-10 form-control NoBorder BottomShadow7"/>
                      </div>
                      );
                    }
                    else{
                      return (
                        <div key={key} className={"BRL Wide100 " + (index > 0 ? "TopMargin20px" : "")}> 
                          <div className="BRL Wide100 HeaderFont Capital FS40 BottomMargin5px">{getEquivalent(key, this.equivalentFields, 1)}</div> 
                          <Input type="text" name={key} classes={"FS37 InputFont Padding10px"} onChange={(e) => this.handleChange('profile', e)} value={this.state.data[key]} className="BRL col-lg-10 col-md-10 col-sm-10 form-control NoBorder BottomShadow7"/>
                        </div>);
                    }
                  }
                  
                } else return null;
              })} 

              <Link className="" to={'/maps'}>
                <button disabled={!this.state.bComplete} className={"BRL col-lg-12 col-md-12 col-sm-12 NoBorder FuturaH Flex1 FS40 Padding10px btn-light TopMargin20px Capital BCTrans BottomShadow7"} >Go to the map</button>
              </Link>
                        
            
            </div>
            
        </div>  

      </div>);
      } else return null;

  }

  // ############################ HANDLE LOGOUT ###################################
  // ############################ HANDLE LOGOUT ###################################
  handleDelete = async () => {

    if(window.confirm('You are about to delete your account and profile?')){  
      await deleteProfile(this.idUser);
      this.handleLogout();
    }
  }

  // ############################ HANDLE LOGOUT ###################################
  // ############################ HANDLE LOGOUT ###################################
  handleLogout = async () => {

    await clearLocalObject();
    // console.log('LOGOUT => ', temp);
    this.props.logout(this.props.history);
    this.setState({data: this.profile});
  }
}

const mapDispatchToProps = dispatch => {
  return {
      sideMenuFunction: (bVal) => dispatch(actions.sideMenu(bVal)),
      navigate: (direction, history) => dispatch(actions.navigate(direction, history)), 
      logout: (history) => dispatch(actions.authLogout(history)),
  }
}
const mapStateToProps = (state) => { return {isAuthenticated: state.status.token !== null && state.status.token !== '' ? true : false, status:state.status, user:state.user, settings:state.settings, architecture:state.architecture};}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));


