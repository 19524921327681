import App from "./App";
import React from "react";
import ReactDOM from "react-dom";

import registerServiceWorker from "./registerServiceWorker";
import * as serviceWorker from "./serviceWorker";
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import reducer from "./store/reducers/general";

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

//ORDER IMPORTANT
import 'bootstrap/dist/css/bootstrap.css';
import "font-awesome/css/font-awesome.css";
import "./resources/style/css/all.css";
import 'bootstrap';
import 'bootstrap/dist/js/bootstrap.js';
import "./index.css";

// const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhances = compose;
const store = createStore(reducer, composeEnhances(applyMiddleware(thunk)));
library.add(fas);

const app = (
    <Provider store = {store}>
        <App />
    </Provider>
  )

  ReactDOM.render(app, document.getElementById("root"));
  registerServiceWorker();
  
  // If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
