import React from 'react';
import Select from 'react-select';
import * as Fields from "../../resources/constants/select";

class Select2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedOption: null };

    switch(props.fields){
        default:break;
        case "age": this.options = Fields.AGE; break;
        case "gender": this.options = Fields.GENDER; break;
        case "target_gender": this.options = Fields.TARGET_GENDER; break;
        case "target_age": this.options = Fields.TARGET_AGE; break;
        case "race": this.options = Fields.RACE; break;
        case "target_race": this.options = Fields.RACE; break;
        case "target_orientation": this.options = Fields.TARGET_ORIENTATION; break;
        case "orientation": this.options = Fields.ORIENTATION; break;
        case "target_stereotype": this.options = Fields.TARGET_STEREOTYPE; break;
        case "stereotype": this.options = Fields.STEREOTYPE; break;
        case "font": this.options = Fields.FONTS; break;
        case "visible": this.options = Fields.VISIBLE; break;
        case "political": this.options = Fields.POLITICAL; break;
        case "target_political": this.options = Fields.TARGET_POLITICAL; break;
        case "custom": this.options = props.list; break;
    }
  }

    handleChange = selectedOption => {
      // console.log(`Option selected B :`, selectedOption);
      this.props.onChange(this.props.name, selectedOption);
  };
  
  render() {
    const { name, style, classes, value } = this.props;

    return (
      // <Select placeholder={name} value={selectedOption} name={name} className={classes} styles={style} onChange={this.handleChange} options={this.options} isMulti={true} blurInputOnSelect={true} isSearchable={false} />
      <Select 
        placeholder={name} value={value} name={name} className={classes} styles={style} onChange={this.handleChange} options={this.options} isMulti={true} blurInputOnSelect={true} isSearchable={false} 
        components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }} menuPlacement="auto"
      />
    );

    
  }
}
    
export default Select2;

