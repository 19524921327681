import $ from 'jquery';
import { convertToRaw} from 'draft-js';
import _ from "lodash";
import * as design from "../../resources/constants/design";

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

// ############################ TRANSFORM STYLE  ###################################
// ############################ TRANSFORM STYLE  ###################################
export function styleMapping(element) {

    let styles = {};
    if(element.style){
        element.style = element.style.trim();
        let properties = element.style.split(";");
        
        properties.forEach(function(property) {
            if(property.trim().length>0){
                var tup = property.split(":");

                if(tup[1]){
                    // console.log('tup[1] : ', tup[1]);
                    if (!parseInt(tup[1], 10) || tup[1].slice(-1) === "%" || tup[1].slice(-2) === "vh" || tup[1].slice(-2) === "px" || tup[0] === "font-weight") {
                        switch (tup[0]) {
                          case "wordBreak":
                            styles["flexWrap"] = "wrap";
                            break;
                          default:
                            const tup2 = tup[0].split("-");
                            if (tup2.length > 1) { tup[0] = tup2[0].trim() + capitalize(tup2[1].trim());                }
                            styles[tup[0]] = tup[1].trim();
                            break;
                        }   
                    } else {
                        // console.log('INT');
                        // const temp = parseInt(tup[1].trim(),10);
                        const tup2 = tup[0].split("-");
                        if(tup2.length>1){tup[0] = tup2[0].trim()+capitalize(tup2[1].trim());}
                        styles[tup[0]] = parseInt(tup[1].trim(),10);
                    }
                }    
            }
        });
        
    }
    Object.assign(styles, {color: element.color, fontFamily: element.font });        
    if (element.background!==''){ Object.assign(styles, {backgroundColor: element.background }); }
    // console.log('styles: ',  styles);
    
    return styles;
};

// ##################################  CALCULATE HEIGHT ACCORDING TO RATIO  ####################################
// ##################################  CALCULATE HEIGHT ACCORDING TO RATIO  ####################################
function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


// ##################################  CALCULATE HEIGHT ACCORDING TO RATIO  ####################################
// ##################################  CALCULATE HEIGHT ACCORDING TO RATIO  ####################################
export function calculateHeight(ratio) {
    
    let windowSize = Math.ceil($(window).width());
    if (windowSize > 1920) { windowSize = 1920; }
    const height = windowSize * parseFloat(ratio); 
    const result = height.toString() + 'px'; 
    // console.log('calc : ', windowSize, ' ', ratio, ' ', height);


    return result;
}

// ##################################  IF ELEMENT BELONGS TO ARRAY  ####################################
// ##################################  IF ELEMENT BELONGS TO ARRAY  ####################################
export function belongsTo(element, array) {
    let bBelongs = false;

    for(const key in array){  
        if (array[key] === element){ bBelongs = true;}
    }

    return bBelongs;
}

// ##################################  IF ELEMENT BELONGS TO ARRAY  ####################################
// ##################################  IF ELEMENT BELONGS TO ARRAY  ####################################
export function getEquivalent(element, array, index) {
    let equivalent = element;

    for(const key in array){  
        if (array[key][0] === element){ equivalent = array[key][index];}
    }
    return equivalent;
}


export function processEquivalentObject(data, equivalent){
    let equivalentObject = {};

    for(const key in data){  
        equivalentObject[key] = getEquivalent(data[key], equivalent, 1);
    }
    // console.log('EQUIVALENT OBJECT C => ', equivalentObject);
    return equivalentObject;
}



// ############################ ARE OBJECTS EQUAL ###################################
// ############################ ARE OBJECTS EQUAL ###################################
export function isObjectEqual(obj1, obj2){
    const temp = _.isEqual(obj1, obj2);
    return temp;
}


// ############################ GET LOCAL DATA ###################################
// ############################ GET LOCAL DATA ###################################
export function getLocalObject(series) {
    try {
        let results = {}; 
        for (const key in series) {
            results= {...results,...{[key]: localStorage.getItem(key)}}
        }
        return results;
    } catch(e) {return 'error : ' + e;}
}

export function getLocalData(key) {
    try {
        const value = localStorage.getItem(key)
        if(value !== null) { return value; } else return null;
    } catch(e) { return 'error : ' + e; }
}

export function setLocalObject(series) {
    try { for (const key in series) { localStorage.setItem(key, series[key]);}
    } catch(e) {return 'error : ' + e;}
}

// ############################ SET LOCAL DATA ###################################
// ############################ SET LOCAL DATA ###################################
export async function setLocalData(key, value) {
    try { localStorage.setItem(key, value) } 
    catch (e) { console.log('error : ', e); }
}



// ############################ DELETE SERIES OF LOCAL DATA ANDROID ###################################
// ############################ DELETE SERIES OF LOCAL DATA ANDROID ###################################
export function deleteLocalObject(series) {
    try {
        for (const key in series) {
            localStorage.removeItem(key);
        }
        return getLocalObject(series);   
    } catch(e) {return 'error : ' + e;}
}


// ############################ CLEAR LOCAL DATA ###################################
// ############################ CLEAR LOCAL DATA ###################################
export async function clearLocalObject() {
    try { localStorage.clear() } 
    catch (e) { console.log('error : ', e); }
}

// ############################ TRANSFORM NULL TO '' ###################################
// ############################ TRANSFORM NULL TO '' ###################################
export function transformNullToString(data) {
    try {
        for (const key in data) {
            if(data[key] == null){
                data[key] = '';
            }
        }
        return data;   
    } catch(e) {return 'error : ' + e;}
}


// ############################ TRUNCATE TO D DECIMALS ###################################
// ############################ TRUNCATE TO D DECIMALS ###################################
export function truncate(v, d) {

    const val = Math.floor(v * Math.pow(10, d)) / Math.pow(10, d).toFixed(d);
    // console.log('TRUNCATE: ', val);
    return val;
}


// ############################ GET TIME IN OBJECT ###################################
// ############################ GET TIME IN OBJECT ###################################
export function getTime() {
    const date = new Date();
    const time = {'hours': date.getHours(), 'minutes': date.getMinutes(), 'seconds': date.getSeconds()}
    return time;
}

// ############################ GET DATE IN SPECIFIC FORMAT ###################################
// ############################ GET DATE IN SPECIFIC FORMAT ###################################
export function getDate() {
    const today = new Date();
    const dd = today.getDate();
    const mm = today.getMonth()+1; 
    const yyyy = today.getFullYear();
    const hours = today.getHours();
    const minutes = today.getminutes();
    const seconds = today.getSeconds();

    const result = {year: yyyy, month: mm, day: dd, hour: hours, minute: minutes, second: seconds};
    return result;
}

// ############################ FORMAT DATE IN SPECIFIC FORMAT ###################################
// ############################ FORMAT DATE IN SPECIFIC FORMAT ###################################
export function formatDate(data, format) {
    const day = new Date(data);
    let result = '';
    switch(format){
        default:break;
        case 'dd/mm/yyL': result = (day.getDate()<=9 ? '0' + day.getDate() : day.getDate()) + '/' + (day.getMonth()< 9 ? '0' + parseInt(day.getMonth() + 1 ,10) : parseInt(day.getMonth() + 1 ,10)) + '/' + day.getFullYear().toString().substr(-2);break;
        case 'dd/mm/yyyyL': result = (day.getDate()<=9 ? '0' + day.getDate() : day.getDate()) + '/' + (day.getMonth()< 9 ? '0' + parseInt(day.getMonth() + 1 ,10) : parseInt(day.getMonth() + 1 ,10)) + '/' + day.getFullYear().toString();break;
        case 'yy/mm/ddL': result = day.getFullYear().toString().substr(-2) + '/' + (day.getMonth()< 9 ? '0' + parseInt(day.getMonth() + 1 ,10) : parseInt(day.getMonth() + 1 ,10)) + '/' + (day.getDate()<=9 ? '0' + day.getDate() : day.getDate());break;
        case 'yyyy/mm/ddL': result = day.getFullYear().toString() + '/' + (day.getMonth()< 9 ? '0' + parseInt(day.getMonth() + 1 ,10) : parseInt(day.getMonth() + 1 ,10)) + '/' + (day.getDate()<=9 ? '0' + day.getDate() : day.getDate());break;
        case 'dd-mm-yyyyL': result = (day.getDate()<=9 ? '0' + day.getDate() : day.getDate()) + '-' + (day.getMonth()< 9 ? '0' + parseInt(day.getMonth() + 1 ,10) : parseInt(day.getMonth() + 1 ,10)) + '-' + day.getFullYear().toString();break;
        case 'yy-mm-ddL': result = day.getFullYear().toString().substr(-2) + '-' + (day.getMonth()< 9 ? '0' + parseInt(day.getMonth() + 1 ,10) : parseInt(day.getMonth() + 1 ,10)) + '-' + (day.getDate()<= 9 ? '0' + day.getDate() : day.getDate());break;
        case 'yyyy-mm-ddL': result = day.getFullYear() + '-' + (day.getMonth()< 9 ? '0' + parseInt(day.getMonth() + 1 ,10) : parseInt(day.getMonth() + 1 ,10)) + '-' + (day.getDate()<=9 ? '0' + day.getDate() : day.getDate());break;
        case 'M ddL': result = months[day.getMonth()] + ' ' + day.getDate();break;
        case 'day ddL': result = weekdays[day.getDay()] + ' ' + day.getDate();break;
        case 'M dd YYL': result = months[day.getMonth()] + ' ' + day.getDate() + ' ' + day.getFullYear();break;
        case 'dd/mm/yy': result = (day.getUTCDate()< 9 ? '0' + day.getUTCDate() : day.getUTCDate()) + '/' + (day.getUTCMonth()< 9 ? '0' + parseInt(day.getUTCMonth() + 1 ,10) : parseInt(day.getUTCMonth() + 1 ,10)) + '/' + day.getUTCFullYear().toString().substr(-2);break;
        case 'dd/mm/yyyy': result = (day.getUTCDate()< 9 ? '0' + day.getUTCDate() : day.getUTCDate()) + '/' + (day.getUTCMonth()< 9 ? '0' + parseInt(day.getUTCMonth() + 1 ,10) : parseInt(day.getUTCMonth() + 1 ,10)) + '/' + day.getUTCFullYear().toString();break;
        case 'yy/mm/dd': result = day.getUTCFullYear().toString().substr(-2) + '/' + (day.getUTCMonth()< 9 ? '0' + parseInt(day.getUTCMonth() + 1 ,10) : parseInt(day.getUTCMonth() + 1 ,10)) + '/' + (day.getUTCDate()< 9 ? '0' + day.getUTCDate() : day.getUTCDate());break;
        case 'yyyy/mm/dd': result = day.getUTCFullYear().toString() + '/' + (day.getUTCMonth()< 9 ? '0' + parseInt(day.getUTCMonth() + 1 ,10) : parseInt(day.getUTCMonth() + 1 ,10)) + '/' + (day.getUTCDate()< 9 ? '0' + day.getUTCDate() : day.getUTCDate());break;
        case 'yy-mm-dd': result = day.getUTCFullYear().toString().substr(-2) + '-' + (day.getUTCMonth()< 9 ? '0' + parseInt(day.getUTCMonth() + 1 ,10) : parseInt(day.getUTCMonth() + 1 ,10)) + '-' + (day.getUTCDate()< 9 ? '0' + day.getUTCDate() : day.getUTCDate());break;
        case 'yyyy-mm-dd': result = day.getUTCFullYear() + '-' + (day.getUTCMonth()< 9 ? '0' + parseInt(day.getUTCMonth() + 1 ,10) : parseInt(day.getUTCMonth() + 1 ,10)) + '-' + (day.getUTCDate()< 9 ? '0' + day.getUTCDate() : day.getUTCDate());break;
        case 'dd/mm hhmm': result = (day.getDate()<=9 ? '' + day.getDate() : day.getDate()) + '/' + (day.getUTCMonth()< 9 ? '' + parseInt(day.getUTCMonth() + 1 ,10) : parseInt(day.getUTCMonth() + 1 ,10)) + ' ' + (day.getHours()< 9 ? '0' + day.getHours() : day.getHours())+ (day.getMinutes()< 30 ? '00' : '30');break;
    
        
    }
    // console.log('FORMAT DATE => ', day, ' => ', result);
    
    return result;
}



// ############################ CHECK IF ALL KEYS HAVE A VALUE ###################################
// ############################ CHECK IF ALL KEYS HAVE A VALUE ###################################
export function isObjectComplete(data, exception) {
    let bComplete = true;

    for (const key in data) {
        if(!belongsTo(key, exception)){
            if(data[key] === null || data[key] === ''){
                bComplete = false;
            }
        }
    }
    // console.log('ISOBJECT COMPLETE => ', data, ' => ', bComplete);
    return bComplete;
}

// ############################ ALL KEYs TRUE ###################################
// ############################ ALL KEYs TRUE ###################################
export function isTrue(data) {
    let bComplete = true;
    for (const key in data) {
        if(data[key] === false){bComplete = false;}
    }
    return bComplete;
}

// ############################ REVERSE ARRAY ###################################
// ############################ REVERSE ARRAY ###################################
export function reverseArray(data) {
    return data.reverse();
}


// ############################ BASE64 CONVERTER ###################################
// ############################ BASE64 CONVERTER ###################################
export function base64Converter(data, bEncode) {
    let result = ''; 
    // console.log('BASE64 ORIGINAL ', bEncode, ' => ', data);
    switch(bEncode){
        default:break;
        case false: result = window.atob(data); break;
        case true: result = window.btoa(data); break;
    }
    return result;
}


// ############################ GENERATE LIST FOR SELECT ###################################
// ############################ GENERATE LIST FOR SELECT ###################################
export function generateCustomOptions(data, fields) {
  
    let results = []; 
    for(const key in data ){
        if(key > 0){
            results.push({'label': data[key][fields['label']], 'value': data[key][fields['value']]});
        }
    }
    return results;
}

// ############################ GET ARRAY INDEX ###################################
// ############################ GET ARRAY INDEX ###################################
export function getArrayIndex(data, value, column) {
    for(const key in data ){
        if(data[key][column] === value){
            return key
        }
    }
}


//########################### TRANSFORM DJANGO DATA INTO GEOJSON ###################################
//########################### TRANSFORM DJANGO DATA INTO GEOJSON ###################################
export const dataToChartFormat = (data) => {    
  
    let output = {};
    for (let key in data) {
        const stat = data[key];
        let results = [];
        for (let key2 in stat) {
            const name = key2;
            const val = stat[key2]['total'];
            // console.log('VAL => ', key2, ' => ', design.COLOR_CODES[key2]);
            const tempVal = { "title": name, "value": val, "color": design.COLOR_CODES[key2]};
            results.push(tempVal);           
        }
        output[key] = results
    }

    
    return output;
}

//########################### INTERPOLATE 2D ARRAY ###################################
//########################### INTERPOLATE 2D ARRAY ###################################
export const interpolate2D = (basis, values, spot ) => {    
    
    let results = null;
    for (let i=0;i<basis.length-1;i++) {
        const refM = basis[i]; 
        const valM = values[i]; 
        const refP = basis[i+1]; 
        const valP = values[i+1]; 
        if(spot >= refM && spot <= refP){ results = valM + (valP - valM) * ((spot - refM) / (refP - refM));}
        if(i === 0 && spot < refM ){ results = valM;}
        if(i === basis.length && spot < refP ){ results = valP;}
    }

    // console.log('INTERPOLATE2D => ', results);
    return results;
}

//########################### TRANSFORM LOCAL TO UTC ###################################
//########################### TRANSFORM LOCAL TO UTC ###################################
export const localToUTC = (date, action ) => {    
    
    let result = null;

    switch(action){
        default:break;
        case true:
            // const temp2 = date.getUTCFullYear() + '/' + parseInt(date.getUTCMonth() + 1, 10) + '/' +  date.getUTCDate()  + ' ' +  date.getUTCHours() + ':' + date.getUTCMinutes() + ':' + date.getUTCSeconds();
            const temp = new Date(date + ' 0:0');
            result = temp.toUTCString();
            // console.log('0 : ', temp, ' => ', result);
            break;
        case false:
            result = new Date(date).toString();
        break;
    }
    // console.log('localToUTC ', action, ' : ', date, ' => ', result);
    return result;
}

//########################### SCROLL TO TOP ###################################
//########################### SCROLL TO TOP ###################################
export const scrollTo = (val1, val2) => {    
    
    window.scrollTo(val1, val2);
}

//########################### SCROLL TO TOP ###################################
//########################### SCROLL TO TOP ###################################
export const transformArray = (action, data, field) => {    
    let results;
    
    switch(action){
        case true:
            results = [];
            for( const key in data){
                results.push(data[key]);
            }
        break;
        case false:
            results = {};
            for( const key in data){
                results[data[key]['field']] = data[key];
            }
        break;
        default:break;
    }
    return results;

}

//########################### SCROLL TO TOP ###################################
//########################### SCROLL TO TOP ###################################
export const selectListGenerator = (data, label, value) => {    
    
    let results = [];
    for( const key in data){
        // console.log('SELECTLISTGENERATOR 1 => ', data[key]);
        results.push({'label': data[key][label], 'value': data[key][value]});
    }
    // console.log('SELECTLISTGENERATOR 2 => ', results);
    return results;
}


//########################### GENERATE REFERENCE ###################################
//########################### GENERATE REFERENCE ###################################
export const generateReference = (len) => {   
    
    var p = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    return [...Array(len)].reduce(a=>a+p[~~(Math.random()*p.length)],'');
}

// ############################ GET TEXT FROM DRAFT ###################################
// ############################ GET TEXT FROM DRAFT ###################################
export function getTextDraft(data) {
    const blocks = convertToRaw(data.getCurrentContent()).blocks;
    return blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
}

//########################### GET ZONE OF SPOTS TO BE DISPLAYED ###################################
//########################### GET ZONE OF SPOTS TO BE DISPLAYED ###################################
export const getZone = (coord, zoom) => {
    
    const unit = 2;
    let index = 0;
    let points = []

    let scale = 2 + ( 18 - Math.floor(zoom));
    scale = Math.min(Math.max(1, scale), 15);

    // console.log('GETZONE 2 : ', scale);

    //DEFINE COORDINATES OF ZONE
    for (let i = -1; i<= 1; i=i+2){
        let x = coord[0] + ((i * scale * unit)/100)
        for (let j = -1; j<= 1; j=j+2){
            let y = coord[1] + ((j * scale * unit)/100)
            points[index] = [x, y];
            index++;
        }
    }
    return points;
}

//########################### RETURNS IF COORD INSIDE AREA ###################################
//########################### RETURNS IF COORD INSIDE AREA ###################################
export const isInsideZone = (coord, zone, record) => {
    
    let minX = zone[0][0];
    let maxX = zone[2][0];
    let minY = zone[2][1];
    let maxY = zone[1][1];

    // console.log(record['city'], ' ==> ', minX,' < ', coord[0] , ' < ', maxX, ' ===> ', minY,' < ', coord[1] , ' < ',maxY);

    if (coord[0]>=minX && coord[0]<=maxX && coord[1]>=minY && coord[1]<=maxY){
        return true;
    }
    return false;
}
