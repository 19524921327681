import React, {Component} from "react";
import * as fields from "../../resources/constants/select";

class Select extends Component {
  constructor(props) {
      super(props);
      this.getOptions(props);
    }

    
  UNSAFE_componentWillReceiveProps(props){
    this.getOptions(props);
  }


  getOptions(data){

    switch(data.fields){
      default:break;
      case "age": this.options = fields.AGE; break;
      case "gender": this.options = fields.GENDER; break;
      case "target_gender": this.options = fields.TARGET_GENDER; break;
      case "target_age": this.options = fields.TARGET_AGE; break;
      case "race": this.options = fields.RACE; break;
      case "target_race": this.options = fields.RACE; break;
      case "target_orientation": this.options = fields.TARGET_ORIENTATION; break;
      case "orientation": this.options = fields.ORIENTATION; break;
      case "target_stereotype": this.options = fields.TARGET_STEREOTYPE; break;
      case "stereotype": this.options = fields.STEREOTYPE; break;
      case "font": this.options = fields.FONTS; break;
      case "visible": this.options = fields.VISIBLE; break;
      case "political": this.options = fields.POLITICAL; break;
      case "target_political": this.options = fields.TARGET_POLITICAL; break;
      case "tag": this.options = fields.TAGS; break;
      case "type": this.options = fields.PAGETYPE; break;
      case "custom": this.options = data.list; break;      }
      if (data.fielda === 'custom'){
        // console.log('GETOPTIONS => ', data.fields, ' => ', this.options);
      }
    }


  handleChange = (e) => {
    const result = {'value': e.target.value, 'label': e.target[e.target.selectedIndex].getAttribute('data-attr')};
    this.props.onChange(this.props.name, result);
  }



  render() {
    const { name, classes, value } = this.props;

    // console.log('OPTIONS =>', this.options);

    return (
      <div className={"NoOutline NoBorder " + classes.options}>
      <select id={name} className={classes.container} value={value} onChange={e => this.handleChange(e)}>
        {this.options.map(option => (
          <option key={option['label'] + name} value={option['value']} data-attr={option['label']} >
            {option['label']}
          </option>
        ))}
      </select>
      </div>
    );
  }
}
export default Select;

