import React, { Component } from 'react'
import { styleMapping, generateReference } from "../utils/tools";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { Link } from 'react-router-dom';
import ContentEditable from "react-contenteditable";
import { Input } from "reactstrap";
import Select from "../elements/select";
import { extractImages } from "../services/editor";
import * as server from "../../resources/constants/api";
import $ from 'jquery';

export default class CWINDOW extends Component {
    constructor(props) {
        super(props)
        this.state = { structure: null, editor: props.editor, images: null, params: null, element: null }
    }

    componentDidMount(){ this.loadData(this.props); }
    UNSAFE_componentWillReceiveProps(props){ this.loadData(props); }
    loadData = async (data)  => { 
        let tempImages = await extractImages(data.structure['tag'], data.structure);;
        if (data !== null && data !== undefined) {        
            if(data.images !== null && data.images !== undefined){tempImages = data.images; }   
        }
        this.setState({structure: data.structure, editor: data.editor, images: tempImages}, function(){
            if (tempImages.length > 0){$('#0_ci').addClass('active');}
        });
    }

    // ############################ HANDLE NEW IMAGE ###################################
    // ############################ HANDLE NEW IMAGE ###################################
    deleteImage = (key) => {
        
        let tempImages = this.state.images;
        let tempImages2 = this.state.structure.structure.image.image.url;
        if (tempImages[key]['action'] === 'new'){
            tempImages.splice(key, 1);
            tempImages2.splice(key, 1);
        }
        else {
            tempImages[key]['action'] = 'delete';
            tempImages[key]['url'] = tempImages[key]['url'].replace(new RegExp(server.URL_SERVER_DJANGO, "g"), '');
            // console.log('DELETE IMAGE => ', tempImages[key], ' => ', );
            tempImages2.splice(key, 1);
        }
        // console.log('DELETE IMAGE 1 => ', key, ' => ', tempImages[key]);
        this.setState({'params': 'structure.image.image', element: this.state.structure.structure.image.image, 'images': tempImages},
        function(){ 
            this.handleChange(this.state.params, 'url', tempImages2);
            if (tempImages.length > 0){$('#0_ci').addClass('active');}
        });
    }
    
    handleImage = (e) => {
        const input = e.target;
        if (input.files && input.files.length) {
            if (input.files && input.files[0]) {
                const reader = new FileReader();
                reader.onloadend = (e) => {
                let image = reader.result;
                this.getParams('structure.image.image', this.state.structure.structure.image.image);
                let tempImages = this.state.images;
                let tempImages2 = this.state.structure.structure.image.image.url;
                const refGen = generateReference(20); 
                const refRelative = server.URL_SERVER_DJANGO_IMAGE_RELATIVE + '/' + this.state.structure.reference + '/' + refGen + '.png';
                const ref = server.URL_SERVER_DJANGO_IMAGE + '/' + this.state.structure.reference + '/' + refGen + '.png';
                tempImages.push({url: image, action: 'new', path: refRelative, pathAbsolute: ref});
                tempImages2.push({url: refRelative, action: ''});
                this.setState({'images': tempImages});
                this.handleChange(this.state.params, 'url', tempImages2);};
                reader.readAsDataURL(input.files[0]);
            }
        }
    } 
     
    // ############################ GET PARAMS WHEN SUBELEMENT CLICKED ###################################
    // ############################ GET PARAMS WHEN SUBELEMENT CLICKED ###################################
    getParams = (params, data) => {
        // console.log('GETPARAMS => ', params, ' => ', data);
        this.setState({element: data, params: params});
        this.props.onChange(params, this.state.structure, this.state.images, true);
    }


    // ############################ HANDLE INPUT CHANGE ###################################
    // ############################ HANDLE INPUT CHANGE ###################################
    handleChange(params, field, e){
        let temp = this.state.element;
        switch(field){
            case 'font': temp[field] = e.value; break;
            default: temp[field] = e; break;
        }
        this.setState({element: temp});
        this.props.onChange(params, this.state.structure, this.state.images, false);
    } 

    // ############################ RENDER ###################################
    // ############################ RENDER ###################################
    render() {
         
        if(this.state.structure){   

            const { editor, images } = this.state;
            const { structure } = this.state.structure;
            this.index = -1;
            this.height = structure.image.container.height * window.innerHeight;
            if(editor === true){   
                return (
                        <div key={'cw'} className="BRL Wide100">
                            {/* ##################### FIELD PARAMETERS ##################### */}
                            {/* ##################### FIELD PARAMETERS ##################### */}
                            {this.state.element && <div className="BRL Wide100 Padding10px BottomShadow7 BorderRadius5 BottomMargin20px">
                                <div className={"BRL Wide100 FS38 TitleFont BottomMargin5px HPadding10px Capital"}>{this.state.params}</div>
                                {Object.keys(this.state.element).map((key, index) => {
                                return (
                                    <React.Fragment key={index}>
                                    {key !== 'text' && key !== 'url' && 
                                    <li key={ 'c_' + key} className={"BRL NoBorder TopMargin5px HPadding5px " + ((key === 'class' || key === 'style') ? "col-lg-6 col-md-6 col-sm-12" : "col-lg-3 col-md-3 col-sm-4")} style={{listStyleType: 'none'}}>
                                    <div key={ 'l_' + key} className="BRL Wide100 BottomMargin2px ButtonFont Capital FS41">{key}</div>             
                                    
                                    {(key !== 'font' && key !== 'color' && key !== 'background') && <Input key={'f1_'+ key} type={(key === 'color' || key === 'background' ? 'color' : 'text')} name={key} onChange={(e) => this.handleChange(this.state.params, key, e.target.value)} value={this.state.element[key]} className={"BRL Wide100 NoBorder btn-light BCTrans InputFont FS40 TopMargin5px BottomShadow7"}/>}
                                    {(key === 'color' || key === 'background') && 
                                        <React.Fragment>
                                        <Input key={'f1_'+ key} type={'color'} name={key} onChange={(e) => this.handleChange(this.state.params, key, e.target.value)} value={this.state.element[key]} className={"BRL Wide30 NoBorder btn-light BCTrans InputFont FS40 TopMargin5px BottomShadow7"}/>
                                        <Input key={'f2_'+ key} type={'text'} name={key + '_code'} onChange={(e) => this.handleChange(this.state.params, key, e.target.value)} value={this.state.element[key]} className={"BRL Wide65 NoBorder btn-light BCTrans InputFont FS40 TopMargin5px LeftMargin5 BottomShadow7"}/>
                                        </React.Fragment>}
                                    {(key === 'font') && 
                                        <Select key={'f2_'+ key} name={key} id={'font'} value={this.state.element[key]} fields={key} 
                                        classes={{container:"BRL form-control NoBorder FS40 LabelFont Padding3px btn-light BCTrans", options:"BRL Wide100 TopMargin5px CenterAlign BottomShadow7"}}  
                                        onChange={(name, val) => this.handleChange(this.state.params, key, val)}></Select>}
                                    </li>}
                                    </React.Fragment>
                                );})}
                            </div>}

                            {/* ##################### DESIGN EDITOR ##################### */}
                            {/* ##################### DESIGN EDITOR ##################### */}
                            <div className={"" + structure.superContainer.class} style={styleMapping(structure.superContainer)}>
                                <div className={"" + structure.container.class} style={styleMapping(structure.container)}>
                                    
                                    {editor && <div className={"Absolute Top20px Right20px Padding5px btn-light BorderRadius5 BottomShadow7 z2000"}>
                                        <div onClick={() => {this.getParams('structure.superContainer', structure.superContainer)}} className={"Hand BRL HPadding5px VPadding0px btn-danger BorderRadius5 BottomShadow7 z1000 "}><FontAwesomeIcon icon={"crop-alt"} style={{fontSize: 13}} color="white"/></div> 
                                        <div onClick={() => {this.getParams('structure.container', structure.container)}} className={"Hand BRL LeftMargin5px HPadding5px VPadding0px btn-light BorderRadius5 BottomShadow7 z1000 "}><FontAwesomeIcon icon={"pen"} style={{fontSize: 13}} color="black"/></div> 
                                    </div>}
                                    
                                    {/* IMAGES */}
                                    <div key={'_img'} className={" " + structure.image.container.class} style={{ ...{height: this.height},...styleMapping(structure.image.container)}}>
                                        <div onClick={() => {this.getParams('structure.image.container', structure.image.container)}} className={"Hand Absolute Top20px Left20px HPadding5px VPadding0px btn-primary BorderRadius5 BottomShadow7 z2000"}><FontAwesomeIcon icon={"crop-alt"} style={{fontSize: 13}} color="white"/></div>
                                        <div onClick={() => {this.getParams('structure.image.image', structure.image.image)}} className={"Hand Absolute Bottom20px Left20px HPadding5px VPadding0px btn-info BorderRadius5 BottomShadow7 z2000"}><FontAwesomeIcon icon={"image"} style={{fontSize: 13}} color="white"/></div>
                                        
                                        {images!== null && <div id="carousel2Edit" className="carousel slide High100" data-ride="carousel">
                                            
                                            {/* ##################### INDICATORS ##################### */}
                                            <ol className="carousel-indicators">                                        
                                                {Object.keys(images).map(key => {
                                                    return (
                                                        <React.Fragment key={'inn_c_' + key}>
                                                            {images[key]['action'] !== 'delete' && <li data-target="#carousel" data-slide-to={key} className={(key === 0 ? "active" : "")}></li>}
                                                        </React.Fragment>
                                                    ); 
                                                })}
                                            </ol>
    
                                            {/* ##################### IMAGES ##################### */}
                                            <div className="carousel-inner High100">
                                                {Object.keys(images).map(key => {
                                                    this.index = this.index + 1;
                                                    if(images[key]['action'] === 'delete') { this.index--;}
                                                    const index = this.index; 
                                                    return (
                                                        <React.Fragment key={'inn_c_e_' + key}>
                                                        {images[key]['action'] !== 'delete' && <div id={index + '_ci'} key={key + '_i'} className={"Wide100 High100 carousel-item " + key + "-" + index + " " + (this.index < 1 ? "" : "" )}>
                                                            <div onClick={() => {this.deleteImage(key)}} className={"Hand Absolute Top10px Right10px HPadding5px VPadding0px btn-danger BorderRadius5 BottomShadow7 z2000"}><FontAwesomeIcon icon={"trash"} style={{fontSize: 12}} color="white"/></div>
                                                            <div className={"High100 Flex FlexRow JustifyCenter AlignCenter"}>
                                                                <img className={"d-block " + structure.image.image.class} style={styleMapping(structure.image.image)} src={images[key]['url']} alt={"Image " + index} />
                                                            </div>
                                                        </div>}
                                                        </React.Fragment>

                                                        // <React.Fragment key={'inn_c_e_' + key}>
                                                        // {images[key]['action'] !== 'delete' && <div key={key + '_i'} className={"carousel-item High100 Wide100 " + key + " " + (this.index === 0 ? "active" : "")}>
                                                        // <div onClick={() => {this.deleteImage(key)}} className={"Hand Absolute Top10px Right10px HPadding5px VPadding0px btn-danger BorderRadius5 BottomShadow7 z2000"}><FontAwesomeIcon icon={"trash"} style={{fontSize: 12}} color="white"/></div>
                                                        //     <img className={"d-block " + structure.image.image.class} style={styleMapping(structure.image.image)} src={images[key]['url']} alt={"Image " + key} />
                                                    );
                                                    
                                                })}
                                            </div>

                                            {/* ##################### ARROWS ##################### */}
                                            {/* ##################### ARROWS ##################### */}
                                            {this.state.structure.structure.image.image.url.length > 1 && <React.Fragment key={'action_c_'}>
                                            <a className="carousel-control-prev" href="#carousel2Edit" role="button" data-slide="prev">
                                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span className="sr-only">Previous</span>
                                            </a>

                                            <a className="carousel-control-next" href="#carousel2Edit" role="button" data-slide="next">
                                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span className="sr-only">Next</span>
                                            </a>
                                            </React.Fragment>}  
                                        </div>}

                                        {/* ##################### ADD IMAGE ##################### */}
                                        {/* ##################### ADD IMAGE ##################### */}
                                        <div className={"Hand Absolute Bottom10px LeftMargin30 Wide40 Padding10px BorderRadius50 BottomShadow7 z2000 btn btn-primary"}>
                                            <button key={'add_image'} className={"BRL Wide100 FS40 ButtonFont NoBorder Capital"} style={{'color':'inherit', 'backgroundColor':'inherit'}}>add image</button>
                                            <input onChange={(e)=> this.handleImage(e)} className="Hand Absolute Wide100 High100 Left0px Top0px z1000 Hand" type="file" style={{opacity: 0}} />        
                                        </div>
                                    </div>

                                    {/* INFO */}
                                    <div key={'_info'} className={"BR " + structure.info.container.class} style={styleMapping(structure.info.container)}>
                                        <div onClick={() => {this.getParams('structure.info.container', structure.info.container)}} className={"Hand Absolute Top10px Left10px HPadding5px VPadding0px btn-primary BorderRadius5 BottomShadow7 z1000"}><FontAwesomeIcon icon={"crop-alt"} size="xs" color="white"/></div>
                                        <div onClick={() => {this.getParams('structure.info.graphic', structure.info.graphic)}} className={"Hand NoBorder NoOutline VPadding5px btn-light " + structure.info.graphic.class} style={styleMapping(structure.info.graphic)}>{structure.info.graphic.icon !== '' && <FontAwesomeIcon icon={structure.info.graphic.icon} />} </div>
                                        <ContentEditable html={structure.info.title.text} disabled={false} onChange={(e) => this.handleChange('structure.info.title', 'text', e.target.value)} onClick={() => {this.getParams('structure.info.title', structure.info.title)}} className={"Hand NoBorder NoOutline btn-light " + structure.info.title.class} style={styleMapping(structure.info.title)} />
                                        <ContentEditable html={structure.info.description.text} disabled={false} onChange={(e) => this.handleChange('structure.info.description', 'text', e.target.value)} onClick={() => {this.getParams('structure.info.description', structure.info.description)}} className={"Hand NoBorder NoOutline btn-light " + structure.info.description.class} style={styleMapping(structure.info.description)} />
                                        <ContentEditable html={structure.info.link.text} disabled={false} onChange={(e) => this.handleChange('structure.info.link', 'text', e.target.value)} onClick={() => {this.getParams('structure.info.link', structure.info.link)}} className={"Hand NoBorder NoOutline btn-light " + structure.info.link.class} style={styleMapping(structure.info.link)} />
                                    </div>                   
                                </div>
                            </div>        
                        </div>        
                );
            } else {
                return (    
                            <div key={'cw_e'} className={"BRL " + structure.container.class} style={styleMapping(structure.container)}>
                                {/* IMAGES */}
                                <div key={'img_'} className={" " + structure.image.container.class} style={{ ...{height: this.height},...styleMapping(structure.image.container)}}>
                                                           
                                        {images!== null && <div id="carousel2Edit" className="carousel slide High100" data-ride="carousel">
                                        {/* ##################### INDICATORS ##################### */}
                                        <ol className="carousel-indicators">                                        
                                            {Object.keys(images).map(key => {
                                                return (
                                                    <React.Fragment key={'inn_c_' + key}>
                                                        {images[key]['action'] !== 'delete' && <li data-target="#carousel" data-slide-to={key} className={(key === 0 ? "active" : "")}></li>}
                                                    </React.Fragment>
                                                ); 
                                            })}
                                        </ol>
    
                                        {/* ##################### IMAGES ##################### */}
                                        <div className="carousel-inner High100">
                                        {Object.keys(images).map(key => {
                                            this.index = this.index + 1;
                                            if(images[key]['action'] === 'delete') { this.index--;}
                                            return (
                                            <React.Fragment key={'inn_c_e_' + key}>{images[key]['action'] !== 'delete' && 
                                            <div key={key + '_i'} className={"carousel-item High100 Wide100 " + key + " " + (this.index === 0 ? "active" : "")}>
                                                <img className={"d-block " + structure.image.image.class} style={styleMapping(structure.image.image)} src={images[key]['url']} alt={"Image " + key} />
                                            </div>}
                                            </React.Fragment>);
                                                })}
                                            </div>
                                            
                                            {/* ##################### ARROWS ##################### */}
                                            {/* ##################### ARROWS ##################### */}
                                            {this.state.structure.structure.image.image.url.length > 1 && <React.Fragment key={'action_c_'}>
                                            <a className="carousel-control-prev" href="#carousel2Edit" role="button" data-slide="prev">
                                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span className="sr-only">Previous</span>
                                            </a>

                                            <a className="carousel-control-next" href="#carousel2Edit" role="button" data-slide="next">
                                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span className="sr-only">Next</span>
                                            </a>
                                            </React.Fragment>}  
                                        </div>}    

                                    {/* {images!== null && <div key={'carousel_'} id="carousel2" className="carousel slide High100" data-ride="carousel">
                                        <ol key={'ind_'} className="carousel-indicators">                                        
                                            {Object.keys(images).map(key => {
                                                return (
                                                    <React.Fragment key={'ci_c_' + key}>
                                                        {images[key]['action'] !== 'delete' && <li key={'ci_' + key} data-target="#carousel" data-slide-to={key} className={(key === '0' ? "active" : "")}></li>}
                                                    </React.Fragment>
                                                ); 
                                            })}
                                        </ol>
                                        <div key={'inn_'} className="carousel-inner High100">
                                            {Object.keys(images).map(key => {
                                                return (<React.Fragment key={'inn_c_' + key}>
                                                    {images[key]['action'] !== 'delete' && 
                                                    <div key={'cii_' + key} className={"carousel-item High100 Wide100 " + key + " " + (key === '0' ? "active" : "")}>
                                                        <img key={'img_' + key} className={"d-block " + structure.image.image.class} style={styleMapping(structure.image.image)} src={images[key]['url']} alt={"Image " + key} />
                                                    </div>}
                                                    </React.Fragment>);
                                            })}
                                        </div>

                                        {this.state.structure.structure.image.image.url.length > 1 && <React.Fragment key={'action_c_'}>
                                        <a key={'prev_'} className="carousel-control-prev" href="#carousel2" role="button" data-slide="prev">
                                            <span key={'1_'} className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span key={'2_'} className="sr-only">Previous</span>
                                        </a>

                                        <a key={'next_'} className="carousel-control-next" href="#carousel2" role="button" data-slide="next">
                                            <span key={'1_'} className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span key={'2_'} className="sr-only">Next</span>
                                        </a>
                                        </React.Fragment>}   
                                    </div>} */}
                                </div>

                                {/* INFO */}
                                <div key={'info_'} className={"BR " + structure.info.container.class} style={styleMapping(structure.info.container)}>
                                    {structure.info.graphic.icon !== '' && <div key={'icon_'} className={"Hand NoBorder NoOutline " + structure.info.graphic.class} style={styleMapping(structure.info.graphic)}><FontAwesomeIcon icon={structure.info.graphic.icon} /> </div>}
                                    {structure.info.title.icon !== '' &&  <div key={'title_'} className={"Hand NoBorder NoOutline " + structure.info.title.class} style={styleMapping(structure.info.title)}>{structure.info.title.text}</div>}
                                    {structure.info.description.icon !== '' && <div key={'desc_'} className={"Hand NoBorder NoOutline " + structure.info.description.class} style={styleMapping(structure.info.description)}>{structure.info.description.text}</div>}
                                    {structure.info.link.icon !== '' && <div key={'link_'} className={"Hand NoBorder NoOutline " + structure.info.link.class} style={styleMapping(structure.info.link)}>{structure.info.link.text}</div>}
                                </div>                   
                            </div>
                        );
            }

            } else return null;
    }
}

