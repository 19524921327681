import Axios from 'axios';
import { getLocalData } from "../utils/tools";
import * as server from "../../resources/constants/api";
// import * as settings from "../../resources/data/settings";

//########################### GET SETTINGS ###################################
//########################### GET SETTINGS ###################################
export async function getAdmin(type) {
    // let results = {}
    // results = settings.SETTINGS;
    // return results;
    
    const token = await getLocalData('token');
    let headers = {'headers': {'authorization': `Token ${token}`}}
    if (token === '' || token == null){headers = {};}
    let url = server.URL_SERVER_DJANGO+'/useradmin/' + type;
    return await Axios.get(url, headers).then(async res => {
        const temp = JSON.parse(res.data.results);
        // console.log('GETADMIN => ', temp.settings);
        return temp.settings;
    }).catch(err => console.log(err));
};

//########################### GET SETTINGS ###################################
//########################### GET SETTINGS ###################################
export async function setAdmin(type, data) {
    // settings.SETTINGS =  data;
    // return true;

    const token = await getLocalData('token');
    let headers = {'headers': {'authorization': `Token ${token}`}}
    if (token !== ''){
        // console.log('DATA => ', data);
        data = {'settings': data};
        let url = server.URL_SERVER_DJANGO+'/useradmin/' + type;
        return Axios.post(url, data, headers).then(async res => {return true;}).catch(err => {return err;});
    }
};


//########################### ARCHIVING / RESTORE CONTROL  ###################################
//########################### ARCHIVING / RESTORE CONTROL  ###################################
export async function geolocationDataManagement(type) {

    const token = await getLocalData('token');
    let headers = {'headers': {'authorization': `Token ${token}`}}
    if (token !== ''){
        let data = {'action': type};
        // console.log('geolocationDataManagement => ', data);
        let url = server.URL_SERVER_DJANGO+'/useradmin/server';
        return Axios.post(url, data, headers).then(async res => {
            return { status: true, data: res.data.data };
        }).catch(err => {return err;});
    }
};
