
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { styleMapping } from "../utils/tools";
import * as design from "../../resources/constants/design";
import FilterMenu2 from '../core/filterMenu2';

export default class ModalFilter extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }
  

  // ############################ RENDER ###################################
  // ############################ RENDER ###################################
  render() {
    const {onClose, show, data, onFilter } = this.props;

    return (
      <Modal centered show={show} size={design.MODAL_FILTER.size} className={"BCTransparent1 z2000"} aria-labelledby="contained-modal-title-vcenter" style={styleMapping(design.MODAL_FILTER.style.container)} onHide={() => {onClose()}}>
        

        {design.MODAL_FILTER.content.title !== '' && <Modal.Header style={styleMapping(design.MODAL_FILTER.style.header)} className="z200" closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className={"BRL Wide100"} >
          <span className={"" + design.MODAL_FILTER.style.title.class} style={styleMapping(design.MODAL_FILTER.style.title)}>
            {design.MODAL_FILTER.content.title}
          </span>
        </Modal.Title>
      </Modal.Header>}

      <Modal.Body className="z90 Padding0" >
        <React.Fragment>
          <span className={design.MODAL_FILTER.style.body.class} style={styleMapping(design.MODAL_FILTER.style.body)}>{design.MODAL_FILTER.content.body}</span>  
          <div className="BRL Wide100"><FilterMenu2 data={data} open={true} onFilter={e => {onFilter(e)}} /></div>
        </React.Fragment>
      </Modal.Body>
      
      
      
    </Modal>
    );
  }
  
}