import Axios from 'axios';
import { getLocalData, base64Converter, getTextDraft, interpolate2D, localToUTC } from "../utils/tools";
import { EditorState, ContentState} from 'draft-js';
import { isPositionExempt } from './geolocation';
import * as server from "../../resources/constants/api";
import { formatDate } from "../utils/tools";
import { toast } from 'react-toastify'; 
import _ from "lodash";

const options = {
    autoClose: 1500, 
    type: toast.TYPE.INFO,
    hideProgressBar: false
};

//############################################# MANAGE BSUINESS #############################################
//############################################# MANAGE BSUINESS #############################################
export async function manageBusinesses(data) {
    let key=-1;
    let result = JSON.parse(JSON.stringify(data));
    const length = result.length;
    for(let i=0; i< length; i++){
        key++;
        if(key>0){
            const obj = data[key];
            const action = obj.action;
            const images = obj.images;
            const temp3 = getTextDraft(obj.description);
            result[key].description = base64Converter(encodeURIComponent(temp3), true);
            
            obj.action = '';
            delete result[key].action;
            delete result[key].images;
            
            //MANAGING DB
            switch(action){
                default:break;
                case "new": 
                    delete result[key].id_business;
                    result[key]['id_business'] = await createBusiness(result[key]);
                    obj.id_business = result[key]['id_business'];
                break;
                
                case "delete": 
                    await deleteBusiness(result[key]);
                    data.splice(key,1);
                    result.splice(key,1);
                    key--;
                break;
                
                case "changed": 
                    await setBusiness(result[key]);
                break;
            }
                    
            //MANAGING IMAGES
            if(obj){
                    // console.log('PRE HANDLING IMAGES => ', images);
                    if(isChanged(images)){ await setImages({images: images, id: obj.id_business});}
                    for(const key2 in images){
                    if(obj.images[key2].action === 'delete'){ delete obj.images[key2];}
                    else if(obj.images[key2].url.substring(0,4) === 'data'){ 
                        obj.images[key2].url = server.URL_MEDIA_BUSINESS_RELATIVE + '/' + obj.id_business + '/business/' + obj.images[key2].name;
                    }
                    if(obj.images[key2]){ obj.images[key2].action = '';}
                }
            }
                
            
        }    
    }
    return data;
}

//############################################# BUSINESS FUNCTIONS WITH ID USER #############################################
//############################################# BUSINESS FUNCTIONS WITH ID USER #############################################
export async function getBusinesses(data, results, request) {
    let ids = [], 
        temp, 
        tempResults = [];

    const token = await getLocalData('token');
    let headers = {'headers': {'authorization': `Token ${token}`}};
    if (token === '' || token === null){headers = {};}
    let businesses = await Axios.get(`${server.URL_SERVER_DJANGO}/business/user/?id_user=${data.id}`, headers).then(res => {
        return res.data;
    });   
    
    // BASE64 TO TEXT
    for (const key in businesses){
        ids.push(businesses[key].id_business); 
        businesses[key] = {...businesses[key], action:''};
        // console.log(' 3 => ',businesses[key].id_business, ' => ', decodeURIComponent(decodeURIComponent(base64Converter(businesses[key].description, false))));
        if(request === 'info'){temp = decodeURIComponent(decodeURIComponent(decodeURIComponent(base64Converter(businesses[key].description, false))));}
        else {temp = EditorState.createWithContent(ContentState.createFromText(decodeURIComponent(decodeURIComponent(decodeURIComponent(base64Converter(businesses[key].description, false))))));}
        businesses[key].description = temp;
    }

    // ADDING IMAGES
    // let images = await Axios.get(`${server.URL_SERVER_DJANGO}/business/images/?id=${ids}`, headers).then(res => {return res.data;});    
    for (const key in businesses){
        // const id = businesses[key].id_business; 
        // if(images[id]){businesses[key] = {...businesses[key], images:images[id]};}
        // else {businesses[key] = {...businesses[key], images:[]};}
        tempResults.push(businesses[key]);
    }

    // SORTING ARRAY
    tempResults = tempResults.sort((a,b) => (a.companyname > b.companyname) ? 1 : ((b.companyname > a.companyname) ? -1 : 0))
    results = results.concat(tempResults)

    // console.log('GETBUSINESSES GETIMAGES 3 => ', results);
    return results;
}


export async function getImages(ids) {   
    const token = await getLocalData('token');
    let headers = {'headers': {'authorization': `Token ${token}`}}; 
    if (token === '' || token === null){headers = {};}
    return await Axios.get(`${server.URL_SERVER_DJANGO}/business/images/?id=${ids}`, headers).then(res => {
    
        // console.log('GETIMAGES 0 >> ', res.data);
        return res.data;
    });    
}



//############################################# BUSINESS FUNCTIONS WITH ID BSUINESS #############################################
//############################################# BUSINESS FUNCTIONS WITH ID BSUINESS #############################################
export async function getBusiness(data) {
    const token = await getLocalData('token');
    let headers = {'headers': {'authorization': `Token ${token}`}};
    if (token === '' || token === null){headers = {};}
    return await Axios.get(`${server.URL_SERVER_DJANGO}/business/${data.id}/`, data, headers).then(res => {
        toast.success("Your profile has been saved successfully", options);
        return res.data;
    });    
}

export async function createBusiness(data)  {
    const token = await getLocalData('token');
    let headers = {'headers': {'authorization': `Token ${token}`}};
    if (token === '' || token === null){headers = {};}
    console.log('BUSINESS CREATED 1 => ', token, ' => ', data );
    const result = await Axios.post(`${server.URL_SERVER_DJANGO}/business/business/`, data, headers).then(res => {
        console.log('BUSINESS CREATED 2 => ', res.data);
        return res.data;
    } );
    
    const id = data.id_user
    const data2 = {'is_business': true};
    await Axios.patch(`${server.URL_SERVER_DJANGO}/users/${id}/`, data2, headers).then(res => {
        // console.log('UPDATING PROFILE DATA: CREATE BUSINESS');
        return res.data;
    });
    return parseInt(result.id_business, 10); 
}

export async function setBusiness(data) {
    const token = await getLocalData('token');
    let headers = {'headers': {'authorization': `Token ${token}`}};
    if (token === '' || token === null){headers = {};}
    const data2 = JSON.parse(JSON.stringify(data));
    delete data2['id_business'];
    // return await Axios.put(`${server.URL_SERVER_DJANGO}/business/business/${data.id_business}/`, {'data': data2}, headers).then(res => {
    return await Axios.put(`${server.URL_SERVER_DJANGO}/business/business/${data.id_business}/`, {'data': data2}, headers).then(res => {
        // console.log('SET BUSINESS 2 : ', data);
        return res.data;
    });
}

export async function deleteBusiness(data) {
    const token = await getLocalData('token');
    let headers = {'headers': {'authorization': `Token ${token}`}};
    if (token === '' || token === null){headers = {};}
    return await Axios.delete(`${server.URL_SERVER_DJANGO}/business/business/${data.id_business}/`, headers).then(res => {
        // console.log('DELETE BUSINESS A => ', res.data);
        return res.data;
    });
}


export async function setImages(data) {
    const token = await getLocalData('token');
    let headers = {'headers': {'authorization': `Token ${token}`}};
    if (token === '' || token === null){headers = {};}

    // console.log('SETIMAGES => ', data, ' => ', headers);
    return await Axios.post(`${server.URL_SERVER_DJANGO}/business/images/`, {'data': data}, headers).then(res => {
        return res.data;
    });
}

//############################################# ACTIVITY FUNCTIONS #############################################
//############################################# ACTIVITY FUNCTIONS #############################################

export async function getActivities(data, results) {
    const token = await getLocalData('token');
    let headers = {'headers': {'authorization': `Token ${token}`}};
    if (token === '' || token === null){headers = {};}
    
    const activities = await Axios.get(`${server.URL_SERVER_DJANGO}/business/activity/?id=${data.id}`, headers).then(res => {
        // console.log('GET ACTIVITIES 2 => ', res.data);
        return res.data;
    });
    
    for (const key in activities){
        let checkDate = new Date();
        checkDate.setDate(checkDate.getDate() - 21);
        // console.log('ACTIVIES A : ', new Date(activities[key]['date']), ' < ', checkDate , ' => ', new Date(activities[key]['date']) < checkDate);
        if(new Date(activities[key]['date']) >= checkDate){
            activities[key].description = decodeURIComponent(decodeURIComponent(decodeURIComponent(base64Converter(activities[key].description, false))));
            activities[key].business.description = decodeURIComponent(decodeURIComponent(base64Converter(activities[key].business.description, false)));
            activities[key]['date'] = formatDate(new Date(activities[key]['date']), 'M dd YYL');
            results.splice(1, 0, activities[key]); 
        }
    }
    // console.log('GET ACTIVITIES 3 => ', results);
    return results;
}

//########################### GET ACTIVITY ###################################
//########################### GET ACTIVITY ###################################
export async function getActivity(data) {
    const token = await getLocalData('token');
    let headers = {'headers': {'authorization': `Token ${token}`}};
    if (token === '' || token === null){headers = {};}
    return await Axios.get(`${server.URL_SERVER_DJANGO}/business/activity/?id=${data.id}/`, data, headers).then(res => {
        // console.log('SETPROFILE 2 => ',res.data);
        return res.data;
    });
}
    
//########################### CREATE ACTIVITY ###################################
//########################### CREATE ACTIVITY ###################################
export async function createActivity(data2, images) {
    const token = await getLocalData('token');
    let headers = {'headers': {'authorization': `Token ${token}`}};
    if (token === '' || token === null){headers = {};}
    
    let temp = base64Converter(encodeURIComponent(encodeURIComponent(encodeURIComponent(data2.description))), true);
    if (temp === 'Cg=='){ temp = '';}
    let temp2 = base64Converter(encodeURIComponent(encodeURIComponent(encodeURIComponent(data2.business.description))), true);
    if (temp2 === 'Cg=='){ temp2 = '';}
    
    //SAVING IMAGES
    // console.log('CREATE ACTIVITY IMAGES => ', images);
    await Axios.post(`${server.URL_SERVER_DJANGO}/business/activity/images/`, {'data': images}, headers).then(res => {});

    //SAVING ACTIVITY
    let data = JSON.parse(JSON.stringify(data2));
    data.description = temp;            
    data.business.description = temp2;            
    data.date = localToUTC(data.date, true); 
    return await Axios.post(`${server.URL_SERVER_DJANGO}/business/activity/?id=${data.business.id_business}`, {'data': data}, headers).then(res => {
        return res.data;
    });
}

//########################### SAVES MODIFY ACTIVITY ###################################
//########################### SAVES MODIFY ACTIVITY ###################################
export async function setActivity(id_business, data, images) {
    const token = await getLocalData('token');
    let headers = {'headers': {'authorization': `Token ${token}`}};
    if (token === '' || token === null){headers = {};}
    let data2 = _.cloneDeep(data);
    data2 = preSetActivity(data2);

    //SAVING IMAGES
    // console.log('SETACTIVITY IMAGES => ', images);
    await Axios.post(`${server.URL_SERVER_DJANGO}/business/activity/images/`, {'data': images}, headers).then(res => {});

    //SAVING ACTIVITY
    return await Axios.put(`${server.URL_SERVER_DJANGO}/business/activity/?id=${id_business}`, {'data': data2}, headers).then(res => {
        // console.log('setActivity 2 => ',res.data);
        return res.data;
    });    
}
 

//########################### PRESET ACTIVITY ###################################
//########################### PRESET ACTIVITY ###################################
export function preSetActivity(data) {
    for (const key in data){
        // console.log('PRESET ACTIVITY 1 => ', data[key]);
        data[key].description = base64Converter(encodeURIComponent(encodeURIComponent(encodeURIComponent(data[key].description))), true);
        if (data[key].description === 'Cg=='){ data[key].description = '';}
        data[key].business.description = base64Converter(encodeURIComponent(encodeURIComponent(encodeURIComponent(data[key].business.description))), true);            
        data[key].date = localToUTC(data[key].date, true); 
    }
    return data
}


//########################### MANAGE EVENT VIEWS ###################################
//########################### MANAGE EVENT VIEWS ###################################
export const manageEventViews = async (data) => {
    const token = await getLocalData('token');
    let headers = {'headers': {'authorization': `Token ${token}`}};
    if (token !== ''){

        return Axios.put(server.URL_SERVER_DJANGO+'/business/activity/views', {data: data}, headers).then(async res => {
            return true;
        }).catch(err => {})
    }
}


//########################### GENERATE REFERENCE ###################################
//########################### GENERATE REFERENCE ###################################
export function generateReference(length) {
    let reference = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for ( var i = 0; i < length; i++ ) {
       reference += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return reference;
 }

//########################### GETIMAGEBUSINESS ###################################
//########################### GETIMAGEBUSINESS ###################################
 export function getImagesBusiness(id) {
    const list = [];
    return list;
 }
 
//########################### ISCHANGED ###################################
//########################### ISCHANGED ###################################
 export function isChanged(data) {
    let bChanged = false;
    for(const key in data){if (data[key].action!==''){bChanged = true;}}
    return bChanged;
 }



 //####################### CALCULATES PRICE AND CHECKS IF ACTIVITY DOESNT OVERLAP WITH PREVIOUS ACTIVITY #######################
 //####################### CALCULATES PRICE AND CHECKS IF ACTIVITY DOESNT OVERLAP WITH PREVIOUS ACTIVITY #######################
 export function processActivity(activity, activities, reference) {
    let results = {};
    results['errors'] = getTimeOverlay(activity, activities);
    results['price'] = calculateFare(activity, reference, results['errors']['data']);
    // console.log('PROCESS ACTIVITY => ', results['price']);
    
    // results['price'] = {'price' : 0, 'discount' : 0, 'total' : 0};
    return results;
 }

 //####################### CAPTURES WITH TIMESLOTS HAS ALREADY BEEN PAID FOR #######################
 //####################### CAPTURES WITH TIMESLOTS HAS ALREADY BEEN PAID FOR #######################
 export function getTimeOverlay(activity, activities) {
    let results = [];
    let simple = [];
    
    //GET IN ARRAY ALL PREVIOUS ACTIVITIES TIME SLOTS
    let timeslotsActivities = [];
    for(const key in activities){
        const act = activities[key];
        if(act['id_event']){
            const timeslot = getTimeslots(act['date'], act['start'], act['end']);  
            // console.log('##############  OLD ACTIVITY ', act['id_event'], ' => ', timeslot);
            timeslotsActivities.push({ id:act['id_event'], timeslot: timeslot});
        }
    }
    // GET TIME SLOTS FOR NEW ACTIVITY 
    const timeslotsNew = getTimeslots(activity['date'], activity['start'], activity['end']);  
    // console.log('############## NEW ACTIVITY ', activity, ' ##############  =>  ', timeslotsNew);
    // //GET OVERLAPPING TIMESLOTS
    for(const key in timeslotsActivities){
        const timeslots = timeslotsActivities[key]['timeslot'];
        const id = timeslotsActivities[key]['id'];
        let overlay = []
        
        for(const key2 in timeslotsNew){
            const tsNew = timeslotsNew[key2];
            for(const key3 in timeslots){
                const ts = timeslots[key3];
                if (ts === tsNew){
                    overlay.push(ts);
                }
            }
        }
        if(overlay.length > 0){
            results.push({ id:id, timeslot: overlay});
            simple.push(overlay);
        }        
    }
    // console.log('FINAL MATCHES => ', results);
    return {complete: results, data: simple};
 }

//####################### GENERATES ARRAY WITH TIMESLOTS COVERED BY ACTIVITY #######################
//####################### GENERATES ARRAY WITH TIMESLOTS COVERED BY ACTIVITY #######################
 export function getTimeslots(day, start, end) {
    let timeslots = [];
    const iStart = parseInt(start.substring(0, 2),10);
    let iEnd = parseInt(end.substring(0, 2),10);
    if (iEnd < iStart){ iEnd = iEnd + 24}
    let dStart = new Date(day);dStart.setHours(iStart);
    let dEnd = new Date(day);dEnd.setHours(iEnd);

    let bDone = false;
    let i = iStart;
    // console.log('START => ', iStart, '  END => ', iEnd);

    if(Number.isInteger(iStart) && Number.isInteger(iEnd) !== ''){
        while (bDone === false){
            // console.log('i : ', i);
            const temp = JSON.parse(JSON.stringify(dStart))
            timeslots.push(temp);
            // console.log(bDone, ' : LOOPING : ', temp);
            if(i === iEnd-1){bDone = true;}
            dStart.setHours(dStart.getHours() + 1);
            i++;
        }
    }
    return timeslots;
 }
 
 //####################### CALCULTES ACTIVITY PRICE #######################
 //####################### CALCULTES ACTIVITY PRICE #######################
 export function calculateFare(activity, reference, overlay) {
    
    // console.log('CALCULTE FARE 0 => ', activity['date'], ' => ', activity['start'], ' => ',  activity['end']);
    let price = 0, discount = 0;
    // let nbDaysMarketing = 0;
    const timeslots = getTimeslots(activity['date'], activity['start'], activity['end']);  
    const bExempt = isPositionExempt(activity['position'], reference.exemptList);
    // nbDaysMarketing = (activity['date'] - today) max 7;

    // FULL PRICING
    // console.log(reference.allExempted, ' : ', bExempt, ' : ', timeslots.length);
    if(reference.allExempted === false && bExempt === false && timeslots.length > 0){
        for(const key in timeslots){
            const temp = new Date(timeslots[key]);
            const hour = temp.getHours();
            const user = interpolate2D(reference.factor_user[0], reference.factor_user[1], activity['users']/reference.basis_user);    
            // console.log('TIMESLOT => ', temp, ' => ', hour, ' USER => ', activity['users'], ' => ', user, ' TIME => ', hour, ' => ', reference.factor_time[hour][1]);
            price += reference.factor_time[hour][1] * user;
        }
        
        //POTENTIAL DISCOUNT + FLATTEN ERROR ARRAY
        let overlay2 = [...new Set(overlay.flat(1))];
        // console.log(overlay, ' => ', overlay2)
        
        for(const key in overlay2){
            const temp2 = new Date(overlay2[key]);
            const hour2 = temp2.getHours();
            const user2 = interpolate2D(reference.factor_user[0], reference.factor_user[1], activity['users']/reference.basis_user);    
            // console.log('DISCOUNT TIMESLOT => ', temp2, ' => ', hour2, ' USER => ', activity['users'], ' => ', user2, ' TIME => ', hour2, ' => ', reference.factor_time[hour2][1]);
            discount += reference.factor_time[hour2][1] * user2;
        }
        const total = (price - discount) * reference.price;
        // console.log('IN => ', {price: price * reference.price, discount: discount * reference.price, total: total});
        return {price: price * reference.price, discount: discount * reference.price, total: total};
    }
    else {
        // console.log('OUT => ', {price: 0, discount: 0, total: 0});
        return {price: 0, discount: 0, total: 0};
    }
}
    