export const AUTH_START = 'AUTH_START'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAIL = 'AUTH_FAIL'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const BROADCAST = 'BROADCAST'
export const NAVIGATE = 'NAVIGATE'
export const CHANGE_MENU = 'CHANGE_MENU'
export const IS_BUSINESS = 'IS_BUSINESS'
export const SET_SETTINGS = 'SET_SETTINGS'
export const SIDE_MENU = 'SIDE_MENU'
export const GEOLOCATION = 'GEOLOCATION'
export const SET_PROFILE = 'SET_PROFILE'
