import React from "react";
import { styleMapping } from "../utils/tools";

const MenuElement = props => {
  
  // console.log('ME: ', props);
  const { settings, index, data, custom } = props;

  if (data.type !== 'fundamental') {
    if(custom !== undefined){
      return (
        <span key={index} className={"Hand NoDecoration " + settings.effect + " " + custom.class} style={styleMapping(custom)}>
          { data.name } 
        </span>
      );
    }
    else{
      return (
        <span key={index} className={"Hand NoDecoration " + settings.effect + " " + settings.class} style={styleMapping(settings)}>
          { data.name } 
        </span>
      );
    }

  } else {
    if(custom !== undefined){
      return (
        <span key={index} className={"Hand NoDecoration " + settings.effect + " " + custom.class} style={styleMapping(custom)}>
          { data.name } 
        </span>
      );
    }
    else{
      return (
        <span key={index} className={"Hand NoDecoration " + settings.effect + " " + settings.class} style={styleMapping(settings)}>
          { data.name }
        </span>
      );
    }
  }
};
export default MenuElement;
