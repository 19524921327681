import React, {Component} from "react";
import { formatDate } from "../utils/tools"
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

class RangeSlider extends Component {
  
  // ############################ CONSTRUCTOR ###################################
  // ############################ CONSTRUCTOR ###################################
  constructor(props) {
    super(props);
    this.marks = {};
    let dNow = Date.now();
    let dStart = dNow + 1000 * 3600 * 24 * -1;
    let dEnd2 = dNow + 1000 * 3600 * 24 * 3;
    let dEnd = dNow + 1000 * 3600 * 24 * 7;
    for(let i=-1;i<=7;i++){
      let temp = {style: {color: 'black', fontFamily: 'FuturaM'}, label: new Date(dNow + 1000 * 3600 * 24 * i).getDate()};
      
      if( i === -1){
        temp = {
          style: {
            fontFamily: 'FuturaM', 
            fontSize: 14, 
            borderRadius:'2px', 
            backgroundColor: '#ad182f', color: '#ffffff', padding: '1px 3px'}, 
          label: 'Live'}}
      if( i >= 0){temp = {style: {color: 'black', fontFamily: 'FuturaM'}, label: formatDate(new Date(dNow + 1000 * 3600 * 24 * i), 'day ddL')};}
      this.marks[dNow + 1000 * 3600 * 24 * i] = temp;
    }
    this.state = { range: [dStart, dEnd2], default:[dStart, dStart], min: dStart, max: dEnd, marks: this.marks };
  }

  // ############################ HANDLE CHANGES ###################################
  // ############################ HANDLE CHANGES ###################################
  handleChange = (e) => {

    // console.log('#### RANGECHANGE 1 => ', e)
    let tLive =  new Date();
    tLive.setHours(0,1)
    let t0 =  new Date(e[0]);
    t0.setHours(0,1)
    let t1 =  new Date(e[1]);
    t1.setHours(23,32)

    // console.log('#### RANGECHANGE 2 => ', t0.toString(), ', ', t1.toString());

    let res = [
      (this.marks[e[0]]['label'] === 'Live' ? 
        tLive.toUTCString() : 
        t0.toUTCString()
      ), 
      (this.marks[e[1]]['label'] === 'Live'? 
        'Live' : 
        t1.toUTCString()
      )
    ];    
    this.props.onSlider(res);
  }

  
  render() {
    return (
        <div className={"col-lg-11 col-md-11 col-sm-12"}>
            <Range 
                draggableTrack
                defaultValue={this.state.default}
                value={this.state.range}
                onChange={(e) => {
                  this.setState({range: e})
                }}
                onAfterChange={(e) => {
                  this.handleChange(e)
                }}
                min={this.state.min}
                max={this.state.max}
                dots={true}
                step={1000 * 3600 * 24}
                marks={this.state.marks}
                trackStyle={[{ backgroundColor: '#ad182f' }]}
                railStyle={{ backgroundColor: '#dddddd' }}
                handleStyle={[{  backgroundColor: '#ad182f', borderColor: '#ad182f'  }, { backgroundColor: '#ad182f', borderColor: '#ad182f' }]}
                activeDotStyle={{  backgroundColor: '#ad182f', borderColor: '#ad182f'  }}
                dotStyle={{  backgroundColor: '#dddddd', borderColor: '#dddddd'  }}
            />
        </div>
    );
  }
}
export default RangeSlider;

