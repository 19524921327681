import React, {Component} from "react";
import Toggle from "../elements/toggle"
import ListCreator from "../elements/listCreator"
import { getArrayIndex, formatDate, isTrue, getTextDraft } from "../utils/tools"
import {Editor, EditorState, ContentState} from 'draft-js';
import { generateReference } from "../services/business";
import Viewer from '../elements/viewer';
import Input from '../elements/input';
import * as server from "../../resources/constants/api";
import * as constants from "../../resources/constants/design";
import 'react-inputs-validation/lib/react-inputs-validation.css';
import MoonLoader from "react-spinners/ClipLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const increase = 30;
const decrease = -30;
const columns = ['item', 'offer'];
const toggleData = [{ label: 'DISCOUNT', value: 'discount', selectedBackgroundColor: "#ad182f" }, { label: 'EVENT', value: 'event', selectedBackgroundColor: "#ad182f" }];
const styleList = {
  container: 'BRL Wide100 TopMargin10px NoBorder',
  line: 'BRL Wide100 NoBorder Flex FlexRow JustifyCenter AlignCenter btn-light BCTrans BottomShadow7',
  column: 'BRL Flex9 Padding8px NoBorder Hand',
  label: 'BRL Flex9 Wide100 LabelFont FS41 Padding0px NoBorder',
  text: 'BRL Wide100 TitleFont FS39 Padding0px NoBorder ',
  columnInput: 'BRL Flex9 NoBorder Hand Wide40 ',
  lineInput: 'BRL Wide100 NoBorder Flex FlexRow JustifyCenter AlignCenter BottomShadow7',
  input: 'BRL Wide100 HPadding10px VPadding8px TopMargin0px NoBorder BCWhite btn-light BCTrans InputFont FS39 NoOutline ',
  columnIcon: 'BRL Flex1 Flex FlexRow JustifyCenter AlignCenter NoBorder Hand BorderRadius5 Padding8px ',
  icon: 'FS39 FCRed fa fa-remove',
  iconAdd: 'FS39 FCBlue fa fa-plus Padding8px ',
};

export default class InputAd extends Component {
  // ############################# CONSTRUCTOR #############################
  // ############################# CONSTRUCTOR #############################
  constructor(props) {
    super(props);
    
    this.state = { data: {id_event: null}, bDisabled: true, loading: false, images:(props.images ? props.images : []) }; 
    this.validFields = {title:true, date:true, start:true, end:true};
    this.businessId = props.data.business.id_business;
    this.activityId = generateReference(6);
    
    this.setEditor = (editor) => { this.editor = editor; };
    this.onChange = (editorState) => {
      let temp = this.state.data;
      temp.description = editorState;
      this.setState({data: temp});
      this.handleSubmit(false);
    };
  }


  // ############################# DISPLAY ACTIVITY FORM #############################
  // ############################# DISPLAY ACTIVITY FORM #############################
  render() {

    if(this.state.data.id_event !== this.props.data.id_event){
      let data = JSON.parse(JSON.stringify(this.props.data));
      data.description = EditorState.createWithContent(ContentState.createFromText(this.props.data.description));
      if(this.props.data.id_event === 'new'){
        data = this.initTime(data);
        // const tempDisabled = (this.props.data.title !== '' ? false : true);
        // console.log('TEMP DISABLED => ', tempDisabled, ' => ', this.props.disabled);
        // this.setState({ bDisabled: tempDisabled});
      } else {
        this.activityId = data.id_event
        this.validFields = {title:true, date:true, start:true, end:true};} 
        this.setState({data: data, images: JSON.parse(JSON.stringify(data['images'])), bDisabled: !isTrue(this.validFields)});
      return null;
    } else {

      const temp = parseInt(getArrayIndex(toggleData, this.state.data.type, 'value'),10);
      this.eventDay = new Date(this.state.data.date);
      this.eventDayEnd = new Date(this.state.data.date);
      this.eventDayEnd.setHours(this.state.data.end.slice(0,-2));
      this.today = new Date();
      this.date2 = formatDate(this.eventDay, 'yyyy-mm-ddL');

      // console.log('INPUTAD => ', this.state.data)

      return (
          <div style={{height:'100%',display:'flex', flexDirection:'column'}} className="BRL Wide100 BottomShadow7">
            <div style={{display:'flex', flex:1,flexDirection:'column', height:'100%', padding:'0 2%'}} className="BRL Wide100 Padding10px">
              {/* SIGNIN REGISTER SWITCH */}
              <div className="BRL Wide100 react-switch-selector-wrapper" style={{flex:1}} >
                <Toggle onChange={(e) => {this.handleChange('','type', e)}} data={toggleData} backgroundColor={"#fff"} selectionIndicatorMargin={2} fontSize={13} initialSelectedIndex={temp} />
                {this.state.data.id_event !== 'new' && this.today > this.eventDayEnd && <div className={"Absolute Top0px Left0px Wide100 High40px BCTransparent4 z10"}></div>}
              </div>
            </div>

            {/* FORM */}
            <div className="BRL Wide100 TopMargin0px" style={{height:'540px', overflow: 'auto', padding:'0 2%'}}>
              <div className="BRL Wide100 TopMargin0px">
                <Input onChange={(type, field, e, validation) => {this.handleChange('', field, e); this.manageFields(field, validation);}} value={this.state.data.title} config={constants.INPUTAD.title} disabled={this.state.data.id_event !== 'new' && this.today > this.eventDayEnd} />
                <Input onChange={(type, field, e, validation) => {this.handleChange('', field, e); this.manageFields(field, validation);}} value={this.date2} config={constants.INPUTAD.date} disabled={this.state.data.id_event !== 'new' && !this.state.data.title.includes('party')} />

                <div className={"BRL col-lg-6 col-md-6 col-sm-12 col-xs-12 TopMargin20px LeftPadding0px RightPadding10px Flex FlexRow"}>
                  <Input onChange={(type, field, e, validation) => {this.handleChange('', field, e); this.manageFields(field, validation);}} value={this.state.data.start} config={constants.INPUTAD.start} disabled={this.state.data.id_event !== 'new'} />
                  <div className={"Flex2 Padding0px Right0px Bottom0px Flex FlexRow JustifyCenter"} style={{"alignItems": "flex-end"}}>
                    <button className={"Flex1 NoBorder NoOutline Padding8px btn-primary BCTrans BorderRadius2"} style={{"fontSize": "14px"}} onClick={() => {this.handleTime('start', increase)}} disabled={this.state.data.id_event !== 'new'}><FontAwesomeIcon size={'1x'} icon={"plus"} /></button>
                    <button className={"Flex1 NoBorder NoOutline Padding8px btn-danger BCTrans BorderRadius2"} style={{"fontSize": "14px"}} onClick={() => {this.handleTime('start', decrease)}} disabled={this.state.data.id_event !== 'new'}><FontAwesomeIcon size={'1x'} icon={"minus"} /></button>
                  </div>
                </div>
                <div className={"BRL col-lg-6 col-md-6 col-sm-12 col-xs-12 TopMargin20px LeftPadding10px RightPadding0px Flex FlexRow"}>
                  <Input onChange={(type, field, e, validation) => {this.handleChange('', field, e); this.manageFields(field, validation);}} value={this.state.data.end} config={constants.INPUTAD.end} disabled={this.state.data.id_event !== 'new'} />
                  <div className={"Flex2 Padding0px Right0px Bottom0px Flex FlexRow JustifyCenter"} style={{"alignItems": "flex-end"}}>
                    <button className={"Flex1 NoBorder NoOutline Padding8px btn-primary BCTrans BorderRadius2"} style={{"fontSize": "12px"}} onClick={() => {this.handleTime('end', increase)}} disabled={this.state.data.id_event !== 'new'}><FontAwesomeIcon size={'1x'} icon={"plus"} /></button>
                    <button className={"Flex1 NoBorder NoOutline Padding8px btn-danger BCTrans BorderRadius2"} style={{"fontSize": "12px"}} onClick={() => {this.handleTime('end', decrease)}} disabled={this.state.data.id_event !== 'new'}><FontAwesomeIcon size={'1x'} icon={"minus"} /></button>
                  </div>
                </div>
                  
              </div>

              <div className="BRL Wide100 TopMargin10px">
                <div className="BRL Wide100 HeaderFont FS42" style={{}}>Image</div>
                  <Viewer styleList={constants.VIEWER_DASHBOARD} id={1} limit={3} images={this.state.images} onAction={(action, index) => { this.handleImages(action, index)}} />           
              </div>

              <div className="BRL Wide100 TopMargin10px">
                <div className="BRL Wide100 HeaderFont FS42" style={{}}>Description</div>
                <div className="BRL Wide100 Padding20px BottomShadow7 TopMargin10px btn-light BCTrans FS40">
                  <Editor readOnly={this.state.data.id_event !== 'new' &&  this.today > this.eventDayEnd} ref={this.setEditor} editorState={this.state.data.description} onChange={(e) => this.onChange(e)}   />
                </div>
              </div>
              <div className="BRL Wide100 TopMargin10px">
                <div className="BRL HeaderFont FS42" style={{}}>Promotion</div>
                <ListCreator type={(this.state.data.id_event !== 'new'  &&  this.today > this.eventDayEnd ? 'display' : '')}  styleList={styleList} id_event={this.state.data.id_event} data={this.state.data.promotion} columns={columns} handleData={(e)=>{this.handlePromotion(e);}}></ListCreator>
              </div>
            </div>


            {/* ACTIONS */}
            <div style={{position:'relative', flex:1, padding:0}} className="form-group FCWhite BottomShadow7 BottomMargin0 BottomtBorderRadius5">
                {this.props.data.cost > 0 && 
                  <React.Fragment>
                    <div className="BRL Wide10 BCSecondary TitleFont FS39 CenterAlign VPadding10px HPadding5px Bold BottomLeftBorderRadius5">
                      &euro;
                    </div>
                    <div className="BRL Wide15 BCSecondary TitleFont FS39 LeftAlign VPadding10px">
                      {this.props.data.cost}
                    </div>
                  </React.Fragment>}
                
                {this.props.data.cost === 0 && 
                  <React.Fragment>
                    <div className="BRL Wide25 BCSecondary TitleFont FS39 CenterAlign VPadding10px HPadding5px Bold">
                      Free
                    </div>
                  </React.Fragment>}
                
                <button disabled={this.state.bDisabled && this.props.disabled} type="submit" 
                  className={"BRL Wide75 BCPrimary TitleFont FCWhite FS39 VPadding10px HPadding20px NoBorder Capital BottomRightBorderRadius5 " + 
                  ((this.state.data.id_event === 'new' && !this.state.bDisabled) || 
                  (this.state.data.id_event === 'new' && !this.props.disabled) || 
                   (this.state.data.id_event !== 'new' && this.today < this.eventDayEnd && !this.state.bDisabled)
                  ? 'BCPrimary' : 'BCPrimaryDisabled') } 
                  onClick={() => this.handleSubmit(true)}>
                    <div className="BRL Wide90 ">
                      {(this.state.data.id_event !== 'new' ? 'Modify' : 'Advertize')}
                    </div>
                    <div className="BRL Wide10 ">
                      <MoonLoader size={20} color={"#ffffff"} loading={this.state.loading}/>
                    </div>
                </button>
                
              </div>
          </div>
      );
    }
  }
    
  //############################# HANDLE CHANGE #############################
  //############################# HANDLE CHANGE #############################
  initTime = (data) => {
    const today2 = new Date();
    data.date = formatDate(today2, 'yyyy-mm-ddL');
    
    let hours = today2.getHours();
    let minutes = today2.getMinutes();
    let hoursStart = hours;
    let minutesStart = "30";
    if (minutes>=30){minutesStart = "00"; hoursStart = parseInt(hoursStart + 1, 10) % 24;}
    let hoursEnd = parseInt(hoursStart + 1, 10) % 24;
    let minutesEnd = minutesStart;

    let start =(parseInt(hoursStart, 10) < 10 ? '0' : '') + hoursStart + minutesStart;
    let end =(parseInt(hoursEnd, 10) < 10 ? '0' : '') + hoursEnd + minutesEnd;
    
    data.start = start;
    data.end = end;
    this.validFields = {title:false, date:true, start:true, end:true};
    return data;
  }


  //############################# HANDLE CHANGE #############################
  //############################# HANDLE CHANGE #############################
  handleTime = (field, step) => {
    let offset = 0;
    let temp = this.state.data;
    let hours = temp[field].substr(0, 2);
    let minutes = temp[field].substr(2, 2);
    if (temp[field] === ''){
      switch(field){
        case 'start': temp[field] = '0000'; break;
        case 'end': temp[field] = '2400'; break;
        default: break;
      }
    } 
    else {
      minutes = parseInt(minutes, 10) + parseInt(step, 10);
      minutes = Math.abs(minutes % 60);
      if (minutes === 0 && step === 30){offset = 1}
      if (minutes === 30 && step === -30){offset = -1}

      hours = parseInt(hours, 10) + parseInt(offset, 10);
      let newTime =(parseInt(hours, 10) < 10 ? '0' : '') + hours + (minutes === 0 ? '00' : minutes);
      if (newTime === '0000' && field === 'end'){newTime = '2400';}
      if (newTime === '2400' && field === 'start'){newTime = '0000';}
      if (newTime === '2430' && field === 'end'){newTime = '0030';}
      if (newTime === '0-130' && field === 'start'){newTime = '2330';}
      temp[field] = newTime;
    }        
    this.setState({data: temp});
  }

  //############################# HANDLE PROMOTION #############################
  //############################# HANDLE PROMOTION #############################
  handlePromotion = (data) => {
    let temp = this.state.data;
    temp.promotion = data;
    // console.log('HANDLE PROMOTION => ', temp);
    this.setState({data: temp});
  }

  //############################# HANDLE CHANGE #############################
  //############################# HANDLE CHANGE #############################
  handleChange = (type, field, e) => {
    let temp = this.state.data;
    switch(type){
      case "": 
        switch(field){
          case "date": temp[field] = e; break;
          default: temp[field] = e; break;
        }
      break;
      default: temp[type][field] = e; break;
    }
    this.setState({data: temp});
    this.handleSubmit(false);
  }

  //############################# HANDLE CHANGE #############################
  //############################# HANDLE CHANGE #############################
  handleToggle = (type, field, e) => {
    let temp = this.state.data;
    switch(type){
      case "": temp[field] = e.target.value;break;
      default: temp[type][field] = e.target.value;break;
    }
    this.setState({data: temp});
    this.handleSubmit(false);
  }

  //############################# HANDLING EMAIL LOGIN SUBMISSION #############################
  //############################# HANDLING EMAIL LOGIN SUBMISSION #############################
  handleSubmit = (bSubmit) => {
    let typeAction = "";
    if(bSubmit === true){this.setState({ loading: true });}
    
    let tempData = JSON.parse(JSON.stringify(this.state.data));
    let tempDescription = getTextDraft(this.state.data.description);
    tempData['description'] = tempDescription;  
    if(bSubmit === true){
      typeAction = (tempData['id_event'] === 'new' ? 'new' : '');
      tempData['id_event'] = (tempData['id_event'] === 'new' ? this.activityId : tempData['id_event']);
    }
    
    // console.log('INPUTAD SUBMIT => ', tempData, ' => ', this.state.images);
    this.props.onSubmit(tempData, this.state.images, bSubmit, typeAction);
    if(bSubmit === true){
      setTimeout(() => {
        this.setState({ loading: false });
        // toast.success("Activity data vas successfully saved", options)
      }, 1500);
    }
  }
  
  //############################# HANDLE CHANGE #############################
  //############################# HANDLE CHANGE #############################
  manageFields = (type, val) => {
    // console.log('AAA => ',type, ' => ' , this.validFields);
    this.validFields[type] = val;
    this.setState({bDisabled: !isTrue(this.validFields)});
  }


  // ############################ HANDLE IMAGES ###################################
  // ############################ HANDLE IMAGES ###################################
  handleImages = (action, data)  => {
    
    let tempData = this.state.data;
    let temp = this.state.images;
    switch(action){
      default:break;
        case "new":
          const nameImage = generateReference(14) + '.png';
          temp.push({url: data, action:'new', name: server.URL_MEDIA_BUSINESS_RELATIVE.substring(1) + '/' + this.businessId + '/activity/' + this.activityId + '/' + nameImage});
          tempData['images'].push({url: server.URL_MEDIA_BUSINESS_RELATIVE + '/' + this.businessId + '/activity/' + this.activityId + '/' + nameImage, action:'', name: nameImage});
          break;
        case "delete":
            tempData['images'].splice(data,1);
            temp.splice(data,1);
        break;
        case "reset": 
          tempData['images'][data].action = ''; 
          temp[data].action = ''; 
        break;
    }

    // console.log('HANDLE IMAGES => ', temp, ' => ', tempData);

    this.setState({data: tempData, images: temp});  
  }
}
