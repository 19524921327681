import React from 'react';
import { styleMapping } from "../utils/tools";
import AddressSimple from "../elements/addressSimple";
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/pseudo/general';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state={setting: null};
    }

    async componentDidMount(){
        if(this.props.settingsManual){this.setState({settings: this.props.settingsManual, addresses: null, architecture: null});} 
        else {this.setState({settings: this.props.settings});}
    }
    UNSAFE_componentWillReceiveProps(){
        this.setState({settings: this.props.settingsManual});
    }


    // ############################ RENDER ###################################
    // ############################ RENDER ###################################
    render() {

        let settings;
        if(this.state.settings){ settings = this.state.settings; }
        else { settings = this.props.settings; }

        if(settings){
            const { architecture, addresses } = this.props;  
            // console.log('FOOTER RENDER 2 => ', settings, ' => ', architecture, ' => ', addresses);
            
            return (
                <React.Fragment>
                {addresses ?
                <div className={ settings.footer.container.class } style={styleMapping(settings.footer.container)}>
                    <div className={ settings.footer.subcontainer.class } style={styleMapping(settings.footer.subcontainer)}>
                        <div className="">
                            <div className={ settings.footer.left.class } style={styleMapping(settings.footer.left)}>
                                <div className={ settings.footer.title.class } style={styleMapping(settings.footer.title)}>{ settings.logo.title['text'] }</div>
                                <div className={ settings.footer.subtitle.class } style={styleMapping(settings.footer.subtitle)}>{ settings.logo.subtitle['text'] }</div>
                            </div>

                            <div className={ settings.footer.right.class } style={styleMapping(settings.footer.right)}>
                                    {Object.keys(addresses).map((address, index)=>{
                                        return (<AddressSimple index={index} key={index} settings={settings.addresses} address={addresses[address]}></AddressSimple>);}) 
                                    }

                            </div>
                        </div>
                                
                                
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 float-left" style={{'padding':'0', 'marginTop':'30px'}}>
                            {Object.keys(architecture.footer).map((link, index)=>{
                                    return (
                                        <Link key={index} to={"/" + architecture.footer[link].link}>
                                            <span key={index} className={ settings.footer.links.class } style={styleMapping(settings.footer.links)}>
                                                { architecture.footer[link].name}
                                            </span>
                                        </Link>
                                    );
                            })}                   
                        </div>

                        {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 float-left" style={{'padding':'0', 'marginTop':'30px'}}> */}
                            <div className={"" + settings.footer.copyright.class} style={styleMapping(settings.footer.copyright)}>{settings.footer.copyright.text}</div>
                        {/* </div> */}

                    </div>
                </div>  
                :null}
            </React.Fragment>
            );
        }            
    }
}

const mapstateToProps = (state) => {
    return {isAuthenticated: state.status.token !== null && state.status.token !== '' ? true : false, status:state.status, user:state.user, addresses:state.addresses, architecture:state.architecture, settings:state.settings};}
const mapDispatchToProps = dispatch => {return {navigate: (direction, history) => dispatch(actions.navigate(direction, history)), sideMenuFunction: (bVal) => dispatch(actions.sideMenu(bVal))}}
export default withRouter(connect(mapstateToProps, mapDispatchToProps)(Footer));

