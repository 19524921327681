import React, { Component } from 'react'
import { styleMapping } from "../utils/tools";
import { getGPSCoordinates } from "../services/geolocation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ContentEditable from "react-contenteditable";
import { Input } from "reactstrap";
import Select from "../elements/select";
import MapBasic from "../geolocation/mapBasic";
// import * as server from "../../resources/constants/api";

export default class CMAPS extends Component {
    constructor(props) {
        super(props)
        this.state = { structure: null, editor: props.editor, params: null, element: null, address: null};
        this.index = 0;
    }

    componentDidMount(){ this.loadData(this.props); }
    UNSAFE_componentWillReceiveProps(props){ this.loadData(props); }
    loadData = async (data)  => { 
        this.setState({structure: data.structure, editor: data.editor}, function(){
            // console.log('!!!!!!! CMAPS LOAD DATA => ', this.index, ' => ',data.structure.structure.addresses.addresses);
            if(data.structure.structure.addresses.addresses.length > 0){
                this.getAddress(this.index);
            }

        });
    }
     
    // ############################ GET PARAMS WHEN SUBELEMENT CLICKED ###################################
    // ############################ GET PARAMS WHEN SUBELEMENT CLICKED ###################################
    getParams = (params, data) => {
        // console.log('GETPARAMS => ', params, ' => ', data);
        this.setState({element: data, params: params});
        this.props.onChange(params, this.state.structure, this.state.videos, true);
    }
    
    // ############################ HANDLE INPUT CHANGE ###################################
    // ############################ HANDLE INPUT CHANGE ###################################
    handleChange(params, field, e){
        let temp = this.state.element;
        switch(field){
            case 'font': temp[field] = e.value; break;
            default: temp[field] = e; break;
        }
        this.setState({element: temp});
        this.props.onChange(null, this.state.structure, null, false);
    } 


    // ############################ ADD ADDRESS ###################################
    // ############################ ADD ADDRESS ###################################
    addAddress = () => {
        let temp = this.state.structure;
        temp.structure.addresses.addresses.push({name:'', street:'', postcode:'', city:'', country:'' });
        this.setState({structure: temp});
        this.props.onChange('', this.state.structure, null, false);
    }

    // ############################ DELETE ADDRESS ###################################
    // ############################ DELETE ADDRESS ###################################
    deleteAddress = (key) => {
        this.index = 0;
        let temp = JSON.parse(JSON.stringify(this.state.structure));
        temp.structure.addresses.addresses.splice(key, 1);
        // this.setState({structure: temp});
        this.props.onChange('', temp, null, false);
        // console.log('##############  DELETEADDRESS 2 => ', this.index, ' => ',temp.structure.addresses);
    }
    
    // ############################ GET ADDRESS ###################################
    // ############################ GET ADDRESS ###################################
    getAddress = async (index) => {
        this.index = index;
        // console.log('GETADDRESS 1 INDEX => ', index, ' => ',  this.state.structure.structure.addresses);
        let address = this.state.structure.structure.addresses.addresses[index];
        address = {...address, ...{address: address.street}};
        const results = await getGPSCoordinates(address);
        if(results){
            this.setState({address: {longitude: JSON.parse(results)[0], latitude: JSON.parse(results)[1]}});
        }
    }

    // ############################ RENDER ###################################
    // ############################ RENDER ###################################
    render() {
        if(this.state.structure){   
            const { editor } = this.state;
            const { structure } = this.state.structure;
            this.height = structure.map.map.height * window.innerHeight;

            if(editor === true){   
                // console.log('RENDER ADDRESSES => ', structure.addresses.addresses, ' => ', structure.addresses.addresses.length);
                return (
                        <div key={'cw'} className="BRL Wide100 OverflowHidden">
                            {/* ##################### FIELD PARAMETERS ##################### */}
                            {/* ##################### FIELD PARAMETERS ##################### */}
                            {this.state.element && <div className="BRL Wide100 Padding10px BottomShadow7 BorderRadius5 BottomMargin20px">
                                <div className={"BRL Wide100 FS38 TitleFont BottomMargin5px HPadding10px Capital"}>{this.state.params}</div>
                                {Object.keys(this.state.element).map((key, index) => {
                                return (
                                    <React.Fragment key={index}>
                                    {key !== 'text' && key !== 'url' && key !== 'container' && key !== 'elements' && key !== 'name' && key !== 'street' && key !== 'postcode' && key !== 'city' && key !== 'country' && 
                                    <li key={ 'c_' + key} className={"BRL NoBorder TopMargin5px HPadding5px " + ((key === 'class' || key === 'style') ? "col-lg-6 col-md-6 col-sm-12" : "col-lg-3 col-md-3 col-sm-4")} style={{listStyleType: 'none'}}>
                                    <div key={ 'l_' + key} className="BRL Wide100 BottomMargin2px ButtonFont Capital FS41">{key}</div>             
                                    
                                    {(key !== 'font' && key !== 'color' && key !== 'background') && <Input key={'f1_'+ key} type={(key === 'color' || key === 'background' ? 'color' : 'text')} name={key} onChange={(e) => this.handleChange(this.state.params, key, e.target.value)} value={this.state.element[key]} className={"BRL Wide100 NoBorder btn-light BCTrans InputFont FS40 TopMargin5px BottomShadow7"}/>}
                                    {(key === 'color' || key === 'background') && 
                                        <React.Fragment>
                                        <Input key={'f1_'+ key} type={'color'} name={key} onChange={(e) => this.handleChange(this.state.params, key, e.target.value)} value={this.state.element[key]} className={"BRL Wide30 NoBorder btn-light BCTrans InputFont FS40 TopMargin5px BottomShadow7"}/>
                                        <Input key={'f2_'+ key} type={'text'} name={key + '_code'} onChange={(e) => this.handleChange(this.state.params, key, e.target.value)} value={this.state.element[key]} className={"BRL Wide65 NoBorder btn-light BCTrans InputFont FS40 TopMargin5px LeftMargin5 BottomShadow7"}/>
                                        </React.Fragment>}
                                    {(key === 'font') && 
                                        <Select key={'f2_'+ key} name={key} id={'font'} value={this.state.element[key]} fields={key} 
                                        classes={{container:"BRL form-control NoBorder FS40 LabelFont Padding3px btn-light BCTrans", options:"BRL Wide100 TopMargin5px CenterAlign BottomShadow7"}}  
                                        onChange={(name, val) => this.handleChange(this.state.params, key, val)}></Select>}
                                    </li>}
                                    </React.Fragment>
                                );})}
                            </div>}

                            {/* ##################### DESIGN EDITOR ##################### */}
                            {/* ##################### DESIGN EDITOR ##################### */}
                            <div id={"container"} className={" " + structure.container.class} style={styleMapping(structure.container)}>
                                {editor && <div onClick={() => {/*this.getParams('structure.container', structure.container)*/}} className={"Hand Absolute Top0px Right10px Padding5px btn-light BorderRadius5 BottomShadow7 z1000 "}><FontAwesomeIcon icon={"pen"} size="xs" color="black"/></div> }
                                
                                    {/*ADDRESSES*/}
                                    <div key={'addresses'} className={" " + structure.addresses.container.class} style={styleMapping(structure.addresses.container)} >
                                        <div className={"Hand Absolute Bottom0px Right0px"}>
                                            <div onClick={() => {this.getParams('structure.addresses.container', structure.addresses.container)}} className={"Hand BRL LeftMargin2px HPadding3px VPadding0px btn-primary BorderRadius5 BottomShadow7 z2000"}><FontAwesomeIcon icon={"pen"} style={{fontSize: 13}} color="white"/></div>
                                            <div onClick={() => {this.getParams('structure.addresses.subContainer', structure.addresses.subContainer)}} className={"Hand BRL LeftMargin2px HPadding3px VPadding0px btn-info BorderRadius5 BottomShadow7 z2000"}><FontAwesomeIcon icon={"crop-alt"} style={{fontSize: 13}} color="white"/></div>
                                            <div onClick={() => {this.getParams('structure.addresses.header', structure.addresses.header)}} className={"Hand BRL LeftMargin2px HPadding3px VPadding0px btn-danger BorderRadius5 BottomShadow7 z2000"}><FontAwesomeIcon icon={"font"} style={{fontSize: 13}} color="white"/></div>
                                            <div onClick={() => {this.getParams('structure.addresses.text', structure.addresses.text)}} className={"Hand BRL LeftMargin2px HPadding3px VPadding0px btn-light BorderRadius5 BottomShadow7 z2000"}><FontAwesomeIcon icon={"font"} style={{fontSize: 13}} color="black"/></div>
                                        </div>
                                        {structure.addresses.addresses.length > 0 && <React.Fragment key={'add_d'}>
                                            {structure.addresses.addresses.map((address, index) => {
                                                return (
                                                <div key={"composite_" + index} className="BRL Wide100">
                                                    <div key={"add_e_" + index} onClick={() => {this.getAddress(index);this.getParams('structure.addresses.addresses['+index+']', structure.addresses.addresses[index]);}} className={"" + structure.addresses.subContainer.class + (this.index === index? ' NoBorder ABorderCGray' : ' NoBorder')} style={styleMapping(structure.addresses.subContainer)}>
                                                    {/* <div key={"add_e_" + index} onClick={() => {}} className={"" + structure.addresses.subContainer.class + (this.index === index? ' NoBorder ABorderCGray' : ' NoBorder')} style={styleMapping(structure.addresses.subContainer)}> */}
                                                        <ContentEditable html={address.name} onChange={(e) => this.handleChange('structure.addresses.addresses['+index+']', 'name', e.target.value)} disabled={false} className={"BRL Wide100 NoBorder NoOutline btn-light " + structure.addresses.header.class} style={styleMapping(structure.addresses.header)} />
                                                        <ContentEditable html={address.street} onChange={(e) => this.handleChange('structure.addresses.addresses['+index+']', 'street', e.target.value)} disabled={false} className={"BRL Wide100 NoBorder NoOutline btn-light " + structure.addresses.text.class} style={styleMapping(structure.addresses.text)} />
                                                        <ContentEditable html={address.postcode} onChange={(e) => this.handleChange('structure.addresses.addresses['+index+']', 'postcode', e.target.value)} disabled={false} className={"BRL MinWide10 NoBorder NoOutline btn-light " + structure.addresses.text.class} style={styleMapping(structure.addresses.text)} />
                                                        <ContentEditable html={address.city} onChange={(e) => this.handleChange('structure.addresses.addresses['+index+']', 'city', e.target.value)} disabled={false} className={"BRL Wide60 LeftMargin10px NoBorder NoOutline btn-light " + structure.addresses.text.class} style={styleMapping(structure.addresses.text)} />
                                                        <ContentEditable html={address.country} onChange={(e) => this.handleChange('structure.addresses.addresses['+index+']', 'country', e.target.value)} disabled={false} className={"BRL Wide100 NoBorder NoOutline btn-light " + structure.addresses.text.class} style={styleMapping(structure.addresses.text)} />
                                                    </div>
                                                    {/* DELETE */}
                                                    <div key="del_add" className={"Hand Absolute Top0px Right0px"}>
                                                        <div onClick={() => {this.deleteAddress(index)}} className={"Hand BRL LeftMargin2px HPadding3px VPadding0px BorderRadius50 BottomShadow7 z2000 btn btn-danger"}>
                                                            <FontAwesomeIcon icon={"trash"} style={{fontSize: 10}} color="white"/>
                                                        </div>
                                                    </div> 
                                                </div> 
                                                ); 
                                            })}
                                        </React.Fragment>}
                                        {/* ADD */}
                                        <div key="new_add" className={"Hand BRL Wide100"}>
                                            <div onClick={() => {this.addAddress()}} className={"Hand BRL LeftMargin2px Padding5px BorderRadius50 FCWhite FS39 ButtonFont BottomShadow7 z2000 btn btn-info"}>
                                                New address
                                            </div>
                                        </div> 
                                    </div>
                                    {/* MAP */}
                                    <div key={'map'} className={"BR " + structure.map.container.class} style={styleMapping(structure.map.container)}>
                                        {this.state.address !== null && 
                                            <MapBasic data={this.state.address} styleMap={{height: this.height, width: structure.map.map.width}} zoom={[structure.map.map.zoom]}></MapBasic>
                                        }
                                        <div className={"Hand Absolute Bottom0px Right0px"}>
                                            <div key={'map_cont'} onClick={() => {this.getParams('structure.map.container', structure.map.container)}} className={"Hand BRL LeftMargin2px HPadding3px VPadding0px btn-primary BorderRadius5 BottomShadow7 z2000"}><FontAwesomeIcon icon={"pen"} style={{fontSize: 13}} color="white"/></div>
                                            <div key={'map_map'} onClick={() => {this.getParams('structure.map.map', structure.map.map)}} className={"Hand BRL LeftMargin2px HPadding3px VPadding0px btn-info BorderRadius5 BottomShadow7 z2000"}><FontAwesomeIcon icon={"map"} style={{fontSize: 13}} color="white"/></div>
                                        </div>
                                        
                                    </div>  

                                </div>
                        </div>        
                );
            } else {
                return (
                        <React.Fragment key={'addresses'}>
                        <div  className={" " + structure.addresses.container.class} style={styleMapping(structure.addresses.container)} >
                            {structure.addresses.addresses.length > 0 && <React.Fragment key={'add_d'}>
                                {structure.addresses.addresses.map((address, index) => {
                                    return (
                                    <div key={'addr_'+index} onClick={() => {this.getAddress(index)}} className={"Hand " + structure.addresses.subContainer.class + (this.index === index? ' NoBorder ' : ' NoBorder')} style={styleMapping(structure.addresses.subContainer)}>
                                        {address.name !== "" && <ContentEditable html={address.name} disabled={true} className={"Hand BRL Wide100 NoBorder NoOutline " + structure.addresses.header.class} style={styleMapping(structure.addresses.header)} />}
                                        { address.street !== "" && <ContentEditable html={address.street} disabled={true} className={"Hand BRL Wide100 NoBorder NoOutline " + structure.addresses.text.class} style={styleMapping(structure.addresses.text)} />}
                                        { address.postcode !== "" && <ContentEditable html={address.postcode} disabled={true} className={"Hand BRL MinWide10 NoBorder NoOutline " + structure.addresses.text.class} style={styleMapping(structure.addresses.text)} />}
                                        { address.city !== "" && <ContentEditable html={address.city} disabled={true} className={"Hand BRL Wide60 LeftMargin10px NoBorder NoOutline " + structure.addresses.text.class} style={styleMapping(structure.addresses.text)} />}
                                        { address.country !== "" && <ContentEditable html={address.country} disabled={true} className={"Hand BRL Wide100 NoBorder NoOutline " + structure.addresses.text.class} style={styleMapping(structure.addresses.text)} />}
                                    </div>); 
                                })}
                            </React.Fragment>}
                        </div>

                        {/*MAP*/}
                        <div key={'map'} className={"BR " + structure.map.container.class} style={styleMapping(structure.map.container)}>
                        {this.state.address !== null && 
                            <MapBasic data={this.state.address} styleMap={{height: this.height, width: structure.map.map.width}} zoom={[structure.map.map.zoom]}></MapBasic>
                        }
                        </div>   
                        </React.Fragment>                
                    );


                       
        }

            } else return null;
    }
}

