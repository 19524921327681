import React, { Component } from "react";
import { toast } from 'react-toastify';
import { withRouter } from "react-router-dom";
import { styleMapping, formatDate } from "../utils/tools";
import { geolocationDataManagement } from "../services/admin";
import MoonLoader from "react-spinners/ClipLoader";
import FlatList from 'flatlist-react';



class Server extends Component {
    // ############################ COMPONENT ###################################
    constructor(props) {
        super(props);
        this.state = { 
            events: null, 
            loading: {  
                archive: false, 
                restore: false, 
                mail: false, 
                get_Events: false, 
                filter_Events: false, 
                create_Events: false, 
                getFilter_Events:false, 
                filterCreate_Events: false, 
                getfilterCreate_Events: false, 
                latest_events: false, 
                createArchive_Events: false,
                clearRaw: false
            }};
      }
    
    
    // ############################ HANDLE BUTTON CLICK ###################################
    // ############################ HANDLE BUTTON CLICK ###################################
    actionServer = async (type)  => {
        let temp = this.state.loading;
        temp[type] = true;
        this.setState({loading: temp});
        let results = await geolocationDataManagement(type);
        console.log('ACTION SERVER 2 : ', type, ' => ', results);
        switch(results['status']){
            case true: toast.success("Action" + type + " was successful", { autoClose: 1500, type: toast.TYPE.SUCCESS, hideProgressBar: false }); break;
            default: toast.error("An error occured for " + type + " action", { autoClose: 1500, type: toast.TYPE.ERROR, hideProgressBar: false });break;
        }
    
        temp[type] = false;
        this.setState({loading: temp, events: (type === 'getFilterCreate_Events' || 'filterCreate_Events'  || 'latest_events' ? results['data'] : null)});
    }
  
  
    // ############################ RENDER ###################################
    // ############################ RENDER ###################################
    render(){

        const { events } = this.state; 

        return (  
        <div className={ this.props.settings.structure.outlet.class} style={styleMapping(this.props.settings.structure.outlet)}>      
            <div className="BRL Wide100 PaddingResponsive">
                <div className="BRL Wide100 Padding10px" style={{margin:'30px 0 0 0'}}>
                    <button onClick={() => { this.actionServer('archive') }} className={"FuturaH FS40 BRL btn btn-primary"} style={{display: 'flex', flexDirection: 'row', margin: "10px 0 0 10px", padding: "10px 30px"}}>
                        <div style={{flex:3}}>Archive</div>
                        {this.state.loading['archive'] === true && <div style={{flex:1}}><MoonLoader size={17} color={"#ffffff"} loading={true}/></div>}
                    </button>
                    
                    <button onClick={() => { this.actionServer('restore') }} className={"FuturaH FS40 BRL btn btn-danger"} style={{display: 'flex', flexDirection: 'row', margin: "10px 0 0 10px", padding: "10px 30px"}}>
                        <div style={{flex:3}}>Restore</div>
                        {this.state.loading['restore'] === true && <div style={{flex:1}}><MoonLoader size={17} color={"#ffffff"} loading={true}/></div>}
                    </button>
                    
                    <button onClick={() => { this.actionServer('mail') }} className={"FuturaH FS40 BRL btn btn-danger"} style={{display: 'flex', flexDirection: 'row', margin: "10px 0 0 10px", padding: "10px 30px"}}>
                        <div style={{flex:3}}>Mail</div>
                        {this.state.loading['mail'] === true && <div style={{flex:1}}><MoonLoader size={17} color={"#ffffff"} loading={true}/></div>}
                    </button>
                </div>


                <div className="BRL Wide100 Padding10px" style={{margin:'30px 0 0 0'}}>
                    
                    <button onClick={() => { this.actionServer('clearRaw') }} className={"FuturaH FS40 BRL btn btn-primary"} style={{display: 'flex', flexDirection: 'row', margin: "10px 0 0 10px", padding: "10px 30px"}}>
                        <div style={{flex:3}}>Clear Raw</div>
                        {this.state.loading['clearRaw'] === true && <div style={{flex:1}}><MoonLoader size={17} color={"#ffffff"} loading={true}/></div>}
                    </button>

                    <button onClick={() => { this.actionServer('get_Events') }} className={"FuturaH FS40 BRL btn btn-primary"} style={{display: 'flex', flexDirection: 'row', margin: "10px 0 0 10px", padding: "10px 30px"}}>
                        <div style={{flex:3}}>Get Events</div>
                        {this.state.loading['get_Events'] === true && <div style={{flex:1}}><MoonLoader size={17} color={"#ffffff"} loading={true}/></div>}
                    </button>

                    <button onClick={() => { this.actionServer('filter_Events') }} className={"FuturaH FS40 BRL btn btn-primary"} style={{display: 'flex', flexDirection: 'row', margin: "10px 0 0 10px", padding: "10px 30px"}}>
                        <div style={{flex:3}}>Filter Events</div>
                        {this.state.loading['filter_Events'] === true && <div style={{flex:1}}><MoonLoader size={17} color={"#ffffff"} loading={true}/></div>}
                    </button>

                    <button onClick={() => { this.actionServer('create_Events') }} className={"FuturaH FS40 BRL btn btn-primary"} style={{display: 'flex', flexDirection: 'row', margin: "10px 0 0 10px", padding: "10px 30px"}}>
                        <div style={{flex:3}}>Create Events</div>
                        {this.state.loading['create_Events'] === true && <div style={{flex:1}}><MoonLoader size={17} color={"#ffffff"} loading={true}/></div>}
                    </button>

                    <button onClick={() => { this.actionServer('createArchive_Events') }} className={"FuturaH FS40 BRL btn btn-danger"} style={{display: 'flex', flexDirection: 'row', margin: "10px 0 0 10px", padding: "10px 30px"}}>
                        <div style={{flex:3}}>Create Events from Archive</div>
                        {this.state.loading['createArchive_Events'] === true && <div style={{flex:1}}><MoonLoader size={17} color={"#ffffff"} loading={true}/></div>}
                    </button>

                    <button onClick={() => { this.actionServer('getFilter_Events') }} className={"FuturaH FS40 BRL btn btn-danger"} style={{display: 'flex', flexDirection: 'row', margin: "10px 0 0 10px", padding: "10px 30px"}}>
                        <div style={{flex:3}}>Get Events and Filter</div>
                        {this.state.loading['getFilter_Events'] === true && <div style={{flex:1}}><MoonLoader size={17} color={"#ffffff"} loading={true}/></div>}
                    </button>


                    <button onClick={() => { this.actionServer('filterCreate_Events') }} className={"FuturaH FS40 BRL btn btn-danger"} style={{display: 'flex', flexDirection: 'row', margin: "10px 0 0 10px", padding: "10px 30px"}}>
                        <div style={{flex:3}}>Filter and Create Events</div>
                        {this.state.loading['filterCreate_Events'] === true && <div style={{flex:1}}><MoonLoader size={17} color={"#ffffff"} loading={true}/></div>}
                    </button>

                    <button onClick={() => { this.actionServer('getFilterCreate_Events') }} className={"FuturaH FS40 BRL btn btn-danger"} style={{display: 'flex', flexDirection: 'row', margin: "10px 0 0 10px", padding: "10px 30px"}}>
                        <div style={{flex:3}}>Get Events, Filter and Create</div>
                        {this.state.loading['getFilterCreate_Events'] === true && <div style={{flex:1}}><MoonLoader size={17} color={"#ffffff"} loading={true}/></div>}
                    </button>

                    <button onClick={() => { this.actionServer('latest_events') }} className={"FuturaH FS40 BRL btn btn-sucess"} style={{display: 'flex', flexDirection: 'row', margin: "10px 0 0 10px", padding: "10px 30px"}}>
                        <div style={{flex:3}}>Latest Events Added</div>
                        {this.state.loading['latest_events'] === true && <div style={{flex:1}}><MoonLoader size={17} color={"#ffffff"} loading={true}/></div>}
                    </button>

                </div>
            </div>
            
            
            {/* DETAILS ACTION */}
            {events &&  Object.keys(events).map((city,index)=>{

                    if (events[city]){

                        return(
                            <div key={city+"_latest"} className="BRL Wide100" style={{padding:20, marginTop: 20}}>
                                <div className="BRL Wide100" style={{padding:'7px 20px', borderRadius: '5px', marginTop: 20, backgroundColor: '#ad182f', color: 'white', fontFamily: 'FuturaH'}}>{city}</div>
                                <FlatList
                                    list={events[city]}
                                    renderItem={this.renderEvent}
                                    renderWhenEmpty={() => <div>List is empty!</div>}
                                     />
                            </div>
                        );
                }

            })}
        </div>
        );
    } 


    renderEvent = (event, idx) => {

        console.log('EVENT => ', event);
        return (
            <div key={"event_" + idx} className="BRL Wide100" style={{display:'flex', flexDirection:'column', padding:5}}>
                <div className="BRL Wide100" style={{flex:1, display:'flex', flexDirection:'row'}}>
                    <div className="FS38 FuturaM" style={{flex:2, flexDirection:'row', justifyContent:'center', alignItems:'center'}}>{event['id']} </div>
                    <div className="FS38 FuturaM" style={{flex:1, flexDirection:'row', justifyContent:'center', alignItems:'center'}}>{event['id_business']} </div>
                    <div className="FS38 FuturaM" style={{flex:4, flexDirection:'row', justifyContent:'center', alignItems:'center'}}>{event['title']} </div>
                    <div className="FS38 FuturaM" style={{flex:3, flexDirection:'row', justifyContent:'center', alignItems:'center'}}>{formatDate(Date.parse(event['date']), 'dd-mm-yyyyL')} </div>
                    {/* <div className="FS38 FuturaM" style={{flex:2, flexDirection:'row', justifyContent:'center', alignItems:'center'}}>{event['start']} </div> */}
                </div>
                <div className="BRL Wide100" style={{flex:1, display:'flex', flexDirection:'row'}}>
                    <div className="FS38 FuturaM" style={{fontSize:'0.8rem', fontFamily:'FuturaM', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>{decodeURIComponent(event['description'])} </div>
                </div>
                
            </div>
        );
    }
  
}

export default withRouter(Server);
