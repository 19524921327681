import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { styleMapping } from "../utils/tools";

class PersonalBusiness extends React.Component {
   
    // ############################ RENDER ###################################
    // ############################ RENDER ###################################
    render() {
        
        const { isAuthenticated, custom, link } = this.props;
        // console.log('PERSONAL BUSINESS : ', this.props);

        if(!isAuthenticated){
            return(
                <React.Fragment>
                {/* <div className={"BRL Wide100"}>
                        <Link to={link}>
                            {custom == null && <div className={"NoDecoration " + this.props.settings.personal.user.class} style={styleMapping(this.props.settings.personal.user)}>For Businesses</div>}
                            {custom != null && <div className={"NoDecoration " + custom.class} style={styleMapping(custom)}>For Businesses</div>}
                        </Link>
                    </div> */}
                    {custom == null && <Link to={link} className={"NoDecoration " + this.props.settings.personal.user.class} style={styleMapping(this.props.settings.personal.user)}>For Businesses</Link>}
                    {custom != null && <Link to={link} className={"NoDecoration " + custom.class} style={styleMapping(custom)}>For Businesses</Link>}
                </React.Fragment>
            );
        } else {
            
            if(this.props.user.isBusiness){
            
                return (
                // <div className={"BRL Wide100"}>
                <React.Fragment>
                    {custom == null && 
                    <div className={this.props.settings.personal.business.class} style={styleMapping(this.props.settings.personal.business)} id="dropdown02" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Business
                    </div> }
                    {custom != null && 
                    <div className={custom.class} style={styleMapping(custom)} id="dropdown02" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Business
                    </div> }
                    
                    <div className={" " + this.props.settings.personal.user_menu.class} style={styleMapping(this.props.settings.personal.user_menu)} aria-labelledby="dropdown02">
                        <Link className="BRL Wide100" to={link}>
                            <div className={this.props.settings.personal.user_menu_element.class} style={styleMapping(this.props.settings.personal.user_menu_element)}>
                                Presentation
                            </div> 
                        </Link>
                        
                        <Link className="BRL Wide100" to={`/business/dashboard`}>
                            <div className={this.props.settings.personal.user_menu_element.class} style={styleMapping(this.props.settings.personal.user_menu_element)}>
                                Dashboard
                            </div> 
                        </Link>
                        
                        <Link className="BRL Wide100" to={`/business/marketing`}>
                            <div className={this.props.settings.personal.user_menu_element.class} style={styleMapping(this.props.settings.personal.user_menu_element)}>
                                Marketing
                            </div> 
                        </Link>
                    </div>
                </React.Fragment>
                // </div>
                );
            } else {
                return (
                // <div className={"BRL Wide100"} >
                <React.Fragment>
                    {custom == null && <div className={this.props.settings.personal.business.class} style={styleMapping(this.props.settings.personal.business)} id="dropdown02" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Register Business</div> }
                    {custom != null && <div className={custom.class} style={styleMapping(custom)} id="dropdown02" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Register Business</div> }
                    
                    <div className={" " + this.props.settings.personal.user_menu.class} style={styleMapping(this.props.settings.personal.user_menu)} aria-labelledby="dropdown02">
                        <Link className="BRL Wide100" to={link}>
                            <div className={this.props.settings.personal.user_menu_element.class} style={styleMapping(this.props.settings.personal.user_menu_element)}>
                                Presentation
                            </div> 
                        </Link>
                        
                        <Link className="BRL Wide100" to={'/registerBusinessForm'}>
                            <div className={this.props.settings.personal.user_menu_element.class} style={styleMapping(this.props.settings.personal.user_menu_element)}>
                            Register Business
                            </div> 
                        </Link>
                        
                    </div>
                </React.Fragment>
                // </div>
                );              
            }
        }
    }
}

const mapstateToProps = (state) => {
    
    // console.log('REDUX PERSONAL : ', state);
    return { isAuthenticated: state.status.token !== null && state.status.token !== '' ? true : false, status:state.status, user:state.user, settings:state.settings, architecture:state.architecture};}
export default withRouter(connect(mapstateToProps, null)(PersonalBusiness));

