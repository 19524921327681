import React from "react";
import * as apiKeys from "../../resources/constants/api";
import ReactMapboxGl, {Layer, Feature} from 'react-mapbox-gl';

const MAPBOX_TOKEN = apiKeys.MAPBOX_TOKEN;
const Map = ReactMapboxGl({accessToken:MAPBOX_TOKEN, doubleClickZoom:false});

export default class MapBasic extends React.Component {

  render() {
    if(!isNaN(this.props.data[0]) && this.props.data[1] !=null ){ 
      
      // return null;
    this.allData = {region: this.props.data.reverse()};       
      return (
        <Map
        containerStyle={this.props.styleMap} style="mapbox://styles/mapbox/light-v9" movingMethod={"jumpTo"}
        center={this.allData.region} zoom = {[this.props.zoom]} minZoom = {[2]} maxZoom = {[19]}
        logoPosition={'top-right'}
        onStyleLoad={(c) => {
          this._map = c; 
          this._map.dragRotate.disable();
          this._map.touchZoomRotate.disableRotation();
          this._map.doubleClickZoom.enable();          
        }}>

        <Layer key={'lay-center'} type="symbol" layout={{ 'icon-image': 'marker-15', 'icon-size': 2 }} >
            <Feature key={'m_center'} coordinates={this.allData.region} className={"Hand"} onClick={() => {}} />
        </Layer>
      </Map>);
    }
    else return null;
  }

}
