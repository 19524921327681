import React, { Component } from 'react';
import { connect } from 'react-redux';

class ListItem extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            // elements : this.props.elements
        };
    }

    // ############################ RENDER ###################################
    // ############################ RENDER ###################################
    render() {
        const {place, index, selectedItem, custom} = this.props;
        return (
        <div className={"BRL Wide100 BottomShadow7 LabelFont btn " + custom +  (selectedItem === index ? " btn-primary " : " btn-light ")} onClick={() => this.elementSelected(place)}>
          {place.place_name}
        </div>
    );
  }


  elementSelected = (data) => {
    const temp ={center: data.center, place: data.place_name};
    // console.log('LIST ITEM => ', temp);
    this.props.onSelected(temp);
  }

}
export default connect(null, null)(ListItem);
