import Axios from 'axios';
import { getLocalData } from "../utils/tools";
import * as server from "../../resources/constants/api";
import { toast } from 'react-toastify';
const options = { autoClose: 1500, type: toast.TYPE.INFO, hideProgressBar: false };
// import * as settings from "../../resources/data/settings";

//########################### ARCHITECTURE ###################################
//########################### ARCHITECTURE ###################################
export async function getArchitecture(type, name) {

    // const token = localStorage.getItem('token');
    // let headers = {'headers': {'authorization': `Token  ${token}`}}
    // if (token === '' || token === null){headers = {};}
    let headers = {}
    const url = server.URL_SERVER_DJANGO+'/useradmin/architecture';
    return await Axios.get(url, headers).then(res => {
        const temp = JSON.parse(res.data.results);
        // const temp = res.data.results;
        return{'results': temp };		
    }).catch(err => console.log(err));
};

export async function setArchitecture(data) {
    
    const token = await getLocalData('token');
    let headers = {'headers': {'authorization': `Token ${token}`}}
    if (token !== ''){
        data = {'data': data};
        let url = server.URL_SERVER_DJANGO+'/useradmin/architecture';
        return Axios.post(url, data, headers).then(async res => {return true;}).catch(err => {return err;});
    }
};


//########################### STRUCTURE ###################################
//########################### STRUCTURE ###################################
export async function getStructureCatalog() {

    const token = localStorage.getItem('token');
    let headers = {'headers': {'authorization': `Token  ${token}`}}
    if (token === '' || token === null){headers = {};}
    const url = server.URL_SERVER_DJANGO+'/useradmin/structure/admin/';
    
    return await Axios.get(url, headers).then(res => {
        const temp = res.data.results;
        return{'results': temp };		
    }).catch(err => console.log(err));
};


export async function structureAction(type, action, structures, reference) {
    const token = localStorage.getItem('token');
    let headers = {'headers': {'authorization': `Token  ${token}`}}
    if (token === '' || token === null){headers = {};}
    
    let data = {'data': structures, 'reference': reference};    
    // if(action === 'remove'){data = {'data': reference};} //DELETING STRUCTURE DETAILS
    
    const url = server.URL_SERVER_DJANGO+'/useradmin/structure/admin/';
    // console.log('STRUCTUREACTION => ', data);
    
    switch(type){
        case 'meta':
            // console.log('structureAction META 1 => ', data);
            return await Axios.post(url, data, headers).then(res => {
                const temp = res.data.results;
                // console.log('structureAction META 2 => ', temp);
                return{'results': temp};		
            }).catch(err => console.log(err));    
        
        case 'details':
            // console.log('structureAction DETAILS 1 => ', data);
            return await Axios.put(url, data, headers).then(res => {
                const temp = res.data.results;
                // console.log('structureAction DETAILS 2 => ', temp);
                return{'results': temp};		
            }).catch(err => console.log(err));    
        default:break        
    }
};


//########################### DESIGN ###################################
//########################### DESIGN ###################################
export async function getDesigns(type) {
    const token = localStorage.getItem('token');
    let headers = {'headers': {'authorization': `Token  ${token}`}}
    if (token === '' || token === null){headers = {};}
    
    const url = server.URL_SERVER_DJANGO+'/useradmin/design/admin/?type='+type;
    return await Axios.get(url, headers).then(res => {
        const temp = res.data.results;
        // console.log('EDITOR GETDESINGS AAA =>  ===> ', typeof temp);
        return{'results': temp };		
    }).catch(err => console.log(err));
};

export async function designAction(designs) {
    const token = localStorage.getItem('token');
    let headers = {'headers': {'authorization': `Token  ${token}`}}
    if (token === '' || token === null){headers = {};}
    const data = {'data': designs};    
    const url = server.URL_SERVER_DJANGO+'/useradmin/design/admin/';
    // console.log('DESIGNACTION => ', data);
    
    return await Axios.post(url, data, headers).then(res => {
        const results = res.data;
        switch(results.status){
            case true: toast.success("design has been saved", options); break;
            default: break;}
        return results; 
    }).catch(err => {
        // console.log(err);
        switch(err){
            case true:  toast.error("design has not been saved", options); break;
            default: break;}
    });
};


export async function designImages(data2) {
    const token = localStorage.getItem('token');
    let headers = {'headers': {'authorization': `Token  ${token}`}}
    if (token === '' || token === null){headers = {};}
    const data = {'data': data2};    
    const url = server.URL_SERVER_DJANGO+'/useradmin/design/images/';
    //SAVES IMAGES
    // console.log('SAVES IMAGES 1 => ', data2);

    return await Axios.post(`${url}`, data, headers).then(res => {
        const results = res.data;
        // console.log('SAVES IMAGES 3 => ', results);
        switch(results.status){
            case true: toast.success("Images have been saved", options); break;
            default: break;}
        return results;
    }).catch(err => {
        // console.log(err);
        switch(err){
            case true: toast.error("design images have not been saved", options); break;
            default: break;}
    });

};


export async function setStructureCatalog(struct) {
    const token = localStorage.getItem('token');
    let headers = {'headers': {'authorization': `Token  ${token}`}}
    if (token === '' || token === null){headers = {};}
    const data = {'data': struct};    
    const url = server.URL_SERVER_DJANGO+'/useradmin/structure/admin/';
    return await Axios.post(url, data, headers).then(res => {
        const temp = res.data.results;
        return{'results': temp };		
    }).catch(err => console.log(err));
};

//########################### TOOLS ###################################
//########################### TOOLS ###################################
export function editorMenuPosition( data, action) {

    const temp = data.splice(action.from, 1)[0];
    data.splice(action.to, 0, temp);

    return data;
};


export function extractImages(tag, structure) {

    let results = [], tempUrls;
    // console.log('EXT IMA=> ', tag, ' => ', structure.structure);

    switch(tag){
        case 'C-WINDOW': tempUrls = structure.structure.image.image.url; break;
        case 'C-VIDEO': tempUrls = structure.structure.video.video.url; break;
        case 'C-IMAGE': tempUrls = structure.structure.carousel; break;
        case 'C-CUSTOM': tempUrls = structure.structure.image; break;
        default: break;
    }

    switch(tag){
        
        case 'C-IMAGE': 
            for (const url in tempUrls){
                const el = {url: server.URL_SERVER_DJANGO_IMAGE + tempUrls[url]['image']['url'], action: '', path: '', pathAbsolute: ''};
                // console.log('EXT CIMAGE=> ', tag, ' => ', el);
                results.push(el);
            }
        break;
        default: 
            for (const url in tempUrls){
                const el = {url: server.URL_SERVER_DJANGO_VIDEO + tempUrls[url]['url'], action: '', path: '', pathAbsolute: ''};
                results.push(el);
            }    
        break;
    }
    
    return results;
};

