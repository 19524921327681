import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/pseudo/general';
import { styleMapping } from "../utils/tools";

class Personal extends React.Component {
    
    //############################ CONSTRUCTOR ###################################
	//############################ CONSTRUCTOR ###################################
	constructor(props) {
        super(props);
        this.state={personal: null};
    }  

    async componentDidMount(){
        if(this.props.settingsManual){
            const temp = JSON.parse(this.props.settingsManual);
            this.setState({personal: temp});
        } 
        else {this.setState({personal: this.props.settings.personal});}
    }
    
    UNSAFE_componentWillReceiveProps(props){
        // console.log('PERSONAL CWRP => ', props.settingsManual);
        if(props.settingsManual){            
            const temp = JSON.parse(props.settingsManual);
            this.setState({personal: temp});
        }
    }

    
    // ############################ RENDER ###################################
    // ############################ RENDER ###################################
    render() {
        const { isAuthenticated, user, custom } = this.props;
        // console.log('PERSONAL => ', user);

        let personal;
        if(this.state.personal){ personal = this.state.personal; }
        else { personal = this.props.settings.personal; }
                        
        if(personal){
            if(!isAuthenticated){
                return (
                <React.Fragment>
                    {custom == null && <Link to="/login" className={personal.user.class} style={styleMapping(personal.user)}>Login</Link> }
                    {custom != null && <Link to="/login" className={custom.class} style={styleMapping(custom)}>Login</Link> }
                </React.Fragment>
            );
            } else {
                return (
                    // <React.Fragment>
                    <div className={"BRL Wide100"} >
                        {custom == null && <div className={personal.user.class} style={styleMapping(personal.user)} id="dropdown02" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{ this.props.user.email }</div> }
                        {custom != null && <div className={custom.class} style={styleMapping(custom)} id="dropdown02" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{ this.props.user.email }</div> }
                    
                        <div className={personal.user_menu.class} style={styleMapping(personal.user_menu)} aria-labelledby="dropdown02">
                            <Link className="BRL Wide100" to={`/profile/${this.props.user.idUser}`}>
                                <div className={personal.user_menu_element.class} style={styleMapping(personal.user_menu_element)} >Profile</div>
                            </Link> 
                            {user.isAdmin ?(<Link className="BRL Wide100" to="/monitor"><div className={this.props.settings.personal.user_menu_element.class} style={styleMapping(this.props.settings.personal.user_menu_element)}>Monitor</div></Link>):null}
                            {user.isAdmin ?(<Link className="BRL Wide100" to="/admin/dashboard/data"><div className={this.props.settings.personal.user_menu_element.class} style={styleMapping(this.props.settings.personal.user_menu_element)}>Data</div></Link>):null}
                            {user.isAdmin ?(<Link className="BRL Wide100" to="/admin"><div className={personal.user_menu_element.class} style={styleMapping(personal.user_menu_element)}>Admin</div></Link>):null}
                            {user.isAdmin ?(<Link className="BRL Wide100" to="/editor"><div className={personal.user_menu_element.class} style={styleMapping(personal.user_menu_element)}>Editor</div></Link>):null}
                            {user.isAdmin ?(<Link className="BRL Wide100" to="/server"><div className={personal.user_menu_element.class} style={styleMapping(personal.user_menu_element)}>Server</div></Link>):null}
                            <span className={personal.user_menu_element.class} style={styleMapping(personal.user_menu_element)} onClick={() => { this.props.logout(this.props.history) }} >Logout</span>
                        </div>
                    </div>
                    // {/* </React.Fragment> */}
                );                    
            }
        }
    }
}

const mapStateToProps = (state) => {return { isAuthenticated: state.status.token !== null && state.status.token !== '' ? true : false, status:state.status, user:state.user, settings:state.settings, architecture:state.architecture};}
const mapDispatchToProps = dispatch => { return { logout: (history) => dispatch(actions.authLogout(history))} }
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Personal));

