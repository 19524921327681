import *  as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import { } from '../../components/utils/tools';

const initialState = { 
    status:{ 
        token: '', 
        error: null, 
        loading: false
    }, 
    user:{ 
        idUser: null, 
        username: null, 
        email: null, 
        broadcast: null, 
        expirationDate: null, 
        isAdmin: null, 
        isBusiness: null, 
        geolocation: null
    }, 
    menu: {
        display: false, 
        direction:null, 
        icon:'bars'
    }, 
    sideMenu: false, 
    current: {direction: null},
    directory: [],
    profile: null, 
};

const authStart = (state, action) => {
    // console.log('REDUCER AUTHSTART 1: ', state);
    return updateObject(state, {
    status:{ 
        error: null,
        loading: true,
        token: null
        }
    });
}

const authSuccess = (state, action) => {
    // console.log('REDUCER AUTHSUCCESS 1: ', state, ' => ', action);
    state = updateObject(state, {
        status:{ 
            token: action.data.token,
            error: null,
            loading: false,
            },
        user:{ 
            idUser: action.data.idUser,
            username: action.data.username,
            email: action.data.email,
            isAdmin: action.data.is_staff,
            isBusiness: action.data.is_business,
            expirationDate: action.data.expirationDate 
        }
    });
    // console.log('REDUCER AUTH SUCCESS 2: ', state);
    return state;
}

const authFail = (state, action) => {

    // console.log('REDUCER AUTH FAIL: ', action);
    return updateObject(state, {
        status:{
            error: null,
            loading: false,
            token: ''
        }
    });
}

const authLogout = (state, action) => {
    return updateObject(state, {
        status:{ token: null },  
        user:{ idUser: null, username: null, email: null, broadcast: null, expirationDate: null, isAdmin: null, isBusiness: null}
    });
}

const broadcastPosition = (state, action) => {

    return updateObject(state, {
        broadcast: 
            {
                status: action.data.status,
                coordinates: action.data.coordinates
            }
    });
}

const navigate = (state, action) => {
    return updateObject(state, {
        current:action.data.direction
    })
}

const changeMenu = (state, action) => {
    return updateObject(state, {
        menu:{
            display: action.data.display,
            icon: action.data.icon,
            direction: action.data.direction
        }
    });
}
const isBusiness2 = (state, action) => {
    const user = updateObject(state.user, {isBusiness: action.data});
    // console.log('ISBUSINESS2 2 => ', user);
    return updateObject(state, {user: user});
}

const setSettings = (state, action) => {
    // console.log('REDUCER SETTINGS: ', action.data);
    return updateObject(state, {
        settings: action.data.settings,
        directory: action.data.directory,
        control: action.data.control,
        architecture: action.data.architecture,
        addresses: action.data.addresses,
        
    })
}

const sideMenu2 = (state, action) => {
    // console.log('REDUCER SIDEMENU: ', action.data);
    return updateObject(state, {
        sideMenu: action.data,
    })
}

const geolocation2 = (state, action) => {
    // console.log('REDUCER SIDEMENU: ', action.data);
    return updateObject(state, {
        user:{
            geolocation: {
                coordinates: action.data.coordinates,
                time: action.data.time,
            }
        }
    })
}

const setProfile = (state, action) => {

    // console.log('SETPROFILE : ', action);
    return updateObject(state, {
        profile: action.data,
    })
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.BROADCAST: return broadcastPosition(state, action);
        case actionTypes.NAVIGATE: return navigate(state, action);
        case actionTypes.CHANGE_MENU: return changeMenu(state, action);
        case actionTypes.SET_SETTINGS: return setSettings(state, action);
        case actionTypes.IS_BUSINESS: return isBusiness2(state, action);
        case actionTypes.SIDE_MENU: return sideMenu2(state, action);
        case actionTypes.GEOLOCATION: return geolocation2(state, action);
        case actionTypes.SET_PROFILE: return setProfile(state, action);
        default: return state;
    }
}

export default reducer;
