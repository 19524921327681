import React, { Component } from 'react'
import SwitchSelector from 'react-switch-selector';
import { } from "../utils/tools";

class Toggle extends Component {

    constructor(props) {
        super(props);    
        this.state = { structure: {}, settings: props.settings, value: false};
    }
    
    render(){

        const {data,design,...otherProps} = this.props;
        return (
                <SwitchSelector 
                    className="react-switch-selector-option react-switch-selector-option-label"
                    {...otherProps}
                    options={data}
                    onChange={value => {
                        this.props.onChange(value); 
                    }}
                    />
        );
    }

    
}
export default Toggle;
